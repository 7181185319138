import PrimarySchoolProfile from 'assets/images/PrimarySchoolProfile.svg';

import { Alert, Avatar, Backdrop, Box, CircularProgress, Stack, Typography, alpha, useTheme } from '@mui/material';

const ConfirmCreateClass = ({ selectedStudents, className, students, isUpdating }) => {
  const theme = useTheme();
  const borderColor = alpha(theme.palette.primary.dark, 0.4);
  const listItemBgColor = alpha(theme.palette.primary.dark, 0.1);

  const selectedStudentList = students.filter(({ id }) => selectedStudents.indexOf(id) > -1);
  const hasStudentsFromOtherClass = selectedStudentList.some(({ currentClass }) => currentClass.length > 1);

  const CircularBackdropProgress = () => (
    <Backdrop open={!!true} sx={{ position: 'absolute', zIndex: 5, height: '100%' }}>
      <CircularProgress />
    </Backdrop>
  );

  return (
    <Box pt={{ xs: 2, sm: 8 }} pb={{ xs: 2, sm: 5 }} px={{ xs: 0, sm: 6 }} sx={{ position: 'relative' }}>
      {isUpdating && <CircularBackdropProgress />}
      <Typography variant="h6" component="h2" sx={{ mb: 3, textAlign: 'center' }}>
        Please confirm class/students
      </Typography>
      <Typography variant="h6" component="h2" mb={1}>
        Class name
      </Typography>
      <Box border={1} p={1} mb={2} borderColor={borderColor} backgroundColor={listItemBgColor} borderRadius={2}>
        <Typography variant="body2">{className}</Typography>
      </Box>
      {selectedStudents.length > 0 && (
        <Typography variant="h6" component="h2" mb={1}>
          Students ({selectedStudents.length})
        </Typography>
      )}
      {hasStudentsFromOtherClass && (
        <Alert variant="outlined" severity="warning" sx={{ mb: 2 }}>
          <Typography variant="body2" component="span" color="text.primary">
            You have selected students which are in another class. These students will be removed from their current
            class.
          </Typography>
        </Alert>
      )}
      {selectedStudents.length > 0 && (
        <Box border={1} borderColor={borderColor} borderRadius={2} maxHeight={194} overflow="auto">
          {selectedStudentList.map(({ studentName, id, email, currentClass }) => (
            <Stack
              direction="row"
              alignItems="center"
              gap={2}
              key={id}
              sx={{
                py: 0.5,
                px: 1,
                width: { xs: 'max-content', smm: 'auto' },
                '&:nth-of-type(odd)': { backgroundColor: listItemBgColor },
              }}
            >
              <Avatar sx={{ width: 32, height: 32 }} src={PrimarySchoolProfile} alt={studentName} />
              <Box sx={{ width: 200 }}>
                <Typography variant="body2">{studentName}</Typography>
              </Box>
              <Box sx={{ width: 330 }}>
                <Typography variant="body2">{email}</Typography>
              </Box>
              {currentClass.length <= 1 ? null : (
                <Box sx={{ width: 220 }}>
                  <Typography variant="body2" color="warning.main">
                    This will remove student from
                  </Typography>
                  <Typography variant="body2" color="warning.main">
                    {currentClass}
                  </Typography>
                </Box>
              )}
            </Stack>
          ))}
        </Box>
      )}
    </Box>
  );
};

export default ConfirmCreateClass;
