import { Typography } from '@mui/material';

import { CircularCourseProgress, CourseProgressIndicatorContainer, CourseProgressLabelContainer } from '../styles';

const CourseProgressIndicator = ({ value = 0 }) => (
  <CourseProgressIndicatorContainer>
    <CircularCourseProgress track variant="determinate" size={272} thickness={1} value={50} />
    <CircularCourseProgress variant="determinate" size={272} thickness={1} value={value / 2} />

    <CourseProgressLabelContainer gap={1} justifyContent="center" alignItems="center">
      <Typography variant="h4">{value}%</Typography>
      <Typography variant="body2" sx={{ whiteSpace: 'nowrap' }}>
        Programme Progress
      </Typography>
    </CourseProgressLabelContainer>
  </CourseProgressIndicatorContainer>
);

export default CourseProgressIndicator;
