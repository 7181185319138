import { useGetBoosterBadgesQuery, useGetProgramBadgesQuery } from 'badges/badgesApi';
import PageIntro from 'components/PageIntro';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { PAGEINTROTEXT, PAGE_TITLE, PALTFORM_NAME } from 'utils/values';

import { Box, CircularProgress, Grid, Stack, Tab, Tabs, Typography } from '@mui/material';

import Container from '../components/Container';
import BoosterBadgeCard from './components/BoosterBadgeCard';
import ProgramBadgeCard from './components/ProgramBadgeCard';
import { BadgeCards } from './styles';

function TabPanel(props) {
  const { children, value, index } = props;

  return <>{value === index && <>{children}</>}</>;
}

const Badges = () => {
  const [value, setValue] = useState('0');
  const {
    data: programBadges,
    isLoading: isLoadingProgramBadges,
    isSuccess: isSuccessProgramBadges,
  } = useGetProgramBadgesQuery();

  const {
    data: boosterBadgesTypes,
    isLoading: isLoadingBoosterBadgesTypes,
    isSuccess: isSuccessBoosterBadgesTypes,
  } = useGetBoosterBadgesQuery();

  const [leftColTypes, setLeftColTypes] = useState([]);
  const [rightColTypes, setRightColTypes] = useState([]);

  useEffect(() => {
    if (boosterBadgesTypes?.length > 0) {
      const leftTypes = boosterBadgesTypes.filter((badge, i) => (i + 1) % 2 !== 0);
      const rightTypes = boosterBadgesTypes.filter((badge, i) => (i + 1) % 2 === 0);
      setLeftColTypes(leftTypes);
      setRightColTypes(rightTypes);
    }
  }, [boosterBadgesTypes]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Container>
      <Helmet>
        <title>{`${PALTFORM_NAME} | ${value === '1' ? PAGE_TITLE?.BOOSTER_BADGES : PAGE_TITLE?.COURSE_BADGES}`}</title>
      </Helmet>
      <Grid container justifyContent="space-between" sx={{ mt: 3, paddingLeft: '16px' }}>
        <Grid item>
          <Typography component="h1" variant="h5">
            Badges
          </Typography>
        </Grid>
        <Grid item>
          <PageIntro introText={value === '0' ? PAGEINTROTEXT.COURSE_BADGE : PAGEINTROTEXT.BOOSTER_BADGE} />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Box sx={{ mb: 3, borderBottom: 2, borderColor: 'rgb(255, 255, 255, .14)' }}>
          <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" variant="fullWidth">
            <Tab label="Course Badges" value="0" />
            <Tab label="Booster Badges" value="1" />
          </Tabs>
        </Box>
      </Grid>
      <TabPanel value={value} index="0">
        <Grid item xs={12}>
          {isLoadingProgramBadges && (
            <Stack alignItems="center" p={3}>
              <CircularProgress />
            </Stack>
          )}

          {programBadges && isSuccessProgramBadges && (
            <BadgeCards>
              {programBadges?.results.map((program) => (
                <ProgramBadgeCard key={program.slug} {...program} />
              ))}
            </BadgeCards>
          )}

          {!programBadges?.results?.length && isSuccessProgramBadges && (
            <Typography variant="h4" textAlign="center">
              No Results Found!
            </Typography>
          )}
        </Grid>
      </TabPanel>
      <TabPanel value={value} index="1">
        <Grid item xs={12}>
          {isLoadingBoosterBadgesTypes && (
            <Stack alignItems="center" p={3}>
              <CircularProgress />
            </Stack>
          )}

          {!boosterBadgesTypes?.length && isSuccessBoosterBadgesTypes && (
            <Typography variant="h4" textAlign="center">
              No Results Found!
            </Typography>
          )}
        </Grid>
        <Grid container spacing={2} direction="row" sx={{ padding: '0', margin: '0 auto' }}>
          <Grid item xs={12} sm={6}>
            <BadgeCards>
              <Grid container direction="column">
                {leftColTypes?.map((skill) => (
                  <Grid item key={skill.id}>
                    <BoosterBadgeCard
                      skillName={skill.name}
                      backgroundImage={skill.imageUrl}
                      badges={skill.boosterBadges}
                    />
                  </Grid>
                ))}
              </Grid>
            </BadgeCards>
          </Grid>
          <Grid item xs={12} sm={6}>
            <BadgeCards>
              <Grid container direction="column">
                {rightColTypes?.map((skill) => (
                  <Grid item key={skill.id}>
                    <BoosterBadgeCard
                      skillName={skill.name}
                      backgroundImage={skill.imageUrl}
                      badges={skill.boosterBadges}
                    />
                  </Grid>
                ))}
              </Grid>
            </BadgeCards>
          </Grid>
        </Grid>
      </TabPanel>
    </Container>
  );
};

export default Badges;
