import { useGetSingleStudentReflectionQuery } from 'classes/classApi';
import BackdropLinearProgress from 'components/BackdropLinearProgress';
import { useEffect } from 'react';

import { Card, Grid, darken, useTheme } from '@mui/material';

import ReflectionCard from './ReflectionCard';
import SinlgeInterroChart from './SingleInterroChart';
import SinlgeLikertChart from './SingleLikertChart';

const SingleStudentReflections = ({ programId, userId, setRefetchFuncRef }) => {
  const theme = useTheme();
  const { data, isFetching, isSuccess, refetch } = useGetSingleStudentReflectionQuery({ programId, userId });
  useEffect(() => {
    setRefetchFuncRef(() => refetch);
  }, [setRefetchFuncRef, refetch]);

  const likert = {
    titles: [],
    xLabels: [],
    data: {
      labels: [],
      datasets: [
        {
          label: 'Start of the course',
          data: [],
          backgroundColor: '#ed207c',
          borderRadius: 5,
        },
        {
          label: 'End of the course',
          data: [],
          backgroundColor: '#5863f8',
          borderRadius: 5,
        },
      ],
    },
  };

  const nuanceInterrogation = {
    titles: [],
    xLabels: [{ text: '', points: 0 }],
    data: {
      labels: [],
      datasets: [
        {
          label: 'Nuanced Interrogration',
          data: [],
          backgroundColor: '#5863f8',
          borderRadius: 5,
        },
      ],
    },
  };

  const prepareLikerts = (lables, index) => {
    lables.forEach((intro) => {
      if (likert.xLabels.length === 0) {
        likert.xLabels = intro?.choices.map(({ text }) => text);
      }
      if (index === 0) {
        likert.titles.push({ skill: intro?.skill, title: intro?.question });
      }
      if (likert.data.labels.indexOf(intro.skill) === -1) {
        likert.data.labels.push(intro.skill);
      }
      if (intro.point !== 0) {
        const { point } = intro;
        const val = point === 1 || point === 2 ? point - 3 : point - 2;
        likert.data.datasets[index].data.push(val);
      }
    });
  };

  const prepareIntros = (lables) => {
    prepareLikerts(lables, 0);
  };
  const prepareOutros = (lables) => {
    prepareLikerts(lables, 1);
  };

  const prepareNuanceInterrogation = (interros) => {
    interros.forEach((interro) => {
      nuanceInterrogation.titles.push({ skill: interro?.skill, title: interro?.question });
      nuanceInterrogation.data.labels.push(interro?.skill);
      nuanceInterrogation.data.datasets[0].data.push(interro?.point);
      if (nuanceInterrogation.xLabels.length < 2) {
        nuanceInterrogation.xLabels = [...nuanceInterrogation.xLabels, ...interro?.choices];
      }
    });
  };

  if (isSuccess) {
    if (data?.intros) {
      prepareIntros(data?.intros);
    }
    if (data?.outros) {
      prepareOutros(data?.outros);
    }
    prepareNuanceInterrogation(data?.nuanceInterrogation);
  }

  return (
    <>
      {isFetching && <BackdropLinearProgress />}
      <Card elevation={4} sx={{ mb: 3 }}>
        <Grid container spacing={3}>
          <Grid
            item
            xs={12}
            sx={{
              mx: 'auto',
              borderBottom: `1px solid ${darken(theme.palette.primary.dark, 0.3)}`,
            }}
          >
            <ReflectionCard questionTitles={likert.titles} student={userId}>
              <SinlgeLikertChart xLabels={likert.xLabels} data={likert.data} />
            </ReflectionCard>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <ReflectionCard questionTitles={nuanceInterrogation.titles} student={userId}>
              <SinlgeInterroChart xLabels={nuanceInterrogation.xLabels} data={nuanceInterrogation.data} />
            </ReflectionCard>
          </Grid>
        </Grid>
      </Card>
    </>
  );
};

export default SingleStudentReflections;
