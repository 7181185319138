import { Navigate } from 'react-router-dom';
import { useGetUserInfoQuery } from 'users/userApi';
import { ROUTES } from 'utils/values';

const RestrictPrimaryStudent = ({ children }) => {
  const { data: user } = useGetUserInfoQuery();

  if (user?.isStudent && user?.belongsToPrimary) {
    return <Navigate to={ROUTES.ROOT} />;
  }

  return children;
};

export default RestrictPrimaryStudent;
