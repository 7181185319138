const LMS_BASE_URL = process.env.REACT_APP_LMS_BASE_URL;
const CMS_BASE_URL = process.env.REACT_APP_CMS_BASE_URL;
const GENZ_BASE_URL = process.env.REACT_APP_GENZ_BASE_URL;
const GENZ_API_URL = 'genplus/api/v1';
const GENZ_LEARNING_API_URL = 'genplus/learning/api/v1';
const GENZ_ASSESSMENT_API_URL = 'genplus/assessment/api/v1';
const GENZ_ASSESSMENT_URL = `${LMS_BASE_URL}genplus/assessment`;
const GENZ_BADGES_API_URL = 'genplus/badges/api/v1';
const GENZ_TEACH_API_URL = `${LMS_BASE_URL}genplus/teach/api/v1/`;
const LMS_LOGIN = `${LMS_BASE_URL}login`;
const LMS_LOGOUT = `${LMS_BASE_URL}logout`;
const GENZ_JOURNAL_DOWNLOAD_PDF_URL = `${GENZ_ASSESSMENT_URL}/download-report`;
const SECONDARY_GETTING_START = '/supporting-materials/19';
const PRIMARY_GETTING_START = '/supporting-materials/18';

export {
  LMS_BASE_URL,
  CMS_BASE_URL,
  GENZ_BASE_URL,
  GENZ_API_URL,
  GENZ_LEARNING_API_URL,
  GENZ_ASSESSMENT_API_URL,
  GENZ_ASSESSMENT_URL,
  GENZ_TEACH_API_URL,
  LMS_LOGIN,
  LMS_LOGOUT,
  GENZ_BADGES_API_URL,
  GENZ_JOURNAL_DOWNLOAD_PDF_URL,
  SECONDARY_GETTING_START,
  PRIMARY_GETTING_START,
};
