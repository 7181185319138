import { useState } from 'react';
import { notifyError, notifySuccess } from 'utils/notification';

import { Box, Button, Dialog, DialogActions, DialogContent, TextField, Typography } from '@mui/material';

import { useRenameClassMutation } from '../classApi';

const RenameClass = ({ open, handleClose, classId }) => {
  const [className, setClassName] = useState('');
  const [classNameError, setClassNameError] = useState('');

  const [renameClass, { isLoading }] = useRenameClassMutation();

  const handleclassNameChagne = (event) => {
    setClassName(event.target.value);
    setClassNameError('');
  };

  const handleClassNamePopUpClose = () => {
    setClassName('');
    handleClose(null);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const formJson = Object.fromEntries(formData.entries());
    const { classNameValue } = formJson;

    await renameClass({ name: classNameValue, classId })
      .unwrap()
      .then(() => {
        notifySuccess('Class Renamed Successfully!');
        handleClassNamePopUpClose();
      })
      .catch(({ data, status }) => {
        if (status === 400) {
          setClassNameError(data.message);
        } else {
          notifyError('Something went wrong. Please try again later!');
        }
      });
    return null;
  };

  return (
    <Dialog
      aria-labelledby="Rename class modal"
      aria-describedby="A modal window for renaming class"
      open={open}
      fullWidth
      sx={{ textAlign: 'center' }}
      maxWidth="sm"
      PaperProps={{
        elevation: 4,
        component: 'form',
        onSubmit: handleSubmit,
      }}
    >
      <DialogContent>
        <Typography variant="h6" component="h2" sx={{ mb: 1, mt: 2, textAlign: 'center' }}>
          Give your class a unique name
        </Typography>
        <Typography variant="caption" component="p" sx={{ mb: 1, textAlign: 'center' }}>
          Eg. P7A - 2023/24
        </Typography>
        <Box sx={{ display: 'flex', alignItems: 'flex-start', py: 3 }}>
          <TextField
            size="small"
            value={className}
            onChange={handleclassNameChagne}
            autoFocus
            error={classNameError.length > 0}
            id="className"
            name="classNameValue"
            label="Class Name"
            type="text"
            helperText={classNameError}
            fullWidth
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button disabled={className.length === 0 || isLoading} type="submit" variant="contained">
          Rename
        </Button>
        <Button onClick={handleClassNamePopUpClose}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
};

export default RenameClass;
