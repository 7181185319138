import { Box, styled } from '@mui/material';

export const Badge = styled('img')`
  position: relative;
  max-width: 120px;
`;

export const BadgeContainer = styled(Box)`
  transition: all 0.4s;

  &:after {
    content: '';
    z-index: 1;
    width: 0;
    height: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    background-image: radial-gradient(50% 50% at 50% 50%, #feffe2 0%, rgba(254, 255, 226, 0) 100%);
    opacity: 0.4;
    transition: all 0.4s;
  }

  &:hover {
    transform: scale(1.3);

    &:after {
      width: 10rem;
      height: 10rem;
    }
  }
`;
