import { nanoid } from '@reduxjs/toolkit';
import { toWords } from 'number-to-words';
import { useState } from 'react';

import CheckIcon from '@mui/icons-material/Check';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import {
  Box,
  Button,
  Dialog,
  Grid,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import PieChart from 'components/charts/PieChart';
import { StyledCount } from 'classes/classDashboard/styles';
import NoAnswerMessage from 'classes/classDashboard/components/studentAnswers/NoAnswerMessage';

import AnswersCard from './AnswersCard';
import ChoicesModal from './ChoicesModal';

const AggregateMultipleChoices = ({ problem, index, ...rest }) => {
  const theme = useTheme();
  const isScreenLtSm = useMediaQuery(theme.breakpoints.down('sm'));
  const buttonSize = () => (isScreenLtSm ? 'small' : 'medium');
  const backgroundColor = [
    'rgba(233, 30, 99, 1)',
    'rgba(103, 58, 183, 1)',
    'rgba(0, 150, 136, 1)',
    'rgba(0, 188, 212, 1)',
    'rgba(0, 125, 255, 1)',
    'rgb(0,255,72)',
    'rgb(255,136,0)',
  ];
  const answerExists = problem?.results.length > 0;
  const data = {
    labels: problem?.results.map((response) => response.title),
    datasets: [
      {
        label: 'Answers',
        data: problem?.results.map((response) => response.count),
        backgroundColor,
        borderColor: '#000',
        borderWidth: 1,
      },
    ],
  };
  const [openViewAnswers, setOpenViewAnswers] = useState(false);
  const handleCloseViewAnswers = () => setOpenViewAnswers(false);
  const handleOpenViewAnswers = () => setOpenViewAnswers(true);

  const rows = [];

  for (let i = 0; i < problem?.results?.length; i++) {
    const response = problem?.results[i];

    rows.push(
      <TableRow key={nanoid()}>
        <TableCell sx={{ border: 0, verticalAlign: 'top', px: 0 }}>
          <FiberManualRecordIcon sx={{ color: backgroundColor[i] }} />
        </TableCell>
        <TableCell sx={{ width: '80%', border: 0, verticalAlign: 'top' }}>{response.title}</TableCell>
        <TableCell sx={{ border: 0, verticalAlign: 'top' }}>{response.count}</TableCell>
        <TableCell sx={{ border: 0, verticalAlign: 'top' }}>
          {response.isCorrect ? <CheckIcon color="primary" /> : ''}
        </TableCell>
      </TableRow>,
    );
  }

  return (
    <AnswersCard
      key={problem?.problemId}
      index={index}
      title={problem?.questionText}
      studentsCount={problem?.studentsCount}
      studentsAnsweredCount={problem?.studentsAnsweredCount}
    >
      <Grid container spacing={2} pt={4}>
        {answerExists ? (
          <>
            <Grid item xs={12} sm={4} order={{ sm: 2 }}>
              <Box sx={{ maxWidth: 160, margin: '0 auto' }}>
                <PieChart data={data} />
              </Box>
            </Grid>
            <Grid item xs={12} sm={8} order={{ sm: 1 }}>
              <TableContainer sx={{ pb: 3 }}>
                <Table aria-label="Question" size="small" sx={{ minWidth: 400 }}>
                  <TableBody>{rows}</TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </>
        ) : (
          <Grid item xs={12}>
            <NoAnswerMessage />
          </Grid>
        )}
        {answerExists && (
          <>
            <Grid item xs={12} order={{ sm: 3 }}>
              <Button variant="text" sx={{ textDecoration: 'underline' }} onClick={handleOpenViewAnswers}>
                View All Answers
              </Button>
            </Grid>
            <Dialog
              aria-labelledby="Student Answers modal"
              aria-describedby="A modal window for Student Answers"
              open={openViewAnswers}
              onClose={handleCloseViewAnswers}
              fullWidth
              maxWidth="md"
              fullScreen={isScreenLtSm}
              PaperProps={{ elevation: 4 }}
            >
              <Box mt={{ xs: 1, sm: 3 }} mb={{ xs: 2, sm: 5 }} px={{ xs: 0, sm: 6 }}>
                <Box sx={{ pl: 4, position: 'relative' }}>
                  <StyledCount variant="body1" component="span">
                    {index}.
                  </StyledCount>
                  <Box sx={{ flexGrow: 1 }}>
                    <Typography variant="body1">{problem?.questionText}</Typography>
                    <Typography variant="body2" sx={{ opacity: 0.7 }}>
                      Select {toWords(problem?.selection)} answers
                    </Typography>
                  </Box>
                </Box>
                <Box>
                  <ChoicesModal problemKey={problem?.problemKey} {...rest} />
                </Box>
              </Box>
              <Stack direction="row" justifyContent="space-between" px={{ xs: 0, sm: 6 }} pb={6}>
                <Button onClick={handleCloseViewAnswers} variant="outlined" size={buttonSize()}>
                  Close
                </Button>
              </Stack>
            </Dialog>
          </>
        )}
      </Grid>
    </AnswersCard>
  );
};

export default AggregateMultipleChoices;
