import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  ListItemText,
  MenuItem,
  Stack,
  TextField,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useState } from 'react';
import { notifyError, notifySuccess } from 'utils/notification';

import { useCreatePortfolioMutation } from '../teachApi';

const AddPortfolio = ({ isOpen, handleClose, skills, gtcss }) => {
  const [thoughts, setThoughts] = useState('');
  const [title, setTitle] = useState('');
  const [skill, setSkill] = useState('');
  const [gtcs, setGtcs] = useState([]);

  const theme = useTheme();
  const isScreenLtSm = useMediaQuery(theme.breakpoints.down('xss'));

  const [createPortfolio, { isLoading }] = useCreatePortfolioMutation();

  const handleAddPortfolio = async () => {
    const data = {
      description: thoughts,
      title,
      skill,
      gtcs,
    };

    await createPortfolio({ data })
      .unwrap()
      .then((res) => {
        notifySuccess(res);
        setThoughts('');
        setTitle('');
        setSkill('');
        setGtcs([]);
      })
      .catch((err) => {
        notifyError(`Bad request ${err.status}`);
      });

    handleClose();
  };

  const handleCloseWrapper = () => {
    setThoughts('');
    setTitle('');
    setSkill('');
    setGtcs([]);

    handleClose();
  };

  return (
    <Dialog open={isOpen} onClose={handleClose} maxWidth="sm" fullWidth PaperProps={{ elevation: 3 }}>
      <DialogContent sx={{ pb: 2 }}>
        <Stack mt={0.5} gap={1.5}>
          <TextField
            id="journal-feedback-title"
            label="Title"
            size="small"
            variant="outlined"
            fullWidth
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            required
          />

          <TextField
            select
            id="portfolio-skill"
            label="Skill"
            size="small"
            variant="outlined"
            fullWidth
            value={skill}
            onChange={(e) => setSkill(e.target.value)}
          >
            {skills?.length ? (
              skills.map((s) => (
                <MenuItem key={s.id} value={s.id}>
                  {s.name}
                </MenuItem>
              ))
            ) : (
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
            )}
          </TextField>

          <TextField
            select
            SelectProps={{
              multiple: true,
              renderValue: (selected) => selected.join(', '),
            }}
            id="portfolio-gtcs"
            label="GTCS Standard"
            size="small"
            variant="outlined"
            fullWidth
            value={gtcs}
            onChange={(e) => setGtcs(e.target.value)}
          >
            {gtcss?.length ? (
              gtcss.map((g) => (
                <MenuItem sx={{ whiteSpace: 'normal' }} key={g.id} value={g.id}>
                  <Checkbox checked={gtcs.indexOf(g.id) > -1} />
                  <ListItemText primary={`${g.id}: ${g.name}`} />
                </MenuItem>
              ))
            ) : (
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
            )}
          </TextField>

          <TextField
            id="portfolio-thoughts-field"
            multiline
            fullWidth
            rows={isScreenLtSm ? 8 : 4}
            placeholder="What are you thinking about?"
            value={thoughts}
            onChange={(e) => setThoughts(e.target.value)}
            required
          />
        </Stack>
      </DialogContent>

      <DialogActions sx={{ justifyContent: 'flex-start', px: 3, pb: 2, pt: 0 }}>
        <Button
          variant="contained"
          size="large"
          onClick={handleAddPortfolio}
          disabled={!title || !thoughts || isLoading}
        >
          Save
        </Button>
        <Button variant="outlined" size="large" onClick={handleCloseWrapper}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default AddPortfolio;
