import { BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip } from 'chart.js';
import { Bar } from 'react-chartjs-2';

import useMediaQuery from '@mui/material/useMediaQuery';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const SinlgeInterroChart = ({ data, xLabels }) => {
  const isMobile = useMediaQuery('(max-width:767px)');
  const options = {
    indexAxis: 'y',
    barThickness: 20,
    elements: {
      bar: {
        inflateAmount: 0,
      },
    },
    plugins: {
      title: {
        display: false,
      },
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        grid: {
          color: 'transparent',
          borderColor: 'rgba(99, 217, 255, 0.5)',
        },
        ticks: {
          font: {
            size: 13,
            weight: 300,
            family: 'IBM Plex Sans, Readex Pro',
          },
          stepSize: 1,
          color: '#defffd',
          callback: (_, index) => xLabels[index].text,
        },
        min: 0,
        max: 4,
      },
      y: {
        grid: {
          color: 'transparent',
          borderColor: 'rgba(99, 217, 255, 0.5)',
        },
        ticks: {
          font: {
            size: 12,
            weight: 300,
            family: 'IBM Plex Sans, Readex Pro',
          },
          color: '#defffd',
        },
      },
    },
  };

  return <Bar options={options} data={data} height={isMobile ? 100 : 50} />;
};

export default SinlgeInterroChart;
