import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import {
  Box,
  Breadcrumbs,
  Button,
  Card,
  CardActions,
  CardContent,
  Checkbox,
  Container,
  Grid,
  Link,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@mui/material';
import { useState } from 'react';

import MainContainer from '../components/Container';

function MuiComponents() {
  const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" href="/">
      Classes
    </Link>,
    <Link underline="hover" key="2" color="inherit" href="/material-ui">
      Core
    </Link>,
    <Typography key="3" color="text.primary">
      Breadcrumb
    </Typography>,
  ];

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
      </div>
    );
  }
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <MainContainer sx={{ pt: 5 }}>
        <Grid item xs={12}>
          <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
            {breadcrumbs}
          </Breadcrumbs>
        </Grid>
      </MainContainer>
      <MainContainer sx={{ pt: 5 }}>
        <Grid item xs={12}>
          <Checkbox defaultChecked />
          <Checkbox />
          <Checkbox disabled checked />
          <Checkbox defaultChecked color="secondary" />
          <Checkbox defaultChecked color="success" />
          <Checkbox defaultChecked color="default" />
        </Grid>
      </MainContainer>
      <MainContainer sx={{ pt: 5 }}>
        <Grid item xs={12}>
          <Typography variant="h1">Heading 1</Typography>
          <Typography variant="h2">Heading 2</Typography>
          <Typography variant="h3">Heading 3</Typography>
          <Typography variant="h4">Heading 4</Typography>
          <Typography variant="h5">Heading 5</Typography>
          <Typography paragraph>Lorem Ipsum</Typography>
          <Typography paragraph>Lorem Ipsum</Typography>
        </Grid>
      </MainContainer>
      <Container fixed>Fixed Container</Container>
      <MainContainer maxWidth="none" sx={{ pb: 3 }}>
        <Grid item xs={12}>
          Full width Container
        </Grid>
      </MainContainer>
      <MainContainer sx={{ pb: 3 }}>
        <Grid item xs={12}>
          MainContainer
        </Grid>
      </MainContainer>
      <MainContainer sx={{ pb: 3 }}>
        <Grid item xs={12} sm={4}>
          <TextField label="Outlined" fullWidth variant="outlined" />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField label="Outlined" fullWidth variant="outlined" defaultValue="Normal" />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField label="Outlined" fullWidth variant="outlined" />
        </Grid>
      </MainContainer>
      <MainContainer sx={{ pb: 3 }}>
        <Grid item xs={12} sm={4}>
          <TextField label="Outlined" size="small" fullWidth variant="outlined" />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField label="Outlined" size="small" fullWidth variant="outlined" defaultValue="Normal" />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField label="Outlined" size="small" fullWidth variant="outlined" />
        </Grid>
      </MainContainer>
      <MainContainer sx={{ pb: 3 }}>
        <Grid item xs={7}>
          <TextField label="Multiline" multiline fullWidth rows={4} defaultValue="Default Value" />
        </Grid>
        <Grid item xs={5}>
          <TextField label="Multiline" multiline fullWidth rows={4} />
        </Grid>
      </MainContainer>
      <MainContainer sx={{ pb: 3 }}>
        <Grid item xs={12}>
          <Card>
            <CardContent>This is card</CardContent>
            <CardActions>
              <Button variant="contained" fullWidth>
                Contained
              </Button>
            </CardActions>
          </Card>
        </Grid>
      </MainContainer>
      <MainContainer sx={{ pb: 3 }}>
        <Grid item xs>
          <Button variant="text" fullWidth>
            Text
          </Button>
        </Grid>
        <Grid item xs>
          <Button variant="contained" fullWidth>
            Contained
          </Button>
        </Grid>
        <Grid item xs>
          <Button variant="outlined" fullWidth>
            Outlined
          </Button>
        </Grid>
      </MainContainer>
      <MainContainer sx={{ pb: 3 }}>
        <Grid item xs>
          xs
        </Grid>
        <Grid item xs>
          xs=6
        </Grid>
        <Grid item xs>
          xs
        </Grid>
      </MainContainer>
      <MainContainer sx={{ pb: 3 }}>
        <Grid item xs={12}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={value} onChange={handleChange} variant="fullWidth" aria-label="basic tabs example">
              <Tab label="Item One" {...a11yProps(0)} />
              <Tab label="Item Two" {...a11yProps(1)} />
              <Tab label="Item Three" {...a11yProps(2)} />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <Typography>Item One</Typography>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <Typography>Item Two</Typography>
          </TabPanel>
          <TabPanel value={value} index={2}>
            <Typography>Item Three</Typography>
          </TabPanel>
        </Grid>
      </MainContainer>
    </>
  );
}

export default MuiComponents;
