import { useGetUserInfoQuery } from 'users/userApi';

import CheckIcon from '@mui/icons-material/Check';
import LockIcon from '@mui/icons-material/Lock';
import { Box, CircularProgress, Stack, Typography } from '@mui/material';

import { UnitProgressLabelContainer } from '../styles';

const UnitProgressSlide = ({ name, isLocked, value = 0 }) => {
  const { data: user = {} } = useGetUserInfoQuery();
  const calculateSecondaryProgress = () =>
    isLocked ? (
      <LockIcon color="disabled" />
    ) : (
      <Typography variant="caption" color="text.secondary">
        {value}%
      </Typography>
    );

  const calculatePrimaryProgress = () =>
    value === 100 ? (
      <CheckIcon color="primary" fontSize="large" />
    ) : (
      <Typography variant="caption" color="text.secondary">
        {value}%
      </Typography>
    );

  return (
    <Box display="flex" justifyContent="center">
      <Stack display="inline-flex" alignItems="center" gap={1}>
        <Box position="relative">
          <CircularProgress
            variant="determinate"
            size={64}
            value={100}
            sx={{ color: '#2B5979', position: 'absolute' }}
          />

          <CircularProgress variant="determinate" size={64} value={value} />

          <UnitProgressLabelContainer>
            {user?.belongsToSecondary && calculateSecondaryProgress()}
            {user?.belongsToPrimary && calculatePrimaryProgress()}
          </UnitProgressLabelContainer>
        </Box>

        <Typography sx={{ textTransform: 'capitalize' }} variant="body2" color="text.secondary">
          {name}
        </Typography>
      </Stack>
    </Box>
  );
};

export default UnitProgressSlide;
