import { Container, Grid } from '@mui/material';

const MainContainer = (props) => (
  <Container maxWidth="md" {...props}>
    <Grid container spacing={2}>
      {props.children}
    </Grid>
  </Container>
);

export default MainContainer;
