import { Card, Grid, Modal, Paper } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledLessons = styled('div')`
  position: relative;
`;

export const StyledSelfAssessment = styled(Card)`
  position: relative;
  margin-bottom: 25px;

  .MuiCardContent-root {
    padding: 15px;
    display: flex;

    ${(props) => props.theme.breakpoints.up('sm')} {
      padding: 24px;
    }

    img {
      max-width: 100%;
    }

    .MuiTypography-h6 {
      margin: 0 0 4px;
    }

    .MuiSvgIcon-root {
      vertical-align: baseline;
      margin: 0 5px -1px 17px;
    }
  }
`;

export const StyledBadgeCard = styled(Grid)`
  z-index: 2;

  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    width: 150px;
    transition: transform 0.2s, filter 0.3s ease-out;
  }
`;

export const StyledAssessmentImageBox = styled('div')`
  max-width: 65px;
  flex: 0 0 65px;
  padding: 10px 10px 0 0;

  ${(props) => props.theme.breakpoints.up('sm')} {
    max-width: 100px;
    flex: 0 0 100px;
    padding: 10px 10px 10px 15px;
  }
`;

export const StyledAssessmentContent = styled('div')`
  position: relative;

  ${(props) => props.theme.breakpoints.up('sm')} {
    padding-right: 175px;
  }

  p {
    font-size: 14px;
  }

  .MuiButton-root {
    margin-top: 15px;
    ${(props) => props.theme.breakpoints.up('sm')} {
      position: absolute;
      right: 0;
      bottom: 8px;
    }
  }
`;

export const StyledUnits = styled('div')``;

export const StyledUnit = styled(Card)`
  position: relative;
  box-shadow: none;
  margin-bottom: 25px;
  background-color: #0e1b3b;
  background-position: 100% 50%;
  background-repeat: no-repeat;
  background-size: auto 100%;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
  }

  &:after {
    background: linear-gradient(to right, rgba(14, 27, 59, 1) 0, rgba(14, 27, 59, 0.6) 100%);
    ${(props) => props.theme.breakpoints.up('sm')} {
      background: linear-gradient(to right, rgba(14, 27, 59, 1) 55%, rgba(14, 27, 59, 0.2) 100%);
    }
  }

  .badge {
    position: relative;
    z-index: 2;
    max-width: 125px;

    ${(props) => props.theme.breakpoints.up('sm')} {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 8.5%;
      max-width: none;
    }

    svg {
      width: 100%;
      height: auto;
    }
  }

  .sponsor-logo {
    position: absolute;
    top: 0;
    right: 0;
    width: 85px;
    height: auto;
    margin: 24px;
    z-index: 1;
  }

  .MuiCardContent-root {
    padding: 15px;
    position: relative;
    z-index: 1;
    min-height: 190px;

    ${(props) => props.theme.breakpoints.up('sm')} {
      padding: 24px 33px 10px;
    }
  }

  .MuiCardActions-root {
    padding: 0 15px 20px;
    position: relative;
    display: block;
    z-index: 1;

    ${(props) => props.theme.breakpoints.up('sm')} {
      padding: 0 33px 31px;
      display: flex;
    }
  }

  .MuiSvgIcon-root {
    vertical-align: baseline;
    margin: 0 -1px -1px;
  }

  .MuiTypography-h4 {
    ${(props) => props.theme.breakpoints.up('sm')} {
      max-width: 55%;
    }
  }

  .MuiTypography-body1 {
    ${(props) => props.theme.breakpoints.up('sm')} {
      max-width: 55%;
    }
  }
`;

export const StyledProgress = styled('div')`
  flex-grow: 1;
  width: 100%;
  padding-top: 15px;
  ${(props) => props.theme.breakpoints.up('sm')} {
    max-width: 222px;
    padding-top: 0;
  }
`;

export const StyledModal = styled(Modal)`
  height: 100%;
  margin-top: 0;
  width: 100%;
  padding: 0 1rem;
  overflow-y: auto;
`;

export const StyledModalWrapper = styled('div')`
  width: 100%;
  max-width: 1000px;
  padding: 25px 0;
  margin: 0 auto;
`;

export const StyledModalContent = styled(Paper)`
  max-width: 1000px;
  width: 100%;
  padding: 1rem;
  pointer-events: auto;
  margin: 0 auto;
`;

export const StyledModalHeader = styled('div')`
  display: flex;
  justify-content: flex-end;
  padding-bottom: 1rem;
`;

export default StyledLessons;
