import { useState } from 'react';
import Slider from 'react-slick';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Button, Card, CardContent, Chip, Grid, Skeleton, Stack, Typography } from '@mui/material';
import { notifyError, notifySuccess } from 'utils/notification';
import { useGetUserInfoQuery, useSetUserOnBoardMutation } from 'users/userApi';
import { useGetCharactersQuery } from 'onboard/student/charactersApi';
import Container from 'components/Container';

import {
  CharacterSliderFrame,
  DescriptionSliderFrame,
  SlickNextButton,
  SlickPrevButton,
  StyledSliders,
} from './styles';

const StudentOnBoard = () => {
  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);

  const { data: user } = useGetUserInfoQuery();
  const { data, isLoading, isSuccess } = useGetCharactersQuery();
  const [setUserOnBoard, { isLoading: seletingCharacter }] = useSetUserOnBoardMutation();

  const characters = isSuccess ? data.results : [];

  const characterSliderSettings = {
    dots: true,
    arrows: false,
    infinite: true,
    fade: true,
    speed: 500,
    asNavFor: nav2,
    slidesToShow: 1,
    slidesToScroll: 1,
    className: 'character-slider',
    swipeToSlide: true,
    focusOnSelect: true,
    ref: (slider) => setNav1(slider),
    responsive: [
      {
        breakpoint: 600,
        settings: {
          dots: false,
        },
      },
    ],
  };

  const descriptionSliderSettings = {
    dots: false,
    fade: true,
    speed: 500,
    asNavFor: nav1,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    className: 'description-slider',
    ref: (slider) => setNav2(slider),
  };

  const setOnboard = (e) => {
    setUserOnBoard(e.currentTarget.id)
      .unwrap()
      .then(() => {
        notifySuccess('Your character has been selected.');
      })
      .catch(() => {
        notifyError('Something went wrong. Please try again later!');
      });
  };

  return (
    <Container>
      <Grid item xs={12}>
        <Typography variant="h5" component="h1" sx={{ color: 'white', pt: 1.2, pb: 3 }}>
          Welcome, {user.name}!
        </Typography>
        <Typography variant="h4" component="h2" sx={{ pb: 3 }}>
          Choose Your Character
        </Typography>
        {isLoading && (
          <StyledSliders>
            <CharacterSliderFrame>
              <Skeleton variant="rectangular" sx={{ mb: 3 }} animation="wave" width={210} height={400} />
            </CharacterSliderFrame>
            <DescriptionSliderFrame>
              <Card>
                <CardContent>
                  <Typography variant="h3" sx={{ mb: 1 }}>
                    <Skeleton variant="text" animation="wave" />
                  </Typography>
                  <Stack direction="row" spacing={1} sx={{ mb: 1 }}>
                    <Skeleton variant="text" animation="wave" width="20%" />
                    <Skeleton variant="text" animation="wave" width="20%" />
                    <Skeleton variant="text" animation="wave" width="20%" />
                  </Stack>
                  <Skeleton variant="text" animation="wave" />
                  <Skeleton variant="text" animation="wave" />
                  <Skeleton variant="text" animation="wave" />
                </CardContent>
              </Card>
            </DescriptionSliderFrame>
          </StyledSliders>
        )}
        {isSuccess && characters.length > 0 && (
          <StyledSliders>
            <CharacterSliderFrame>
              <SlickPrevButton variant="outlined" onClick={nav1?.slickPrev}>
                <ArrowBackIcon />
              </SlickPrevButton>
              <SlickNextButton variant="outlined" onClick={nav1?.slickNext}>
                <ArrowForwardIcon />
              </SlickNextButton>
              <Slider {...characterSliderSettings}>
                {characters.map((character) => (
                  <div key={character.id}>
                    <img src={character.standing} alt={character.name} />
                  </div>
                ))}
              </Slider>
            </CharacterSliderFrame>
            <DescriptionSliderFrame>
              <Slider {...descriptionSliderSettings}>
                {characters.map((character) => (
                  <div key={character.id}>
                    <Card>
                      <CardContent>
                        <Typography variant="h5" sx={{ mb: 3 }}>
                          {character.name}
                        </Typography>
                        <Stack direction="row" spacing={1} sx={{ mb: 2.5 }}>
                          {character.skills.map((skill) => (
                            <Chip size="small" key={skill.name} label={skill.name} />
                          ))}
                        </Stack>
                        <Typography variant="body2" color="text.secondary" sx={{ mb: 3 }}>
                          {character.description}
                        </Typography>
                        <Button
                          disabled={seletingCharacter}
                          fullWidth
                          size="large"
                          id={character.id}
                          variant="contained"
                          onClick={setOnboard}
                        >
                          {seletingCharacter ? 'Selecting ...' : 'Select character'}
                        </Button>
                      </CardContent>
                    </Card>
                  </div>
                ))}
              </Slider>
            </DescriptionSliderFrame>
          </StyledSliders>
        )}
      </Grid>
    </Container>
  );
};

export default StudentOnBoard;
