import { nanoid } from '@reduxjs/toolkit';

import AggregateShortAnswer from './AggregateShortAnswer';
import ShortAnswer from './ShortAnswer';

const ShortAnswers = ({ problem, index, student, ...rest }) => {
  let count = index - Object.keys(problem?.results).length;

  return (
    <>
      {Object.keys(problem?.results).length > 0 &&
        Object.keys(problem?.results).map((id) => {
          count += 1;
          const problemUnit = problem?.results[id];
          return student === 'all' ? (
            <AggregateShortAnswer
              {...rest}
              index={count}
              key={nanoid()}
              problem={problemUnit}
              problemKey={problem?.problemKey}
              problemId={problemUnit?.answerId}
              studentsCount={problem?.studentsCount}
              studentsAnsweredCount={problem?.studentsAnsweredCount}
            />
          ) : (
            <ShortAnswer problem={problemUnit} key={nanoid()} index={count} />
          );
        })}
    </>
  );
};

export default ShortAnswers;
