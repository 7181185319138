import { Button } from '@mui/material';
import { Link as RouterLink, useMatch, useResolvedPath } from 'react-router-dom';

export const NavLink = (props) => {
  const { children, to, ...rest } = props;
  const resolved = useResolvedPath(to);
  const match = useMatch({ path: resolved.pathname, end: true });
  return (
    <Button
      component={RouterLink}
      to={to}
      sx={{
        my: 1,
        mr: 1,
        color: `${match ? 'text.primary' : 'text.disabled'}`,
        ':hover': { color: 'text.primary' },
      }}
      {...rest}
    >
      {children}
    </Button>
  );
};

export default NavLink;
