import { Link } from 'react-router-dom';
import { Box, Button, Typography, useMediaQuery, useTheme } from '@mui/material';
import Max from 'assets/images/Characters/Max.png';
import door from 'assets/images/Door.png';
import { ROUTES } from 'utils/values';


const NoAccessPage = ({ children }) => {
  const theme = useTheme();
  const isScreenLtSm = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box
      mt="-30px"
      mb={10}
      sx={{
        minHeight: 550,
        position: 'relative',
        textAlign: 'center',
        background: `linear-gradient(to bottom, ${theme.palette.background.default} 50%, rgba(36, 49, 77, 1))`,
      }}
    >
      <Box pt={6} px={2}>
        {!children && (
          <>
            <Typography variant={isScreenLtSm ? 'h4' : 'h3'} color="white">
              This door is locked!
            </Typography>
            <Typography
              variant="body1"
              color="white"
              mb={3}
            >{`You don't have access to this classroom yet. Max will take you back to`}</Typography>
            <Button variant="contained" color="secondary" mb={3} to={ROUTES.ROOT} component={Link}>
              back to GEN+
            </Button>
          </>
        )}
        {children}
      </Box>

      <Box sx={{ position: 'absolute', bottom: 0, left: '50%', transform: 'translateX(-50%)' }}>
        <img src={door} alt="door" height={300} />
      </Box>
      <Box
        sx={{
          position: 'absolute',
          bottom: -30,
          left: '50%',
          transform: 'translateX(-135%)',
          '&::after': {
            content: '""',
            position: 'absolute',
            width: isScreenLtSm ? 120 : 160,
            height: 35,
            borderRadius: '100%',
            bottom: -8,
            right: -8,
            backgroundColor: 'rgba(0, 0, 0, 0.2)',
            zIndex: -1,
            transform: 'rotate(-2deg)',
          },
        }}
      >
        <img src={Max} alt="Max" />
      </Box>
    </Box>
  );
};

export default NoAccessPage;
