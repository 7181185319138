import {
  Box,
  Grid,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';
import Skeleton from '@mui/material/Skeleton';

import { ActivityNotificationIcon } from '../styles';

const NotificationSkeleton = () => (
  <TableContainer component={Paper} elevation={2}>
    <Table aria-label="recent-activity-table">
      <TableBody>
        <TableRow>
          <TableCell sx={{ pl: 3 }}>
            <Stack direction="row" gap={2} alignItems="center">
              <ActivityNotificationIcon elevation={18}>{}</ActivityNotificationIcon>

              <Stack>
                <Typography variant="body2" color="text.secondary">
                  <Skeleton width={60} />
                </Typography>
                <Typography variant="body1">
                  <Skeleton width={60} />
                </Typography>
              </Stack>
            </Stack>
          </TableCell>
          <TableCell>
            <Typography variant="body2">
              <Skeleton width={60} />
            </Typography>
          </TableCell>
          <TableCell sx={{ pr: 3 }}>
            <Stack>
              <Typography>
                <Skeleton width={60} />
              </Typography>
            </Stack>
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  </TableContainer>
);

const ActivityFeedSkeleton = () => (
  <Paper elevation={3}>
    <Grid container p={3} spacing={1} justifyContent="space-between">
      <Grid item xs={3}>
        <Typography vairant="h6" sx={{ fontSize: '1.25rem', fontWeight: 500 }}>
          <Skeleton />
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography vairant="h6" sx={{ fontSize: '1.25rem', fontWeight: 500 }}>
          <Skeleton />
        </Typography>
      </Grid>
    </Grid>

    <Box sx={{ height: 245, overflowY: 'scroll' }}>
      <NotificationSkeleton />
      <NotificationSkeleton />
      <NotificationSkeleton />
      <NotificationSkeleton />
      <NotificationSkeleton />
      <NotificationSkeleton />
      <NotificationSkeleton />
      <NotificationSkeleton />
      <NotificationSkeleton />
    </Box>
  </Paper>
);

export default ActivityFeedSkeleton;
