import { Box, Typography } from '@mui/material';
import { colors } from 'assets/styles/theme';

const StaticFooter = () => (
  <Box
    py={2}
    px={3}
    display="flex"
    color="white"
    justifyContent={{ xs: 'center', xss: 'space-between' }}
    flexWrap="wrap"
    columnGap={5}
    sx={{ backgroundColor: colors.red }}
  >
    <Typography variant="caption">&copy;GenPlus Leaders {new Date().getFullYear()}</Typography>
    <Typography variant="caption">Scottish Charity Number: SC050610</Typography>
  </Box>
);

export default StaticFooter;
