import { BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip } from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

export const options = {
  indexAxis: 'y',
  barThickness: 20,
  elements: {
    bar: {
      inflateAmount: 0,
    },
  },
  plugins: {
    title: {
      display: false,
    },
    legend: {
      position: 'bottom',
      labels: {
        usePointStyle: true,
        boxWidth: 8,
        boxHeight: 8,
        color: '#defffd',
        font: {
          size: 12,
          family: 'Readex Pro',
        },
        generateLabels: (chart) => {
          const [notConfident, notConfidentAtAll, ...rest] = chart.data.datasets;
          const sortedData = [notConfidentAtAll, notConfident, ...rest];

          return sortedData.map(({ label, backgroundColor }, index) => {
            let dataIndex = 0;
            if (index === 0) {
              dataIndex = 1;
            } else if (index === 1) {
              dataIndex = 0;
            } else {
              dataIndex = index;
            }
            return {
              text: label,
              fillStyle: backgroundColor,
              strokeStyle: backgroundColor,
              datasetIndex: dataIndex,
              textAlign: 'left',
              hidden: chart ? chart.getDatasetMeta(dataIndex).hidden : false,
            };
          });
        },
      },
    },
    tooltip: {
      callbacks: {
        label: (context) => {
          const value = context.formattedValue;
          return `${context?.dataset?.label} ${value < 0 ? value * -1 : value}%`;
        },
      },
    },
  },
  scales: {
    x: {
      stacked: true,
      grid: {
        color: ({ tick }) => (tick.value === 0 ? '#defffd' : 'transparent'),
        borderDash: [1, 2],
        tickColor: 'rgba(99, 217, 255, 0)',
        borderColor: 'rgba(99, 217, 255, 0.5)',
      },
      ticks: {
        font: {
          size: 13,
          weight: 300,
          family: 'IBM Plex Sans, Readex Pro',
        },
        color: '#defffd',
        callback: (value) => `${Math.abs(value)}${value === 0 ? '' : '%'}`,
      },
      min: -100,
      max: 100,
    },
    y: {
      stacked: true,
      grid: {
        color: 'transparent',
        borderColor: 'rgba(99, 217, 255, 0.5)',
      },
      ticks: {
        font: {
          size: 12,
          weight: 300,
          family: 'IBM Plex Sans, Readex Pro',
        },
        color: '#defffd',
      },
    },
  },
};

const SatckedChart = ({ skills, dataset, height }) => {
  const data = {
    labels: skills,
    datasets: dataset,
  };

  return <Bar options={options} data={data} height={height} />;
};

export default SatckedChart;
