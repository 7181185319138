export const stringToColor = (string) => {
  let hash = 0;

  for (let i = 0; i < string.length; i++) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash); // eslint-disable-line no-bitwise
  }

  const hue = hash % 360;
  return `hsl(${hue}, 30%, 60%)`;
};

export const stringToAvatarColors = (string) => {
  let hash = 0;

  for (let i = 0; i < string.length; i++) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash); // eslint-disable-line no-bitwise
  }

  const hue = hash % 360;

  const backgroundColor = `hsl(${(hue + 180) % 360}, 30%, 80%)`;
  const textColor = `hsl(${hue}, 30%, 20%)`;

  return [backgroundColor, textColor];
};

export const getCookie = (name) => {
  // https://stackoverflow.com/questions/10730362/get-cookie-by-name?page=1&tab=scoredesc#tab-top
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
  return null;
};

export const checkCookie = () => {
  let { cookie: cachedCookie } = document;

  return (callback, ...callbackParams) => {
    const { cookie } = document;

    if (cookie !== cachedCookie) {
      cachedCookie = cookie;

      callback(...callbackParams);
    }
  };
};

export const debounce = (fn, wait = 700) => {
  let timer;

  const internalFn = (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => fn(...args), wait);
  };

  internalFn.cancel = () => clearTimeout(timer);

  return internalFn;
};

export const equivalentSet = (xs, ys) => xs.size === ys.size && [...xs].every((x) => ys.has(x));

export const getGreetings = () => {
  const greetings = [
    { hour: 17, greet: 'Good Evening' },
    { hour: 12, greet: 'Good Afternoon' },
    { hour: 5, greet: 'Good Morning' },
    { hour: 0, greet: 'Good Night' },
  ];

  const hour = new Date().getHours();

  for (let i = 0; i < greetings.length; i++) {
    const greeting = greetings[i];

    if (hour >= greeting.hour) {
      return greeting.greet;
    }
  }

  return '';
};
