import { Tab, Tabs } from '@mui/material';

const ReflectionTabs = ({ value, tabs, handleTabChange }) => {
  const handleChange = (_, newValue) => {
    handleTabChange(newValue);
  };

  const a11yProps = (index) => ({
    id: `skill-reflection-programs-tab-${index}`,
    'aria-controls': `skill-reflection-tabpanel-${index}`,
  });

  return (
    <Tabs value={value} onChange={handleChange} aria-label="Program Tabs">
      {tabs.map(({ id, programName }) => (
        <Tab {...a11yProps(id)} value={id} label={programName} key={`program_id${id}`} sx={{ textTransform: 'none' }} />
      ))}
    </Tabs>
  );
};

export default ReflectionTabs;
