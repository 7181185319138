import { Box, Grid } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';

import { SemiCircle } from '../styles';

const ProgramProgressSkeleton = () => (
  <Box sx={{ maxWidth: '345px', pt: 1, mx: 5, margin: '0 auto' }} textAlign="center">
    <Skeleton animation="wave" height={20} width="80%" style={{ margin: '0 auto', marginBottom: '32px' }} />
    <SemiCircle width={300} height={150} style={{ margin: '0 auto', marginTop: '5px' }} />
    <Grid container justifyContent="space-around" sx={{ mt: 2 }}>
      <Grid item>
        <Skeleton variant="circular" height={60} width={60} />
        <Skeleton animation="wave" height={10} width="100%" style={{ margin: '0 auto', marginTop: '5px' }} />
      </Grid>
      <Grid item>
        <Skeleton variant="circular" height={60} width={60} />
        <Skeleton animation="wave" height={10} width="100%" style={{ margin: '0 auto', marginTop: '5px' }} />
      </Grid>
      <Grid item>
        <Skeleton variant="circular" height={60} width={60} />
        <Skeleton animation="wave" height={10} width="100%" style={{ margin: '0 auto', marginTop: '5px' }} />
      </Grid>
    </Grid>
  </Box>
);

export default ProgramProgressSkeleton;
