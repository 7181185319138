import GettingStartedPrimary from 'assets/images/GettingStartedPrimaryThumbnail.png';
import GettingStartedSecondary from 'assets/images/GettingStartedSecondaryThumbnail.png';
import { PRIMARY_GETTING_START, SECONDARY_GETTING_START } from 'config';
import { Link } from 'react-router-dom';

import AddToPhotosIcon from '@mui/icons-material/AddToPhotos';
import ArticleIcon from '@mui/icons-material/Article';
import GroupIcon from '@mui/icons-material/Group';
import LiveHelpIcon from '@mui/icons-material/LiveHelp';
import {
  Box,
  Button,
  Container,
  Grid,
  Paper,
  Stack,
  Table,
  TableBody,
  TableContainer,
  Typography,
} from '@mui/material';

import { useGetClassesQuery } from '../../classes/classApi';
import { useGetUserInfoQuery } from '../../users/userApi';
import { getGreetings } from '../../utils/utilityFunctions';
import { ROUTES } from '../../utils/values';
import { useGetAlertBarEntryQuery, useGetFeaturedArticleQuery, useGetQuoteQuery } from '../dashboardApi';
import ClassRow from './Components/ClassRow';
import ClassRowSkeleton from './Components/ClassRowSkeleton';
import FeaturedArticle from './Components/FeaturedArticle';
import FeaturedArticleSkeleton from './Components/FeaturedArticleSkeleton';
import Notification from './Components/Notification';
import NotificationSkeleton from './Components/NotificationSkeleton';
import { QuoteBanner, QuoteBannerSkeleton } from './Components/QuoteBanner';

const TeacherDashboard = () => {
  const { data: user } = useGetUserInfoQuery();
  const { data: quote, isSuccess: isSuccessQuote, isLoading: isLoadingQuote } = useGetQuoteQuery();
  const {
    data: featuredArticle,
    isSuccess: isSuccessFeaturedArticle,
    isLoading: isLoadingFeaturedArticle,
  } = useGetFeaturedArticleQuery();
  const {
    data: AlertBarEntry,
    isSuccess: isSuccessAlertBarEntry,
    isLoading: isLoadingAlertBarEntry,
  } = useGetAlertBarEntryQuery();

  const {
    data: classes,
    isLoading: isLoadingFavouriteClasses,
    isSuccess: isSuccessFavouriteClasses,
  } = useGetClassesQuery();

  const gettingStartedImage = user?.belongsToPrimary ? GettingStartedPrimary : GettingStartedSecondary;
  const gettingStartedLink = user?.belongsToPrimary ? PRIMARY_GETTING_START : SECONDARY_GETTING_START;

  return (
    <>
      <Box mt="-30px">
        {isSuccessQuote && <QuoteBanner {...quote} />}
        {isLoadingQuote && <QuoteBannerSkeleton />}
      </Box>

      <Container maxWidth="md" sx={{ mt: 5 }}>
        <Typography variant="h4" color="white">{`${getGreetings()}, ${user.name}`}</Typography>

        <Stack gap={1} sx={{ mt: 3 }}>
          {isSuccessAlertBarEntry && <Notification type="info" {...AlertBarEntry} />}
          {isLoadingAlertBarEntry && <NotificationSkeleton type="grey" />}
        </Stack>

        <Box mt={4}>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={6}>
              <Typography variant="h6" sx={{ mb: 2 }}>
                Getting Started Guide
              </Typography>
              <Link to={gettingStartedLink}>
                <Box
                  component="img"
                  sx={{
                    width: {
                      xs: '100%',
                      sm: 350,
                      md: 450,
                    },
                    height: {
                      xs: 'auto',
                      sm: 210,
                      md: 280,
                    },
                    cursor: 'pointer',
                  }}
                  src={gettingStartedImage}
                  alt="Getting Started Guide"
                />
              </Link>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="h6" sx={{ mb: 2 }}>
                Featured Gen+ Teach Content
              </Typography>

              {isSuccessFeaturedArticle && <FeaturedArticle {...featuredArticle} />}
              {isLoadingFeaturedArticle && <FeaturedArticleSkeleton />}
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h6" sx={{ mb: 2 }}>
                Quick Links
              </Typography>

              <Grid
                container
                rowSpacing={2}
                columnSpacing={1}
                sx={{ '& a': { borderRadius: '9999px', flex: '1 0 auto' }, '& > div': { display: 'flex' } }}
              >
                <Grid item xs={12} xss={6} sm={12} md={6}>
                  <Button
                    variant="outlined"
                    startIcon={<AddToPhotosIcon />}
                    LinkComponent={Link}
                    to={`${ROUTES.TEACH}?activeTab=1`}
                    state={{ activeTab: 1 }}
                  >
                    CLPL E-PORTFOLIO
                  </Button>
                </Grid>

                <Grid item xs={12} xss={6} sm={12} md={6}>
                  <Button
                    variant="outlined"
                    startIcon={<LiveHelpIcon />}
                    LinkComponent={Link}
                    to={ROUTES.SUPPORT_MATERIALS}
                  >
                    SUPPORTING MATERIALS
                  </Button>
                </Grid>
                <Grid item xs={12} xss={6} sm={12} md={6}>
                  <Button variant="outlined" startIcon={<GroupIcon />} LinkComponent={Link} to={ROUTES.CLASSES}>
                    All Classes
                  </Button>
                </Grid>
                <Grid item xs={12} xss={6} sm={12} md={6}>
                  <Button variant="outlined" startIcon={<ArticleIcon />} LinkComponent={Link} to={ROUTES.TEACH}>
                    GEN+ TEACH LIBRARY
                  </Button>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Stack
                direction={{ xs: 'row' }}
                mb={2}
                justifyContent="space-between"
                alignItems={{ xss: 'center' }}
                columnGap={4}
                rowGap={0.5}
              >
                <Typography variant="h6">My Classes</Typography>

                <Button size="large" LinkComponent={Link} to={ROUTES.CLASSES} sx={{ alignSelf: 'flex-end' }}>
                  View all classes
                </Button>
              </Stack>

              <TableContainer component={Paper} elevation={2}>
                <Table aria-label="recent-class-activity-table">
                  <TableBody>
                    {isSuccessFavouriteClasses &&
                      classes.favouriteClasses.map((classData) => (
                        <ClassRow key={classData.id} {...classData} belongsToSecondary={user?.belongsToSecondary} />
                      ))}
                    {isLoadingFavouriteClasses && <ClassRowSkeleton />}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </>
  );
};

export default TeacherDashboard;
