import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';

const ConfirmationDialog = ({ isOpen, handleClose, handleConfirm, title, content }) => (
  <Dialog open={isOpen} onClose={handleClose} aria-labelledby="confirmation dialog" PaperProps={{ elevation: 3 }}>
    <DialogTitle>{title}</DialogTitle>
    <DialogContent>{content}</DialogContent>

    <DialogActions>
      <Button variant="contained" size="small" onClick={handleConfirm}>
        Confirm
      </Button>
      <Button size="small" onClick={handleClose} autoFocus>
        Cancel
      </Button>
    </DialogActions>
  </Dialog>
);

export default ConfirmationDialog;
