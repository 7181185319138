import { Chip, Paper, Skeleton, Stack, Typography } from '@mui/material';

const CpdCardSkeleton = () => (
  <Paper sx={{ p: 2 }} elevation={3}>
    <Typography variant="caption">
      <Skeleton />
      <Skeleton />
    </Typography>

    <Stack direction="row" flexWrap="wrap" gap={0.5} mt={2}>
      <Skeleton>
        <Chip size="small" label="Audio" sx={{ fontSize: '0.625rem', m: '2px 4px 2px -2px' }} />
      </Skeleton>
      <Skeleton>
        <Chip size="small" label="Text" sx={{ fontSize: '0.625rem', m: '2px 4px 2px -2px' }} />
      </Skeleton>
    </Stack>

    <Typography varaint="caption" color="text.disabled" fontSize="0.75rem" mt={1}>
      <Skeleton width={60} />
    </Typography>
  </Paper>
);

export default CpdCardSkeleton;
