import { useAwardBadgesMutation, useGetBoosterBadgesQuery, useGetProgramBadgesQuery } from 'badges/badgesApi';
import { useGetClassStudentsQuery } from 'classes/classApi';
import BadgesSkeleton from 'classes/classDashboard/components/badges/BadgesSkeleton';
import BoosterBadges from 'classes/classDashboard/components/badges/BoosterBadges';
import CourseBadges from 'classes/classDashboard/components/badges/CourseBadges';
import SelectBadges from 'classes/classDashboard/components/classSummary/SelectBadges';
import BackdropLinearProgress from 'components/BackdropLinearProgress';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { notifyError, notifySuccess } from 'utils/notification';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import StarsIcon from '@mui/icons-material/Stars';
import SyncIcon from '@mui/icons-material/Sync';
import {
  Box,
  Button,
  Dialog,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Stack,
  Tab,
  Tabs,
  TextField,
  styled,
  useMediaQuery,
  useTheme,
} from '@mui/material';

import { useTabs } from '../../tabsContext';

const StyledTab = styled((props) => <Tab {...props} />)(() => ({
  textTransform: 'none',
  '&.Mui-selected': {
    backgroundColor: '#1F4C6E',
  },
}));

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`tabpanel-${index}`} aria-labelledby={`tab-${index}`} {...other}>
      {value === index && <Box sx={{ mt: 4 }}>{children}</Box>}
    </div>
  );
};

const a11yProps = (index) => ({
  id: `tab-${index}`,
  'aria-controls': `tabpanel-${index}`,
});

const ClassBadges = () => {
  const theme = useTheme();
  const { activeTab } = useTabs();
  const [openAwardBadge, setOpenAwardBadge] = useState(false);
  const isScreenLtSm = useMediaQuery(theme.breakpoints.down('sm'));
  const [currentTab, setCurrentTab] = useState(0);
  const handleOpenAwardBadge = () => setOpenAwardBadge(true);
  const handleCloseAwardBadge = () => setOpenAwardBadge(false);
  const buttonSize = () => (isScreenLtSm ? 'small' : 'medium');
  const [selectedBadges, setSelectedBadges] = useState([]);
  const [student, setStudent] = useState(-1);

  const { classId } = useParams();
  const {
    data: students,
    isLoading: isLoadingStudents,
    isSuccess: isSuccessStudents,
  } = useGetClassStudentsQuery({ classId });

  const disableStudentNavButton = isLoadingStudents || !students?.results?.length;

  const {
    data: programBadges,
    isLoading: isLoadingProgramBadges,
    isFetching: isFetchingProgramBadges,
    isSuccess: isSuccessProgramBadges,
    refetch: refetchProgrameBadges,
  } = useGetProgramBadgesQuery(student, { skip: student < 0 });

  const {
    data: boosterBadges,
    isLoading: isLoadingBoosterBadges,
    isFetching: isFetchingBoosterBadges,
    isSuccess: isSuccessBoosterBadges,
    refetch: refetchBoosterBadges,
  } = useGetBoosterBadgesQuery(student, { skip: student < 0 });

  useEffect(() => {
    if (isSuccessStudents && students.results.length) {
      setStudent(() => (activeTab.studentId === -1 ? students.results[0].userId : activeTab.userId));
    }
    if (activeTab.userId !== 'all') {
      setCurrentTab(1);
    }
  }, [isSuccessStudents, students, activeTab, setCurrentTab]);

  const handleTabChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  const handleStudentNavClick = (offset) => {
    const totalStudents = students.results.length;
    const studentList = students.results.map((s) => s.userId);
    const nextStudent = (offset + totalStudents + parseInt(studentList.indexOf(student))) % totalStudents;
    setStudent(studentList[nextStudent]);
  };

  const [awardBadges] = useAwardBadgesMutation();

  const handleAwardBadges = async () => {
    const data = {
      user: [student],
      badge: selectedBadges,
      feedback: '',
    };

    await awardBadges(data)
      .unwrap()
      .then((res) => {
        notifySuccess(res);
        handleCloseAwardBadge();
      })
      .catch((err) => {
        notifyError(err.data.non_field_errors[0]);
      });
    return null;
  };

  return (
    <Paper elevation={4}>
      <Grid container padding={2} pt={0} spacing={2} mt={0} position="relative">
        <Grid item xs={12} smm={6} pr={{ xs: '100px', smm: 0 }}>
          <InputLabel sx={{ overflow: 'visible' }}>
            <TextField
              select
              id="select-student"
              label="Student"
              size="small"
              fullWidth
              sx={{
                minWidth: 200,
              }}
              value={student}
              onChange={(e) => setStudent(e.target.value)}
            >
              <MenuItem value={-1} disabled>
                {isLoadingStudents ? <em>Loading...</em> : ''}
              </MenuItem>
              {isSuccessStudents &&
                students.results.map((currentStudent) => (
                  <MenuItem key={currentStudent.id} value={currentStudent.userId}>
                    {currentStudent.username}
                  </MenuItem>
                ))}
            </TextField>
          </InputLabel>
        </Grid>
        <Grid
          item
          xs={12}
          smm={6}
          sx={{
            display: 'flex',
            alignItems: { xs: 'stretch', smm: 'center' },
            gap: 2,
            justifyContent: 'flex-end',
            flexDirection: { xs: 'column', smm: 'row' },
          }}
        >
          <Stack
            direction="row"
            sx={{
              position: { xs: 'absolute', smm: 'static' },
              right: 16,
              top: 16,
              mr: 'auto',
            }}
          >
            <IconButton
              aria-label="previous-student"
              disabled={disableStudentNavButton}
              onClick={() => handleStudentNavClick(-1)}
            >
              <ChevronLeftIcon />
            </IconButton>
            <IconButton
              aria-label="next-student"
              disabled={disableStudentNavButton}
              onClick={() => handleStudentNavClick(1)}
            >
              <ChevronRightIcon />
            </IconButton>
          </Stack>
          <Button
            size="small"
            variant="outlined"
            sx={{ ml: 'auto', display: 'none' }}
            startIcon={<StarsIcon />}
            onClick={handleOpenAwardBadge}
          >
            Give badges/feedback
          </Button>
          <Button
            size="small"
            variant="outlined"
            onClick={currentTab === 0 ? refetchProgrameBadges : refetchBoosterBadges}
            aria-label="Refetch Badges"
          >
            <SyncIcon />
          </Button>
        </Grid>
      </Grid>

      <Box sx={{ mt: 1, width: '100%', pb: 6 }}>
        <Box sx={{ borderBottom: 2, borderColor: '#1F4C6E', position: 'relative', zIndex: '1' }}>
          <Tabs
            value={currentTab}
            onChange={handleTabChange}
            aria-label="class dashboard option selection tabs"
            variant={isScreenLtSm ? 'scrollable' : 'fullWidth'}
            scrollButtons="auto"
            selectionFollowsFocus
          >
            <StyledTab label="Course Badges" {...a11yProps(0)} />
            <StyledTab label="Booster Badges" {...a11yProps(1)} />
          </Tabs>
        </Box>
        <TabPanel value={currentTab} index={0}>
          {(isLoadingStudents || isLoadingProgramBadges) && <BadgesSkeleton courseBadges />}
          {programBadges && isSuccessProgramBadges && (
            <Box position="relative">
              {isFetchingProgramBadges && (
                <BackdropLinearProgress backdropProps={{ invisible: true, sx: { marginTop: 3.8 } }} />
              )}
              <CourseBadges badges={programBadges?.results} />
            </Box>
          )}
        </TabPanel>
        <TabPanel value={currentTab} index={1}>
          {(isLoadingStudents || isLoadingBoosterBadges) && <BadgesSkeleton />}
          {boosterBadges && isSuccessBoosterBadges && (
            <Box position="relative">
              {isFetchingBoosterBadges && (
                <BackdropLinearProgress backdropProps={{ invisible: true, sx: { marginTop: 3.8 } }} />
              )}
              <BoosterBadges skills={boosterBadges} />
            </Box>
          )}
        </TabPanel>
      </Box>

      <Dialog
        aria-labelledby="Award badges modal"
        aria-describedby="A modal window for awarding badges to a students"
        open={openAwardBadge}
        onClose={handleCloseAwardBadge}
        fullWidth
        maxWidth="md"
        fullScreen={isScreenLtSm}
        PaperProps={{ elevation: 4 }}
      >
        <SelectBadges
          selectedBadges={selectedBadges}
          setSelectedBadges={setSelectedBadges}
          closeModal={handleCloseAwardBadge}
          title="Select Badges"
        />
        <Stack direction="row" justifyContent="space-between" px={{ xs: 0, sm: 6 }} pb={6}>
          <Stack direction="row" gap={2}>
            <Button
              variant="contained"
              size={buttonSize()}
              disabled={selectedBadges.length === 0}
              onClick={handleAwardBadges}
            >
              Award Badges
            </Button>
          </Stack>
          <Button onClick={handleCloseAwardBadge} variant="outlined" size={buttonSize()}>
            Cancel
          </Button>
        </Stack>
      </Dialog>
    </Paper>
  );
};

export default ClassBadges;
