import { useEffect, useMemo, useReducer, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import {
  Box,
  Button,
  Fab,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Pagination,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';

import ComingSoon from '../../assets/images/coming-soon.png';
import BackdropLinearProgress from '../../components/BackdropLinearProgress';
import { debounce } from '../../utils/utilityFunctions';
import { StyledBox } from '../styles';
import { useGetContentFiltersQuery, useGetPortfoliosReflectionsQuery } from '../teachApi';
import AddPortfolio from './AddPortfolio';
import PortfolioCard from './PortfolioCard';
import PortfolioCardSkeleton from './PortfolioCardSkeleton';

const filtersInitialState = {
  search: '',
  standard: '',
  skill: '',
  page: 1,
};

const filtersReducer = (state, action) => {
  switch (action.type) {
    case 'page':
      return { ...state, page: action.payload };

    case 'search':
      return { ...state, page: 1, search: action.payload };

    case 'standard':
      return { ...state, page: 1, standard: action.payload };

    case 'skill':
      return { ...state, page: 1, skill: action.payload };

    default:
      throw new Error(`Filters Reducer: Invalid action type (${action.type})`);
  }
};

const Portfolio = () => {
  const [filters, dispatch] = useReducer(filtersReducer, filtersInitialState);
  const { skill, search, standard, page } = filters;
  const theme = useTheme();
  const isScreenLtSmm = useMediaQuery(theme.breakpoints.down('smm'));
  const [isAddPortfolioOpen, setIsAddPortfolioOpen] = useState(false);
  const handleOpenAddPortfolio = () => setIsAddPortfolioOpen(true);
  const handleCloseAddPortfolio = () => setIsAddPortfolioOpen(false);

  const {
    data: portfolios,
    isLoading: isLoadingPortfolios,
    isSuccess: isSuccessPortfolios,
    isFetching: isFetchingPortfolios,
  } = useGetPortfoliosReflectionsQuery({
    page,
    search,
    skill: skill === 'All' ? '' : skill,
    standard: standard === 'All' ? '' : standard,
  });

  const pageCount = portfolios?.numPages;
  const { data: filtersData, isSuccess: filtersIsSuccess } = useGetContentFiltersQuery();
  const handleSearchChange = (event) => dispatch({ type: 'search', payload: event.target.value });
  const debouncedSearch = useMemo(() => debounce(handleSearchChange), []);
  useEffect(() => () => debouncedSearch.cancel(), [debouncedSearch]);

  return (
    <Box>
      {filtersData && filtersIsSuccess && (
        <Grid container spacing={1} alignItems="stretch">
          <Grid item xs={12} smm={6}>
            <InputLabel htmlFor="search-portfolio" className="sr-only">
              Search Teach Portfolio
            </InputLabel>
            <TextField
              id="search-portfolio"
              placeholder="Search..."
              size="small"
              variant="outlined"
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              onChange={debouncedSearch}
            />
          </Grid>

          <Grid item xs={7} smm={3}>
            <InputLabel sx={{ overflow: 'visible' }}>
              <TextField
                select
                id="select-gtcs-standard"
                label="GTCS Standard"
                size="small"
                fullWidth
                value={standard}
                onChange={(e) => dispatch({ type: 'standard', payload: e.target.value })}
              >
                <MenuItem value="All">
                  <em>All</em>
                </MenuItem>
                {filtersData.gtcs.map((gtc) => (
                  <MenuItem value={gtc.id} key={gtc.id}>
                    {gtc.name}
                  </MenuItem>
                ))}
              </TextField>
            </InputLabel>
          </Grid>

          <Grid item xs={5} smm={3}>
            <InputLabel sx={{ overflow: 'visible' }}>
              <TextField
                select
                id="select-skill"
                label="Skill"
                size="small"
                fullWidth
                value={skill}
                onChange={(e) => dispatch({ type: 'skill', payload: e.target.value })}
              >
                <MenuItem value="All">
                  <em>All</em>
                </MenuItem>
                {filtersData.skills.map((filterSkill) => (
                  <MenuItem value={filterSkill.id} key={filterSkill.name}>
                    {filterSkill.name}
                  </MenuItem>
                ))}
              </TextField>
            </InputLabel>
          </Grid>

          <Grid item xs={12} smm={1.5} display="flex" justifyContent="flex-end" sx={{ position: 'relative' }} hidden>
            <Button fullWidth={!isScreenLtSmm} variant="outlined" disabled>
              Export
            </Button>
            <img
              src={ComingSoon}
              alt="comming-soon"
              height={40}
              width={62}
              style={{ position: 'absolute', transform: 'rotate(28deg)', top: '-5px', right: '-4px' }}
            />
          </Grid>
        </Grid>
      )}

      <StyledBox sx={{ mt: 2 }}>{isFetchingPortfolios && !isLoadingPortfolios && <BackdropLinearProgress />}</StyledBox>
      {isLoadingPortfolios && (
        <Stack gap={2} mt={2}>
          <PortfolioCardSkeleton />
          <PortfolioCardSkeleton />
          <PortfolioCardSkeleton />
          <PortfolioCardSkeleton />
        </Stack>
      )}
      {isSuccessPortfolios && (
        <Stack gap={2} mt={2}>
          {portfolios?.results.map(({ id, created, entryType, contentObject }) => (
            <PortfolioCard key={id} created={created} entryType={entryType} data={contentObject} />
          ))}
        </Stack>
      )}
      <Stack mt={2.5} alignItems="Center">
        {isSuccessPortfolios && (
          <Pagination
            count={pageCount}
            size="large"
            page={page}
            onChange={(e, value) => {
              window.scrollTo(0, 0);
              dispatch({ type: 'page', payload: value });
            }}
          />
        )}
        {!portfolios?.results?.length && isSuccessPortfolios && (
          <Typography variant="h4" textAlign="center">
            No Results Found!
          </Typography>
        )}
      </Stack>

      <Fab
        variant="extended"
        color="primary"
        aria-label="add to portfolio"
        sx={{ position: 'fixed', right: 32, bottom: 32 }}
        onClick={handleOpenAddPortfolio}
      >
        <AddIcon sx={{ mr: 1 }} />
        Add
      </Fab>

      <AddPortfolio
        isOpen={isAddPortfolioOpen}
        handleClose={handleCloseAddPortfolio}
        skills={filtersData?.skills || []}
        gtcss={filtersData?.gtcs || []}
      />
    </Box>
  );
};

export default Portfolio;
