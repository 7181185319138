import { SUPPORT_EMAIL } from 'utils/values';

import { Box, Link, Typography } from '@mui/material';

const NoResponse = () => (
  <Box textAlign="center" py={5}>
    <Typography variant="subtitle2">No response submitted yet!</Typography>
    <Typography variant="subtitle2">
      If you think there is an issue then please email&nbsp;
      <Link href={`mailto:${SUPPORT_EMAIL}`} target="_blank">
        {SUPPORT_EMAIL}
      </Link>
    </Typography>
  </Box>
);

export default NoResponse;
