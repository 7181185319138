import { Avatar, Button, Skeleton, Stack, TableCell, TableRow, Typography } from '@mui/material';

const ClassRowSkeleton = () => (
  <TableRow
    sx={{
      '&:last-child td': { border: 0 },
      '& td': { borderColor: 'divider' },
      whiteSpace: 'nowrap',
    }}
  >
    <TableCell>
      <Stack direction="row" alignItems="center" gap={2}>
        <Skeleton variant="circular">
          <Avatar />
        </Skeleton>

        <Stack flexGrow={1}>
          <Typography variant="caption">
            <Skeleton width={100} />
          </Typography>
          <Typography>
            <Skeleton width={140} />
          </Typography>
        </Stack>
      </Stack>
    </TableCell>

    <TableCell>
      <Stack>
        <Typography variant="caption">
          <Skeleton width={50} />
        </Typography>
        <Typography variant="body2">
          <Skeleton width={80} />
        </Typography>
      </Stack>
    </TableCell>

    <TableCell>
      <Stack>
        <Typography variant="caption">
          <Skeleton width={50} />
        </Typography>
        <Typography variant="body2">
          <Skeleton width={80} />
        </Typography>
      </Stack>
    </TableCell>

    <TableCell>
      <Skeleton>
        <Button />
      </Skeleton>
    </TableCell>

    <TableCell>
      <Skeleton>
        <Button size="small" />
      </Skeleton>
    </TableCell>
  </TableRow>
);

export default ClassRowSkeleton;
