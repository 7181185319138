import PrimarySchoolProfile from 'assets/images/PrimarySchoolProfile.svg';
import { stringToAvatarColors } from 'utils/utilityFunctions';

import { Avatar } from '@mui/material';

const CreateAvatar = ({
  profileImage,
  username,
  isTeacher,
  belongsToPrimary,
  height = 32,
  width = 32,
  isStudent,
  size = 'small',
  fontSize = '1rem',
}) => {
  const [backgroundColor, textColor] = stringToAvatarColors(username);
  if (size === 'large') {
    height = 56;
    width = 56;
    fontSize = '2.4rem';
  }

  if ((belongsToPrimary && isStudent) || (belongsToPrimary && isTeacher && !profileImage)) {
    return <Avatar sx={{ width, height }} src={PrimarySchoolProfile} alt={username} />;
  }

  return profileImage ? (
    <Avatar alt={username} src={profileImage} sx={{ width, height }} />
  ) : (
    <Avatar sx={{ width, height, backgroundColor, color: textColor, fontSize }}>{username[0].toUpperCase()}</Avatar>
  );
};

export default CreateAvatar;
