import { Link as RouterLink } from 'react-router-dom';

import NavigateNext from '@mui/icons-material/NavigateNext';
import { Box, Breadcrumbs, Container, Link, Typography } from '@mui/material';

import { useGetUserInfoQuery } from '../../users/userApi';
import { ROUTES } from '../../utils/values';
import SkillsAssessment from './components/skillsReflection';

const SkillAssessment = () => {
  const { data: user } = useGetUserInfoQuery();

  return (
    <Container maxWidth="md">
      <Breadcrumbs separator={<NavigateNext />} aria-label="breadcrumbs">
        <Link underline="hover" key="1" color="inherit" component={RouterLink} to={ROUTES.ROOT}>
          Dashboard
        </Link>
        <Typography key="2" color="text.primary">
          Skill Reflection
        </Typography>
      </Breadcrumbs>
      <Box mt={3}>
        <SkillsAssessment isStudent studentId={user?.id} studentClass={user?.classId} />
      </Box>
    </Container>
  );
};

export default SkillAssessment;
