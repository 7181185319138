import { Box, Link, Typography } from '@mui/material';
import { SUPPORT_EMAIL } from 'utils/values';

const NoAnswerMessage = ({ singleUser }) => (
  <Box textAlign="center">
    <Typography variant="subtitle2">
      {singleUser ? 'This student has not answered this question yet!' : 'No students have answered the question yet!'}
    </Typography>
    <Typography variant="subtitle2">
      If you think there is an issue then please email&nbsp;
      <Link href={`mailto:${SUPPORT_EMAIL}`} target="_blank">
        {SUPPORT_EMAIL}
      </Link>
    </Typography>
  </Box>
);

export default NoAnswerMessage;
