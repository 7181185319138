import { useReducer } from 'react';
import { useNavigate } from 'react-router-dom';
import { useChangePasswordMutation } from 'users/userApi';
import { notifyError, notifySuccess } from 'utils/notification';
import { ROUTES } from 'utils/values';

import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import {
  Box,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';

const initialPasswordState = {
  value: '',
  error: '',
  visibile: false,
};

const passwordReducer = (state, action) => {
  switch (action.type) {
    case 'value':
      return { ...state, value: action.payload };

    case 'error':
      return { ...state, error: action.payload };

    case 'clearError':
      return { ...state, error: '' };

    case 'toggleVisibility':
      return { ...state, visibile: !state.visibile };

    case 'reset':
      return { ...initialPasswordState };

    default:
      throw new Error(`Password Reducer: Invalid action type (${action.type})`);
  }
};

const ChangePassword = ({ title, forceChange = false }) => {
  const theme = useTheme();
  const isScreenLtSmm = useMediaQuery(theme.breakpoints.down('smm'));

  const textFieldWidth = isScreenLtSmm || forceChange ? '100%' : '50%';

  const [oldPassword, dispatchOldPass] = useReducer(passwordReducer, initialPasswordState);
  const [newPassword1, dispatchNewPass1] = useReducer(passwordReducer, initialPasswordState);
  const [newPassword2, dispatchNewPass2] = useReducer(passwordReducer, initialPasswordState);

  const [changePassword, { isLoading }] = useChangePasswordMutation();

  const disableChangePasswordBtn = newPassword1 === '' || newPassword2 === '' || isLoading || oldPassword === '';

  const navigate = useNavigate();

  const visibilityAdornment = (visible, dispatch) => (
    <InputAdornment position="end">
      <IconButton aria-label="toggle password visibility" onClick={() => dispatch({ type: 'toggleVisibility' })}>
        {visible ? <VisibilityOffIcon color="error" /> : <VisibilityIcon />}
      </IconButton>
    </InputAdornment>
  );

  const passwordValidation = () => {
    const passwordRegex = new RegExp('^(.{0,7}|[^0-9]*|[^A-Z]*|[^a-z]*|[a-zA-Z0-9]*)$');

    if (newPassword1.value.length < 8) {
      dispatchNewPass1({
        type: 'error',
        payload:
          'Password must have more than 7 characters including ' +
          'an upper letter, a lower letter, a digit and a special character ',
      });
      return false;
    }

    if (passwordRegex.test(newPassword1.value)) {
      dispatchNewPass1({
        type: 'error',
        payload: 'Password must contain atleast an upper letter, a lower letter, a digit and a special character',
      });
      return false;
    }

    if (newPassword1.value !== newPassword2.value) {
      dispatchNewPass1({ type: 'error', payload: 'New password mismatch' });
      dispatchNewPass2({ type: 'error', payload: 'New password mismatch' });
      return false;
    }

    return true;
  };

  const handleChangePassword = async () => {
    dispatchOldPass({ type: 'clearError' });
    dispatchNewPass1({ type: 'clearError' });
    dispatchNewPass2({ type: 'clearError' });

    if (!passwordValidation()) return;

    const data = {
      oldPassword: oldPassword.value,
      newPassword1: newPassword1.value,
      newPassword2: newPassword2.value,
      isForceChange: forceChange,
    };

    try {
      await changePassword(data).unwrap();
      notifySuccess('Your password has been updated');

      dispatchOldPass({ type: 'reset' });
      dispatchNewPass1({ type: 'reset' });
      dispatchNewPass2({ type: 'reset' });
      navigate(ROUTES.ROOT);
    } catch (error) {
      const errors = error?.data?.errors;

      dispatchOldPass({ type: 'error', payload: errors?.old_password || '' });
      dispatchNewPass1({ type: 'error', payload: errors?.new_password1 || '' });
      dispatchNewPass2({ type: 'error', payload: errors?.new_password2 || '' });

      if (!errors) {
        notifyError('Failed to change password');
      }
    }
  };

  return (
    <Box mt={4}>
      <Typography variant={forceChange ? 'subtitle1' : 'h5'}>{title}</Typography>

      <Paper elevation={3} sx={{ p: 3, mt: 1.5 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              type={oldPassword.visibile ? 'text' : 'password'}
              id="old-password"
              label="Old Password"
              variant="outlined"
              sx={{ width: textFieldWidth }}
              value={oldPassword.value}
              onChange={(e) => dispatchOldPass({ type: 'value', payload: e.target.value })}
              InputProps={{
                endAdornment: visibilityAdornment(oldPassword.visibile, dispatchOldPass),
              }}
              error={Boolean(oldPassword.error)}
              helperText={oldPassword.error}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              type={newPassword1.visibile ? 'text' : 'password'}
              id="new-password"
              label="New Password"
              variant="outlined"
              sx={{ width: textFieldWidth, mt: forceChange ? 0 : 3 }}
              value={newPassword1.value}
              onChange={(e) => dispatchNewPass1({ type: 'value', payload: e.target.value })}
              InputProps={{
                endAdornment: visibilityAdornment(newPassword1.visibile, dispatchNewPass1),
              }}
              error={Boolean(newPassword1.error)}
              helperText={newPassword1.error}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              type={newPassword2.visibile ? 'text' : 'password'}
              id="confirm-password"
              label="Confirm New Password"
              variant="outlined"
              sx={{ width: textFieldWidth }}
              value={newPassword2.value}
              onChange={(e) => dispatchNewPass2({ type: 'value', payload: e.target.value })}
              InputProps={{
                endAdornment: visibilityAdornment(newPassword2.visibile, dispatchNewPass2),
              }}
              error={Boolean(newPassword2.error)}
              helperText={newPassword2.error}
            />
          </Grid>
        </Grid>
      </Paper>

      <Button variant="contained" sx={{ mt: 3 }} disabled={disableChangePasswordBtn} onClick={handleChangePassword}>
        Change Password
      </Button>
    </Box>
  );
};

export default ChangePassword;
