import { Box, Button, Chip, List, Slider, Tab, TableRow, TextField, Typography, alpha, styled } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';

export const StyledDataGrid = styled(DataGrid)(() => ({
  border: 'none',

  '& .MuiDataGrid-columnHeaders': {
    border: 'none',
  },

  '& .MuiDataGrid-columnSeparator': {
    display: 'none',
  },

  '& .MuiDataGrid-columnHeaderTitle': {
    whiteSpace: 'break-spaces',
    lineHeight: 1.5,
  },

  '& .MuiDataGrid-row.even': {
    backgroundColor: '#63D9FF14',
  },

  '& .MuiDataGrid-row.odd:hover': {
    backgroundColor: 'transparent',
  },

  '& .MuiDataGrid-cell': {
    border: 'none',
  },

  '& [role=progressbar]': {
    zIndex: 10,
  },

  '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus': {
    outline: 'none',
  },
}));

export const ClassSummaryBorderTab = styled(Tab)(({ theme }) => ({
  position: 'relative',
  alignItems: 'flex-start',
  textTransform: 'none',

  '$.Mui-selected': {
    backgroundColor: alpha(theme.palette.primary.dark, 0.16),
    borderRadius: 0,
  },

  '&::after': {
    content: '""',
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    borderLeft: `1px solid ${theme.palette.divider}`,

    [theme.breakpoints.up('sm')]: {
      left: '8%',
      right: '8%',
      borderTop: `1px solid ${theme.palette.divider}`,
      borderLeft: 'none',
    },
  },
}));

export const LessonAvailabilitySelect = styled(TextField, {
  shouldForwardProp: (prop) => prop !== 'isUnlocked',
})(({ theme, isUnlocked }) => ({
  ...(isUnlocked && {
    '& .MuiInputBase-input.Mui-disabled': {
      textFillColor: theme.palette.success.main,
    },

    '& .MuiInputBase-input': {
      color: theme.palette.success.main,
    },

    '& .MuiInput-underline:before': {
      borderBottomColor: theme.palette.success.light,
    },
  }),
}));

export const StyledTableRow = styled(TableRow)`
  position: relative;

  &:nth-of-type(2n + 1) {
    background: rgba(99, 217, 255, 0.08);

    td {
      border-top: 1px solid rgba(81, 81, 81, 1);
    }
  }
`;

export const StyledSlider = styled(Slider)`
  position: relative;

  .MuiSlider-thumb {
    display: none;
  }

  .MuiSlider-track {
    height: 6px;
    color: rgba(99, 217, 255, 1);
  }

  &.EndYear {
    .MuiSlider-track {
      color: rgba(255, 1, 157, 1);
    }
  }
`;

export const StyledCount = styled(Typography)`
  position: absolute;
  left: 0;
`;

export const UnStyledList = styled(List)`
  margin: 0;
  padding: 0;
  list-style: disc;
`;

export const StyledSRTitles = styled(Box)(({ theme }) => ({
  paddingBottom: 30,

  [theme.breakpoints.up('sm')]: {
    display: 'flex',
    paddingBottom: 10,
  },

  '.MuiTypography-body1': {
    flex: '0 0 150px',
  },

  '.MuiTypography-body2': {
    flexGrow: 1,
  },
}));

export const StyledClassImage = styled('img')`
  display: block;
  width: 100%;
  height: auto;
`;

export const StyledPlayIcon = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  height: 48px;
  width: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.38);
  transform: translate(-50%, -50%);
`;

export const StyledViewLesson = styled(Button)(() => ({
  padding: 0,

  '&:hover .MuiBox-root': {
    color: 'white',
  },
}));

export const StyledLessonCardTitle = styled(Typography)(() => ({
  flexGrow: 1,
  paddingRight: 8,
  marginRight: 'auto',
  maxWidth: 'calc(100% - 34px)',
  fontSize: '0.825rem',
  overflow: 'hidden',
  minHeight: 40,
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: '2',
  WebkitBoxOrient: 'vertical',
}));

export const StyledInfoChip = styled(Chip)(({ theme }) => ({
  background: theme.palette.action.hover,

  '&.lesson-complete .MuiChip-icon': {
    color: theme.palette.success.main,
  },

  '&.lesson-incomplete .MuiChip-icon': {
    color: theme.palette.warning.main,
  },

  '&.lesson-not-started .MuiChip-icon': {
    color: theme.palette.primary.main,
  },

  '&.teacher-only .MuiChip-icon': {
    color: theme.palette.info.main,
    opacity: 0.5,
  },
}));
