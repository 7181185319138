import { useGetClassStudentsQuery } from 'classes/classApi';
import BackdropLinearProgress from 'components/BackdropLinearProgress';
import { useParams } from 'react-router-dom';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PendingIcon from '@mui/icons-material/Pending';
import { Button, Skeleton, Stack, Table, TableBody, TableCell, TableRow, Tooltip, Typography } from '@mui/material';

import { useGetUserInfoQuery } from '../../../../users/userApi';
import { StyledDataGrid } from '../../styles';
import { CLASS_TABS_ACTIONS, useTabs } from '../../tabsContext';
import StudentCell from './StudentCell';
import UnitProgressionCell from './UnitProgressionCell';

const StudentsSkeleton = () => (
  <Table size="small" sx={{ minWidth: 650 }} aria-label="Loading Student list">
    <TableBody>
      {Array.from(Array(3).keys()).map((id) => (
        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }} key={`skeletonRows${id}`}>
          <TableCell width={230}>
            <Skeleton />
          </TableCell>
          <TableCell width={100}>
            <Skeleton />
          </TableCell>
          <TableCell width={80}>
            <Skeleton />
          </TableCell>
          <TableCell width={180}>
            <Skeleton />
          </TableCell>
          <TableCell width={180}>
            <Skeleton />
          </TableCell>
          <TableCell width={180}>
            <Skeleton />
          </TableCell>
        </TableRow>
      ))}
    </TableBody>
  </Table>
);

const NoDataOverlay = () => (
  <Stack alignItems="center" justifyContent="center" sx={{ width: '100%', height: '100%' }}>
    <Typography variant="h6">No Results Found!</Typography>
  </Stack>
);

const generateColumns = ({ unitLessonCompletion }) => {
  const columns = [];
  unitLessonCompletion.forEach(({ unitDisplayName }, i) => {
    columns.push({
      field: `unit${i}`,
      width: 160,
      sortable: false,
      renderHeader: () => (
        <Typography fontSize={14} fontWeight={500} whiteSpace="normal">
          {unitDisplayName}
        </Typography>
      ),
      renderCell: (params) => <UnitProgressionCell lessonCompletions={params.value} />,
    });
  });

  return columns;
};

const generateRows = (studentList) => {
  let rows = [];
  const edxUsers = [];
  const nonEdxUsers = [];

  studentList.forEach((student, i) => {
    const row = {
      id: i,
      studentName: student?.username,
      boosterBadges: student?.boosterBadges,
      student: {
        name: student?.username,
        profilePic: student?.profilePic,
        studentId: i,
        userId: student?.userId,
      },
      isActive: student?.hasFirstLogon,
      skillsAssessment: student?.skillsAssessment,
      impactSurveyCompletion: student?.selfReflectionCompletion,
    };

    student.unitLessonCompletion.forEach(({ lessonCompletions }, j) => {
      row[`unit${j}`] = lessonCompletions;
    });

    if (student?.hasFirstLogon) {
      edxUsers.push(row);
    } else {
      nonEdxUsers.push(row);
    }
  });

  rows = [...edxUsers, ...nonEdxUsers];
  return rows;
};

const StudentDataGrid = () => {
  const { dispatch } = useTabs();
  const { classId } = useParams();
  const { data, isLoading, isFetching, isSuccess } = useGetClassStudentsQuery({ classId });
  const { allResults: studentList } = data || [];
  const { data: user } = useGetUserInfoQuery();

  let columns = [
    {
      field: 'studentName',
      headerName: 'Student',
      width: 300,
      renderCell: (params) => <StudentCell student={params.row.student} showTabs />,
    },
  ];

  const impactSurveyColumn = [
    {
      field: 'impactSurveyCompletion',
      headerName: 'Impact Survey',
      sortable: false,
      width: 100,
      renderCell: (params) => {
        const reflections = params.row.impactSurveyCompletion;
        return (
          <Stack direction="row">
            {reflections.map((reflection) =>
              reflection.isCompleted ? (
                <Tooltip arrow title={reflection.name} placement="top" key={`${reflection.name}-${reflection.id}`}>
                  <CheckCircleIcon sx={{ fontSize: '19px', color: 'success.dark' }} />
                </Tooltip>
              ) : (
                <Tooltip arrow title={reflection.name} placement="top" key={`${reflection.name}-${reflection.id}`}>
                  <PendingIcon sx={{ fontSize: '19px', color: 'primary.dark' }} />
                </Tooltip>
              ),
            )}
          </Stack>
        );
      },
    },
  ];

  const boosterBadgesColumn = [
    {
      field: 'boosterBadges',
      headerName: 'Booster Badges',
      sortable: false,
      width: 130,
      renderHeader: () => (
        <Typography fontSize={14} fontWeight={500}>
          Booster
          <br />
          Badges
        </Typography>
      ),
      renderCell: (params) => {
        const { studentId, userId } = params.row.student;
        const { boosterBadges } = params.row;
        const ids = {
          studentId,
          userId,
        };
        return (
          <Button
            size="small"
            onClick={() =>
              dispatch({ type: CLASS_TABS_ACTIONS.SHOW_BADGES, belongsToPrimary: user.belongsToPrimary, ...ids })
            }
            variant="text"
            sx={{ textTransform: 'none' }}
          >
            {boosterBadges}
          </Button>
        );
      },
    },
  ];

  let rows = [];

  if (isSuccess && studentList.length) {
    columns = [...columns, ...impactSurveyColumn, ...generateColumns(studentList[0]), ...boosterBadgesColumn];
    rows = generateRows(studentList);
  }

  return (
    <StyledDataGrid
      rows={rows}
      columns={columns}
      disableSelectionOnClick
      components={{
        LoadingOverlay: isLoading ? StudentsSkeleton : BackdropLinearProgress,
        NoRowsOverlay: NoDataOverlay,
      }}
      sx={{ overflowX: 'scroll' }}
      autoHeight
      hideFooter
      disableColumnMenu
      loading={isLoading || isFetching}
      initialState={{ pinnedColumns: { left: ['studentName'] } }}
      getRowClassName={(params) => (params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd')}
    />
  );
};

export default StudentDataGrid;
