import { Box, Grid, Skeleton, Stack, Typography, alpha, useTheme } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import { nanoid } from '@reduxjs/toolkit';

const BadgesSkeleton = ({ courseBadges }) => {
  const theme = useTheme();
  const borderColor = alpha(theme.palette.primary.dark, 0.4);
  const listItemBgColor = alpha(theme.palette.primary.dark, 0.1);

  return (
    <Grid container rowSpacing={8} columnSpacing={6} px={{ xs: 2, sm: 4 }}>
      {Array.from(Array(4).keys()).map(() => (
        <Grid item xs={12} sm={6} key={nanoid()}>
          <Stack direction="row" alignItems="center" gap={2} pb={2}>
            <Skeleton variant="circular">
              <Avatar sx={{ width: 50, height: 50 }} />
            </Skeleton>
            <Typography sx={{ flexGrow: 1 }} variant="h6">
              <Skeleton />
            </Typography>
          </Stack>
          <Box border={1} borderColor={borderColor} borderRadius={2}>
            {Array.from(Array(5).keys()).map(() => (
              <Stack
                key={nanoid()}
                direction="row"
                alignItems="center"
                gap={2}
                sx={{ p: 1, '&:nth-of-type(odd)': { backgroundColor: listItemBgColor } }}
              >
                {courseBadges && (
                  <Box>
                    <Skeleton variant="circular">
                      <Avatar sx={{ width: 35, height: 35 }} />
                    </Skeleton>
                  </Box>
                )}
                <Box sx={{ flexGrow: 1 }}>
                  <Typography variant="body2">
                    <Skeleton />
                  </Typography>
                </Box>
                <Box>
                  <Skeleton variant="circular">
                    <Avatar sx={{ width: 20, height: 20 }} />
                  </Skeleton>
                </Box>
              </Stack>
            ))}
          </Box>
        </Grid>
      ))}
    </Grid>
  );
};

export default BadgesSkeleton;
