import { useState } from 'react';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Button,
  IconButton,
  Stack,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { notifyError, notifySuccess } from 'utils/notification';
import { useAwardBadgesMutation } from 'badges/badgesApi';

import ConfirmAwardBadges from './ConfirmAwardBadges';
import SelectBadges from './SelectBadges';
import SelectStudents from './SelectStudents';

const steps = [
  {
    label: 'Select Students',
    component: (props) => <SelectStudents {...props} />,
  },
  {
    label: 'Select Badges',
    component: (props) => <SelectBadges {...props} />,
  },
  {
    label: 'Confirm',
    component: (props) => <ConfirmAwardBadges {...props} />,
  },
];

const AwardBadge = ({ closeModal }) => {
  const theme = useTheme();
  const isScreenLtSm = useMediaQuery(theme.breakpoints.down('sm'));

  const [activeStep, setActiveStep] = useState(0);
  const [selectedStudents, setSelectedStudents] = useState([]);
  const [selectedBadges, setSelectedBadges] = useState([]);
  const [feedback, setFeedBack] = useState('');
  const [awardBadges] = useAwardBadgesMutation();

  const handleAwardBadges = async () => {
    const data = {
      user: selectedStudents,
      badge: selectedBadges,
      feedback,
    };

    await awardBadges(data)
      .unwrap()
      .then((res) => {
        notifySuccess(res);
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      })
      .catch((err) => {
        notifyError(err.data.non_field_errors[0]);
      });
    return null;
  };

  const handleBackStep = () => setActiveStep((prevActiveStep) => prevActiveStep - 1);
  const handleNextStep = () => {
    if (activeStep === steps.length - 1) {
      handleAwardBadges();
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const stateMap = {
    selectedStudents,
    setSelectedStudents,
    selectedBadges,
    setSelectedBadges,
    feedback,
    setFeedBack,
  };

  const buttonSize = () => (isScreenLtSm ? 'small' : 'medium');

  const StepperControls = () => (
    <Stack direction="row" justifyContent="space-between" px={{ xs: 0, sm: 6 }}>
      <Stack direction="row" gap={2}>
        <Button
          variant="contained"
          disabled={activeStep === steps.length - 2 ? selectedBadges.length === 0 : selectedStudents.length === 0}
          onClick={handleNextStep}
          size={buttonSize()}
        >
          {activeStep === steps.length - 1 ? 'Confirm' : 'Next'}
        </Button>
        {activeStep > 0 && (
          <Button onClick={handleBackStep} variant="outlined" size={buttonSize()}>
            Back
          </Button>
        )}
      </Stack>
      <Button onClick={closeModal} variant="outlined" size={buttonSize()}>
        Cancel
      </Button>
    </Stack>
  );

  return (
    <Box sx={{ paddingY: 4, paddingX: { xs: 2, sm: 0 } }}>
      <Stepper
        activeStep={activeStep}
        alternativeLabel={!isScreenLtSm}
        orientation={isScreenLtSm ? 'vertical' : 'horizontal'}
      >
        {steps.map((step) => (
          <Step key={step.label}>
            <StepLabel>{step.label}</StepLabel>
            {isScreenLtSm && (
              <StepContent>
                {step.component(stateMap)}
                <StepperControls />
              </StepContent>
            )}
          </Step>
        ))}
      </Stepper>

      {activeStep === steps.length && (
        <Stack justifyContent="center" alignItems="center" py={{ xs: 8, sm: 15 }}>
          {closeModal ? (
            <IconButton
              aria-label="close"
              onClick={closeModal}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
              }}
            >
              <CloseIcon />
            </IconButton>
          ) : null}
          <CheckCircleIcon color="primary" sx={{ width: 120, height: 120 }} />
          <Typography mb={2} variant="body2">
            Badges Awarded Successfully!
          </Typography>
          <Button onClick={closeModal} variant="outlined" size={buttonSize()}>
            Close
          </Button>
        </Stack>
      )}
      {activeStep < steps.length && !isScreenLtSm && (
        <Box>
          {steps[activeStep].component(stateMap)}
          <StepperControls />
        </Box>
      )}
    </Box>
  );
};

export default AwardBadge;
