import { nanoid } from '@reduxjs/toolkit';
import { stringToColor } from 'utils/utilityFunctions';

import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardHeader,
  Chip,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';

import { LMS_BASE_URL } from '../../config';
import { useGetUserInfoQuery } from '../../users/userApi';

const ProblemEntry = ({
  title,
  description,
  skill,
  id,
  cardElevation = 3,
  extended,
  usageKey,
  courseKey,
  isEnrolled,
}) => {
  const {
    data: { isStudent },
  } = useGetUserInfoQuery();
  const parsedDescription = JSON.parse(description);
  return (
    <Card
      key={`problem-entry-${id}`}
      elevation={cardElevation}
      sx={{ display: { sm: extended ? 'flex' : 'block' }, justifyContent: 'space-between' }}
    >
      <CardHeader
        title={
          <Typography component="h2" variant="h6" mb={2}>
            {title}
          </Typography>
        }
        subheader={
          <List>
            {parsedDescription.results.answers?.map((answer) => (
              <ListItem key={nanoid()}>
                <ListItemIcon>
                  {answer.value ? <CheckCircleIcon color="success" /> : <CancelIcon color="error" />}
                </ListItemIcon>
                <ListItemText primary={answer.name} />
              </ListItem>
            ))}
          </List>
        }
        disableTypography
        sx={{ paddingBottom: { xs: 0, sm: extended ? 2 : 0 } }}
      />

      <CardActions
        sx={{
          padding: 2,
          justifyContent: 'space-between',
          alignItems: 'flex-end',
          flexDirection: { sm: extended ? 'column' : 'row' },
        }}
      >
        {skill && (
          <Chip
            label={skill.name}
            avatar={
              skill.image ? (
                <Avatar src={skill.image} alt={`${skill.name} skill image`} />
              ) : (
                <Avatar sx={{ backgroundColor: stringToColor(skill.name) }}>{skill.name[0].toUpperCase()}</Avatar>
              )
            }
            size="small"
          />
        )}
        {isStudent && courseKey && usageKey && (
          <Button
            size="small"
            target="_blank"
            sx={{ textTransform: 'none' }}
            href={`${LMS_BASE_URL}courses/${courseKey}/jump_to/${usageKey}`}
            disabled={!isEnrolled}
          >
            Edit Answer
          </Button>
        )}
        <Typography display="block" mt={1} variant="caption">
          {parsedDescription.correct === parsedDescription.total
            ? 'You got them all right!'
            : `You got ${parsedDescription.correct} out of ${parsedDescription.total} right!`}
        </Typography>
      </CardActions>
    </Card>
  );
};

export default ProblemEntry;
