import { useGetClassStudentsQuery } from 'classes/classApi';
import CreateAvatar from 'components/CreateAvatar';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useGetUserInfoQuery } from 'users/userApi';

import { Box, Grid, Stack, TextField, Typography, alpha, useTheme } from '@mui/material';

import { useGetBadgesQuery } from '../../../../badges/badgesApi';

const ConfirmAwardBadges = ({ selectedStudents, selectedBadges, feedback, setFeedBack }) => {
  const theme = useTheme();
  const borderColor = alpha(theme.palette.primary.dark, 0.4);
  const listItemBgColor = alpha(theme.palette.primary.dark, 0.1);
  const { classId } = useParams();
  const { data, isSuccess } = useGetClassStudentsQuery({ classId });
  const { results: studentList } = data || [];
  const [students, setStudents] = useState([]);
  const [badges, setBadges] = useState([]);
  const { data: allBadges, isLoading } = useGetBadgesQuery();
  const { data: user } = useGetUserInfoQuery();

  useEffect(() => {
    if (isSuccess && studentList.length) {
      setStudents(studentList.filter((student) => selectedStudents.includes(student.userId)));
    }
    if (!isLoading && allBadges) {
      setBadges(allBadges.filter((badge) => selectedBadges.includes(badge.id)));
    }
  }, [selectedBadges, selectedStudents, isSuccess, studentList, allBadges, isLoading]);

  return (
    <Box pt={{ xs: 1, sm: 5.5 }} pb={{ xs: 2, sm: 5.5 }} paddingX={{ xs: 0, sm: 5 }}>
      <Grid container rowSpacing={2} columnSpacing={6}>
        <Grid item xs={12} textAlign="center">
          <Typography variant="h6">Award these badges to the following students?</Typography>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Typography variant="h6">Badges</Typography>
          <Box border={1} borderColor={borderColor} borderRadius={2} maxHeight={194} overflow="auto">
            {badges.map((badge) => (
              <Stack
                key={badge.id}
                direction="row"
                alignItems="center"
                gap={2}
                sx={{ p: 1, '&:nth-of-type(odd)': { backgroundColor: listItemBgColor } }}
              >
                <Box component="img" width={50} src={badge.imageUrl} alt="Gen Plus" />
                <Typography variant="body2">{badge.displayName}</Typography>
              </Stack>
            ))}
          </Box>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Typography variant="h6">Students</Typography>
          <Box border={1} borderColor={borderColor} borderRadius={2} maxHeight={194} overflow="auto">
            {students?.map((student) => (
              <Stack
                key={student.username}
                direction="row"
                alignItems="center"
                gap={2}
                sx={{ p: 1, '&:nth-of-type(odd)': { backgroundColor: listItemBgColor } }}
              >
                <CreateAvatar
                  belongsToPrimary={user?.belongsToPrimary}
                  username={student?.username}
                  profileImage={student?.profilePic}
                />
                <Typography variant="body2">{student.username}</Typography>
              </Stack>
            ))}
          </Box>
        </Grid>

        <Grid item xs={12}>
          <TextField
            id="teacher-comment"
            multiline
            rows={4}
            fullWidth
            placeholder="Add an optional comment here for each student above..."
            value={feedback}
            onChange={(e) => setFeedBack(e.target.value)}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default ConfirmAwardBadges;
