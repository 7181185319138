import { Avatar, Skeleton, Stack, TableCell, TableRow, Typography } from '@mui/material';

const ClassRowSkeleton = () => (
  <TableRow
    sx={{
      '&:last-child td': { border: 0 },
      '& td': { borderColor: 'divider' },
      whiteSpace: 'nowrap',
    }}
  >
    <TableCell sx={{ pl: 3 }}>
      <Stack direction="row" gap={2} alignItems="center">
        <Skeleton variant="circular">
          <Avatar />
        </Skeleton>
        <Stack>
          <Typography variant="body1">
            <Skeleton width={100} />
          </Typography>
          <Typography variant="body1">
            <Skeleton />
          </Typography>
        </Stack>
      </Stack>
    </TableCell>

    <TableCell>
      <Stack>
        <Typography variant="body1" color="text.secondary">
          <Skeleton width={100} />
        </Typography>
        <Typography variant="body1">
          <Skeleton width={100} />
        </Typography>
      </Stack>
    </TableCell>

    <TableCell>
      <Stack>
        <Typography variant="body2" color="text.secondary">
          <Skeleton width={100} />
        </Typography>
        <Typography variant="body1">
          <Skeleton width={100} />
        </Typography>
      </Stack>
    </TableCell>

    <TableCell sx={{ pr: 3 }}>
      <Stack>
        <Typography variant="body1">
          <Skeleton width={100} />
        </Typography>
      </Stack>
    </TableCell>
  </TableRow>
);

export default ClassRowSkeleton;
