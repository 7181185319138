import { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, TextField } from '@mui/material';
import { useUpdateJournalMutation } from 'journals/journalsApi';
import { notifyError, notifySuccess } from 'utils/notification';


const EditTeacherFeedback = ({ isOpen, handleClose, id, oldTitle, oldContent }) => {
  const [title, setTitle] = useState(oldTitle);
  const [content, setContent] = useState(oldContent);

  const [updateJournal, { isLoading }] = useUpdateJournalMutation();

  const disableSaveButton = isLoading || !(title.trim() && content.trim());

  const handleUpdate = async () => {
    const data = { id, title, description: content };

    try {
      const res = await updateJournal(data).unwrap();
      notifySuccess(res);
      handleClose();
    } catch (error) {
      console.log('Error:', error);
      notifyError(error.data.detail);
    }
  };

  return (
    <Dialog open={isOpen} onClose={handleClose} maxWidth="sm" fullWidth PaperProps={{ elevation: 3 }} scroll="body">
      <DialogTitle>Update feedback</DialogTitle>

      <DialogContent>
        <Stack mt={0.5} gap={1.5}>
          <TextField
            id="journal-feedback-title"
            label="Title"
            size="small"
            variant="outlined"
            fullWidth
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            required
          />
          <TextField
            multiline
            rows={4}
            id="journal-feedback-content"
            placeholder="Type your comments here..."
            size="small"
            variant="outlined"
            fullWidth
            value={content}
            onChange={(e) => setContent(e.target.value)}
            required
          />
        </Stack>
      </DialogContent>

      <DialogActions sx={{ justifyContent: 'flex-start' }}>
        <Button type="submit" variant="contained" disabled={disableSaveButton} onClick={handleUpdate}>
          Update
        </Button>
        <Button variant="outlined" onClick={handleClose}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditTeacherFeedback;
