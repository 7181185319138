import baseApiSlice from 'api/baseApiSlice';
import { GENZ_TEACH_API_URL } from 'config';

const SUPPORT_MATERIALS_API_URL = `${GENZ_TEACH_API_URL}help-guides/`;

const supportMaterialsApiWithTags = baseApiSlice.enhanceEndpoints({
  addTagTypes: ['SupportMaterials'],
});

const supportMaterialsApiSlice = supportMaterialsApiWithTags.injectEndpoints({
  endpoints: (builder) => ({
    getSupportArticles: builder.query({
      query: (arg) => ({
        url: SUPPORT_MATERIALS_API_URL,
        params: arg,
      }),
    }),

    getSupportArticle: builder.query({
      query: ({ id }) => ({
        url: `${SUPPORT_MATERIALS_API_URL}${id}/`,
      }),
      providesTags: (result) => (result ? [{ type: 'SupportMaterials', id: result.id }] : []),
    }),

    rateGuide: builder.mutation({
      query: ({ id, data }) => ({
        url: `${SUPPORT_MATERIALS_API_URL}${id}/rate/`,
        method: 'PUT',
        data,
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'SupportMaterials', id: arg.id }],
    }),
  }),

  overrideExisting: false,
});

export const { useGetSupportArticlesQuery, useGetSupportArticleQuery, useRateGuideMutation } = supportMaterialsApiSlice;

export default supportMaterialsApiSlice;
