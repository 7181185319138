import SecurityIcon from '@mui/icons-material/Security';
import { Box, Container, Grid } from '@mui/material';
import Jay from 'assets/images/Characters/Jay.png';

import ChangePassword from './Components/ChangePassword';

const ForcePasswordChange = () => (
  <Container maxWidth="md">
    <Grid container spacing={2} alignItems="center" minHeight="calc(100vh - 150px)">
      <Grid item xs={12} sm={7}>
        <ChangePassword
          title={`Jay knows the importance of good security.
        He needs you to change your password to something only you know and is hard to guess.
        So go on, please change your password.`}
          forceChange
        />
      </Grid>
      <Grid item xs={12} sm={5} mt={{ xs: 4, sm: 0 }} position="relative">
        <Box
          sx={{
            textAlign: 'right',
            '& img': {
              width: {
                xs: 100,
                sm: 'auto',
              },
            },
          }}
        >
          <SecurityIcon
            sx={{
              position: 'absolute',
              top: 0,
              left: {
                xs: '30%',
                sm: '13%',
              },
              zIndex: -1,
              fontSize: {
                xs: '150px',
                sm: '250px',
              },
            }}
          />
          <img src={Jay} alt="Jay" />
        </Box>
      </Grid>
    </Grid>
  </Container>
);

export default ForcePasswordChange;
