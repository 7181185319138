import { Box, Button, Card, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';

export const BadgeCards = styled('div')``;
export const StyledBadgeCard = styled(Grid)`
  z-index: 2;

  display: flex;
  flex-direction: column;
  align-items: center;

  &.badge-card {
    margin-bottom: 16px;
    margin-top: 16px;
  }

  img {
    max-width: 120px;
    transition: transform 0.2s, filter 0.3s ease-out;
  }
  &:hover {
    img {
      transform: scale(1.3);
      filter: drop-shadow(0 0 40px #fff);
    }
  }

  &.in-active {
    img {
      opacity: 0.3;
    }
  }

  &.program-badge {
    width: 121px;
    transform: scale(1.5);
    position: relative;
    overflow: hidden;

    &.in-active {
      img {
        opacity: 1;
        transform: translate(-100%);
      }
    }
    img {
      filter: drop-shadow(120px 0 0 #555);
    }
  }
`;

export const StyledUnitBadges = styled(Box)`
  position: relative;
  flex: 0 0 100%;
  ${(props) => props.theme.breakpoints.up('sm')} {
    order: 1;
    flex: 1 0 auto;
  }
`;

export const StyledProgramBadge = styled(Box)`
  position: relative;
  flex: 0 0 100%;
  z-index: 1;
  border-bottom: 1px solid ${(props) => props.theme.palette.primary.main};
  align-self: flex-start;
  ${(props) => props.theme.breakpoints.up('sm')} {
    border-left: 1px solid ${(props) => props.theme.palette.primary.main};
    border-bottom: 0;
    flex: 0 0 25%;
    order: 2;
  }
`;
export const StyledBadgeSection = styled(Card)`
  position: relative;
  box-shadow: none;
  margin-bottom: 25px;
  background-color: #0e1b3b;
  background-position: 100% 50%;
  background-repeat: no-repeat;
  background-size: auto 100%;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
  }

  &:after {
    background: linear-gradient(to right, rgba(14, 27, 59, 1) 0, rgba(14, 27, 59, 0.8) 100%);
    ${(props) => props.theme.breakpoints.up('sm')} {
      background: linear-gradient(to right, rgba(14, 27, 59, 1) 45%, rgba(14, 27, 59, 0.8) 100%);
    }
  }

  .MuiCardContent-root {
    padding: 15px;
    position: relative;
    z-index: 1;
    min-height: 100px;

    ${(props) => props.theme.breakpoints.up('sm')} {
      padding: 24px 33px 10px;
    }
  }

  .MuiTypography-h4 {
    ${(props) => props.theme.breakpoints.up('sm')} {
      max-width: 390px;
    }
  }

  .MuiTypography-body1 {
    ${(props) => props.theme.breakpoints.up('sm')} {
      max-width: 275px;
    }
  }
  .MuiGrid-root {
    justify-content: space-evenly;
    max-width: 100%;
    margin: 0 auto;
    text-align: center;
    ${(props) => props.theme.breakpoints.down('sm')} {
      padding-bottom: 20px;
    }
    .MuiGrid-item {
      padding-left: 0;
    }
  }

  .MuiLinearProgress-root {
    height: 10px;
    border-radius: 8px;
  }
  .header,
  .footer {
    height: 80px;
  }
`;

export const StyledAwardBadgeSlider = styled(Box)`
  position: relative;
  max-width: 350px;
  margin: 0 auto;
  position: relative;
  padding: 20px 0;

  img {
    max-width: 100%;
    display: block;
    margin: 0 auto 10px;
  }

  .awardbadge-slider {
    padding: 0 50px;
  }
`;

export const SlickPrevButton = styled(Button)`
  position: absolute;
  left: 0;
  top: 50%;
  min-width: 0;
  padding: 6px;
  height: 38px;
  width: 38px;
  z-index: 10;
  color: ${(props) => props.theme.palette.primary.main};

  &.slick-disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  &:hover,
  &:focus {
    color: ${(props) => props.theme.palette.primary.main};
  }

  &:before {
    content: '';
  }
`;

export const SlickNextButton = styled(Button)`
  position: absolute;
  right: 0;
  top: 50%;
  min-width: 0;
  height: 38px;
  width: 38px;
  padding: 5px;
  z-index: 10;
  color: ${(props) => props.theme.palette.primary.main};

  &.slick-disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  &:hover,
  &:focus {
    color: ${(props) => props.theme.palette.primary.main};
  }

  &:before {
    content: '';
  }
`;

export default StyledAwardBadgeSlider;
