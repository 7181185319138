import BadgePlaceholder from 'assets/images/badge-placehold.svg';
import { useGetRecentBadgesQuery } from 'dashboard/dashboardApi';
import { Link as RouterLink } from 'react-router-dom';
import { ROUTES } from 'utils/values';

import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { Box, Button, IconButton, Paper, Skeleton, Stack, Tooltip, Typography } from '@mui/material';

const RecentBadges = () => {
  const { data: badges, isSuccess, isLoading } = useGetRecentBadgesQuery();

  const Badge = ({ badge, hasBadge }) => (
    <Box sx={{ flexGrow: 1, textAlign: 'center' }}>
      <img
        width={97}
        alt={hasBadge ? badge?.badgeName : 'Badge place holder'}
        src={hasBadge ? badge?.imageUrl : BadgePlaceholder}
      />
    </Box>
  );

  return (
    <Paper elevation={3} sx={{ px: 2, py: 2 }}>
      <Stack pb={2} direction="row" alignItems="center" justifyContent="space-between">
        <Typography vairant="h2" sx={{ fontSize: '1.25rem', fontWeight: 500 }}>
          Recent Badges
          <Tooltip title="As you earn badges, they will be displayed here!" arrow placement="top">
            <IconButton size="small" variant="text" color="primary">
              <HelpOutlineIcon />
            </IconButton>
          </Tooltip>
        </Typography>
        <Button size="small" to={ROUTES.BADGES} component={RouterLink}>
          See all Badges
        </Button>
      </Stack>
      <Stack direction="row" alignItems="center" gap={2} sx={{ overflowX: 'auto' }}>
        {isSuccess &&
          Array.from(Array(7).keys()).map((key, index) => (
            <Badge key={key} badge={badges[index]} hasBadge={index < badges.length} />
          ))}
        {isLoading &&
          Array.from(Array(7).keys()).map((key) => (
            <Skeleton key={key} sx={{ flexGrow: 1, height: 110, minWidth: 97 }} />
          ))}
      </Stack>
    </Paper>
  );
};

export default RecentBadges;
