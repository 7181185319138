import parse from 'html-react-parser';
import { Link, generatePath } from 'react-router-dom';
import { ROUTES } from 'utils/values';

import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { Box, CardActionArea, CardContent, CardHeader, CardMedia, Chip, Stack, Typography } from '@mui/material';

import { FeaturedArticleContainer } from '../styles';

const FeaturedArticle = ({ id, cover, time, skills, title, summary }) => {
  const articleSummary = parse(summary);
  return (
    <FeaturedArticleContainer elevation={2}>
      <CardActionArea LinkComponent={Link} to={generatePath(ROUTES.TEACH_ARTICLE, { id })}>
        <Box sx={{ position: 'absolute', top: '1rem', right: '1rem', zIndex: 10 }}>
          <Chip label="New!" color="primary" />
        </Box>

        <Box sx={{ overflow: 'hidden' }}>
          <CardMedia component="img" height="140" image={cover} alt={title} />
        </Box>

        <CardHeader
          title={title}
          titleTypographyProps={{ fontWeight: 700, fontSize: '0.75rem', lineHeight: 1.6 }}
          subheader={articleSummary}
          subheaderTypographyProps={{
            fontWeight: 500,
            fontSize: '0.625rem',
            color: 'text',
            lineHeight: 1.4,
            sx: {
              '& img': { display: 'none' },
              display: '-webkit-box',
              WebkitLineClamp: 3,
              WebkitBoxOrient: 'vertical',
              overflow: 'hidden',
            },
          }}
        />

        <CardContent sx={{ paddingTop: 0.5 }}>
          <Stack direction="row" flexWrap="wrap" gap={1}>
            {skills?.map((skill) => (
              <Chip label={skill.name} key={skill.id} size="small" />
            ))}

            <Stack direction="row" alignItems="center" gap={0.6}>
              <AccessTimeIcon fontSize="0.75rem" sx={{ color: 'text.secondary' }} />
              <Typography varaint="caption" color="text.secondary" fontSize="0.75rem">
                {time} Min Read
              </Typography>
            </Stack>
          </Stack>
        </CardContent>
      </CardActionArea>
    </FeaturedArticleContainer>
  );
};
export default FeaturedArticle;
