import { Box, Card, styled } from '@mui/material';

export const QuoteBox = styled(Box, { shouldForwardProp: (prop) => prop !== 'banner' })`
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${({ banner }) => banner});
  background-size: cover;
  background-position: center;
`;

export const YouTubeIframeWrapper = styled(Box)`
  position: relative;
  padding-top: 60.2%;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

export const FeaturedArticleContainer = styled(Card)`
  position: relative;

  & .MuiCardMedia-img {
    transition: all 0.4s ease-in-out;
  }

  &:hover .MuiCardMedia-img {
    transform: scale(1.2);
  }

  &::after {
    content: '';
    width: 31rem;
    height: 7.5rem;
    position: absolute;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0) 100%);
    top: 1rem;
    right: -13rem;
    transform: rotate(55deg);
    transition: all 0.6s;
    pointer-events: none;
  }

  &:hover::after {
    right: -8rem;
  }
`;
