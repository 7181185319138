import Slider from 'react-slick';

import { Box, Typography } from '@mui/material';

import CourseProgressIndicator from './CourseProgressIndicator';
import UnitProgressSlide from './UnitProgressSlide';

const ProgramProgress = ({ progress: { averageProgress, unitsProgress }, hideProgramName }) => {
  const programUnitCount = unitsProgress?.units?.length;

  return (
    <Box>
      {!hideProgramName && (
        <Box textAlign="center">
          <Typography variant="h4">{unitsProgress.yearGroupName}</Typography>
        </Box>
      )}

      <Box mt={4}>
        <CourseProgressIndicator value={averageProgress} />
      </Box>

      <Box mt={-12}>
        <Slider slidesToShow={programUnitCount < 3 ? programUnitCount : 3}>
          {unitsProgress?.units?.map((unit) => (
            <UnitProgressSlide
              name={unit?.displayName}
              isLocked={unit?.isLocked}
              key={unit?.id}
              value={unit?.progress}
            />
          ))}
        </Slider>
      </Box>
    </Box>
  );
};

export default ProgramProgress;
