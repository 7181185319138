import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledDashboard = styled('div')`
  position: relative;
`;

export const StyledSliders = styled('div')`
  position: relative;

  ${(props) => props.theme.breakpoints.up('sm')} {
    display: flex;
    align-items: center;
    justify-content: space-around;
  }

  ${(props) => props.theme.breakpoints.up('md')} {
    padding-left: 50px;
  }

  .slick-slider {
    width: 100%;
  }

  .slick-list {
    z-index: 1;
  }

  .slick-slide {
    &.slick-active {
      z-index: 1;
    }
  }

  .character-slider {
    &:before {
      content: '';
      position: absolute;
      left: 50%;
      top: 70px;
      width: 160px;
      height: 160px;
      margin-left: -80px;
      background: radial-gradient(50% 50% at 50% 50%, #feffe2 0%, rgba(254, 255, 226, 0) 100%);
      background-size: 100% 100%;
      background-repeat: no-repeat;
      background-position: 0 0;
      opacity: 0.4;
    }

    &:after {
      content: '';
      position: absolute;
      left: 50%;
      margin-left: -55px;
      bottom: 25px;
      width: 110px;
      height: 22px;
      background: radial-gradient(50% 50% at 50% 50%, #c4c4c4 0%, rgba(196, 196, 196, 0) 100%);
      background-size: 100% 100%;
      background-repeat: no-repeat;
      background-position: 0 0;
    }

    img {
      margin: 0 auto;
      max-height: 400px;
    }

    .slick-dots {
      bottom: -12px;

      li {
        width: auto;
        height: auto;
        vertical-align: top;

        button {
          height: 8px;
          width: 8px;
          background: ${(props) => props.theme.palette.text.secondary};
          padding: 0;
          margin: 0;
          border-radius: 20px;

          &:hover {
            background: ${(props) => props.theme.palette.text.primary};
          }

          &:before {
            display: none;
          }
        }

        &.slick-active {
          button {
            background: ${(props) => props.theme.palette.text.primary};
          }
        }
      }
    }
  }
`;

export const DescriptionSliderFrame = styled('div')`
  position: relative;

  ${(props) => props.theme.breakpoints.up('sm')} {
    flex: 0 0 auto;
    flex-grow: 1;
    max-width: 230px;
  }

  ${(props) => props.theme.breakpoints.up('smm')} {
    max-width: 368px;
  }
`;

export const CharacterSliderFrame = styled('div')`
  max-width: 200px;
  position: relative;
  flex: 0 0 auto;
  flex-grow: 1;
  margin: 0 auto;

  ${(props) => props.theme.breakpoints.up('sm')} {
    margin: 0;
  }

  .slick-list {
    padding-bottom: 22px;
  }

  .slick-slide {
    height: 420px;
    padding-bottom: 18px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
  }
`;

export const SlickPrevButton = styled(Button)`
  position: absolute;
  right: 100%;
  top: 50%;
  min-width: 0;
  padding: 6px;
  margin: -18px 7px 0 0;
  z-index: 10;
`;

export const SlickNextButton = styled(Button)`
  position: absolute;
  left: 100%;
  top: 50%;
  min-width: 0;
  padding: 5px;
  margin: -18px 0 0 7px;
  z-index: 10;
`;

export default StyledDashboard;
