import { useState } from 'react';
import { CompletedImgGradient, CompletedTagContainer, CpdCardContainer } from 'teach/styles';
import { notifyError } from 'utils/notification';

import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import StarIcon from '@mui/icons-material/Star';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import {
  Box,
  CardActionArea,
  CardContent,
  CardHeader,
  CardMedia,
  Chip,
  Grid,
  Grow,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';

import { useSetFavoriteArticleMutation } from '../teachApi';
import ArticlePreview from './ArticlePreview';

const CpdCard = ({ isFavorite, cpdData, totalFavoriteArticles = 0 }) => {
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const { title, cover, time, isCompleted, id, skills } = cpdData;

  const [setFavoriteArticle] = useSetFavoriteArticleMutation();

  const maxFavArticlesAllowed = 10;

  const handleFavoriteArticle = async () => {
    if (!isFavorite && totalFavoriteArticles >= maxFavArticlesAllowed) {
      notifyError(`Only ${maxFavArticlesAllowed} articles are allowed to be marked as favorite`);
      return;
    }

    const data = { action: isFavorite ? 'remove' : 'add', id, article: cpdData };
    await setFavoriteArticle(data)
      .unwrap()
      .catch((err) => {
        notifyError(err.data.detail);
      });
  };

  return (
    <Grow in={Boolean(true)} timeout={1000}>
      <Grid item xs={12} xss={6} smm={4} md={3}>
        <CpdCardContainer elevation={3}>
          <CardActionArea onClick={() => setIsPreviewOpen(true)}>
            {isCompleted && (
              <CompletedTagContainer direction="row" gap={0.5} alignItems="center">
                <Typography variant="caption">Completed</Typography>
                <CheckCircleIcon color="success" fontSize=".8rem" />
              </CompletedTagContainer>
            )}

            <Box sx={{ overflow: 'hidden', position: 'relative' }}>
              <CardMedia component="img" height="140" image={cover} alt={title} />
              {isCompleted && <CompletedImgGradient role="presentation" />}
            </Box>

            <CardHeader
              title={title}
              titleTypographyProps={{
                variant: 'caption',
                sx: {
                  display: '-webkit-box',
                  WebkitLineClamp: 2,
                  WebkitBoxOrient: 'vertical',
                  overflow: 'hidden',
                  height: `${2 * 0.75 * 16 * 1.66}px`, // number of lines * font-size * 1rem * line-height
                },
              }}
            />

            <CardContent sx={{ pt: 0, minHeight: 75 }}>
              {skills.map((skill) => (
                <Chip
                  size="small"
                  key={skill?.id}
                  label={skill?.name}
                  sx={{ fontSize: '0.725rem', m: '2px 4px 2px -2px' }}
                />
              ))}
            </CardContent>
          </CardActionArea>

          <CardContent sx={{ pt: 1 }}>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <Stack direction="row" alignItems="center" gap={1}>
                <AccessTimeIcon fontSize="0.75rem" color="text.secondary" />
                <Typography varaint="caption" color="text.disabled" fontSize="0.75rem">
                  {time} Min
                </Typography>
              </Stack>

              <IconButton aria-label="favorite" size="small" onClick={handleFavoriteArticle}>
                {isFavorite ? (
                  <StarIcon color="text.secondary" sx={{ width: '1rem', height: '1rem' }} />
                ) : (
                  <StarOutlineIcon color="text.secondary" sx={{ width: '1rem', height: '1rem' }} />
                )}
              </IconButton>
            </Stack>
          </CardContent>
        </CpdCardContainer>

        <ArticlePreview isPreviewOpen={isPreviewOpen} closePreview={() => setIsPreviewOpen(false)} article={cpdData} />
      </Grid>
    </Grow>
  );
};

export default CpdCard;
