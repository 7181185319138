import { Box, Grid, Paper, Skeleton, Stack, Typography } from '@mui/material';

import { ActivityNotificationIcon } from '../styles';

const SelfAssessmentSkeleton = () => (
  <Paper elevation={3}>
    <Grid container direction="row" p={4} alignItems="center" justifyContent="space-between" spacing={2} mt={1}>
      <Grid item xs={9}>
        <Stack direction="row" mb={1}>
          <Box mr={4}>
            <ActivityNotificationIcon elevation={18}>{}</ActivityNotificationIcon>
          </Box>
          <Stack direction="column">
            <Typography vairant="h6" mr={1} sx={{ fontSize: '1.25rem', fontWeight: 500 }}>
              <Skeleton width={100} />
            </Typography>
            <Typography variant="body2">
              <Skeleton width={150} />
            </Typography>
            <Typography variant="body2">
              <Skeleton width={150} />
            </Typography>
          </Stack>
        </Stack>
      </Grid>
      <Grid item xs={2}>
        <Stack direction="column" justifyContent="center">
          <Skeleton sx={{ height: 35 }} />
          <Skeleton sx={{ height: 35 }} />
        </Stack>
      </Grid>
    </Grid>
  </Paper>
);

export default SelfAssessmentSkeleton;
