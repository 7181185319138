import { Box, CardContent, Grid, Typography } from '@mui/material';

import { StyledBadgeSection } from '../styles';
import Badge from './Badge';

const BoosterBadgeCard = (props) => {
  const { skillName, backgroundImage, badges } = props;

  return (
    <StyledBadgeSection style={{ backgroundImage: `url(${backgroundImage})` }}>
      <Box className="header">
        <CardContent>
          <Typography component="h2" variant="h5" sx={{ mb: 1.8 }}>
            {skillName}
          </Typography>
        </CardContent>
      </Box>
      <Grid container spacing={2}>
        {badges?.map((badge) => (
          <Badge key={badge.id} badge={badge} booster />
        ))}
      </Grid>
    </StyledBadgeSection>
  );
};

export default BoosterBadgeCard;
