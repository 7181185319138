import { EditorState, convertToRaw } from 'draft-js';
import { useState } from 'react';
import GenEditor from 'components/GenEditor';

const useEditor = (initialState = EditorState.createEmpty()) => {
  const [editorState, setEditorState] = useState(initialState);

  const handleEditorStateChange = (newEditorState) => setEditorState(newEditorState);
  const getRawEditorContent = () => convertToRaw(editorState.getCurrentContent());
  const getEditorStateInJson = () => JSON.stringify(convertToRaw(editorState.getCurrentContent()));
  const clearEditorState = () => setEditorState(EditorState.createEmpty());
  const isEditorEmpty = () => {
    const content = editorState.getCurrentContent();
    return !content.hasText() || !content.getPlainText().trim().length;
  };

  const Editor = <GenEditor editorState={editorState} handleEditorStateChange={handleEditorStateChange} />;

  return { editorState, clearEditorState, getEditorStateInJson, getRawEditorContent, isEditorEmpty, Editor };
};

export default useEditor;
