import { Grid, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import BadgeEntry from 'journals/components/BadgeEntry';
import ProblemEntry from 'journals/components/ProblemEntry';
import StudentEntry from 'journals/components/StudentEntry';
import TeacherEntry from 'journals/components/TeacherEntry';


const entryComponents = {
  BoosterBadgeAward: (props) => <BadgeEntry {...props} />,
  StudentReflection: (props) => <StudentEntry {...props} />,
  TeacherFeedback: (props) => <TeacherEntry {...props} />,
  ProblemEntry: (props) => <ProblemEntry {...props} />,
};

const JournalEntries = ({ journals, view }) => {
  const theme = useTheme();
  const isScreenGtMd = useMediaQuery(theme.breakpoints.up('md'));
  const isScreenGtSm = useMediaQuery(theme.breakpoints.up('sm'));
  const getGridCols = () => {
    if (isScreenGtMd) return 3;
    if (isScreenGtSm) return 2;
    return 1;
  };

  let content;

  if (!journals.length) {
    content = (
      <Grid item xs={12}>
        <Typography variant="h4" textAlign="center">
          No Results Found!
        </Typography>
      </Grid>
    );
  } else if (view === 'list') {
    content = journals.map((journal) => (
      <Grid item xs={12} key={`${journal.id}${journal.type}`}>
        {entryComponents[journal.type]({ ...journal, extended: true, cardElevation: 8 })}
      </Grid>
    ));
  } else if (view === 'grid') {
    const maxCols = getGridCols();
    const columnContent = [];

    for (let i = 0; i < maxCols; i++) {
      columnContent.push({ id: i, content: [] });
    }

    for (let i = 0; i < journals.length; i++) {
      const item = entryComponents[journals[i].type]({
        ...journals[i],
        key: `${journals[i].id}${journals[i].type}`,
        cardElevation: 8,
      });
      columnContent[i % maxCols].content.push(item);
    }

    content = columnContent.map((columnEntries) => (
      <Grid item xs={12 / maxCols} key={columnEntries.id}>
        <Stack gap={2}>{columnEntries.content}</Stack>
      </Grid>
    ));
  }

  return (
    <Grid container spacing={2} p={3}>
      {content}
    </Grid>
  );
};

export default JournalEntries;
