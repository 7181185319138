import { Helmet } from 'react-helmet';
import { PALTFORM_NAME } from 'utils/values';

const Page = ({ title = '', children }) => (
  <>
    <Helmet>
      <title>{title === '' ? PALTFORM_NAME : `${PALTFORM_NAME} | ${title}`}</title>
    </Helmet>
    {children}
  </>
);

export default Page;
