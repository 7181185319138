import { Box, Card, Grid, Skeleton, darken, useTheme } from '@mui/material';

const ReflectionSkeleton = () => {
  const theme = useTheme();

  const ChartSkeleton = (
    <>
      <Skeleton sx={{ mb: 3, height: 40 }} />
      <Skeleton variant="recangle" sx={{ mb: 4, height: 150 }} />
      <Skeleton variant="recangle" sx={{ height: 40, width: 100 }} />
    </>
  );

  const InterrogationChartSkeleton = (
    <Grid item xs={12}>
      <Box
        sx={{
          p: 4,
          mx: 'auto',
          borderTop: `1px solid ${darken(theme.palette.primary.dark, 0.3)}`,
        }}
      >
        {ChartSkeleton}
      </Box>
    </Grid>
  );

  const LikertSkeleton = (
    <>
      <Grid item xs={12} smm={6}>
        <Box sx={{ p: 4, mx: 'auto' }}>{ChartSkeleton}</Box>
      </Grid>
      <Grid item xs={12} smm={6}>
        <Box sx={{ p: 4, mx: 'auto' }}>{ChartSkeleton}</Box>
      </Grid>
    </>
  );

  return (
    <Card elevation={4} sx={{ mb: 3 }}>
      <Grid container spacing={3} mb={3}>
        {LikertSkeleton}
        {InterrogationChartSkeleton}
      </Grid>
    </Card>
  );
};

export default ReflectionSkeleton;
