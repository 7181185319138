import { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, TextField } from '@mui/material';
import useEditor from 'hooks/useEditor';
import { notifyError, notifySuccess } from 'utils/notification';


import { useUpdateJournalMutation } from '../journalsApi';

const EditJournal = ({ open, handleClose, title, reflections, id }) => {
  const [newTitle, setNewTitle] = useState(title);
  const { getEditorStateInJson, isEditorEmpty, Editor } = useEditor(reflections);

  const [updateJournal, { isLoading }] = useUpdateJournalMutation();

  const disableSaveButton = isEditorEmpty() || isLoading;

  const handleUpdateJournal = async () => {
    const data = { id, title: newTitle, description: getEditorStateInJson() };

    try {
      const res = await updateJournal(data).unwrap();
      notifySuccess(res);
      handleClose();
    } catch (error) {
      notifyError(error.data.detail);
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md" PaperProps={{ elevation: 3 }}>
      <DialogContent>
        <TextField
          id="journal-title"
          label="Title"
          size="small"
          fullWidth
          value={newTitle}
          onChange={(e) => setNewTitle(e.target.value)}
        />
        {Editor}
      </DialogContent>

      <DialogActions sx={{ justifyContent: 'flex-start' }}>
        <Button variant="contained" onClick={handleUpdateJournal} disabled={disableSaveButton}>
          Save
        </Button>
        <Button variant="outlined" onClick={handleClose}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditJournal;
