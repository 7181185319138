import { useClassFavoriteMutation } from 'classes/classApi';
import ConfirmationDialog from 'components/ConfirmationDialog';
import { memo, useState } from 'react';
import { Link, generatePath } from 'react-router-dom';
import { stringToAvatarColors } from 'utils/utilityFunctions';
import { CLASS_MESSAGES, ROUTES } from 'utils/values';

import StarIcon from '@mui/icons-material/Star';
import {
  Avatar,
  Button,
  IconButton,
  Stack,
  TableCell,
  TableRow,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';

import StyledClassRowTooltip from '../styles';

const TeacherClassRow = ({ isFavorite = false, classData, belongsToPrimary = false }) => {
  const [classFavoriteDialogOpen, setClassFavoriteDialogOpen] = useState(false);

  const [setClassFavoriteStatus, { isLoading: changingFavoriteStatus }] = useClassFavoriteMutation();
  const theming = useTheme();
  const isScreenLtSm = useMediaQuery(theming.breakpoints.down('xss'));

  const handleSetClassFavoriteStatus = async (action) => {
    try {
      await setClassFavoriteStatus({ classId: classData.id, action }).unwrap();
    } catch (error) {
      console.log(error);
    }
  };

  const handleclassFavoriteDialogConfirm = () => {
    setClassFavoriteDialogOpen(false);
    handleSetClassFavoriteStatus('remove');
  };

  let addToFavoriteButton;

  if (isFavorite) {
    addToFavoriteButton = (
      <>
        <IconButton
          color="primary"
          disabled={changingFavoriteStatus}
          onClick={() => setClassFavoriteDialogOpen(true)}
          aria-label="Unfavorite"
        >
          <StarIcon />
        </IconButton>

        <ConfirmationDialog
          isOpen={classFavoriteDialogOpen}
          title={`Class ${classData.name}`}
          content="Do you want to remove this class from your Classes?"
          handleClose={() => setClassFavoriteDialogOpen(false)}
          handleConfirm={handleclassFavoriteDialogConfirm}
        />
      </>
    );
  } else {
    addToFavoriteButton = (
      <Button disabled={changingFavoriteStatus} onClick={() => handleSetClassFavoriteStatus('add')}>
        <Typography noWrap variant="button">
          + My CLASSES
        </Typography>
      </Button>
    );
  }

  const [backgroundColor, textColor] = stringToAvatarColors(classData.name);

  return (
    <TableRow
      sx={{
        '&:last-child td': { border: 0 },
        '& td': { borderColor: 'divider' },
        whiteSpace: 'nowrap',
      }}
    >
      <TableCell>
        <Stack direction="row" alignItems="center" gap={2}>
          <Avatar sx={{ backgroundColor, color: textColor }}>{classData.name.slice(0, 2)}</Avatar>
          <Stack>
            <Typography noWrap variant="caption" sx={{ color: 'text.disabled' }}>
              Class Name
            </Typography>
            <Typography sx={{ color: 'white' }}>{classData.name}</Typography>
          </Stack>
        </Stack>
      </TableCell>

      {!isScreenLtSm && (
        <>
          {belongsToPrimary ? (
            <TableCell>
              <Stack>
                <StyledClassRowTooltip title={CLASS_MESSAGES?.JOIN_CLASS} placement="top" arrow>
                  <Typography variant="caption" sx={{ color: 'text.disabled' }}>
                    Join Code
                  </Typography>
                </StyledClassRowTooltip>
                <Typography variant="body2">{classData?.groupId}</Typography>
              </Stack>
            </TableCell>
          ) : (
            <>
              <TableCell>
                <Stack>
                  <Typography variant="caption" sx={{ color: 'text.disabled' }}>
                    Current Unit
                  </Typography>
                  <Typography variant="body2">{classData.currentUnit}</Typography>
                </Stack>
              </TableCell>

              <TableCell>
                <Stack>
                  <Typography variant="caption" sx={{ color: 'text.disabled' }}>
                    Lesson
                  </Typography>
                  <Typography variant="body2">{classData.lesson}</Typography>
                </Stack>
              </TableCell>
            </>
          )}
        </>
      )}

      <TableCell sx={{ textAlign: 'center' }}>{addToFavoriteButton}</TableCell>

      <TableCell>
        <Stack sx={{ alignItems: 'flex-end' }}>
          <Button
            variant="outlined"
            size="small"
            LinkComponent={Link}
            sx={{ width: 100 }}
            to={generatePath(ROUTES.CLASSDASHBOARD, { classId: classData.id })}
          >
            View
          </Button>
        </Stack>
      </TableCell>
    </TableRow>
  );
};

export default memo(TeacherClassRow);
