import AddAlertIcon from '@mui/icons-material/AddAlert';
import { Button, Skeleton, Stack, Typography, useTheme } from '@mui/material';

const NotificationSkeleton = ({ type }) => {
  const theme = useTheme();

  const borderColors = {
    info: theme.palette.info.dark,
    warning: theme.palette.warning.dark,
    success: theme.palette.success.dark,
    error: theme.palette.error.dark,
    grey: theme.palette.grey['400'],
  };

  return (
    <Stack
      border="1px solid"
      borderColor={borderColors[type]}
      borderRadius={1}
      p={1}
      direction="row"
      alignItems="center"
      gap={1}
      flexWrap="wrap"
    >
      <AddAlertIcon color={type} />
      <Typography variant="caption">
        <Skeleton width={300} />
      </Typography>
      <Button size="small" sx={{ ml: 'auto' }}>
        <Skeleton width={60} />
      </Button>
    </Stack>
  );
};

export default NotificationSkeleton;
