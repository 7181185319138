import SearchIcon from '@mui/icons-material/Search';
import { Backdrop, Box, CircularProgress, Grid, InputAdornment, Stack, TextField, Typography } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { debounce } from 'utils/utilityFunctions';

import { useGetBadgesQuery } from '../../../../badges/badgesApi';
import { StyledDataGrid } from '../../styles';

const CircularBackdropProgress = () => (
  <Backdrop open={!!true} sx={{ position: 'relative', zIndex: 5, height: '100%' }}>
    <CircularProgress />
  </Backdrop>
);

const columns = [
  {
    field: 'badgeName',
    headerName: 'Badges',
    width: '500',
    renderCell: (params) => (
      <Stack direction="row" gap={2} alignItems="center">
        <Box component="img" width={50} src={params.value.imageUrl} alt="Gen Plus" />
        <Typography variant="body2">{params.value.displayName}</Typography>
      </Stack>
    ),
  },
];

const SelectBadges = ({ title, setSelectedBadges, selectedBadges }) => {
  const [badgeNameSearch, setBadgeNameSearch] = useState('');
  const { data: badges, isLoading } = useGetBadgesQuery(badgeNameSearch);
  const [rows, setRows] = useState([]);
  const [selectionModel, setSelectionModel] = useState([]);
  const handleSearch = (event) => setBadgeNameSearch(event.target.value);
  const debouncedSearch = useMemo(() => debounce(handleSearch), []);

  useEffect(() => {
    if (!isLoading) {
      const recs = badges.map((badge) => ({
        id: badge.id,
        badgeName: { displayName: badge.displayName, imageUrl: badge.imageUrl },
      }));
      setRows(recs);
      setSelectionModel(selectedBadges);
    }
  }, [badgeNameSearch, badges, isLoading, selectedBadges]);

  return (
    <Box mt={{ xs: 1, sm: 3 }} mb={{ xs: 2, sm: 5 }}>
      <Grid container px={2}>
        <Grid item xs={8}>
          <Typography variant="h6">{title}</Typography>
        </Grid>
        <Grid item xs={4}>
          <Box>
            <TextField
              id="badge-search"
              placeholder="Search..."
              size="small"
              variant="outlined"
              sx={{ width: '100%' }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              onChange={debouncedSearch}
            />
          </Box>
        </Grid>
      </Grid>

      <Box sx={{ width: '100%', height: 400 }} borderBottom={1} borderColor="primary.dark">
        <StyledDataGrid
          rows={rows}
          columns={columns}
          components={{
            LoadingOverlay: CircularBackdropProgress,
          }}
          loading={isLoading}
          rowHeight={55}
          disableSelectionOnClick
          checkboxSelection
          hideFooter
          disableColumnMenu
          getRowClassName={(params) => (params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd')}
          selectionModel={selectionModel}
          onSelectionModelChange={(ids) => {
            setSelectedBadges(ids);
            setSelectionModel(ids);
          }}
        />
      </Box>
    </Box>
  );
};

export default SelectBadges;
