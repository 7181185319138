import { Navigate } from 'react-router-dom';
import { useGetUserInfoQuery } from 'users/userApi';
import { ROUTES } from 'utils/values';

import StudentDashboard from './studentDashboard';
import TeacherDashboard from './teacherDashboard';

const Dashboard = () => {
  const { data: user } = useGetUserInfoQuery();

  if (!user.hasChangedPassword) {
    // redirect to force password change
    return <Navigate to={ROUTES.PASSWORD_CHANGE} />;
  }

  if (user.isStudent && !user.onBoard) {
    return <Navigate to={ROUTES.STUDENTONBOARD} />;
  }

  if (user.isStudent) {
    return <StudentDashboard />;
  }

  return <TeacherDashboard />;
};

export default Dashboard;
