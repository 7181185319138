import { useGetClassStudentsQuery, useGetFiltersQuery, useGetStudentsAnswersQuery } from 'classes/classApi';
import BackdropLinearProgress from 'components/BackdropLinearProgress';
import { Fragment, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import SyncIcon from '@mui/icons-material/Sync';
import {
  Box,
  Button,
  Card,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Skeleton,
  Stack,
  TextField,
  Typography,
  darken,
  useTheme,
} from '@mui/material';

import { useTabs } from '../../tabsContext';
import AggregateMultipleChoices from './AggregateMultipleChoices';
import AggregateSingleChoice from './AggregateSingleChoice';
import AnswersSkeleton from './AnswersSkeleton';
import MultipleChoices from './MultipleChoices';
import ShortAnswers from './ShortAnswers';

const StudentAnswers = () => {
  const { activeTab } = useTabs();
  const { classId } = useParams();
  const { data: filters, isLoading: isLoadingFilters, isSuccess: isSuccessFilters } = useGetFiltersQuery({ classId });

  const {
    data: students,
    isLoading: isLoadingStudents,
    isSuccess: isSuccessStudents,
  } = useGetClassStudentsQuery({ classId });

  const theme = useTheme();
  const [skip, setSkip] = useState(true);
  const [student, setStudent] = useState(activeTab.userId);
  const [lesson, setLesson] = useState('');
  const [unit, setUnit] = useState('');
  const disableStudentNavButton = isLoadingFilters || !filters?.students?.length;
  let index = 0;

  const handleStudentNavClick = (offset) => {
    const totalStudents = students?.results.length;
    const studentList = students?.results.map((s) => s.userId);
    const nextStudent = (offset + totalStudents + parseInt(studentList.indexOf(student))) % totalStudents;
    setStudent(studentList[nextStudent]);
  };

  const {
    data: problems,
    isFetching,
    isSuccess,
    isLoading: isLoadingProblems,
    refetch,
  } = useGetStudentsAnswersQuery({ student, classId, lesson, unit }, { skip });

  const classLessons = filters?.classUnits.filter((cUnit) => cUnit.courseKey === unit)[0]?.classLessons || [];

  useEffect(() => {
    if (isSuccessFilters && isSuccessStudents && filters?.classUnits.length) {
      const course = filters?.classUnits[0];
      setUnit(course?.courseKey);
      setLesson(course?.classLessons[0]?.usageKey || '');
      setSkip(!course?.classLessons[0]?.usageKey || false);
    }
  }, [isSuccessFilters, isSuccessStudents, filters?.classUnits, setSkip]);

  const handleLessons = (e) => {
    setLesson(e.target.value);
  };

  const handleUnits = (e) => {
    setUnit(e.target.value);
    const firstLesson = filters?.classUnits.filter((cUnit) => cUnit.courseKey === e.target.value)[0]?.classLessons;
    const lessonKey = firstLesson.length > 0 ? firstLesson[0]?.usageKey : '';
    setLesson(lessonKey);
    setSkip(!lessonKey.length > 0);
  };

  const ProblemUnit = (problem) => {
    index += 1;
    if (problem.problemType === 'multiple_choice') {
      return (
        <>
          {student === 'all' ? (
            <AggregateMultipleChoices index={index} problem={problem} classId={classId} unit={unit} />
          ) : (
            <MultipleChoices index={index} problem={problem} />
          )}
        </>
      );
    }
    if (problem.problemType === 'short_answers') {
      index += Object.keys(problem?.results).length - 1;
      return <ShortAnswers student={student} index={index} problem={problem} classId={classId} unit={unit} />;
    }
    return (
      <>
        {student === 'all' ? (
          <AggregateSingleChoice index={index} problem={problem} classId={classId} unit={unit} />
        ) : (
          <MultipleChoices index={index} problem={problem} />
        )}
      </>
    );
  };

  return (
    <>
      <Card elevation={4} sx={{ mb: 3, position: 'relative' }}>
        {isFetching && <BackdropLinearProgress />}
        <Box
          sx={{
            borderBottom: `1px solid ${darken(theme.palette.primary.dark, 0.3)}`,
            p: 2,
          }}
        >
          {(isLoadingFilters || isLoadingStudents) && (
            <>
              <Grid container spacing={2} pb={2}>
                <Grid item xs={12} sm={6}>
                  <Skeleton variant="rectangular" height={40} />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Skeleton variant="rectangular" height={40} />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Skeleton variant="rectangular" height={40} />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Skeleton variant="rectangular" height={40} />
                </Grid>
              </Grid>
            </>
          )}
          {isSuccessStudents && isSuccessFilters && (
            <>
              <Grid container spacing={2} pb={2}>
                <Grid item xs={12} smm={6} pr={{ xs: '100px', smm: 0 }}>
                  <InputLabel sx={{ overflow: 'visible' }}>
                    <TextField
                      select
                      id="select-student"
                      label="Student"
                      size="small"
                      fullWidth
                      value={student}
                      onChange={(e) => setStudent(e.target.value)}
                    >
                      <MenuItem value="all">All Students</MenuItem>
                      {students?.results?.map((currentStudent) => (
                        <MenuItem key={currentStudent?.id} value={currentStudent?.userId}>
                          {currentStudent?.username}
                        </MenuItem>
                      ))}
                    </TextField>
                  </InputLabel>
                </Grid>
                <Grid
                  item
                  xs={12}
                  smm={6}
                  sx={{
                    display: 'flex',
                    alignItems: { xs: 'stretch', smm: 'center' },
                    gap: 2,
                    justifyContent: 'flex-end',
                    flexDirection: { xs: 'column', smm: 'row' },
                  }}
                >
                  <Stack
                    direction="row"
                    sx={{
                      position: { xs: 'absolute', smm: 'static' },
                      right: 16,
                      top: 16,
                      mr: 'auto',
                    }}
                  >
                    <IconButton
                      aria-label="previous-student"
                      disabled={disableStudentNavButton}
                      onClick={() => handleStudentNavClick(-1)}
                    >
                      <ChevronLeftIcon />
                    </IconButton>
                    <IconButton
                      aria-label="next-student"
                      disabled={disableStudentNavButton}
                      onClick={() => handleStudentNavClick(1)}
                    >
                      <ChevronRightIcon />
                    </IconButton>
                  </Stack>
                  <Button
                    size="small"
                    variant="outlined"
                    disabled={isFetching}
                    onClick={refetch}
                    aria-label="Refetch Student Answers"
                  >
                    <SyncIcon />
                  </Button>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <InputLabel sx={{ overflow: 'visible' }}>
                    <TextField
                      id="course-filter"
                      label="Unit"
                      size="small"
                      select
                      fullWidth
                      value={unit}
                      onChange={handleUnits}
                    >
                      {filters?.classUnits.map((classUnit) => (
                        <MenuItem key={classUnit.courseKey} value={classUnit.courseKey}>
                          {classUnit.displayName}
                        </MenuItem>
                      ))}
                    </TextField>
                  </InputLabel>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <InputLabel sx={{ overflow: 'visible' }}>
                    <TextField
                      id="unit-filter"
                      label="Lesson"
                      size="small"
                      select
                      fullWidth
                      value={lesson}
                      onChange={handleLessons}
                    >
                      {classLessons.map((classLesson) => (
                        <MenuItem key={classLesson.usageKey} value={classLesson.usageKey}>
                          {classLesson.displayName}
                        </MenuItem>
                      ))}
                    </TextField>
                  </InputLabel>
                </Grid>
              </Grid>
            </>
          )}
        </Box>
        {(isLoadingFilters || isLoadingStudents || isLoadingProblems) && <AnswersSkeleton />}
        {isSuccess &&
          lesson &&
          !isFetching &&
          problems.length > 0 &&
          problems.map((problem) => <Fragment key={problem.problemId}>{ProblemUnit(problem)}</Fragment>)}
        {isSuccess && !problems.length && (
          <Typography p={3} textAlign="center">
            There are no questions in this lesson.
          </Typography>
        )}
      </Card>
    </>
  );
};

export default StudentAnswers;
