import SatckedChart from 'components/charts/StackedChart';

import { useMediaQuery } from '@mui/material';

import NoResponse from './NoResponse';

const AllStudentsChart = ({ skills, dataset, isLikert }) => {
  let nuanceChartHeight = 170;
  let likertChartHeight = 170;
  if (useMediaQuery('(min-width: 480px)')) {
    nuanceChartHeight = 130;
    likertChartHeight = 130;
  }
  if (useMediaQuery('(min-width: 650px)')) {
    nuanceChartHeight = 100;
    likertChartHeight = 100;
  }
  if (useMediaQuery('(min-width: 767px)')) {
    likertChartHeight = 170;
  }
  if (useMediaQuery('(min-width: 992px)')) {
    nuanceChartHeight = 70;
    likertChartHeight = 120;
  }

  const sortedData = ([veryUncompfortable, uncompfortable, ...rest]) => [uncompfortable, veryUncompfortable, ...rest];
  return dataset.length > 0 ? (
    <SatckedChart
      skills={skills}
      dataset={sortedData(dataset)}
      height={isLikert ? likertChartHeight : nuanceChartHeight}
    />
  ) : (
    <NoResponse />
  );
};

export default AllStudentsChart;
