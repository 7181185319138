import { Box, Typography } from '@mui/material';
import { nanoid } from '@reduxjs/toolkit';

import AnswersCard from './AnswersCard';

const ShortAnswer = ({ problem, index }) => {
  const { questionText, answers } = problem;
  return (
    <AnswersCard key={problem.problemId} title={questionText} index={index}>
      <Box sx={{ py: 4 }}>
        {answers.map((answer) => (
          <Typography key={nanoid()}>{answer?.answer}</Typography>
        ))}
      </Box>
    </AnswersCard>
  );
};

export default ShortAnswer;
