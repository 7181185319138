import { nanoid } from '@reduxjs/toolkit';
import ConfirmationDialog from 'components/ConfirmationDialog';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Navigate, Link as RouterLink, useParams } from 'react-router-dom';
import { useGetUserInfoQuery } from 'users/userApi';
import { notifyError, notifySuccess } from 'utils/notification';
import { DEFAULT_CLASS, PAGE_TITLE, PALTFORM_NAME, ROUTES } from 'utils/values';

import {
  DeleteOutlined as DeleteOutlinedIcon,
  DriveFileRenameOutlineOutlined as DriveFileRenameOutlineOutlinedIcon,
  InfoOutlined as InfoOutlinedIconIcon,
  MoreVert as MoreVertIcon,
  NavigateNext as NavigateNextIcon,
} from '@mui/icons-material';
import {
  Box,
  Breadcrumbs,
  Button,
  Container,
  IconButton,
  Link,
  ListItemIcon,
  Menu,
  MenuItem,
  Skeleton,
  Tab,
  Tabs,
  Tooltip,
  Typography,
  styled,
  useMediaQuery,
  useTheme,
} from '@mui/material';

import { useDeleteClassMutation, useGetClassQuery } from '../classApi';
import RenameClass from './RenameClass';
import ClassBadges from './components/badges';
import ClassSummary from './components/classSummary';
import Journals from './components/journals';
import SkillsReflection from './components/skillsReflection';
import StudentAnswers from './components/studentAnswers';
import { CLASS_TABS_ACTIONS, useTabs } from './tabsContext';

const StyledTab = styled(Tab)(() => ({
  textTransform: 'none',
  '&.Mui-selected': {
    backgroundColor: '#1F4C6E',
  },
}));

const StyledJoinCodeButton = styled(Button)(({ theme }) => ({
  marginLeft: 'auto',
  borderRadius: '50px',
  backgroundColor: theme.palette.action.hover,

  '&:hover': {
    backgroundColor: 'transparent',
  },
}));

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`tabpanel-${index}`} aria-labelledby={`tab-${index}`} {...other}>
      {value === index && <Box sx={{ mt: 4 }}>{children}</Box>}
    </div>
  );
};

const a11yProps = (index) => ({
  id: `tab-${index}`,
  'aria-controls': `tabpanel-${index}`,
});

const ClassDashboard = () => {
  const theme = useTheme();
  const isScreenLtSm = useMediaQuery(theme.breakpoints.down('sm'));
  const { data: user } = useGetUserInfoQuery();
  const { activeTab, dispatch } = useTabs();
  const [anchorEl, setAnchorEl] = useState(null);
  const [classNamePopOpen, setClassNamePopOpen] = useState(false);
  const [openClassDeletionDialog, setOpenClassDeletionDialog] = useState(false);
  const [joinCodeCopied, setJoinCodeCopied] = useState(false);

  const [deleteClass, { isSuccess: classDeleted }] = useDeleteClassMutation();

  const { classId } = useParams();
  const { data: classData, isLoading, isSuccess } = useGetClassQuery({ classId });

  const handleTabChange = (event) => {
    dispatch({ type: event.target.name, belongsToPrimary: user.belongsToPrimary });
  };
  const tabs = [
    { label: 'Class Summary', component: <ClassSummary />, action: CLASS_TABS_ACTIONS.SHOW_SUMMARY },
    { label: 'Journals', component: <Journals />, action: CLASS_TABS_ACTIONS.SHOW_JOURNALS },
    { label: 'Meta-Skills Tracker', component: <SkillsReflection />, action: CLASS_TABS_ACTIONS.SHOW_REFLECTIONS },
    { label: 'Badges', component: <ClassBadges />, action: CLASS_TABS_ACTIONS.SHOW_BADGES },
  ];

  // Add the Student Answers tab conditionally
  if (!user?.belongsToPrimary) {
    tabs.splice(2, 0, {
      label: 'Student Answers',
      component: <StudentAnswers />,
      action: CLASS_TABS_ACTIONS.SHOW_ANSWERS,
    });
  }
  useEffect(() => () => dispatch({ type: CLASS_TABS_ACTIONS.SHOW_SUMMARY }), [dispatch]);

  const handleClassDeletion = () => {
    setAnchorEl(null);
    setOpenClassDeletionDialog(true);
  };

  const handleClassRenameOpen = () => {
    setAnchorEl(null);
    setClassNamePopOpen(true);
  };

  const handleClassDeletionConfirm = async () => {
    await deleteClass({ name: classData?.name, classId })
      .unwrap()
      .then(() => {
        notifySuccess('Class deleted successfully');
      })
      .catch(() => {
        notifyError('Something went wrong. Please try again later!');
      });
  };

  const isMenuOpen = Boolean(anchorEl);
  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const copyJoinCode = () => {
    navigator.clipboard.writeText(classData?.groupId);
    setJoinCodeCopied(true);
  };

  const isDefaultClass = classData?.name === DEFAULT_CLASS;

  if (classDeleted) {
    return <Navigate to={ROUTES.CLASSES} />;
  }

  return (
    <Container maxWidth="md">
      {isSuccess && (
        <Helmet>
          <title>{`${PALTFORM_NAME} | ${PAGE_TITLE?.CLASSES} | ${
            classData.programName ? `${classData.name} - ${classData.programName}` : classData.name
          } | ${user?.school}`}</title>
        </Helmet>
      )}
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Breadcrumbs separator={<NavigateNextIcon />} aria-label="breadcrumbs">
          <Link underline="hover" key="1" color="inherit" component={RouterLink} to={ROUTES.CLASSES}>
            Classes
          </Link>
          {isLoading && <Skeleton variant="text" width={50} />}
          {isSuccess && (
            <Typography key="2" color="text.primary">
              {classData.programName ? `${classData.name} - ${classData.programName}` : classData.name}
            </Typography>
          )}
        </Breadcrumbs>
        {isSuccess && user?.belongsToPrimary && !isDefaultClass && (
          <>
            <IconButton
              aria-label="more"
              id="Edit/Delete Class Button"
              aria-controls={isMenuOpen ? 'Edit/Delete Class Mwnu' : undefined}
              aria-expanded={isMenuOpen ? 'true' : undefined}
              aria-haspopup="true"
              onClick={handleMenuClick}
            >
              <MoreVertIcon fontSize="small" />
            </IconButton>
            <Menu
              id="Edit/Delete Class Mwnu"
              MenuListProps={{
                'aria-labelledby': 'Edit/Delete Class Button',
              }}
              anchorEl={anchorEl}
              open={isMenuOpen}
              onClose={handleMenuClose}
            >
              <MenuItem onClick={handleClassRenameOpen} sx={{ color: 'primary.main' }}>
                <ListItemIcon sx={{ color: 'inherit' }}>
                  <DriveFileRenameOutlineOutlinedIcon fontSize="small" />
                </ListItemIcon>
                <Typography variant="inherit">Rename Class</Typography>
              </MenuItem>
              <MenuItem onClick={handleClassDeletion} sx={{ color: 'primary.main' }}>
                <ListItemIcon sx={{ color: 'inherit' }}>
                  <DeleteOutlinedIcon fontSize="small" />
                </ListItemIcon>
                <Typography variant="inherit">Delete Class</Typography>
              </MenuItem>
            </Menu>
            <Tooltip
              placement="top"
              arrow
              onMouseLeave={() => setJoinCodeCopied(false)}
              title={joinCodeCopied ? 'Copied to clipboard' : 'Click to Copy Code'}
            >
              <StyledJoinCodeButton
                variant="filled"
                onClick={copyJoinCode}
                startIcon={<InfoOutlinedIconIcon color="primary" />}
              >
                Join Code: {classData?.groupId}
              </StyledJoinCodeButton>
            </Tooltip>
          </>
        )}
      </Box>

      <Box mt={3}>
        <Box sx={{ borderBottom: 2, borderColor: '#1F4C6E' }}>
          <Tabs
            value={activeTab?.activeMainTab}
            onChange={handleTabChange}
            aria-label="class dashboard option selection tabs"
            variant={isScreenLtSm ? 'scrollable' : 'fullWidth'}
            scrollButtons="auto"
            allowScrollButtonsMobile
            selectionFollowsFocus
          >
            {tabs.map((tab, index) => (
              <StyledTab key={nanoid()} name={tab.action} label={tab.label} {...a11yProps(index)} />
            ))}
          </Tabs>
        </Box>

        {tabs.map((tab, index) => (
          <TabPanel key={nanoid()} value={activeTab?.activeMainTab} index={index}>
            {tab.component}
          </TabPanel>
        ))}
      </Box>
      {isSuccess && (
        <ConfirmationDialog
          isOpen={openClassDeletionDialog}
          handleClose={() => setOpenClassDeletionDialog(false)}
          handleConfirm={handleClassDeletionConfirm}
          title={`Are you sure you want to delete ${classData?.name}`}
          content=""
        />
      )}
      {classNamePopOpen && <RenameClass open={classNamePopOpen} handleClose={setClassNamePopOpen} classId={classId} />}
    </Container>
  );
};

export default ClassDashboard;
