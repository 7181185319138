import { useContactGenPlusMutation } from 'classes/classApi';
import { useState } from 'react';
import { notifyError, notifySuccess } from 'utils/notification';

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';

const placeholderText = `Please list your missing classes like:
- Class 2A
- Class 3B
...
`;

const ContactUs = ({ open, handleClose }) => {
  const [message, setMessage] = useState('');

  const [contact, { isLoading }] = useContactGenPlusMutation();

  const handleContact = async () => {
    try {
      const res = await contact({ message }).unwrap();
      notifySuccess(res.success);
      setMessage('');
    } catch (error) {
      notifyError('Failed');
    }

    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth PaperProps={{ elevation: 3 }}>
      <DialogTitle>Contact Us</DialogTitle>

      <DialogContent>
        <TextField
          multiline
          id="email-body"
          fullWidth
          rows={6}
          placeholder={placeholderText}
          value={message}
          onChange={(e) => setMessage(e.target.value)}
        />
      </DialogContent>

      <DialogActions sx={{ justifyContent: 'flex-start' }}>
        <Button type="submit" variant="contained" onClick={handleContact} disabled={isLoading || !message}>
          Send
        </Button>
        <Button variant="outlined" onClick={handleClose}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ContactUs;
