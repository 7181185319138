import { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, TextField } from '@mui/material';
import { useCreateJournalMutation } from 'journals/journalsApi';
import { notifyError, notifySuccess } from 'utils/notification';


const AddTeacherFeedback = ({ isOpen, handleClose, student }) => {
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');

  const [createJournal, { isLoading }] = useCreateJournalMutation();

  const disableSaveButton = isLoading || !(title.trim() && content.trim());

  const { id, username } = student || {};

  const handleSave = async () => {
    const data = { studentId: id, title, description: content };

    try {
      const res = await createJournal(data).unwrap();
      notifySuccess(res);

      setTitle('');
      setContent('');
      handleClose();
    } catch (error) {
      notifyError(error.data);
    }
  };

  return (
    <Dialog open={isOpen} onClose={handleClose} maxWidth="sm" fullWidth PaperProps={{ elevation: 3 }} scroll="body">
      <DialogTitle>{`Send feedback to ${username}'s Journal`}</DialogTitle>

      <DialogContent>
        <Stack mt={0.5} gap={1.5}>
          <TextField
            id="journal-feedback-title"
            label="Title"
            size="small"
            variant="outlined"
            fullWidth
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            required
          />
          <TextField
            multiline
            rows={4}
            id="journal-feedback-content"
            placeholder="Type your comments here..."
            size="small"
            variant="outlined"
            fullWidth
            value={content}
            onChange={(e) => setContent(e.target.value)}
            required
          />
        </Stack>
      </DialogContent>

      <DialogActions sx={{ justifyContent: 'flex-start' }}>
        <Button type="submit" variant="contained" disabled={disableSaveButton} onClick={handleSave}>
          Save
        </Button>
        <Button variant="outlined" onClick={handleClose}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddTeacherFeedback;
