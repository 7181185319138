import ChatIcon from '@mui/icons-material/Chat';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import NoteAltIcon from '@mui/icons-material/NoteAlt';
import { IconButton, Stack, Tooltip, Typography } from '@mui/material';

import { useGetUserInfoQuery } from '../../../../users/userApi';
import { CLASS_TABS_ACTIONS, useTabs } from '../../tabsContext';
import CreateAvatar from './CreateAvatar';

const StudentCell = ({ student, showTabs = false }) => {
  const { dispatch } = useTabs();

  const ids = {
    studentId: student?.studentId,
    userId: student?.userId,
  };
  const {
    data: { belongsToPrimary },
  } = useGetUserInfoQuery();
  const handleJouranlClick = () => {
    dispatch({ type: CLASS_TABS_ACTIONS.SHOW_JOURNALS, ...ids });
  };

  const handleAnswersClick = () => {
    dispatch({ type: CLASS_TABS_ACTIONS.SHOW_ANSWERS, ...ids });
  };

  const handleReflectionsClick = () => {
    dispatch({ type: CLASS_TABS_ACTIONS.SHOW_REFLECTIONS, ...ids });
  };

  return (
    <Stack direction="row" alignItems="center" flexGrow={1}>
      <CreateAvatar profileImage={student?.profilePic} name={student?.name} />
      <Tooltip title={student.name} placement="top-start">
        <Typography noWrap variant="body2" color="primary" width={100} px={1} flexGrow={1}>
          {student.name}
        </Typography>
      </Tooltip>
      {showTabs && (
        <>
          <Tooltip arrow title="View Journal" placement="top">
            <IconButton onClick={handleJouranlClick} color="primary" size="small">
              <ChatIcon fontSize="inherit" />
            </IconButton>
          </Tooltip>
          <Tooltip arrow title={belongsToPrimary ? 'View Meta-Skills Tracker' : 'View Answers'} placement="top">
            <IconButton onClick={handleAnswersClick} color="primary" size="small">
              <NoteAltIcon fontSize="inherit" />
            </IconButton>
          </Tooltip>
          <Tooltip arrow title={belongsToPrimary ? 'View Badges' : 'View Meta-Skills Tracker'} placement="top">
            <IconButton onClick={handleReflectionsClick} color="primary" size="small">
              <LightbulbIcon fontSize="inherit" />
            </IconButton>
          </Tooltip>
        </>
      )}
    </Stack>
  );
};

export default StudentCell;
