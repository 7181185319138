import { Link, generatePath } from 'react-router-dom';
import { stringToColor } from 'utils/utilityFunctions';
import { ROUTES } from 'utils/values';

import { Avatar, Button, Stack, TableCell, TableRow, Typography, useMediaQuery, useTheme } from '@mui/material';

const ClassRow = ({ id, name, currentUnit, lesson, belongsToSecondary }) => {
  const theming = useTheme();
  const isScreenLtSm = useMediaQuery(theming.breakpoints.down('xss'));

  return (
    <TableRow
      sx={{
        '&:last-child td': { border: 0 },
        '& td': { borderColor: 'divider' },
        whiteSpace: 'nowrap',
      }}
    >
      <TableCell sx={{ pl: 3 }}>
        <Stack direction="row" gap={2} alignItems="center">
          <Avatar sx={{ backgroundColor: stringToColor(name) }}>{name.slice(0, 2)}</Avatar>
          <Stack>
            <Typography variant="body2" color="text.secondary">
              ClassName
            </Typography>
            <Typography variant="body1">{name}</Typography>
          </Stack>
        </Stack>
      </TableCell>
      {!isScreenLtSm && belongsToSecondary && (
        <>
          <TableCell>
            <Stack>
              <Typography variant="body2" color="text.secondary">
                Current Unit
              </Typography>
              <Typography variant="body1">{currentUnit}</Typography>
            </Stack>
          </TableCell>

          <TableCell>
            <Stack>
              <Typography variant="body2" color="text.secondary">
                Lesson
              </Typography>
              <Typography variant="body1">{lesson}</Typography>
            </Stack>
          </TableCell>
        </>
      )}

      <TableCell>
        <Stack sx={{ alignItems: 'flex-end' }}>
          <Button
            size="small"
            variant="outlined"
            LinkComponent={Link}
            sx={{ width: 100 }}
            to={generatePath(ROUTES.CLASSDASHBOARD, { classId: id })}
          >
            View class
          </Button>
        </Stack>
      </TableCell>
    </TableRow>
  );
};

export default ClassRow;
