import baseApiSlice from 'api/baseApiSlice';
import { GENZ_TEACH_API_URL } from 'config';

const teachApiWithTags = baseApiSlice.enhanceEndpoints({
  addTagTypes: ['Teach', 'Portfolio'],
});

const teachApiSlice = teachApiWithTags.injectEndpoints({
  endpoints: (builder) => ({
    getContentFilters: builder.query({
      query: () => ({
        url: `${GENZ_TEACH_API_URL}filters/`,
      }),
      providesTags: [{ type: 'Teach', id: 'LIST' }],
    }),

    getArticles: builder.query({
      query: ({
        page = '',
        pageSize = '',
        skill = '',
        mediaType = '',
        gtcs = '',
        search = '',
        isCompleted = '',
        fields = 'id,title,skills,cover,time,is_completed,summary,created,gtcs',
        academicYear,
      }) => ({
        url: `${GENZ_TEACH_API_URL}articles/`,
        params: { page, pageSize, skill, mediaType, gtcs, search, isCompleted, fields, academicYear },
      }),
      providesTags: [{ type: 'Teach', id: 'LIST' }],
    }),

    getArticle: builder.query({
      query: ({ id }) => ({
        url: `${GENZ_TEACH_API_URL}articles/${id}/`,
        method: 'GET',
      }),
      providesTags: (result) => (result ? [{ type: 'Teach', id: result.id }] : []),
    }),
    setFavoriteArticle: builder.mutation({
      query: ({ action, id }) => {
        const request = {
          url: `${GENZ_TEACH_API_URL}articles/${id}/add_favorite/`,
          method: 'PUT',
          data: { action },
        };
        return request;
      },
      invalidatesTags: ['Teach'],
    }),

    addArticleReflection: builder.mutation({
      query: ({ id, answer }) => ({
        url: `${GENZ_TEACH_API_URL}articles/${id}/answer/`,
        method: 'PUT',
        data: { answer },
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'Teach', id: arg.articleId },
        { type: 'Teach', id: 'LIST' },
        { type: 'Portfolio', id: 'LIST' },
      ],
    }),

    rateArticle: builder.mutation({
      query: ({ id, data }) => ({
        url: `${GENZ_TEACH_API_URL}articles/${id}/rate/`,
        method: 'PUT',
        data,
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'Teach', id: arg.id }],
    }),

    logArticleEngagementTime: builder.mutation({
      query: ({ id, data }) => ({
        url: `${GENZ_TEACH_API_URL}articles/${id}/log/`,
        method: 'PUT',
        data,
      }),
    }),

    getPortfolios: builder.query({
      query: ({ limit = 10, offset = 0, skill = '', standard = '', search = '' }) => ({
        url: `${GENZ_TEACH_API_URL}portfolio/`,
        method: 'GET',
        params: { limit, offset, skill, search, gtcs: standard, o: '-created' },
      }),
      providesTags: (result) =>
        result
          ? [...result.results?.map(({ id }) => ({ type: 'Portfolio', id })), { type: 'Portfolio', id: 'LIST' }]
          : [{ type: 'Portfolio', id: 'LIST' }],
    }),

    getPortfoliosReflections: builder.query({
      query: ({ page = 1, skill = '', standard = '', search = '', academicYear = '' }) => ({
        url: `${GENZ_TEACH_API_URL}portfolio-reflection/`,
        method: 'GET',
        params: { page, skill, standard, search, academicYear },
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.results?.map(({ objectId }) => ({ type: 'Portfolio', id: objectId })),
              { type: 'Portfolio', id: 'LIST' },
            ]
          : [{ type: 'Portfolio', id: 'LIST' }],
    }),

    updatePortfolio: builder.mutation({
      query: ({ id, data }) => ({
        url: `${GENZ_TEACH_API_URL}portfolio/${id}/`,
        method: 'PUT',
        data,
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'Portfolio', id: arg.id }],
    }),

    createPortfolio: builder.mutation({
      query: ({ data }) => ({
        url: `${GENZ_TEACH_API_URL}portfolio/`,
        method: 'POST',
        data,
      }),
      invalidatesTags: [{ type: 'Portfolio', id: 'LIST' }],
    }),
  }),

  overrideExisting: false,
});

export const {
  useGetContentFiltersQuery,
  useGetArticlesQuery,
  useGetArticleQuery,
  useAddArticleReflectionMutation,
  useGetFavoriteArticlesQuery,
  useSetFavoriteArticleMutation,
  useRateArticleMutation,
  useLogArticleEngagementTimeMutation,
  useGetPortfoliosQuery,
  useUpdatePortfolioMutation,
  useCreatePortfolioMutation,
  useGetPortfoliosReflectionsQuery,
} = teachApiSlice;

export default teachApiSlice;
