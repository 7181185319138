import { Box, Grid, Paper, Skeleton, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';

const ClassSummarySkeleton = () => {
  const theme = useTheme();
  const isScreenLtSm = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Paper elevation={4}>
      <Box borderBottom={1} borderColor="divider" sx={{ p: 2 }}>
        <Typography variant="h6">Available Lessons</Typography>
      </Box>

      <Grid container>
        <Grid
          item
          xs={12}
          sm={4}
          md={2.5}
          sx={{
            py: 2,
            borderRight: {
              xs: 0,
              sm: `1px solid ${theme.palette.divider}`,
            },
            borderBottom: {
              xs: `1px solid ${theme.palette.divider}`,
              sm: 0,
            },
          }}
        >
          <Skeleton variant="rectangular" height={isScreenLtSm ? '3.5rem' : '11rem'} />
        </Grid>

        <Grid item xs={12} sm={8} md={9.5}>
          <Stack direction="row" gap={2} p={2} flexWrap="wrap">
            {[0, 1, 2, 4].map((x) => (
              <Skeleton
                variant="rectangular"
                height="7rem"
                width="9rem"
                key={x}
                sx={{ display: 'flex', flexGrow: 1, borderRadius: 2 }}
              />
            ))}
          </Stack>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default ClassSummarySkeleton;
