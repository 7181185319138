import { nanoid } from '@reduxjs/toolkit';
import { Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { useGetAllStudentsAnswersQuery } from 'classes/classApi';
import NoAnswerMessage from 'classes/classDashboard/components/studentAnswers/NoAnswerMessage';
import { StyledTableRow, UnStyledList } from 'classes/classDashboard/styles';


const ChoicesModal = ({ classId, unit, problemKey }) => {
  const { data: respose, isLoading } = useGetAllStudentsAnswersQuery({ classId, unit, problemKey });

  return (
    <>
      {isLoading && (
        <>
          <Skeleton height={30} sx={{ mt: 4 }} />
          <Skeleton height={30} />
          <Skeleton height={30} />
          <Skeleton height={30} />
          <Skeleton height={30} />
        </>
      )}
      {respose && (
        <TableContainer>
          <Table sx={{ minWidth: 650, mt: 4 }} aria-label="simple table" size="small">
            <TableHead>
              <TableRow>
                <TableCell width="25%">
                  <Typography sx={{ opacity: 0.7 }}>Name</Typography>
                </TableCell>
                <TableCell width="55%">
                  <Typography sx={{ opacity: 0.7 }}>Response</Typography>
                </TableCell>
                <TableCell width="20%">
                  <Typography sx={{ opacity: 0.7 }}>Result</Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {respose?.results.length > 0 ? (
                respose?.results.map((result) => (
                  <StyledTableRow key={result.username}>
                    <TableCell>{result.fullName}</TableCell>
                    <TableCell>
                      <UnStyledList>
                        {result?.answer.split('||').map((listItem) => (
                          <li key={nanoid()}>{listItem}</li>
                        ))}
                      </UnStyledList>
                    </TableCell>
                    <TableCell>
                      {result?.earnedScore !== 0 && result?.totalScore !== 0
                        ? `${result?.earnedScore}/${result?.totalScore}`
                        : '-'}
                    </TableCell>
                  </StyledTableRow>
                ))
              ) : (
                <StyledTableRow>
                  <TableCell colSpan={3} sx={{ p: 2, textAlign: 'center' }}>
                    <NoAnswerMessage />
                  </TableCell>
                </StyledTableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
};

export default ChoicesModal;
