import { Box, CardContent, Grid, Typography } from '@mui/material';

import { StyledBadgeSection, StyledProgramBadge, StyledUnitBadges } from '../styles';
import Badge from './Badge';
import ProgressBar from './ProgressBar';

const ProgramBadgeCard = ({ displayName, imageUrl, unitBadges, awarded, awardedOn, slug, bannerImageUrl = '' }) => {
  const programBadge = { imageUrl, awarded, awardedOn };
  const unitBadgesEarned = unitBadges?.map((a) => (a.awarded ? 1 : 0));
  const totalUnitBadgesEarned = unitBadgesEarned.reduce((a, b) => a + b);
  const totalUnitBadges = unitBadges?.length;
  const programProgress = parseInt((totalUnitBadgesEarned / totalUnitBadges) * 100);

  return (
    <StyledBadgeSection style={{ backgroundImage: `url(${bannerImageUrl})` }}>
      <Box className="header">
        <CardContent>
          <Typography component="h2" variant="h5" sx={{ mb: 1.8 }}>
            {displayName}
          </Typography>
        </CardContent>
      </Box>
      <Grid container spacing={2}>
        <StyledProgramBadge sx={{ pt: 2 }}>
          <Badge key={slug} badge={programBadge} program />
        </StyledProgramBadge>
        <StyledUnitBadges>
          <Grid container spacing={2}>
            {unitBadges?.map((badge) => (
              <Badge key={badge.slug} badge={badge} />
            ))}
          </Grid>
          <ProgressBar earned={totalUnitBadgesEarned} total={totalUnitBadges} progress={programProgress} />
        </StyledUnitBadges>
      </Grid>
    </StyledBadgeSection>
  );
};

export default ProgramBadgeCard;
