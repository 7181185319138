import Abby from 'assets/images/Characters/Abby.png';
import Max from 'assets/images/Characters/Max.png';
import Nadiya from 'assets/images/Characters/Nadiya.png';
import door from 'assets/images/Door.png';

import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';

const UserRestriction = ({ title, helpText }) => {
  const theme = useTheme();
  const isScreenLtSm = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box
      mt="-30px"
      mb={10}
      sx={{
        minHeight: 550,
        position: 'relative',
        background: `linear-gradient(to bottom, ${theme.palette.background.default} 50%, rgba(36, 49, 77, 1))`,
      }}
    >
      <Box pt={6} px={2}>
        <Typography variant={isScreenLtSm ? 'h4' : 'h3'} color="white" textAlign="center">
          {title}
        </Typography>
        <Typography variant="body1" color="white" textAlign="center">
          {helpText}
        </Typography>
      </Box>

      <Box sx={{ position: 'absolute', bottom: 0, left: '50%', transform: 'translateX(-50%)' }}>
        <img src={door} alt="door" height={300} />
      </Box>
      <Box
        sx={{
          position: 'absolute',
          bottom: -30,
          left: '50%',
          transform: 'translateX(-135%)',
          '&::after': {
            content: '""',
            position: 'absolute',
            width: isScreenLtSm ? 120 : 160,
            height: 35,
            borderRadius: '100%',
            bottom: -8,
            right: -8,
            backgroundColor: 'rgba(0, 0, 0, 0.2)',
            zIndex: -1,
            transform: 'rotate(-2deg)',
          },
        }}
      >
        <img src={Max} alt="Max" />
      </Box>

      <Box
        sx={{
          position: 'absolute',
          bottom: -65,
          left: '50%',
          transform: 'translateX(-40%)',
          '&::after': {
            content: '""',
            position: 'absolute',
            width: 100,
            height: 50,
            borderRadius: '100%',
            bottom: -16,
            left: '50%',
            transform: 'translateX(-50%)',
            backgroundColor: 'rgba(0, 0, 0, 0.2)',
            zIndex: -1,
          },
        }}
      >
        <img src={Abby} alt="Abby" />
      </Box>

      <Box
        sx={{
          position: 'absolute',
          bottom: -30,
          left: '50%',
          transform: 'translateX(60%)',
          '&::after': {
            content: '""',
            position: 'absolute',
            width: isScreenLtSm ? 100 : 140,
            height: 35,
            borderRadius: '100%',
            bottom: -8,
            left: 10,
            backgroundColor: 'rgba(0, 0, 0, 0.2)',
            zIndex: -1,
            transform: 'rotate(2deg)',
          },
        }}
      >
        <img src={Nadiya} alt="Nadiya" height={280} />
      </Box>
    </Box>
  );
};

export default UserRestriction;
