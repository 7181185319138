import { MenuItem } from '@mui/material';
import React from 'react';
import { Link as RouterLink, useMatch, useResolvedPath } from 'react-router-dom';

export const MenuLink = (props) => {
  const { children, to, ...rest } = props;
  const resolved = useResolvedPath(to);
  const match = useMatch({ path: resolved.pathname, end: true });
  return (
    <MenuItem to={to} {...rest} selected={!!match} component={RouterLink}>
      {children}
    </MenuItem>
  );
};

export default MenuLink;
