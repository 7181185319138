import { ClassTabsProvider } from 'classes/classDashboard/tabsContext';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import store from 'utils/store';

import { ThemeProvider } from '@mui/material/styles';

import App from './App';
import GlobalCSS from './assets/styles/global';
import { darkTheme, lightTheme } from './assets/styles/theme';

function Main() {
  const light = Boolean(0);
  return (
    <Provider store={store}>
      <ThemeProvider theme={light ? lightTheme : darkTheme}>
        <ClassTabsProvider>
          <BrowserRouter>
            <GlobalCSS theme={light} />
            <App />
          </BrowserRouter>
          <ToastContainer
            position="top-right"
            theme="colored"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        </ClassTabsProvider>
      </ThemeProvider>
    </Provider>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<Main />);
