import CancelIcon from '@mui/icons-material/Cancel';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';

import { StyledTableRow } from '../../styles';
import AnswersCard from './AnswersCard';

const MultipleChoices = ({ problem, index }) => {
  const { problemChoices } = problem;

  const givenAnswers = problem?.results.length > 0 ? problem?.results[0]?.answer : '';

  const selectIcon = (value) => (value ? <CheckCircleIcon color="success" /> : <CancelIcon color="error" />);

  return (
    <AnswersCard key={problem.problemId} index={index} title={problem.questionText}>
      <Box sx={{ py: 4 }}>
        {problem?.results.length > 0 ? (
          <TableContainer>
            <Table aria-label="Question 1" size="small" sx={{ minWidth: 400 }}>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ width: '25%', border: 0 }}> </TableCell>
                  <TableCell sx={{ width: '25%', border: 0, textAlign: 'center' }}>
                    <Typography sx={{ opacity: 0.7 }}>Choosen Answers</Typography>
                  </TableCell>
                  <TableCell sx={{ width: '25%', border: 0, textAlign: 'center' }}>
                    <Typography sx={{ opacity: 0.7 }}>Correct Answers</Typography>
                  </TableCell>
                  <TableCell sx={{ width: '25%', border: 0, textAlign: 'center' }}>
                    <Typography sx={{ opacity: 0.7 }}>Result</Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.keys(problemChoices).length > 0 &&
                  Object.keys(problemChoices).map((choice) => (
                    <StyledTableRow key={problemChoices[choice]?.statement}>
                      <TableCell>{problemChoices[choice]?.statement}</TableCell>
                      <TableCell sx={{ textAlign: 'center' }}>
                        {givenAnswers.indexOf(problemChoices[choice]?.statement) !== -1 ? (
                          <CheckBoxIcon color="primary" />
                        ) : (
                          <CheckBoxOutlineBlankIcon />
                        )}
                      </TableCell>
                      <TableCell sx={{ textAlign: 'center' }}>
                        {problemChoices[choice]?.correct === 'true' ? (
                          <CheckBoxIcon sx={{ color: 'rgba(255, 255, 255, 0.3)' }} />
                        ) : (
                          <CheckBoxOutlineBlankIcon sx={{ color: 'rgba(255, 255, 255, 0.3)' }} />
                        )}
                      </TableCell>
                      <TableCell sx={{ textAlign: 'center' }}>
                        {givenAnswers.indexOf(problemChoices[choice]?.statement) !== -1 &&
                          selectIcon(problemChoices[choice]?.correct === 'true')}
                      </TableCell>
                    </StyledTableRow>
                  ))}
                <StyledTableRow>
                  <TableCell>TOTAL</TableCell>
                  <TableCell sx={{ textAlign: 'center' }}> </TableCell>
                  <TableCell sx={{ textAlign: 'center' }}> </TableCell>
                  <TableCell sx={{ textAlign: 'center' }}>
                    {problem?.results.length > 0 &&
                    problem?.results[0]?.earnedScore !== 0 &&
                    problem?.results[0]?.totalScore !== 0
                      ? `${problem?.results[0]?.earnedScore}/${problem?.results[0]?.totalScore}`
                      : '--/--'}
                  </TableCell>
                </StyledTableRow>
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          'No answer submitted yet'
        )}
      </Box>
    </AnswersCard>
  );
};

export default MultipleChoices;
