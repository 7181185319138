import { Box, CircularProgress, InputBase, MenuItem, Paper, Stack, alpha, lighten, styled } from '@mui/material';
import { red } from '@mui/material/colors';

export const StyledBadges = styled('div')`
  ${(props) => props.theme.breakpoints.up('md')} {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    display: flex;
    align-items: flex-end;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    justify-content: space-around;
    padding: ${({ theme }) => theme.spacing(2)};

    ${(props) => props.theme.breakpoints.up('md')} {
      display: flex;
      padding: 0;
      flex-direction: column-reverse;
    }

    li {
      overflow: hidden;
      ${(props) => props.theme.breakpoints.up('md')} {
        margin-bottom: 10px;
      }

      img {
        width: 32px;
        height: auto;
      }

      &.disabled {
        img {
          transform: translate(-100%);
          filter: drop-shadow(32px 0 0 #555);
        }
      }
    }
  }
`;

export const CharacterDisplayContainer = styled(Box)`
  position: relative;
`;

export const CharacterImage = styled('img')`
  display: block;
  width: 140px;
  z-index: 1;
`;

export const CourseSelectInputBase = styled(InputBase, { shouldForwardProp: (props) => props !== 'isNew' })`
  & .MuiInputBase-input {
    border-radius: 4px;
    position: relative;
    font-size: 1.5rem;
    padding: 0.5rem 1rem 0.1rem;

    transition: ${({ theme }) => theme.transitions.create(['background-color'])};

    &:hover {
      background-color: ${({ theme }) => alpha(lighten(theme.palette.primary.main, 0.1), 0.1)};
    }

    &:focus {
      background-color: ${({ theme }) => alpha(lighten(theme.palette.primary.main, 0.1), 0.1)};
    }

    ${(props) =>
      props.isNew &&
      `
      &::before {
        content: '';
        position: absolute;
        width: 8px;
        height: 8px;
        top: 0;
        left: 0;
        border-radius: 50%;
        background-color: ${red[500]};
      }
    `}
  }
`;

export const CourseMenuItem = styled(MenuItem, { shouldForwardProp: (props) => props !== 'isNew' })`
  ${(props) =>
    props.isNew &&
    `
      &::before {
        content: '';
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: ${red[500]};
        margin-right: 5px;
      }
    `}
`;

export const CourseProgressIndicatorContainer = styled(Box)`
  width: 17rem;
  height: 17rem;
  margin-inline: auto;
  position: relative;
`;

export const CircularCourseProgress = styled(CircularProgress, { shouldForwardProp: (props) => props !== 'track' })`
  position: absolute;
  top: 0;
  left: 0;
  transform: rotate(180deg) !important;

  color: ${(props) => (props.track ? '#2B5979' : props.theme.palette.primary)};

  & .MuiCircularProgress-circle {
    stroke-linecap: round;
  }
`;

export const CourseProgressLabelContainer = styled(Stack)`
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  top: 19%;
`;

export const UnitProgressLabelContainer = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const NextLessonContainer = styled(Paper)`
  padding: 1.5rem 2rem;
  position: relative;
  overflow: hidden;
`;

export const NextLessonImageContainer = styled(Box, { shouldForwardProp: (props) => props !== 'src' })`
  position: absolute;
  width: 60%;
  height: 100%;
  right: 0;
  top: 0;
  opacity: 0.4;
  background-image: linear-gradient(90deg, #142241 0%, rgba(20, 34, 65, 0) 100%), url(${(props) => props.src});
  background-size: cover;
  background-position: center;
  z-index: 5;
`;

export const ActivityNotificationIcon = styled(Paper, { shouldForwardProp: (props) => props !== 'isNew' })`
  display: flex;
  width: 40px;
  height: 40px;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  flex-shrink: 0;

  position: relative;

  ${(props) =>
    props.isNew &&
    `
      &::before {
        content: '';
        position: absolute;
        width: 8px;
        height: 8px;
        top: 0;
        left: 0;
        border-radius: 50%;
        background-color: ${red[500]};
      }
    `}
`;

export const SemiCircle = styled('div')`
  display: inline-block;
  background: rgba(255, 255, 255, 0.13);
  width: ${(props) => props.width}px;
  margin: 0 1em 1em 0;
  height: ${(props) => props.height}px;
  border-top-left-radius: ${(props) => props.width}px;
  border-top-right-radius: ${(props) => props.width}px;
`;

export const StyledJoinClassSection = styled(Paper)`
  display: flex;
  align-items: center;

  ${(props) => props.theme.breakpoints.down('sm')} {
    flex-direction: column;
    padding: 1rem 0;
  }

  img {
    height: auto;
    width: 225px;

    ${(props) => props.theme.breakpoints.up('sm')} {
      width: 160px;
    }

    ${(props) => props.theme.breakpoints.up('smm')} {
      width: 225px;
      height: 124px;
      object-fit: cover;
    }
  }
`;
