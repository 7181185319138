import baseApiSlice from 'api/baseApiSlice';
import { GENZ_API_URL } from 'config';
import { ROLE, SCHOOL_LEVEL, TEACHING_ROLES } from 'utils/values';

const userApiWithTags = baseApiSlice.enhanceEndpoints({ addTagTypes: ['Userinfo'] });

const userApiSlice = userApiWithTags.injectEndpoints({
  endpoints: (builder) => ({
    getUserInfo: builder.query({
      query: () => ({
        url: `${GENZ_API_URL}/userinfo/`,
      }),
      providesTags: ['Userinfo'],
      transformResponse: (response) => {
        localStorage.setItem('lms-csrf-token', response.csrfToken);

        return {
          ...response,
          isLoggedIn: true,
          isStudent: response.role === ROLE.STUDENT,
          isTeacher: TEACHING_ROLES.includes(response.role),
          belongsToPrimary: response?.schoolLevel === SCHOOL_LEVEL.PRIMARY,
          belongsToSecondary: response?.schoolLevel === SCHOOL_LEVEL.SECONDARY,
        };
      },
    }),

    setUserOnBoard: builder.mutation({
      query(data) {
        return {
          url: `${GENZ_API_URL}/characters/select/${data}/`,
          method: 'PUT',
          data: {
            onboarded: true,
          },
        };
      },
      invalidatesTags: ['Userinfo'],
    }),

    updateProfile: builder.mutation({
      query: (body) => {
        const request = {
          url: `${GENZ_API_URL}/userinfo/`,
          method: 'POST',
          data: body,
          queryOptions: {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          },
        };
        return request;
      },
      invalidatesTags: ['Userinfo', 'Progress'],
    }),

    changePassword: builder.mutation({
      query: (data) => ({
        url: `${GENZ_API_URL}/change-password/`,
        method: 'POST',
        data,
      }),
      invalidatesTags: ['Userinfo'],
    }),

    TeacherPasswordReset: builder.mutation({
      query: (data) => ({
        url: `${GENZ_API_URL}/change-password-teacher/`,
        method: 'POST',
        data,
      }),
      invalidatesTags: ['Userinfo'],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetUserInfoQuery,
  useSetUserOnBoardMutation,
  useUpdateProfileMutation,
  useChangePasswordMutation,
  useTeacherPasswordResetMutation,
} = userApiSlice;

export default userApiSlice;
