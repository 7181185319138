import { nanoid } from '@reduxjs/toolkit';
import { StyledTableRow } from 'classes/classDashboard/styles';
import { GENZ_JOURNAL_DOWNLOAD_PDF_URL } from 'config';
import { useState } from 'react';

import {
  Avatar,
  Box,
  Button,
  Dialog,
  Radio,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';

const ExportModal = ({ openExportReport, students, handleCloseExportReport }) => {
  const theme = useTheme();
  const isScreenLtSm = useMediaQuery(theme.breakpoints.down('sm'));
  const buttonSize = () => (isScreenLtSm ? 'small' : 'medium');
  const [student, setSudent] = useState('');

  const handleRadioChange = (event) => {
    setSudent(event.target.value);
  };

  const handleCloseModal = () => {
    setSudent('');
    handleCloseExportReport();
  };

  return (
    <Dialog
      aria-labelledby="Export pdf"
      aria-describedby="A modal window for export pdf for student(s)"
      open={openExportReport}
      onClose={handleCloseModal}
      fullWidth
      maxWidth="md"
      fullScreen={isScreenLtSm}
      PaperProps={{ elevation: 4 }}
    >
      <Box mt={{ xs: 1, sm: 3 }} mb={{ xs: 2, sm: 5 }} px={{ xs: 0, sm: 6 }} textAlign="center">
        <Typography variant="h6" sx={{ mb: 2, mt: 3 }}>
          Export Report/Journal
        </Typography>
        <Typography variant="body1">Select a student and click ‘Export’ to begin downloading their report.</Typography>
        <Box sx={{ maxHeight: '65vh', overflowY: 'auto' }}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell sx={{ width: '20px' }}> </TableCell>
                <TableCell colSpan={2}>Name</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {students.map((stdnt) => (
                <StyledTableRow key={nanoid()}>
                  <TableCell sx={{ paddingRight: 0 }}>
                    <Radio
                      checked={student === stdnt?.userId}
                      onChange={handleRadioChange}
                      value={stdnt?.userId}
                      name="Students"
                      inputProps={{ 'aria-label': stdnt?.username }}
                    />
                  </TableCell>
                  <TableCell sx={{ width: '20px', paddingRight: 0 }}>
                    <Avatar alt={stdnt?.username} src={stdnt?.profilePic} />
                  </TableCell>
                  <TableCell>{stdnt?.username}</TableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </Box>
      <Stack direction="row" justifyContent="space-between" px={{ xs: 0, sm: 6 }} pb={6}>
        <Stack direction="row" gap={2}>
          <Button
            variant="contained"
            size={buttonSize()}
            disabled={student === ''}
            download
            href={`${GENZ_JOURNAL_DOWNLOAD_PDF_URL}?user_id=${student}`}
          >
            Export
          </Button>
        </Stack>
        <Button onClick={handleCloseModal} variant="outlined" size={buttonSize()}>
          Cancel
        </Button>
      </Stack>
    </Dialog>
  );
};

export default ExportModal;
