import { useAddClassMutation, useValidateClassNameMutation } from 'classes/classApi';
import { useState } from 'react';
import { notifyError } from 'utils/notification';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Button,
  IconButton,
  Stack,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';

import AddClassName from './AddClassName';
import ConfirmCreateClass from './ConfirmCreateClass';
import SelectStudents from './SelectStudents';

const steps = [
  {
    label: 'Class Name',
    component: (props) => <AddClassName {...props} />,
  },
  {
    label: 'Add Students',
    component: (props) => <SelectStudents {...props} />,
  },
  {
    label: 'Confirm',
    component: (props) => <ConfirmCreateClass {...props} />,
  },
];

const CreateClass = ({ closeModal }) => {
  const theme = useTheme();
  const isScreenLtSm = useMediaQuery(theme.breakpoints.down('sm'));

  const [activeStep, setActiveStep] = useState(0);
  const [className, setClassName] = useState('');
  const [classNameError, setClassNameError] = useState('');
  const [selectedStudents, setSelectedStudents] = useState([]);
  const [students, setStudents] = useState([]);

  const [validateClassName] = useValidateClassNameMutation();
  const [addClass, { isLoading: isUpdating }] = useAddClassMutation();

  const buttonSize = () => (isScreenLtSm ? 'small' : 'medium');
  const handleClassValidation = async () => {
    await validateClassName(className.trim())
      .unwrap()
      .then(() => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      })
      .catch((err) => {
        setClassNameError(err.data.name);
      });
    setClassName(className.trim());
  };

  const handleCleateClass = async () => {
    const data = {
      name: className,
      studentIds: selectedStudents,
    };

    await addClass(data)
      .unwrap()
      .then(() => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      })
      .catch(() => {
        notifyError('Something went wrong. Please try again later!');
      });
    return null;
  };

  const stateMap = {
    className,
    setClassName,
    classNameError,
    students,
    isUpdating,
    setStudents,
    setClassNameError,
    selectedStudents,
    setSelectedStudents,
  };

  const handleBackStep = () => setActiveStep((prevActiveStep) => prevActiveStep - 1);
  const handleNextStep = () => {
    if (activeStep === 0) {
      handleClassValidation();
    } else if (activeStep === steps.length - 1) {
      handleCleateClass();
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const StepperControls = () => (
    <Stack direction="row" justifyContent="space-between" px={{ xs: 0, sm: 6 }}>
      <Stack direction="row" gap={2}>
        <Button
          variant="contained"
          disabled={className.length === 0 || isUpdating}
          onClick={handleNextStep}
          size={buttonSize()}
        >
          {activeStep === steps.length - 1 ? 'Create Class' : 'Next'}
        </Button>
        {activeStep > 0 && (
          <Button onClick={handleBackStep} variant="outlined" size={buttonSize()}>
            Back
          </Button>
        )}
      </Stack>
      <Button onClick={closeModal} variant="outlined" size={buttonSize()}>
        Cancel
      </Button>
    </Stack>
  );

  return (
    <Box sx={{ paddingY: 4, paddingX: { xs: 2, sm: 0 } }}>
      <Stepper
        activeStep={activeStep}
        alternativeLabel={!isScreenLtSm}
        orientation={isScreenLtSm ? 'vertical' : 'horizontal'}
      >
        {steps.map((step) => (
          <Step key={step.label}>
            <StepLabel>{step.label}</StepLabel>
            {isScreenLtSm && (
              <StepContent>
                {step.component(stateMap)}
                <StepperControls />
              </StepContent>
            )}
          </Step>
        ))}
      </Stepper>

      {activeStep === steps.length && (
        <Stack justifyContent="center" alignItems="center" py={{ xs: 8, sm: 15 }}>
          {closeModal ? (
            <IconButton
              aria-label="close"
              onClick={closeModal}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
              }}
            >
              <CloseIcon />
            </IconButton>
          ) : null}
          <CheckCircleIcon color="primary" sx={{ width: 120, height: 120 }} />
          <Typography mb={2} variant="body2">
            Class Created Successfully!
          </Typography>
          <Typography mb={2} variant="body2">
            {className}
          </Typography>
          <Button onClick={closeModal} variant="outlined" size={buttonSize()}>
            Close
          </Button>
        </Stack>
      )}
      {activeStep < steps.length && !isScreenLtSm && (
        <Box>
          {steps[activeStep].component(stateMap)}
          <StepperControls />
        </Box>
      )}
    </Box>
  );
};

export default CreateClass;
