import { Paper, Typography } from '@mui/material';

const PageIntro = ({ introText }) => (
  <Paper sx={{ marginTop: '16px', padding: '16px' }}>
    <Typography variant="body2" sx={{ color: 'text.primary' }}>
      {introText}
    </Typography>
  </Paper>
);

export default PageIntro;
