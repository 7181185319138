import baseApiSlice from 'api/baseApiSlice';
import { GENZ_BADGES_API_URL } from 'config';

const badgesApiSlice = baseApiSlice.injectEndpoints({
  tagTypes: ['BoosterBadges'],
  endpoints: (builder) => ({
    getBadges: builder.query({
      query: (search = '') => ({
        url: `${GENZ_BADGES_API_URL}/booster-badges/?search=${search}`,
      }),
    }),
    getProgramBadges: builder.query({
      query: (userId = '') => ({
        url: `${GENZ_BADGES_API_URL}/program-badges/?user_id=${userId}`,
      }),
    }),
    getBoosterBadges: builder.query({
      query: (userId = '') => ({
        url: `${GENZ_BADGES_API_URL}/booster-badges/student/?user_id=${userId}`,
      }),
      providesTags: ['BoosterBadges'],
    }),
    getRecentAwardedBadges: builder.query({
      query: () => ({
        url: `${GENZ_BADGES_API_URL}/recent-awarded-badges/`,
      }),
      providesTags: ['RecentAwardedBadges'],
    }),
    confirmRecentAwardedBadges: builder.mutation({
      query: (body) => {
        const request = {
          url: `${GENZ_BADGES_API_URL}/recent-awarded-badges/`,
          method: 'POST',
          data: { badges: body },
        };
        return request;
      },
      invalidatesTags: ['RecentAwardedBadges'],
    }),
    awardBadges: builder.mutation({
      query: (body) => {
        const request = {
          url: `${GENZ_BADGES_API_URL}/award-badges/`,
          method: 'POST',
          data: body,
        };
        return request;
      },
      invalidatesTags: ['BoosterBadges'],
    }),
  }),
});

export const {
  useGetBadgesQuery,
  useGetProgramBadgesQuery,
  useAwardBadgesMutation,
  useGetBoosterBadgesQuery,
  useConfirmRecentAwardedBadgesMutation,
  useGetRecentAwardedBadgesQuery,
} = badgesApiSlice;

export default badgesApiSlice;
