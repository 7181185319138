import { useGetClassStudentsQuery } from 'classes/classApi';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useGetUserInfoQuery } from 'users/userApi';
import { SCHOOLTYPES } from 'utils/values';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ElectricBoltIcon from '@mui/icons-material/ElectricBolt';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import GroupRemoveIcon from '@mui/icons-material/GroupRemove';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import LockResetIcon from '@mui/icons-material/LockReset';
import PendingIcon from '@mui/icons-material/Pending';
import SchoolIcon from '@mui/icons-material/School';
import StarsIcon from '@mui/icons-material/Stars';
import {
  Box,
  Button,
  Dialog,
  Grid,
  IconButton,
  ListItem,
  ListItemIcon,
  Menu,
  MenuItem,
  Paper,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';

import { StyledInfoChip } from '../../styles';
import AddRemoveStudents from './AddRemoveStudents';
import AwardBadge from './AwardBadge';
import ExportModal from './ExportModal';
import PasswordResetModal from './PasswordResetModal';
import StudentDataGrid from './StudentDataGrid';

const StudentList = () => {
  const theme = useTheme();
  const { classId } = useParams();
  const isScreenLtSm = useMediaQuery(theme.breakpoints.down('sm'));
  const [openAwardBadge, setOpenAwardBadge] = useState(false);
  const [actionMenu, setActionMenu] = useState(null);
  const [infoMenu, setInfoMenu] = useState(null);
  const [actionType, setActionType] = useState(false);
  const actionMenuOpen = Boolean(actionMenu);
  const infoMenuOpen = Boolean(infoMenu);

  const handleOpenAwardBadge = () => setOpenAwardBadge(true);
  const handleCloseAwardBadge = () => {
    setActionMenu(null);
    setOpenAwardBadge(false);
  };
  const [openExportReport, setOpenExportReport] = useState(false);
  const handleCloseExportReport = () => setOpenExportReport(false);

  const [openPasswordResetModal, setOpenPasswordResetModal] = useState(false);
  const handleClosePasswordResetModal = () => setOpenPasswordResetModal(false);
  const handleOpenPasswordResetModal = () => {
    setActionMenu(null);
    setOpenPasswordResetModal(true);
  };

  const [openAddRemoveStudents, setOpenAddRemoveStudents] = useState(false);
  const handleCloseAddRemoveStudents = () => {
    setOpenAddRemoveStudents(false);
  };
  const handleOpenAddRemoveStudents = (type) => () => {
    setActionType(type === 'add');
    setActionMenu(null);
    setOpenAddRemoveStudents(true);
  };

  const { data, isSuccess } = useGetClassStudentsQuery({ classId });
  const { results: studentList } = data || [];
  const { data: user = {}, isSuccess: isUserSuccess } = useGetUserInfoQuery();

  const handleCloseActionMenu = () => {
    setActionMenu(null);
  };

  const handleActionButtonClick = (event) => {
    setActionMenu(event.currentTarget);
  };

  const handleCloseInfoMenu = () => {
    setInfoMenu(null);
  };

  const handleInfoButtonClick = (event) => {
    setInfoMenu(event.currentTarget);
  };

  return (
    <Paper elevation={4}>
      <Stack
        p={2}
        direction={{
          xs: 'column',
          smm: 'row',
        }}
        justifyContent="space-between"
        alignItems="center"
        borderBottom={1}
        borderColor="primary.dark"
        gap={2}
      >
        <Typography component="h2" variant="h6">
          Student Progress
        </Typography>

        <Grid container spacing={1} sx={{ width: { sm: 'auto' } }}>
          {isUserSuccess && (
            <>
              <IconButton
                id="info-button"
                size="small"
                variant="text"
                color="primary"
                aria-controls={infoMenuOpen ? 'info-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={infoMenuOpen ? 'true' : undefined}
                aria-label="Toggle Help Menu"
                onClick={handleInfoButtonClick}
              >
                <HelpOutlineIcon />
              </IconButton>

              <Button
                id="action-button"
                size="small"
                variant="contained"
                startIcon={<ElectricBoltIcon />}
                aria-controls={actionMenuOpen ? 'action-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={actionMenuOpen ? 'true' : undefined}
                onClick={handleActionButtonClick}
              >
                Actions
              </Button>
            </>
          )}
          <Menu
            id="action-menu"
            anchorEl={actionMenu}
            open={actionMenuOpen}
            onClose={handleCloseActionMenu}
            MenuListProps={{
              'aria-labelledby': 'action-button',
            }}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            {isUserSuccess && user.schoolType === SCHOOLTYPES.PRIVATE && (
              <MenuItem onClick={handleOpenPasswordResetModal}>
                <ListItemIcon sx={{ minWidth: '30px' }}>
                  <LockResetIcon fontSize="small" color="primary" />
                </ListItemIcon>
                <Typography variant="inherit" fontSize={14} color="primary">
                  Reset Student Password
                </Typography>
              </MenuItem>
            )}
            <MenuItem onClick={handleOpenAwardBadge}>
              <ListItemIcon sx={{ minWidth: '30px' }}>
                <StarsIcon fontSize="small" color="primary" />
              </ListItemIcon>
              <Typography variant="inherit" fontSize={14} color="primary">
                Give Badges/Feedback
              </Typography>
            </MenuItem>
            {isUserSuccess && user?.belongsToPrimary && (
              <MenuItem onClick={handleOpenAddRemoveStudents('remove')}>
                <ListItemIcon sx={{ minWidth: '30px' }}>
                  <GroupRemoveIcon fontSize="small" color="primary" />
                </ListItemIcon>
                <Typography variant="inherit" fontSize={14} color="primary">
                  Remove Class Members
                </Typography>
              </MenuItem>
            )}
            {isUserSuccess && user?.belongsToPrimary && (
              <MenuItem onClick={handleOpenAddRemoveStudents('add')}>
                <ListItemIcon sx={{ minWidth: '30px' }}>
                  <GroupAddIcon fontSize="small" color="primary" />
                </ListItemIcon>
                <Typography variant="inherit" fontSize={14} color="primary">
                  Add Class Members
                </Typography>
              </MenuItem>
            )}
          </Menu>
          <Menu
            id="info-menu"
            anchorEl={infoMenu}
            open={infoMenuOpen}
            onClose={handleCloseInfoMenu}
            MenuListProps={{
              'aria-labelledby': 'info-button',
            }}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <ListItem>
              <Typography sx={{ fontWeight: '500' }}>Progress icons</Typography>
            </ListItem>
            <ListItem>
              <StyledInfoChip className="lesson-complete" icon={<CheckCircleIcon />} label="Lesson complete" />
            </ListItem>
            <ListItem sx={{ paddingTop: 0 }}>
              <StyledInfoChip
                className="lesson-incomplete"
                icon={<CheckCircleOutlineIcon />}
                label="Lesson incomplete"
              />
            </ListItem>
            <ListItem sx={{ paddingTop: 0 }}>
              <StyledInfoChip className="lesson-not-started" icon={<PendingIcon />} label="Lesson not started" />
            </ListItem>
            <ListItem sx={{ paddingTop: 0 }}>
              <StyledInfoChip className="teacher-only" icon={<SchoolIcon />} label="Teacher only" />
            </ListItem>
          </Menu>
        </Grid>
      </Stack>

      <Box sx={{ mt: 1, width: '100%' }}>
        <StudentDataGrid />
      </Box>

      <Dialog
        aria-labelledby="Award badges modal"
        aria-describedby="A modal window for awarding badges and providing feedback to the students"
        open={openAwardBadge}
        onClose={handleCloseAwardBadge}
        fullWidth
        maxWidth="md"
        fullScreen={isScreenLtSm}
        PaperProps={{ elevation: 4 }}
      >
        <AwardBadge closeModal={handleCloseAwardBadge} />
      </Dialog>
      {isSuccess && (
        <>
          <ExportModal
            openExportReport={openExportReport}
            handleCloseExportReport={handleCloseExportReport}
            students={studentList}
          />
          <PasswordResetModal
            open={openPasswordResetModal}
            closeModal={handleClosePasswordResetModal}
            students={studentList}
          />
        </>
      )}
      {openAddRemoveStudents && (
        <AddRemoveStudents
          open={openAddRemoveStudents}
          closeModal={handleCloseAddRemoveStudents}
          addStudents={actionType}
        />
      )}
    </Paper>
  );
};

export default StudentList;
