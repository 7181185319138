import { BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import IconCheck from 'assets/images/icon-check.svg';
import { barChart } from 'utils/chartValues';

const { defaultOptions, defaultValues } = barChart;

const AddCorrectBarIcon = {
  id: 'AddCorrectBarIcon',
  afterDatasetDraw: (chart) => {
    const {
      ctx,
      data,
      scales: { x: xAxis, y: yAxis },
    } = chart;
    const imageSize = 18;
    ctx.save();

    if (data?.datasets[0]?.correctAnswerIndex !== undefined && data?.datasets[0]?.correctAnswerIndex > -1) {
      const image = new Image();
      image.src = IconCheck;
      const x = xAxis.getPixelForTick(data?.datasets[0]?.correctAnswerIndex);
      ctx.drawImage(image, x - imageSize * 0.5, yAxis.bottom + imageSize * 0.3, imageSize, imageSize);
    }
    ctx.restore();
  },
};

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, AddCorrectBarIcon);

const BarChart = ({ data, options }) => (
  <Bar data={{ ...defaultValues, ...data }} options={{ ...defaultOptions, ...options }} height={300} />
);

export default BarChart;
