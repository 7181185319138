import { EditorState, convertFromRaw } from 'draft-js';
import { useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import { useGetUserInfoQuery } from 'users/userApi';
import { stringToColor } from 'utils/utilityFunctions';

import { Avatar, Button, Card, CardActions, CardHeader, Chip, Typography } from '@mui/material';

import { LMS_BASE_URL } from '../../config';
import EditJournal from './EditJournal';

const StudentEntry = ({
  title,
  description,
  isEditable,
  modified,
  skill,
  id,
  cardElevation = 3,
  extended,
  usageKey,
  courseKey,
  isEnrolled,
}) => {
  const {
    data: { isStudent },
  } = useGetUserInfoQuery();
  const [openEditJournal, setOpenEditJournal] = useState(false);
  const state = EditorState.createWithContent(convertFromRaw(JSON.parse(description)));

  return (
    <Card
      elevation={cardElevation}
      sx={{ display: { sm: extended ? 'flex' : 'block' }, justifyContent: 'space-between' }}
    >
      <CardHeader
        title={
          <Typography component="h2" variant="h6" mb={2}>
            {title}
          </Typography>
        }
        subheader={
          <>
            <Editor editorState={state} readOnly toolbarHidden />
            <Typography display="block" mt={1} variant="caption" color="text.disabled">
              {new Date(modified).toLocaleDateString('en-GB')}
            </Typography>
          </>
        }
        disableTypography
        sx={{ paddingBottom: { xs: 0, sm: extended ? 2 : 0 } }}
      />

      <CardActions
        sx={{
          padding: 2,
          justifyContent: 'space-between',
          alignItems: 'flex-end',
          flexDirection: { sm: extended ? 'column' : 'row' },
        }}
      >
        {skill && (
          <Chip
            label={skill?.name}
            avatar={
              skill?.image ? (
                <Avatar src={skill?.image} alt={`${skill?.name} skill image`} />
              ) : (
                <Avatar sx={{ backgroundColor: stringToColor(skill?.name) }}>{skill?.name[0].toUpperCase()}</Avatar>
              )
            }
            size="small"
          />
        )}
        {isStudent && isEditable && (
          <Button size="small" onClick={() => setOpenEditJournal(true)}>
            Edit
          </Button>
        )}
        {isStudent && courseKey && usageKey && (
          <Button
            size="small"
            target="_blank"
            sx={{ textTransform: 'none' }}
            href={`${LMS_BASE_URL}courses/${courseKey}/jump_to/${usageKey}`}
            disabled={!isEnrolled}
          >
            Edit Answer
          </Button>
        )}
      </CardActions>
      {isStudent && (
        <EditJournal
          open={openEditJournal}
          handleClose={() => setOpenEditJournal(false)}
          title={title}
          reflections={state}
          id={id}
        />
      )}
    </Card>
  );
};

export default StudentEntry;
