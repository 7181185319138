import { MenuItem, styled } from '@mui/material';

export const StyledProfileItem = styled(MenuItem)`
  cursor: default;

  & > .MuiTouchRipple-root {
    display: none;
  }

  &:hover {
    background: none;
  }
`;

export default StyledProfileItem;
