import { useState } from 'react';

import { Box, Button, Dialog, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import BarChart from 'components/charts/BarChart';

import { StyledCount } from '../../styles';
import AnswersCard from './AnswersCard';
import ChoicesModal from './ChoicesModal';
import NoAnswerMessage from './NoAnswerMessage';

const AggregateSingleChoice = ({ problem, index, ...rest }) => {
  const theme = useTheme();
  const isScreenLtSm = useMediaQuery(theme.breakpoints.down('sm'));
  const buttonSize = () => (isScreenLtSm ? 'small' : 'medium');
  const responses = [];
  const labels = [];
  let correctAnswerIndex = '';
  const results = Object.values(problem?.results);

  const answerExists = problem?.results.length > 0;

  const findCount = (label, items) => {
    let count = 0;
    items.forEach((item) => {
      if (item?.title === label) {
        count = item?.count;
      }
    });
    return count;
  };

  Object.values(problem?.problemChoices).forEach((choice, choiceIndex) => {
    const label = choice?.statement;
    if (choice?.correct === 'true') {
      correctAnswerIndex = choiceIndex;
    }
    labels.push(label);
    responses.push(findCount(label, results));
  });

  const studentsAnswer = {
    labels,
    datasets: [
      {
        data: responses,
        backgroundColor: '#15A7CC',
        borderColor: '#15A7CC',
        barThickness: 23,
        borderRadius: 5,
        correctAnswerIndex,
      },
    ],
  };

  const BarChartOptions = {
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        mode: 'nearest',
        intersect: false,
        borderColor: '#15a7cc',
        borderWidth: 1,
      },
    },
    scales: {
      x: {
        title: {
          display: false,
          text: 'Answers',
          color: '#defffd',
          font: {
            size: 14,
            family: 'IBM Plex Sans, Readex Pro',
            weight: 400,
          },
        },
        grid: {
          color: 'transparent',
          borderColor: 'rgba(99, 217, 255, 0.5)',
        },
        ticks: {
          color: '#defffd',
          beginAtZero: true,
          stepSize: 1,
          family: 'IBM Plex Sans, Readex Pro, FontAwesome',
          fontSize: 16,
          padding: 20,
          callback: (ind) => {
            let str = '';
            if (labels[ind].length > 20) {
              str = labels[ind].length > 40 ? `${labels[ind].substring(0, 40)}...` : labels[ind];
              const strToArray = str.split(' ');
              const midPoint = Math.ceil(strToArray.length / 2);
              const firstHalf = strToArray.filter((_, labelIndex) => labelIndex < midPoint).join(' ');
              const secondHalf = strToArray.filter((_, labelIndex) => labelIndex >= midPoint).join(' ');
              str = [firstHalf, secondHalf];
            } else {
              str = labels[ind];
            }
            return str;
          },
        },
      },
      y: {
        title: {
          display: true,
          text: 'NO. OF RESPONSES',
          color: '#defffd',
          font: {
            size: 14,
            family: 'IBM Plex Sans, Readex Pro',
            weight: 400,
          },
        },
        grid: {
          color: 'transparent',
          borderColor: 'rgba(99, 217, 255, 0.5)',
        },
        ticks: {
          color: '#defffd',
          beginAtZero: true,
          stepSize: 5,
          family: 'IBM Plex Sans, Readex Pro',
          fontSize: 16,
          precision: 0,
        },
      },
    },
  };
  const [openViewAnswers, setOpenViewAnswers] = useState(false);
  const handleCloseViewAnswers = () => setOpenViewAnswers(false);
  const handleOpenViewAnswers = () => setOpenViewAnswers(true);

  return (
    <AnswersCard
      key={problem?.problemId}
      index={index}
      title={problem?.questionText}
      studentsCount={problem?.studentsCount}
      studentsAnsweredCount={problem?.studentsAnsweredCount}
    >
      <Box sx={{ py: 4 }}>
        {answerExists ? <BarChart data={studentsAnswer} options={BarChartOptions} /> : <NoAnswerMessage />}
      </Box>
      {answerExists && (
        <>
          <Button variant="text" sx={{ textDecoration: 'underline' }} onClick={handleOpenViewAnswers}>
            View All Answers
          </Button>
          <Dialog
            aria-labelledby="Student Answers modal"
            aria-describedby="A modal window for Student Answers"
            open={openViewAnswers}
            onClose={handleCloseViewAnswers}
            fullWidth
            maxWidth="md"
            fullScreen={isScreenLtSm}
            PaperProps={{ elevation: 4 }}
          >
            <Box mt={{ xs: 1, sm: 3 }} mb={{ xs: 2, sm: 5 }} px={{ xs: 0, sm: 6 }}>
              <Box sx={{ pl: 4, position: 'relative' }}>
                <StyledCount variant="body1" component="span">
                  {index}.
                </StyledCount>
                <Box sx={{ flexGrow: 1 }}>
                  <Typography variant="body1">{problem?.questionText}</Typography>
                </Box>
              </Box>
              <Box>
                <ChoicesModal problemKey={problem?.problemKey} {...rest} />
              </Box>
            </Box>
            <Stack direction="row" justifyContent="space-between" px={{ xs: 0, sm: 6 }} pb={6}>
              <Button onClick={handleCloseViewAnswers} variant="outlined" size={buttonSize()}>
                Close
              </Button>
            </Stack>
          </Dialog>
        </>
      )}
    </AnswersCard>
  );
};

export default AggregateSingleChoice;
