import { Box, CardContent, LinearProgress, Typography } from '@mui/material';

const ProgressBar = ({ earned, total, progress }) => (
  <Box className="footer">
    <CardContent>
      <LinearProgress variant="determinate" value={progress} />
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography variant="body2" component="span">
          {earned}/{total}
        </Typography>
        <Typography variant="body2" component="span">
          {progress}%
        </Typography>
      </Box>
    </CardContent>
  </Box>
);

export default ProgressBar;
