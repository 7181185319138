import { Dialog } from '@mui/material';

import { activityTypes } from './FeedComponents';

const ActivityFeedModal = ({ modal, closeModal, data, isScreenLtSm }) => (
  <Dialog
    aria-labelledby="Read Activity Modal"
    aria-describedby="A modal window of opening an activity."
    open={modal}
    onClose={closeModal}
    fullWidth
    maxWidth={isScreenLtSm ? 'md' : 'sm'}
    PaperProps={{ elevation: 4, style: { margin: '0', width: '100%' } }}
    sx={{ p: 1 }}
  >
    {activityTypes[data.type].component(data)}
  </Dialog>
);

export default ActivityFeedModal;
