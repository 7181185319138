export const barChart = {
  defaultOptions: {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'right',
        labels: {
          usePointStyle: true,
          pointStyle: 'circle',
          boxWidth: 6,
          boxHeight: 6,
          font: {
            size: 12,
            family: 'Readex Pro',
          },
          color: '#defffd',
        },
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: 'RATING',
          color: '#defffd',
          font: {
            size: 14,
            family: 'IBM Plex Sans, Readex Pro',
            weight: 400,
          },
        },
        grid: {
          color: 'transparent',
          borderColor: 'rgba(99, 217, 255, 0.5)',
        },
        ticks: {
          color: '#defffd',
          beginAtZero: true,
          stepSize: 1,
          family: 'IBM Plex Sans, Readex Pro',
          fontSize: 16,
        },
      },
      y: {
        title: {
          display: true,
          text: 'NO. OF RESPONSES',
          color: '#defffd',
          font: {
            size: 14,
            family: 'IBM Plex Sans, Readex Pro',
            weight: 400,
          },
        },
        grid: {
          color: 'transparent',
          borderColor: 'rgba(99, 217, 255, 0.5)',
        },
        ticks: {
          color: '#defffd',
          beginAtZero: true,
          stepSize: 5,
          family: 'IBM Plex Sans, Readex Pro',
          fontSize: 16,
          precision: 0,
        },
      },
    },
  },
  defaultValues: {
    labels: ['1', '2', '3', '4', '5'],
    datasets: [
      {
        label: 'Responses',
        data: [3, 5, 6, 8, 2],
        backgroundColor: '#15A7CC',
        borderColor: '#15A7CC',
        barThickness: 23,
        borderRadius: 5,
      },
    ],
  },
};

export const fillChart = {
  defaultOptions: {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'right',
        labels: {
          usePointStyle: true,
          pointStyle: 'circle',
          boxWidth: 6,
          boxHeight: 6,
          font: {
            size: 12,
            family: 'Readex Pro',
          },
          color: '#defffd',
        },
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: 'RATING',
          color: '#defffd',
          font: {
            size: 14,
            family: 'IBM Plex Sans, Readex Pro',
            weight: 400,
          },
        },
        grid: {
          color: 'transparent',
          borderColor: 'rgba(99, 217, 255, 0.5)',
        },
        ticks: {
          color: '#defffd',
          beginAtZero: true,
          stepSize: 1,
          family: 'IBM Plex Sans, Readex Pro',
          fontSize: 16,
        },
      },
      y: {
        title: {
          display: true,
          text: 'NO. OF RESPONSES',
          color: '#defffd',
          font: {
            size: 14,
            family: 'IBM Plex Sans, Readex Pro',
            weight: 400,
          },
        },
        grid: {
          color: 'transparent',
          borderColor: 'rgba(99, 217, 255, 0.5)',
        },
        ticks: {
          color: '#defffd',
          beginAtZero: true,
          stepSize: 5,
          family: 'IBM Plex Sans, Readex Pro',
          fontSize: 16,
        },
      },
    },
  },
  defaultValues: {
    labels: [1, 2, 3, 4, 5],
    datasets: [
      {
        fill: 'origin',
        label: 'START OF YEAR',
        data: [2, 11, 14, 9, 6],
        borderColor: '#15A7CC',
        backgroundColor: (context) => {
          const { ctx } = context.chart;
          const gradient = ctx.createLinearGradient(0, 0, 0, context.chart.height);
          gradient.addColorStop(0, 'rgba(21, 167, 204, .2)');
          gradient.addColorStop(1, 'rgba(21, 167, 204, 0)');
          return gradient;
        },
      },
      {
        fill: 'origin',
        label: 'END OF YEAR',
        data: [0, 8, 10, 14, 9],
        borderColor: '#C6006F',
        backgroundColor: (context) => {
          const { ctx } = context.chart;
          const gradient = ctx.createLinearGradient(0, 0, 0, context.chart.height);
          gradient.addColorStop(0, 'rgba(198, 0, 111, .2)');
          gradient.addColorStop(1, 'rgba(198, 0, 111, 0)');
          return gradient;
        },
      },
    ],
  },
};

export const horizontalBarChart = {
  defaultOptions: {
    indexAxis: 'y',
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'right',
        labels: {
          usePointStyle: true,
          pointStyle: 'circle',
          boxWidth: 6,
          boxHeight: 6,
          font: {
            size: 12,
            family: 'Readex Pro',
          },
          color: '#defffd',
        },
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: 'AVERAGE SCORE',
          color: '#defffd',
          font: {
            size: 14,
            family: 'IBM Plex Sans, Readex Pro',
            weight: 400,
          },
        },
        grid: {
          color: 'transparent',
          borderColor: 'rgba(99, 217, 255, 0.5)',
        },
        ticks: {
          color: '#defffd',
          beginAtZero: true,
          stepSize: 1,
          family: 'IBM Plex Sans, Readex Pro',
          fontSize: 16,
        },
        max: 5,
      },
      y: {
        grid: {
          color: 'transparent',
          borderColor: 'rgba(99, 217, 255, 0.5)',
        },
      },
    },
  },
  defaultValues: {
    labels: [''],
    datasets: [
      {
        label: 'START OF YEAR',
        data: [3.8],
        backgroundColor: '#15A7CC',
        borderColor: '#15A7CC',
        barThickness: 23,
        borderRadius: 5,
      },
      {
        label: 'END OF YEAR',
        data: [5],
        borderColor: '#C6006F',
        backgroundColor: '#C6006F',
        barThickness: 23,
        borderRadius: 5,
      },
    ],
  },
};

export const radarChart = {
  defaultOptions: {
    responsive: true,
    spanGaps: true,
    scales: {
      r: {
        angleLines: {
          color: 'rgba(99, 217, 255, 0.5)',
        },
        grid: {
          color: 'rgba(99, 217, 255, 0.5)',
        },
        suggestedMin: 0,
        pointLabels: {
          font: {
            size: 13,
            weight: 300,
            family: 'IBM Plex Sans, Readex Pro',
          },
          color: '#defffd',
          pointBorderColor: '#fff',
        },
        ticks: {
          backdropColor: 'black',
          color: '#fff',
          beginAtZero: true,
          fontSize: 20,
          stepSize: 1,
        },
        max: 15,
      },
    },
    plugins: {
      legend: {
        position: 'bottom',
        labels: {
          usePointStyle: true,
          pointStyle: 'circle',
          boxWidth: 6,
          boxHeight: 6,
          font: {
            size: 12,
            family: 'Readex Pro',
          },
          color: '#defffd',
        },
      },
    },
  },
  defaultValues: {
    labels: ['Collaboration', 'Resilience', 'Initiative', 'Communication', 'Leadership', 'Organisation'],
    datasets: [
      {
        label: 'START OF YEAR',
        data: [2, 6, 3, null, 2, 3],
        backgroundColor: 'rgba(99, 217, 255, .24)',
        borderColor: 'rgba(99, 217, 255, 1)',
        pointBackgroundColor: 'rgba(99, 217, 255, 1)',
        pointBorderWidth: 1,
        pointWidth: 6,
        borderWidth: 2,
      },
      {
        label: 'END OF YEAR',
        data: [4, 14, 9, null, 4, 6],
        backgroundColor: 'rgba(255, 1, 157, .24)',
        borderColor: 'rgba(255, 1, 157, 1)',
        pointBackgroundColor: 'rgba(255, 1, 157, 1)',
        pointBorderWidth: 1,
        pointWidth: 6,
        borderWidth: 2,
      },
    ],
  },
};

export const pieChart = {
  defaultOptions: {
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
        callbacks: {
          label: (tooltipItem) => tooltipItem?.label?.split(', '),
        },
      },
    },
  },
  defaultValues: {
    labels: ['label 1', 'label 2', 'label 3', 'label 4', 'label 5'],
    datasets: [
      {
        label: 'Answers',
        data: [15, 10, 5, 8, 7],
        backgroundColor: [
          'rgba(233, 30, 99, 1)',
          'rgba(103, 58, 183, 1)',
          'rgba(0, 150, 136, 1)',
          'rgba(0, 188, 212, 1)',
          'rgba(0, 125, 255, 1)',
        ],
        borderColor: '#000',
        borderWidth: 1,
      },
    ],
  },
};

export default barChart;
