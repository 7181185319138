import { useUnlockLessonMutation } from 'classes/classApi';
import { LessonAvailabilitySelect } from 'classes/classDashboard/styles';
import ConfirmationDialog from 'components/ConfirmationDialog';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useGetUserInfoQuery } from 'users/userApi';
import { CLASS_MESSAGES, PROGRESS_STATUS } from 'utils/values';

import {
  ComputerOutlined as ComputerOutlinedIcon,
  PlayArrow as PlayArrowIcon,
  School as SchoolIcon,
} from '@mui/icons-material';
import { Box, Button, InputLabel, MenuItem, Stack, Tooltip, alpha, useTheme } from '@mui/material';

import { StyledClassImage, StyledLessonCardTitle, StyledPlayIcon, StyledViewLesson } from '../../styles';

const UNLOCKED = 'unlocked';
const LOCKED = 'locked';

const LessonCard = ({ lesson }) => {
  const theme = useTheme();

  const lessonStatus = lesson?.isLocked ? LOCKED : UNLOCKED;
  const {
    data: { belongsToSecondary },
  } = useGetUserInfoQuery();

  const [openDialog, setOpenDialog] = useState(false);

  const { classId } = useParams();
  const [unlockLesson, { isLoading }] = useUnlockLessonMutation();

  const handleUnlockLessonConfirm = () => {
    unlockLesson({ lessonId: lesson.id, classId });
    setOpenDialog(false);
  };

  const handlelessonStatusChange = (event) => {
    const status = event.target.value;

    if (status === LOCKED) return;
    setOpenDialog(true);
  };

  return (
    <>
      <ConfirmationDialog
        isOpen={openDialog}
        handleClose={() => setOpenDialog(false)}
        handleConfirm={handleUnlockLessonConfirm}
        title={`Unlock lesson ${lesson.displayName}`}
        content="Lessons cannot be locked again once unlocked"
      />

      <Stack
        flexGrow={1}
        sx={{
          borderRadius: 2,
          overflow: 'hidden',
          border: `1px solid ${alpha(theme.palette.primary.dark, 0.16)}`,
        }}
      >
        <Stack direction="row" p={1}>
          <StyledLessonCardTitle variant="body2">{lesson?.displayName}</StyledLessonCardTitle>
          {PROGRESS_STATUS.TEACHER_LED === lesson?.ledType && (
            <Tooltip arrow placement="top" title={CLASS_MESSAGES?.TEACHER_LED}>
              <Button size="small" sx={{ p: 0, minWidth: 0, alignSelf: 'flex-start' }}>
                <SchoolIcon sx={{ fontSize: 16 }} color="primary" />
              </Button>
            </Tooltip>
          )}
          {PROGRESS_STATUS.TEACHER_AND_STUDENT === lesson?.ledType && (
            <Tooltip arrow placement="top" title={CLASS_MESSAGES?.TEACHER_STUDENTS_LED}>
              <Button size="small" sx={{ p: 0, minWidth: 0, alignSelf: 'flex-start' }}>
                <SchoolIcon sx={{ fontSize: 16 }} color="primary" />
                +
                <ComputerOutlinedIcon sx={{ fontSize: 16 }} color="primary" />
              </Button>
            </Tooltip>
          )}
        </Stack>
        <StyledViewLesson size="small" variant="text" href={lesson.lmsUrl} aria-label={`Link to ${lesson.displayName}`}>
          {lesson?.thumbnail ? (
            <StyledClassImage
              loading="lazy"
              className="img-fluid"
              src={lesson?.thumbnail}
              alt={lesson?.displayName}
              height="88"
            />
          ) : (
            <Box sx={{ height: 88, backgroundColor: 'grey.500', opacity: 0.15, flexGrow: 1 }} />
          )}
          <StyledPlayIcon component="span">
            <PlayArrowIcon />
          </StyledPlayIcon>
        </StyledViewLesson>
        {belongsToSecondary && (
          <Box p={1}>
            <InputLabel sx={{ overflow: 'visible' }}>
              <LessonAvailabilitySelect
                select
                id="select-lesson-status"
                size="small"
                sx={{ minWidth: 100, width: '100%' }}
                variant="standard"
                value={lessonStatus}
                onChange={handlelessonStatusChange}
                isUnlocked={!lesson.isLocked}
                disabled={!lesson.isLocked || isLoading}
              >
                <MenuItem value={UNLOCKED}>Available</MenuItem>
                <MenuItem value={LOCKED}>Unavailable</MenuItem>
              </LessonAvailabilitySelect>
            </InputLabel>
          </Box>
        )}
      </Stack>
    </>
  );
};

export default LessonCard;
