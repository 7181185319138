import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { CircularProgress, Container, Stack, Typography } from '@mui/material';
import { LMS_LOGOUT } from 'config';
import baseApiSlice from 'api/baseApiSlice';

const UserLogout = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    localStorage.clear();

    dispatch(baseApiSlice.util.resetApiState());
    dispatch(baseApiSlice.util.invalidateTags(['Userinfo']));

    window.location.replace(LMS_LOGOUT);
  }, [dispatch]);

  return (
    <Container maxWidth="md">
      <Stack direction="row" gap={2} justifyContent="center">
        <Typography variant="h4">Logging out...</Typography>
        <CircularProgress />
      </Stack>
    </Container>
  );
};

export default UserLogout;
