import { Backdrop, LinearProgress, styled } from '@mui/material';

const ContainedBackdrop = styled(Backdrop)`
  position: absolute;
  z-index: 5;
  align-items: flex-start;
`;

const BackdropLinearProgress = ({ backdropProps, linearProgressProps }) => (
  <ContainedBackdrop open={!!true} {...backdropProps}>
    <LinearProgress sx={{ width: '100%' }} {...linearProgressProps} />
  </ContainedBackdrop>
);

export default BackdropLinearProgress;
