import baseApiSlice from 'api/baseApiSlice';
import { GENZ_API_URL, GENZ_ASSESSMENT_API_URL, GENZ_LEARNING_API_URL } from 'config';

const classApiWithTags = baseApiSlice.enhanceEndpoints({
  addTagTypes: ['Class'],
});

const classApiSlice = classApiWithTags.injectEndpoints({
  endpoints: (builder) => ({
    getClasses: builder.query({
      query: () => ({
        url: `${GENZ_API_URL}/classes/`,
      }),
      providesTags: [{ type: 'Class', id: 'LIST' }],
    }),

    getFilters: builder.query({
      query: ({ classId }) => ({
        url: `${GENZ_ASSESSMENT_API_URL}/genz-filters/${classId}/`,
      }),
      providesTags: [{ type: 'Answers', id: 'Filters' }],
    }),

    getStudentsAnswers: builder.query({
      query: ({ student, classId, lesson, unit }) => ({
        url: `${GENZ_ASSESSMENT_API_URL}/students-response/${classId}/`,
        method: 'GET',
        params: {
          studentId: student,
          filter: student === 'all' ? 'aggregate_response' : 'individual_response',
          courseId: unit,
          problemLocations: lesson,
        },
      }),
      providesTags: [{ type: 'Answers', id: 'Filters' }],
    }),

    getAllSkillsReflection: builder.query({
      query: ({ programId, classId }) => ({
        url: `${GENZ_ASSESSMENT_API_URL}/skill-reflection/${classId}/`,
        method: 'GET',
        params: {
          programId,
        },
      }),
      providesTags: [{ type: 'Answers', id: 'Filters' }],
    }),

    getSingleStudentReflection: builder.query({
      query: ({ programId, userId }) => ({
        url: `${GENZ_ASSESSMENT_API_URL}/skill-reflection-individual/${userId}/`,
        method: 'GET',
        params: {
          programId,
        },
      }),
      providesTags: [{ type: 'Answers', id: 'Filters' }],
    }),

    getReflectionResponses: builder.query({
      query: ({ programId, problemType, classId }) => ({
        url: `${GENZ_ASSESSMENT_API_URL}/skill-reflection/question/`,
        method: 'GET',
        params: {
          programId,
          problemType,
          classId,
        },
      }),
      providesTags: [{ type: 'Answers', id: 'Filters' }],
    }),

    getSkillsAssessments: builder.query({
      query: ({ assessmentType, startYearUsageKey, endYearUsageKey, classId }) => ({
        url: `${GENZ_ASSESSMENT_API_URL}/assessment-response/${classId}`,
        method: 'GET',
        params: {
          assessmentType,
          startYearUsageKey,
          endYearUsageKey,
        },
      }),
      providesTags: [{ type: 'Answers', id: 'Filters' }],
    }),

    getAllStudentsAnswers: builder.query({
      query: ({ classId, problemKey, unit, problemId }) => ({
        url: `${GENZ_ASSESSMENT_API_URL}/students-response/${classId}/`,
        method: 'GET',
        params: {
          studentId: 'all',
          filter: 'individual_response',
          courseId: unit,
          problemLocations: problemKey,
          problemId,
        },
      }),
      providesTags: [{ type: 'Answers', id: 'Filters' }],
    }),

    classFavorite: builder.mutation({
      query: ({ classId, action }) => ({
        url: `${GENZ_API_URL}/classes/${classId}/add_class/`,
        method: 'PUT',
        data: { action },
      }),
      invalidatesTags: [{ type: 'Class', id: 'LIST' }],
    }),

    getClass: builder.query({
      query: ({ classId }) => ({
        url: `${GENZ_LEARNING_API_URL}/class-summary/${classId}/`,
      }),
      providesTags: (result, error, arg) => [{ type: 'Class', id: arg.classId }],
    }),

    getPrograms: builder.query({
      query: () => ({
        url: `${GENZ_LEARNING_API_URL}/program/`,
      }),
    }),

    validateClassName: builder.mutation({
      query: (name) => ({
        url: `${GENZ_API_URL}/primary/validate_class_name/`,
        method: 'POST',
        data: { name },
      }),
    }),

    getPrimaryStudents: builder.query({
      query: ({ addStudents, classId }) => ({
        url: `${GENZ_API_URL}/primary/students/`,
        params: {
          excludedClassId: addStudents ? classId : null,
          classId: !addStudents ? classId : null,
        },
      }),
      keepUnusedDataFor: 5,
    }),

    getAllPrimaryStudents: builder.query({
      query: () => ({
        url: `${GENZ_API_URL}/primary/students/`,
      }),
    }),

    removeClassStudents: builder.mutation({
      query: ({ classId, studentIds }) => ({
        url: `${GENZ_API_URL}/primary/students/remove/`,
        method: 'POST',
        data: {
          classId,
          studentIds,
        },
      }),
      invalidatesTags: [{ type: 'ClassStudents' }],
    }),

    addClass: builder.mutation({
      query: ({ name, classId, studentIds }) => ({
        url: `${GENZ_API_URL}/primary/students/add/`,
        method: 'POST',
        data: {
          name,
          classId,
          studentIds,
        },
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'Class', id: arg.classId }, { type: 'ClassStudents' }],
    }),

    deleteClass: builder.mutation({
      query: ({ name, classId }) => ({
        url: `${GENZ_API_URL}/primary/classes/delete/${classId}/`,
        method: 'DELETE',
        data: { name },
      }),
      invalidatesTags: [{ type: 'Class', id: 'LIST' }],
    }),

    renameClass: builder.mutation({
      query: ({ name, classId }) => ({
        url: `${GENZ_API_URL}/primary/classes/${classId}/`,
        method: 'PUT',
        data: { name },
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'Class', id: arg.classId }],
    }),

    unlockLesson: builder.mutation({
      query: ({ lessonId }) => ({
        url: `${GENZ_LEARNING_API_URL}/class-summary/lesson/unlock/${lessonId}/`,
        method: 'PUT',
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'Class', id: arg.classId }],
    }),

    getClassStudents: builder.query({
      query: ({ classId, studentNameSearch = '' }) => ({
        url: `${GENZ_LEARNING_API_URL}/class-students/${classId}/?search=${studentNameSearch}`,
      }),
      transformResponse: (responseData) => ({
        ...responseData,
        results: responseData?.filter((user) => user?.userId !== null),
        allResults: responseData,
      }),
      providesTags: [{ type: 'ClassStudents' }],
    }),

    ContactGenPlus: builder.mutation({
      query: ({ message }) => ({
        url: `${GENZ_API_URL}/contact-us/`,
        method: 'POST',
        data: { message },
      }),
    }),
  }),

  overrideExisting: false,
});

export const {
  useGetClassesQuery,
  useGetProgramsQuery,
  useClassFavoriteMutation,
  useGetClassQuery,
  useGetStudentsAnswersQuery,
  useGetAllStudentsAnswersQuery,
  useGetSkillsAssessmentsQuery,
  useGetAllSkillsReflectionQuery,
  useGetReflectionResponsesQuery,
  useGetSingleStudentReflectionQuery,
  useGetFiltersQuery,
  useUnlockLessonMutation,
  useRemoveClassStudentsMutation,
  useValidateClassNameMutation,
  useAddClassMutation,
  useRenameClassMutation,
  useDeleteClassMutation,
  useGetPrimaryStudentsQuery,
  useGetAllPrimaryStudentsQuery,
  useGetClassStudentsQuery,
  useContactGenPlusMutation,
} = classApiSlice;

export default classApiSlice;
