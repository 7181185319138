import axios from 'axios';
import { camelizeKeys, decamelizeKeys } from 'humps';

const axiosClient = axios.create({
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
  },
});

axiosClient.interceptors.response.use((response) => {
  const newResponse = { ...response };
  const isJsonResponse = response.headers['content-type']?.includes('application/json');

  if (response.data && isJsonResponse) {
    newResponse.data = camelizeKeys(response.data);
  }

  return newResponse;
});

axiosClient.interceptors.request.use((config) => {
  const csrftoken = localStorage.getItem('lms-csrf-token');
  if (csrftoken) {
    config.headers = { ...config.headers, 'X-CSRFToken': csrftoken };
  }
  const newConfig = { ...config };

  if (config.headers['Content-Type'] === 'multipart/form-data') {
    return config;
  }

  if (config.params) {
    newConfig.params = decamelizeKeys(config.params);
  }

  if (config.data) {
    newConfig.data = decamelizeKeys(config.data);
  }

  return newConfig;
});

export const clientGet = (url, options = {}) => axiosClient.get(url, options);

export const clientPost = (url, data, options = {}) => axiosClient.post(url, data, options);

export const clientPut = (url, data, options = {}) => axiosClient.put(url, data, options);

export const clientPatch = (url, data, options = {}) => axiosClient.patch(url, data, options);

export const clientDelete = (url, options = {}) => axiosClient.delete(url, options);

export default axiosClient;
