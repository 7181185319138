import { Main, Wrapper } from 'assets/styles/global';
import { useGetRecentAwardedBadgesQuery } from 'badges/badgesApi';
import AwardAllBadgePopup from 'badges/components/AwardAllBadgePopup';
import UserRestriction from 'components/UserRestriction';
import Footer from 'components/footer';
import StaticFooter from 'components/footer/StaticFooter';
import Header from 'components/header';
import StaticHeader from 'components/header/StaticHeader';
import { LMS_LOGIN } from 'config';
import NoAccessPage from 'error-pages/error-403';
import { useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import AppRoutes from 'routes';
import GetMarketingInfoPopup from 'users/Components/GetMarketingInfoPopup';
import ForcePasswordChange from 'users/ForcePasswordChange';
import { useGetUserInfoQuery } from 'users/userApi';
import { SUPPORT_EMAIL } from 'utils/values';

import { Backdrop, CircularProgress, Link, Typography, useMediaQuery, useTheme } from '@mui/material';

if (process.env.NODE_ENV === 'production') {
  ReactGA.initialize('G-PHDCFYK2YE');
}

function App() {
  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      ReactGA.send(window.location);
    }
  }, []);
  const theme = useTheme();
  const isScreenLtSm = useMediaQuery(theme.breakpoints.down('sm'));
  const [skip, setSkip] = useState(true);
  const { data: user = {}, isLoading, isFetching, isError, error } = useGetUserInfoQuery();
  const { data: badges, isSuccess, isFetching: fetchingBadges } = useGetRecentAwardedBadgesQuery(null, { skip });
  const { isLoggedIn, onBoard, isStudent, hasAccessToLessons, isSchoolActive, isTeacher, hasSubmittedMarketingForm } =
    user;

  useEffect(() => {
    if (user?.isStudent) {
      setSkip(false);
    }
  }, [setSkip, user]);

  if (isLoading) {
    return (
      <Backdrop
        open={Boolean(true)}
        sx={{
          background: 'none',
          zIndex: (UItheme) => UItheme.zIndex.drawer + 1,
        }}
      >
        <CircularProgress />
      </Backdrop>
    );
  }

  if (!isLoggedIn && !isLoading && !isFetching && isError) {
    if (error.status === 401) {
      return window.location.replace(LMS_LOGIN);
    }

    return (
      <Wrapper>
        <StaticHeader />
        <Main>
          <NoAccessPage>
            <Typography variant={isScreenLtSm ? 'h4' : 'h3'} color="white">
              This door is locked!
            </Typography>
            <Typography variant="body1" color="white" mb={3}>
              {`You don't have access to the classroom yet.`}
              <br />
              If you think there is an issue please contact us&nbsp;
              <Link href={`mailto:${SUPPORT_EMAIL}`} target="_blank">
                {SUPPORT_EMAIL}
              </Link>
            </Typography>
          </NoAccessPage>
        </Main>
        <StaticFooter />
      </Wrapper>
    );
  }

  if (isLoggedIn) {
    if (!user.hasChangedPassword) {
      return (
        <Wrapper>
          <StaticHeader />
          <Main>
            <ForcePasswordChange />
          </Main>
          <StaticFooter />
        </Wrapper>
      );
    }

    if (!isSchoolActive) {
      return (
        <Wrapper>
          <StaticHeader />
          <Main>
            <UserRestriction
              title="Your school isn’t ready!"
              helpText="Email support@genplus.co.uk if you think this is in error."
            />
          </Main>
          <StaticFooter />
        </Wrapper>
      );
    }

    if (isStudent && !hasAccessToLessons) {
      return (
        <Wrapper>
          <StaticHeader />
          <Main>
            <UserRestriction
              title="You are not part of a class!"
              helpText={`Don't worry, we can get this fixed. Speak to your teacher, they will be able to help.`}
            />
          </Main>
          <StaticFooter />
        </Wrapper>
      );
    }

    return (
      <Wrapper>
        {isStudent && !onBoard ? <StaticHeader /> : <Header />}
        <Main>
          <AppRoutes />
        </Main>
        {isTeacher && !hasSubmittedMarketingForm && <GetMarketingInfoPopup />}
        {isStudent && onBoard && !fetchingBadges && isSuccess && badges?.badges.length > 0 && <AwardAllBadgePopup />}
        <Footer />
      </Wrapper>
    );
  }
}

export default App;
