import { styled } from '@mui/material/styles';

export const StyledFooterNav = styled('nav')`
  flex-grow: 1;

  ul {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    margin: 0;
    padding: 0;
  }

  a {
    color: ${(props) => props.theme.palette.text.secondary};
    text-transform: none;
    font-weight: 400;
    font-size: 0.75rem;

    &:hover {
      color: ${(props) => props.theme.palette.text.primary};
    }
  }
`;
export const StyledList = styled('ul')`
  li:first-of-type a {
    color: white;
    font-size: 0.8125rem;
    font-weight: 500;
    text-transform: uppercase;
  }
`;
export const StyledFooter = styled('footer')`
  padding: 10px 20px;

  .MuiGrid-item {
    display: flex;
    align-items: center;
    flex-grow: 1;
  }

  p {
    font-size: 0.75rem;
    color: white;
  }

  .footer-description {
    justify-content: flex-end;
  }
`;

export default StyledFooterNav;
