import { GENZ_BASE_URL } from 'config';
import parse from 'html-react-parser';
import moment from 'moment';
import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { ROUTES } from 'utils/values';

import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CancelIcon from '@mui/icons-material/Cancel';
import ShareIcon from '@mui/icons-material/Share';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  Chip,
  Dialog,
  Divider,
  IconButton,
  Stack,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';

const ArticlePreview = ({ isPreviewOpen, closePreview, article }) => {
  const theme = useTheme();
  const isScreenLtSm = useMediaQuery(theme.breakpoints.down('sm'));
  const [linkCopied, setLinkCopied] = useState(false);

  const { id, title, summary, cover, time, author, created, skills, gtcs } = article;

  const copyLink = (link) => {
    navigator.clipboard.writeText(link);
    setLinkCopied(true);
  };

  return (
    <Dialog
      open={isPreviewOpen}
      onClose={closePreview}
      maxWidth="sm"
      fullScreen={isScreenLtSm}
      scroll="body"
      aria-labelledby="modal-card-title"
      aria-describedby="modal-card-description"
      PaperProps={{ sx: { justifyContent: 'center' } }}
    >
      <Card sx={{ overflowY: 'auto', position: 'relative' }} elevation={3}>
        <CardMedia component="img" image={cover} alt={title} />

        <Stack
          direction={{ xs: 'column', sm: 'row' }}
          alignItems={{ xs: 'flex-start', sm: 'center' }}
          justifyContent="space-between"
          gap={2}
          p={3}
        >
          <CardHeader
            title={<Box sx={{ whiteSpace: 'nowrap' }}>{moment(created).format('MMMM YYYY')}</Box>}
            subheader={author}
            titleTypographyProps={{ variant: 'subtitle1' }}
            subheaderTypographyProps={{ varaint: 'body2', fontSize: '0.875rem' }}
            sx={{ p: 0 }}
          />

          <Stack direction="row" alignSelf={{ xs: 'flex-end', sm: 'center' }} gap={2}>
            <Box>
              {skills.map((skill) => (
                <Chip
                  size={isScreenLtSm ? 'small' : 'medium'}
                  key={skill.id}
                  label={skill.name}
                  sx={{ m: '2px 4px 2px -2px' }}
                />
              ))}
              {gtcs.map((gtc) => (
                <Tooltip key={gtc.id} title={gtc.name}>
                  <Chip
                    label={gtc.name.length > 20 ? `${gtc.name.slice(0, 16)}...` : gtc.name}
                    size={isScreenLtSm ? 'small' : 'medium'}
                    sx={{ m: '2px 4px 2px -2px' }}
                  />
                </Tooltip>
              ))}
            </Box>
          </Stack>
        </Stack>

        <Divider sx={{ mx: 3 }} />

        <CardContent sx={{ px: 3 }}>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Stack direction="row" gap={1} alignItems="center">
              <AccessTimeIcon color="primary" sx={{ width: 12 }} />
              <Typography variant="caption" color="primary">
                {time} Minutes
              </Typography>
            </Stack>
            <Tooltip
              placement="top-start"
              onMouseLeave={() => setLinkCopied(false)}
              title={linkCopied ? 'Copied to clipboard' : 'Click to Copy Link'}
            >
              <Button
                startIcon={<ShareIcon />}
                size="small"
                onClick={() => copyLink(`${GENZ_BASE_URL}teach/article/${id}`)}
              >
                Share
              </Button>
            </Tooltip>
          </Stack>

          <Typography variant="h5" mt={2}>
            {title}
          </Typography>

          <Typography
            varaint="body2"
            component="div"
            mt={2}
            fontSize="0.875rem"
            sx={{ '& img': { maxWidth: '100%', height: 'auto' } }}
          >
            {parse(summary)}
          </Typography>
        </CardContent>

        <CardActions sx={{ p: 3 }}>
          <Button
            variant="contained"
            component={RouterLink}
            to={`${ROUTES.TEACH_ARTICLE_URL}/${id}`}
            size={isScreenLtSm ? 'medium' : 'large'}
          >
            View
          </Button>

          <IconButton onClick={closePreview} sx={{ position: 'absolute', top: '0.5rem', right: '0.5rem' }}>
            <CancelIcon />
          </IconButton>
        </CardActions>
      </Card>
    </Dialog>
  );
};

export default ArticlePreview;
