import { Box, CardActionArea, CardContent, CardHeader, Chip, Skeleton, Stack } from '@mui/material';

import { FeaturedArticleContainer } from '../styles';

const FeaturedArticleSkeleton = () => (
  <FeaturedArticleContainer elevation={2}>
    <CardActionArea>
      <Box sx={{ position: 'absolute', top: '1rem', right: '1rem', zIndex: 10 }}>
        <Chip label="" sx={{ width: '50px' }} />
      </Box>

      <Box sx={{ overflow: 'hidden' }}>
        <Skeleton variant="rectangular" height={140} />
      </Box>

      <CardHeader
        title={<Skeleton />}
        titleTypographyProps={{ fontWeight: 700, fontSize: '0.75rem', lineHeight: 1.6 }}
        subheader={<Skeleton />}
        subheaderTypographyProps={{ fontWeight: 500, fontSize: '0.625rem', color: 'text', lineHeight: 1.4 }}
      />

      <CardContent sx={{ paddingTop: 0.5 }}>
        <Stack direction="row" flexWrap="wrap" gap={1}>
          <Chip label="" size="small" sx={{ width: '100px' }} />
          <Chip label="" size="small" sx={{ width: '60px' }} />
        </Stack>
      </CardContent>
    </CardActionArea>
  </FeaturedArticleContainer>
);
export default FeaturedArticleSkeleton;
