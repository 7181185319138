import { styled } from '@mui/material';

const StyledEditor = styled('div')`
  margin: 0;
  padding: 0;

  .demo-wrapper {
    color: black;
    opacity: 0.8;
    border-radius: 4px;
    border: 1px solid rgb(189, 189, 189, 0.5);
    margin-top: 8px;
  }

  .demo-editor {
    box-sizing: border-box;
    border-radius: 4px;
    color: #defffd;
    padding: 10px;
    text-align: center;
    min-height: 170px;
  }
  .rdw-image-modal,
  .rdw-emoji-modal,
  .rdw-embedded-modal,
  .rdw-colorpicker-modal,
  .rdw-link-modal {
    box-shadow: none;
    background: #000f30;
    color: white;
  }
  .demo-wrapper:hover {
    border-color: #fff;
  }

  .rdw-image-alignment-options-popup {
    color: black;
  }
  .rdw-editor-toolbar,
  .rdw-option-wrapper {
    background: none;
  }
  .rdw-option-active {
    box-shadow: 1px 1px 16px #bfbdbd inset;
  }
  .rdw-editor-toolbar img {
    filter: invert(1);
  }
  .rdw-dropdown-wrapper {
    background: transparent;
    color: #fff;
  }
  .rdw-dropdownoption-default {
    background: #000f30;
  }

  .rdw-dropdownoption-highlighted {
    background: #000f30;
    opacity: 0.8;
  }

  .rdw-dropdown-carettoopen {
    border-top: 6px solid #fff;
  }

  .rdw-dropdown-carettoclose {
    border-bottom: 6px solid #fff;
  }

  .rdw-embedded-modal-size-input,
  .rdw-link-modal-input,
  .rdw-image-modal-size-input,
  .rdw-link-modal-btn,
  .rdw-image-modal-btn,
  .rdw-embedded-modal-link-input {
    border-radius: 4px;
    border: 1px solid #63d9ff;
    color: rgba(0, 0, 0, 0.87);
    text-align: center;
  }

  .rdw-image-modal-btn {
    background: #63d9ff;
  }
  .rdw-embedded-modal-btn,
  .rdw-link-modal-btn,
  .rdw-image-modal-btn {
    background: #63d9ff;
    border: 1px solid #63d9ff;
  }

  .rdw-embedded-modal-size-input,
  .rdw-embedded-modal-link-input,
  .rdw-link-modal-input,
  .rdw-image-modal-size-input {
    background: transparent;
    color: white;
  }
  .rdw-image-modal-upload-option,
  .rdw-link-modal-btn:disabled,
  .rdw-image-modal-btn:disabled {
    background: none !important;
  }
`;

export default StyledEditor;
