import PrimarySchoolProfile from 'assets/images/PrimarySchoolProfile.svg';
import { useGetAllPrimaryStudentsQuery } from 'classes/classApi';
import { useEffect, useMemo, useState } from 'react';
import { debounce } from 'utils/utilityFunctions';

import SearchIcon from '@mui/icons-material/Search';
import {
  Avatar,
  Backdrop,
  Box,
  CircularProgress,
  Grid,
  InputAdornment,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';

import { StyledDataGrid } from '../../classDashboard/styles';

const CircularBackdropProgress = () => (
  <Backdrop open={!!true} sx={{ position: 'relative', zIndex: 5, height: '100%' }}>
    <CircularProgress />
  </Backdrop>
);

const StudentCell = ({ studentName }) => (
  <Stack direction="row" alignItems="center" flexGrow={1}>
    <Avatar sx={{ width: 32, height: 32 }} src={PrimarySchoolProfile} alt={studentName} />
    <Tooltip title={studentName} placement="top-start">
      <Typography noWrap variant="body2" color="primary" width={100} px={1} flexGrow={1}>
        {studentName}
      </Typography>
    </Tooltip>
  </Stack>
);

const columns = [
  {
    field: 'studentName',
    headerName: 'Name',
    width: 250,
    renderCell: (params) => <StudentCell studentName={params.value} />,
  },
  {
    field: 'email',
    headerName: 'Email',
    width: 350,
  },
  {
    field: 'currentClass',
    headerName: 'Current Class',
    width: 200,
  },
];

const SelectStudents = ({ setSelectedStudents, selectedStudents, className, setStudents }) => {
  const [studentNameSearch, setStudentNameSearch] = useState('');
  const { data: studentList, isLoading } = useGetAllPrimaryStudentsQuery();
  const [selectionModel, setSelectionModel] = useState([]);

  const [rows, setRows] = useState([]);
  const handleSearch = (event) => setStudentNameSearch(event.target.value);
  const debouncedSearch = useMemo(() => debounce(handleSearch), []);

  useEffect(() => {
    setRows([]);
    if (!isLoading && studentList) {
      const recs = studentList.map((student) => ({
        id: student?.id,
        studentName: student?.username,
        email: student?.email,
        currentClass: student?.alreadyEnrolledClasses.length > 0 ? student?.alreadyEnrolledClasses.join(', ') : '-',
      }));
      setRows(recs);
      setStudents(recs);
      setSelectionModel(selectedStudents);
    }
  }, [isLoading, studentList, studentNameSearch, selectedStudents, setStudents]);

  return (
    <Box mt={{ xs: 1, sm: 3 }} mb={{ xs: 2, sm: 5 }}>
      <Typography variant="h6" component="h2" sx={{ mb: 3, textAlign: 'center' }}>
        Select Students to add to {className}
      </Typography>
      <Typography variant="caption" component="p" sx={{ textAlign: 'center' }}>
        Only students who have logged in before will appear in this list.
      </Typography>
      <Typography variant="caption" component="p" sx={{ mb: 3, textAlign: 'center' }}>
        You can also add/remove students later on.
      </Typography>
      <Grid container px={2} hidden>
        <Grid item xs={8}>
          &nbsp;
        </Grid>
        <Grid item xs={4}>
          <Box>
            <TextField
              id="student-search"
              placeholder="Search..."
              size="small"
              variant="outlined"
              sx={{ width: '100%' }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              onChange={debouncedSearch}
            />
          </Box>
        </Grid>
      </Grid>
      <Box sx={{ width: '100%', height: 400 }} borderBottom={1} borderColor="primary.dark">
        <StyledDataGrid
          rows={rows}
          columns={columns}
          components={{
            LoadingOverlay: CircularBackdropProgress,
          }}
          loading={isLoading}
          rowHeight={42}
          disableSelectionOnClick
          checkboxSelection
          hideFooter
          disableColumnMenu
          getRowClassName={(params) => (params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd')}
          selectionModel={selectionModel}
          onSelectionModelChange={(ids) => {
            setSelectedStudents(ids);
            setSelectionModel(ids);
          }}
        />
      </Box>
    </Box>
  );
};

export default SelectStudents;
