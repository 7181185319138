import { nanoid } from '@reduxjs/toolkit';
import SelfAssessment from 'components/selfAssessment';
import { useEffect, useState } from 'react';
import { useGetUserInfoQuery } from 'users/userApi';

import DownloadIcon from '@mui/icons-material/Download';
import { Box, Button, Grid, Tab, Tabs } from '@mui/material';

import Unit from './components/Unit';
import { StyledModal, StyledModalContent, StyledModalHeader, StyledModalWrapper, StyledUnits } from './styles';

const BrowseLessons = ({ programs }) => {
  const [open, setOpen] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const [pdf, setPdf] = useState(null);
  const handleOpenModal = () => setOpen(true);
  const handleCloseModal = () => setOpen(false);
  const { data: user = {} } = useGetUserInfoQuery();

  const handleChange = (_, newValue) => {
    setTabValue(newValue);
    localStorage.setItem('lastTab', newValue);
  };

  useEffect(() => {
    const lastTab = parseInt(localStorage.getItem('lastTab'), 10);
    if (!Number.isNaN(lastTab) && lastTab >= 0 && lastTab < programs.length) {
      setTabValue(lastTab);
    } else {
      const activeProgramIndex = programs.findIndex((p) => p.isCurrentlyActiveProgram);
      if (activeProgramIndex !== -1) {
        setTabValue(activeProgramIndex);
      }
    }
  }, [programs]);

  function a11yProps(index) {
    return {
      id: `lessons-tab-${index}`,
      'aria-controls': `lessons-tabpanel-${index}`,
    };
  }

  function CustomTabPanel(props) {
    const { children, value, index, name, ...other } = props;

    return (
      <Box role="tabpanel" hidden={value !== index} id={`tabpanel-${name}`} aria-label={`tab-${name}`} {...other}>
        {value === index && <>{children}</>}
      </Box>
    );
  }

  return (
    <Grid item xs={12}>
      {user?.belongsToSecondary && (
        <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 4, mt: 2 }}>
          <Tabs value={tabValue} onChange={handleChange} aria-label="program tabs">
            {programs &&
              programs?.map((program, programIndx) => (
                <Tab
                  key={nanoid()}
                  label={program?.programName}
                  {...a11yProps(programIndx)}
                  disabled={Date.parse(program?.startDate) > Date.now()}
                />
              ))}
          </Tabs>
        </Box>
      )}
      {programs?.map((program, indx) => (
        <CustomTabPanel key={nanoid()} value={tabValue} index={indx} name={program?.programName}>
          {user?.belongsToSecondary && program?.introUnit && <SelfAssessment {...program?.introUnit} />}
          <StyledUnits>
            {program?.units?.map((lessonUnit, index) => (
              <Unit
                index={index}
                {...lessonUnit}
                setPdf={setPdf}
                key={lessonUnit?.id}
                openModal={handleOpenModal}
                isTeacher={user?.isTeacher}
                isStudent={user?.isStudent}
              />
            ))}
          </StyledUnits>
          {user?.belongsToSecondary && program?.outroUnit && <SelfAssessment {...program?.outroUnit} />}
          <StyledModal
            open={open}
            onClose={handleCloseModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <StyledModalWrapper>
              <StyledModalContent id="modal-modal-description">
                <StyledModalHeader>
                  <Button component="a" target="_blank" href={pdf}>
                    <DownloadIcon aria-label="Download Unit Spec" />
                    Download Unit Spec
                  </Button>
                </StyledModalHeader>
                <object type="application/pdf" data={pdf} width="100%" height="500">
                  It appears you don&apos;t have a PDF plugin for this browser.
                </object>
              </StyledModalContent>
            </StyledModalWrapper>
          </StyledModal>
        </CustomTabPanel>
      ))}
    </Grid>
  );
};

export default BrowseLessons;
