import { useGetProgramsQuery } from 'classes/classApi';
import { useEffect, useState } from 'react';

import { Box } from '@mui/material';

import { useTabs } from '../../tabsContext';
import AllStudentsReflection from './AllStudentsReflection';
import ReflectionFilter from './ReflectionFilter';
import ReflectionSkeleton from './ReflectionSkeleton';
import ReflectionTabs from './ReflectionTabs';
import SingleStudentReflections from './SingleStudentReflections';

const SkillsReflection = () => {
  const { activeTab } = useTabs();
  const [tabValue, setTabValue] = useState(1);
  const [student, setStudent] = useState(activeTab.userId);
  const [refetchFunc, setRefetchFunc] = useState(null);
  const [browseablePrograms, setBrowseablePrograms] = useState([]);

  const { data: programs, isSuccess: isSuccessPrograms, isLoading: isLoadingPrograms } = useGetProgramsQuery();

  useEffect(() => {
    if (isSuccessPrograms) {
      const filteredPrograms = programs?.results.filter(({ staffBrowsable }) => staffBrowsable);
      setTabValue(filteredPrograms[0]?.id);
      setBrowseablePrograms(filteredPrograms);
    }
  }, [isSuccessPrograms, programs, setBrowseablePrograms]);

  const handleTabChange = (newValue) => {
    setTabValue(newValue);
  };

  return (
    <Box sx={{ position: 'relative' }}>
      <ReflectionFilter student={student} setStudent={setStudent} refetch={refetchFunc} />

      {isLoadingPrograms && <ReflectionSkeleton />}

      {isSuccessPrograms && (
        <>
          <ReflectionTabs value={tabValue} handleTabChange={handleTabChange} tabs={browseablePrograms} />
          {student === 'all' ? (
            <AllStudentsReflection programId={tabValue} setRefetchFuncRef={setRefetchFunc} />
          ) : (
            <SingleStudentReflections programId={tabValue} userId={student} setRefetchFuncRef={setRefetchFunc} />
          )}
        </>
      )}
    </Box>
  );
};

export default SkillsReflection;
