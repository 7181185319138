import { Card } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledSelfAssessment = styled(Card)`
  position: relative;
  margin-bottom: 25px;

  .MuiCardContent-root {
    padding: 15px;
    display: flex;

    ${(props) => props.theme.breakpoints.up('sm')} {
      padding: 24px;
    }

    img {
      max-width: 100%;
    }

    .MuiTypography-h6 {
      margin: 0 0 4px;
    }

    .MuiSvgIcon-root {
      vertical-align: baseline;
      margin: 0 5px -1px 17px;
    }
  }
`;

export const StyledAssessmentImageBox = styled('div')`
  max-width: 65px;
  flex: 0 0 65px;
  padding: 10px 10px 0 0;

  ${(props) => props.theme.breakpoints.up('sm')} {
    max-width: 100px;
    flex: 0 0 100px;
    padding: 10px 10px 10px 15px;
  }
`;

export const StyledAssessmentContent = styled('div')`
  position: relative;
  flex-grow: 1;

  ${(props) => props.theme.breakpoints.up('sm')} {
    padding-right: 175px;
  }

  p {
    font-size: 14px;
  }

  .MuiButton-root {
    margin-top: 15px;
    ${(props) => props.theme.breakpoints.up('sm')} {
      position: absolute;
      right: 0;
      bottom: 8px;
    }
  }
`;
