import { Navigate, useLocation } from 'react-router-dom';
import { useGetUserInfoQuery } from 'users/userApi';
import { ROUTES } from 'utils/values';

const RequireStudentAuth = ({ children }) => {
  const { data: user } = useGetUserInfoQuery();

  const location = useLocation();

  if (!user.isStudent || (user.onBoard && location.pathname === ROUTES.STUDENTONBOARD)) {
    return <Navigate to="/" />;
  }

  if (!user.onBoard && location.pathname !== ROUTES.STUDENTONBOARD) {
    return <Navigate to={ROUTES.STUDENTONBOARD} />;
  }

  if (!user.hasChangedPassword) {
    return <Navigate to={ROUTES.PASSWORD_CHANGE} />;
  }

  return children;
};

export default RequireStudentAuth;
