import { Badge, BadgeContainer } from 'journals/styles';
import { stringToColor } from 'utils/utilityFunctions';

import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import { Avatar, Card, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';

const BadgeEntry = ({ extended, cardElevation = 3, imageUrl, modified, feedback, badgeName, badgeType, teacher }) => {
  const theme = useTheme();
  const isScreenUpMd = useMediaQuery(theme.breakpoints.up('sm'));
  const isListView = extended && isScreenUpMd;
  return (
    <Card
      elevation={cardElevation}
      sx={{
        position: 'relative',
        paddingTop: isListView ? 2 : 4,
        paddingBottom: isListView ? 2 : 4.5,
        paddingX: 2,
      }}
    >
      <Stack gap={2} direction={isListView ? 'row' : 'column'} alignItems="center">
        <BadgeContainer>
          <Badge src={imageUrl} alt="badge" />
        </BadgeContainer>

        <Stack
          sx={{
            flexGrow: 1,
            gap: 1,
            alignItems: isListView ? 'flex-start' : 'center',
            width: '100%',
            py: isListView ? 2 : 0,
          }}
        >
          <Typography
            color="text.disabled"
            variant="caption"
            sx={{
              position: isListView ? 'absolute' : 'static',
              top: 20,
              right: 20,
            }}
          >
            {new Date(modified).toLocaleDateString('en-GB')}
          </Typography>
          <Typography color="text" component="h2" variant="h6" textAlign="center">
            {badgeName} {isListView ? '-' : <br />} {badgeType}
          </Typography>
          {feedback && (
            <Typography
              component="blockquote"
              variant="body2"
              textAlign="left"
              sx={{
                pl: 3,
                py: 1,
                borderLeft: 3,
                textAlign: 'left',
                position: 'relative',
                alignSelf: 'stretch',
                borderColor: 'primary.dark',
              }}
            >
              <Typography
                component="q"
                sx={{
                  quotes: 'none',
                }}
              >
                <FormatQuoteIcon
                  sx={{
                    transform: 'rotate(180deg)',
                    opacity: 0.3,
                    fontSize: 26,
                    position: 'absolute',
                    top: -8,
                    left: 0,
                  }}
                />
                {feedback}
              </Typography>
              {teacher && (
                <Stack direction="row" gap={1} alignItems="center" sx={{ mt: 2 }}>
                  {teacher?.profileImage ? (
                    <Avatar src={teacher?.profileImage} alt={`${teacher?.name} profile image`} />
                  ) : (
                    <Avatar sx={{ width: 32, height: 32, backgroundColor: stringToColor(teacher?.name) }}>
                      {teacher?.name[0]?.toUpperCase()}
                    </Avatar>
                  )}
                  <Typography variant="body2" textTransform="uppercase">
                    {teacher?.name}
                  </Typography>
                </Stack>
              )}
            </Typography>
          )}
        </Stack>
      </Stack>
    </Card>
  );
};

export default BadgeEntry;
