import { useGetClassStudentsQuery } from 'classes/classApi';
import { useParams } from 'react-router-dom';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import SyncIcon from '@mui/icons-material/Sync';
import { Button, Grid, IconButton, InputLabel, MenuItem, Paper, Skeleton, Stack, TextField } from '@mui/material';

const ReflectionFilter = ({ student, setStudent, isFetching, refetch }) => {
  const { classId } = useParams();

  const {
    data: students,
    isLoading: isLoadingStudents,
    isSuccess: isSuccessStudents,
  } = useGetClassStudentsQuery({ classId });

  const disableStudentNavButton = isLoadingStudents || !students?.results?.length;

  const handleStudentNavClick = (offset) => {
    const totalStudents = students?.results?.length;
    const studentList = students?.results?.map((s) => s.userId);
    const nextStudent = (offset + totalStudents + parseInt(studentList.indexOf(student))) % totalStudents;
    setStudent(studentList[nextStudent]);
  };

  const handleRefetch = () => {
    if (refetch) {
      refetch();
    }
  };

  return (
    <Paper elevation={4} sx={{ mb: 3 }}>
      <Grid container padding={2} pt={0} spacing={2} mt={0} position="relative">
        {!isLoadingStudents ? (
          <>
            <Grid item xs={12} smm={6} pr={{ xs: '100px', smm: 0 }}>
              <InputLabel sx={{ overflow: 'visible' }}>
                <TextField
                  select
                  id="select-student"
                  label="Student"
                  size="small"
                  fullWidth
                  value={student}
                  onChange={(e) => setStudent(e.target.value)}
                >
                  <MenuItem value="all">{isLoadingStudents ? <em>Loading...</em> : 'All'}</MenuItem>
                  {isSuccessStudents &&
                    students.results.map((currentStudent) => (
                      <MenuItem key={currentStudent.id} value={currentStudent.userId}>
                        {currentStudent.username}
                      </MenuItem>
                    ))}
                </TextField>
              </InputLabel>
            </Grid>
            <Grid
              item
              xs={12}
              smm={6}
              sx={{
                display: 'flex',
                alignItems: { xs: 'stretch', smm: 'center' },
                gap: 2,
                justifyContent: 'flex-end',
                flexDirection: { xs: 'column', smm: 'row' },
              }}
            >
              <Stack
                direction="row"
                sx={{
                  position: { xs: 'absolute', smm: 'static' },
                  right: 16,
                  top: 16,
                  mr: 'auto',
                }}
              >
                <IconButton
                  aria-label="previous-student"
                  disabled={disableStudentNavButton}
                  onClick={() => handleStudentNavClick(-1)}
                >
                  <ChevronLeftIcon />
                </IconButton>
                <IconButton
                  aria-label="next-student"
                  disabled={disableStudentNavButton}
                  onClick={() => handleStudentNavClick(1)}
                >
                  <ChevronRightIcon />
                </IconButton>
              </Stack>
              <Button
                size="small"
                variant="outlined"
                disabled={isFetching}
                onClick={handleRefetch}
                aria-label="Refetch Meta-Skills Tracker"
              >
                <SyncIcon />
              </Button>
            </Grid>
          </>
        ) : (
          <>
            <Grid item xs={12} smm={6}>
              <Skeleton variant="rectangular" height={40} />
            </Grid>
            <Grid item xs={12} smm={6}>
              <Skeleton variant="rectangular" height={40} />
            </Grid>
          </>
        )}
      </Grid>
    </Paper>
  );
};

export default ReflectionFilter;
