import { BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip } from 'chart.js';
import { Bar } from 'react-chartjs-2';

import useMediaQuery from '@mui/material/useMediaQuery';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const SinlgeLikertChart = ({ data, xLabels }) => {
  const isMobile = useMediaQuery('(max-width:767px)');
  const options = {
    indexAxis: 'y',
    barThickness: 20,
    elements: {
      bar: {
        inflateAmount: 0,
      },
    },
    plugins: {
      title: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            const label = context.dataset.label || '';
            return `${label}: ${xLabels[Math.abs(context.raw < 0 ? context.raw + 2 : context.raw + 1)]}`;
          },
        },
      },
      legend: {
        position: 'bottom',
        labels: {
          usePointStyle: true,
          boxWidth: 8,
          boxHeight: 8,
          color: '#defffd',
          font: {
            size: 12,
            family: 'Readex Pro',
          },
        },
      },
    },
    scales: {
      x: {
        grid: {
          color: ({ tick }) => (tick.value === 0 ? '#defffd' : 'transparent'),
          borderDash: [1, 2],
          tickColor: 'rgba(99, 217, 255, 0)',
          borderColor: 'rgba(99, 217, 255, 0.5)',
        },
        ticks: {
          font: {
            size: 13,
            weight: 300,
            family: 'IBM Plex Sans, Readex Pro',
          },
          stepSize: 1,
          color: '#defffd',
          callback: (value) => {
            if (value < 0) return xLabels[value + 2];
            if (value > 0) return xLabels[value + 1];
            return '';
          },
        },
        min: -2,
        max: 2,
      },
      y: {
        grid: {
          color: 'transparent',
          borderColor: 'rgba(99, 217, 255, 0.5)',
        },
        ticks: {
          font: {
            size: 12,
            weight: 300,
            family: 'IBM Plex Sans, Readex Pro',
          },
          color: '#defffd',
        },
      },
    },
  };

  return <Bar options={options} data={data} height={isMobile ? 150 : 70} />;
};

export default SinlgeLikertChart;
