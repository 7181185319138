import { useGetAllSkillsReflectionQuery } from 'classes/classApi';
import BackdropLinearProgress from 'components/BackdropLinearProgress';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { Card, Grid, Typography } from '@mui/material';

import AllStudentsChart from './AllStudentsChart';
import ReflectionCard from './ReflectionCard';

const AllStudentsReflection = ({ programId, setRefetchFuncRef }) => {
  const { classId } = useParams();

  const barColors = ['#ed217c', '#f26ca7', '#8a94f9', '#5863f8'];

  const likert = {
    titles: [],
    totalResponses: 0,
    skills: [],
    intro: {
      labels: {},
    },
    outro: {
      labels: {},
    },
  };

  const nuanceInterrogation = {
    titles: [],
    totalResponses: 0,
    skills: [],
    labels: {},
  };

  const { data, isLoading, isSuccess, isFetching, refetch } = useGetAllSkillsReflectionQuery({ programId, classId });

  useEffect(() => {
    setRefetchFuncRef(() => refetch);
  }, [setRefetchFuncRef, refetch]);

  const calcPercentValue = (xValue, total, point) => {
    const percentage = Math.round((xValue / total) * 100);
    return point === 1 || point === 2 ? percentage * -1 : percentage;
  };

  const createLabels = (submissions, totalSubmissions, existingLables) => {
    let labels = { ...existingLables };
    submissions.forEach(({ key, value, points }) => {
      const percentValue = value > 0 ? calcPercentValue(value, totalSubmissions, points) : 0;
      if (points.toString() in labels) {
        labels[points].data.push(percentValue);
      } else {
        labels = {
          ...labels,
          [points]: {
            label: key,
            data: [percentValue],
            backgroundColor: barColors[points - 1],
            borderRadius: 5,
          },
        };
      }
    });

    return labels;
  };

  const prepareLikerts = (likerts) => {
    likerts.forEach(({ title, skill, intro, outro }) => {
      likert.title = title;
      likert.titles.push({
        skill,
        title,
      });
      if (intro) {
        likert.intro.labels = createLabels(intro?.submissions, intro?.totalSubmissions, likert.intro.labels);
      }
      if (outro) {
        likert.outro.labels = createLabels(outro?.submissions, outro?.totalSubmissions, likert.outro.labels);
      }
      likert.skills.push(skill);
    });
  };
  const prepareNuanceInterrogation = (interrogations) => {
    interrogations.forEach(({ title, skill, submissions, totalSubmissions }) => {
      nuanceInterrogation.titles.push({ skill, title });
      nuanceInterrogation.labels = createLabels(submissions, totalSubmissions, nuanceInterrogation.labels);
      nuanceInterrogation.skills.push(skill);
    });
  };

  if (isSuccess) {
    prepareLikerts(data?.likerts);
    prepareNuanceInterrogation(data?.nuanceInterrogation);
  }

  return (
    <>
      {isFetching && <BackdropLinearProgress />}
      {!isLoading && (
        <Card elevation={4} sx={{ mb: 3 }}>
          <ReflectionCard
            questionTitles={likert?.titles}
            student="all"
            classStudents={data?.classStudents}
            isLikert
            programId={programId}
          >
            <Grid container spacing={3} mb={3}>
              <Grid item xs={12} smm={6}>
                <Typography component="h2" variant="h6" color="primary" textAlign="center" mb={2}>
                  Start of the Year
                </Typography>
                <AllStudentsChart isLikert skills={likert.skills} dataset={Object.values(likert?.intro?.labels)} />
              </Grid>
              <Grid item xs={12} smm={6}>
                <Typography component="h2" variant="h6" color="primary" textAlign="center" mb={2}>
                  End of the Year
                </Typography>
                <AllStudentsChart isLikert skills={likert.skills} dataset={Object.values(likert?.outro?.labels)} />
              </Grid>
            </Grid>
          </ReflectionCard>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <ReflectionCard
                questionTitles={nuanceInterrogation?.titles}
                student="all"
                classStudents={data?.classStudents}
                programId={programId}
              >
                <AllStudentsChart
                  skills={nuanceInterrogation.skills}
                  dataset={Object.values(nuanceInterrogation?.labels)}
                />
              </ReflectionCard>
            </Grid>
          </Grid>
        </Card>
      )}
    </>
  );
};

export default AllStudentsReflection;
