import Badges from 'badges';
import ClassDashboard from 'classes/classDashboard';
import SkillAssessment from 'classes/classDashboard/skillAssessement';
import TeacherClasses from 'classes/teacherClasses';
import ContactUs from 'contact';
import Dashboard from 'dashboard';
import ActivityFeedPage from 'dashboard/studentDashboard/components/ActivityFeedPage';
import NoAccessPage from 'error-pages/error-403';
import NoPageFound from 'error-pages/error-404';
import Journals from 'journals';
import Lessons from 'lessons';
import MuiComponents from 'mui-components';
import StudentOnBoard from 'onboard/student';
import { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import RequireStudentAuth from 'routes/StudentAuth';
import RequireTeacherAuth from 'routes/TeacherAuth';
import SupportMaterials from 'supportMaterials';
import Article from 'supportMaterials/components/Article';
import TeachDashboard from 'teach';
import TeachArticle from 'teach/TeachArticle';
import ForcePasswordChange from 'users/ForcePasswordChange';
import UserLogout from 'users/UserLogout';
import UserProfile from 'users/UserProfile';
import { useGetUserInfoQuery } from 'users/userApi';
import { checkCookie, getCookie } from 'utils/utilityFunctions';
import { PAGE_TITLE, ROUTES } from 'utils/values';

import Page from './Page';
import RequireAuth from './RequireAuth';
import RequirePasswordChange from './RequirePasswordChange';
import RestrictPrimaryStudent from './RestrictPrimaryStudent';
import RequireStaffAuth from './StaffAuth';

const cookieChangeListener = checkCookie();

const pollCookieLogout = () => {
  const isLoggedIn = getCookie('edxloggedin') === 'true';

  if (!isLoggedIn) {
    window.location.replace(ROUTES.APPLOGOUT);
  }
};

const AppRoutes = () => {
  const { data: user } = useGetUserInfoQuery();
  const pageTitleRole = user?.isStudent ? PAGE_TITLE?.FOR_STUDENT : PAGE_TITLE?.FOR_TEACHER;
  useEffect(() => {
    const id = window.setInterval(cookieChangeListener, 1000, pollCookieLogout);
    return () => window.clearInterval(id);
  }, []);

  return (
    <Routes>
      <Route
        index
        path={ROUTES.ROOT}
        element={
          <Page title={`${PAGE_TITLE?.DASHBOARD} | ${pageTitleRole}`}>
            <Dashboard />
          </Page>
        }
      />
      <Route
        path={ROUTES.ACTIVITY_FEED}
        element={
          <RequireStudentAuth>
            <Page title={PAGE_TITLE?.ACTIVITY_FEED}>
              <ActivityFeedPage />
            </Page>
          </RequireStudentAuth>
        }
      />
      <Route
        path={ROUTES.LESSONS}
        element={
          <RequirePasswordChange>
            <RestrictPrimaryStudent>
              <Page title={`${PAGE_TITLE?.LESSONS} | ${pageTitleRole}`}>
                <Lessons />
              </Page>
            </RestrictPrimaryStudent>
          </RequirePasswordChange>
        }
      />
      <Route
        path={ROUTES.BADGES}
        element={
          <RequireStudentAuth>
            <Page title={PAGE_TITLE?.BADGES}>
              <Badges />
            </Page>
          </RequireStudentAuth>
        }
      />
      <Route
        path={ROUTES.JOURNALS}
        element={
          <RequireStudentAuth>
            <Page title={PAGE_TITLE?.JOURNALS}>
              <Journals />
            </Page>
          </RequireStudentAuth>
        }
      />
      <Route
        path={ROUTES.STUDENTONBOARD}
        element={
          <RequireStudentAuth>
            <Page title={PAGE_TITLE?.STUDENT_ONBOARD}>
              <StudentOnBoard />
            </Page>
          </RequireStudentAuth>
        }
      />
      <Route
        path={ROUTES.PASSWORD_CHANGE}
        element={
          <RequireAuth>
            <Page title={`${PAGE_TITLE?.PASSWORD_CHANGE} | ${pageTitleRole}`}>
              <ForcePasswordChange />
            </Page>
          </RequireAuth>
        }
      />
      <Route
        path={ROUTES.SKILL_ASSESSMENT}
        element={
          <RequireStudentAuth>
            <Page>
              <SkillAssessment />
            </Page>
          </RequireStudentAuth>
        }
      />
      <Route
        path={ROUTES.CLASSES}
        element={
          <RequireTeacherAuth>
            <Page title={PAGE_TITLE?.CLASSES}>
              <TeacherClasses />
            </Page>
          </RequireTeacherAuth>
        }
      />
      <Route
        path={ROUTES.USERPROFILE}
        element={
          <Page title={`${PAGE_TITLE?.USER_PROFILE} | ${pageTitleRole}`}>
            <UserProfile />
          </Page>
        }
      />
      <Route
        path={ROUTES.CONTACTUS}
        element={
          <Page title={`${PAGE_TITLE?.CONTACTUS} | ${pageTitleRole}`}>
            <ContactUs />
          </Page>
        }
      />
      <Route
        path={ROUTES.CLASSDASHBOARD}
        element={
          <RequireTeacherAuth>
            <Page>
              <ClassDashboard />
            </Page>
          </RequireTeacherAuth>
        }
      />
      <Route
        path={ROUTES.TEACH}
        element={
          <RequireTeacherAuth>
            <Page title={PAGE_TITLE?.TEACH}>
              <TeachDashboard />
            </Page>
          </RequireTeacherAuth>
        }
      />
      <Route
        path={ROUTES.TEACH_ARTICLE}
        element={
          <RequireTeacherAuth>
            <Page title={PAGE_TITLE?.TEACH}>
              <TeachArticle />
            </Page>
          </RequireTeacherAuth>
        }
      />
      <Route
        path={ROUTES.SUPPORT_MATERIALS}
        element={
          <RequireStaffAuth>
            <Page title={PAGE_TITLE?.SUPPORT_MATERIALS}>
              <SupportMaterials />
            </Page>
          </RequireStaffAuth>
        }
      />
      <Route
        path={ROUTES.SUPPORT_ARTICLE}
        element={
          <RequireTeacherAuth>
            <Page>
              <Article />
            </Page>
          </RequireTeacherAuth>
        }
      />
      <Route
        path={ROUTES.MUICOMPONENTS}
        element={
          <Page>
            <MuiComponents />
          </Page>
        }
      />
      <Route
        path={ROUTES.APPLOGOUT}
        element={
          <RequireAuth>
            <UserLogout />
          </RequireAuth>
        }
      />
      <Route path="/403" element={<NoAccessPage />} />
      <Route
        path="*"
        element={
          <Page title={PAGE_TITLE?.NO_PAGE_FOUND}>
            <NoPageFound />
          </Page>
        }
      />
    </Routes>
  );
};

export default AppRoutes;
