import PrimarySchoolProfile from 'assets/images/PrimarySchoolProfile.svg';
import { useGetUserInfoQuery } from 'users/userApi';
import { stringToColor } from 'utils/utilityFunctions';

import { Avatar } from '@mui/material';

const CreateAvatar = ({ profileImage, name = '' }) => {
  const {
    data: { belongsToPrimary },
  } = useGetUserInfoQuery();

  if (belongsToPrimary) {
    return <Avatar sx={{ width: 32, height: 32 }} src={PrimarySchoolProfile} alt={name} />;
  }

  return (
    <>
      {profileImage ? (
        <Avatar sx={{ width: 32, height: 32 }} src={profileImage} alt={`${name}'s profile image`} />
      ) : (
        <Avatar sx={{ width: 32, height: 32, backgroundColor: stringToColor(name) }}>
          {name.slice(0, 1).toUpperCase()}
        </Avatar>
      )}
    </>
  );
};

export default CreateAvatar;
