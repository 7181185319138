import baseApiSlice from 'api/baseApiSlice';

const GENZ_API_URL = '/genplus/api/v1';

const charactersApiSlice = baseApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getCharacters: builder.query({
      query: () => ({
        url: `${GENZ_API_URL}/characters/`,
      }),
    }),
  }),
  overrideExisting: false,
});

export const { useGetCharactersQuery } = charactersApiSlice;

export default charactersApiSlice;
