import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useState } from 'react';
import { notifyError, notifySuccess } from 'utils/notification';

import { useUpdatePortfolioMutation } from '../teachApi';

const EditPortfolio = ({ isOpen, handleClose, oldTitle, oldThoughts, id, teacher }) => {
  const [thoughts, setThoughts] = useState(oldThoughts);
  const theme = useTheme();
  const isScreenLtSm = useMediaQuery(theme.breakpoints.down('xss'));
  const [updatePortfolio, { isLoading }] = useUpdatePortfolioMutation();

  const handleUpdate = async () => {
    const data = {
      description: thoughts,
      title: oldTitle,
      teacher: teacher.id,
    };

    await updatePortfolio({ id, data })
      .unwrap()
      .then((res) => {
        setThoughts(res.description);
        notifySuccess('Successfully edited.');
      })
      .catch((err) => {
        notifyError(`Bad request ${err.status}`);
      });
    handleClose();
    return null;
  };
  return (
    <Dialog open={isOpen} onClose={handleClose} maxWidth="sm" fullWidth PaperProps={{ elevation: 3 }}>
      <DialogTitle>{oldTitle}</DialogTitle>
      <DialogContent sx={{ pb: 2 }}>
        <TextField
          id="portfolio-thoughts-field"
          multiline
          fullWidth
          rows={isScreenLtSm ? 8 : 4}
          placeholder="What are you thinking about?"
          value={thoughts}
          onChange={(e) => setThoughts(e.target.value)}
        />
      </DialogContent>

      <DialogActions sx={{ justifyContent: 'flex-start', px: 3, pb: 2, pt: 0 }}>
        <Button
          variant="contained"
          size="large"
          onClick={handleUpdate}
          disabled={oldThoughts === thoughts || thoughts === '' || isLoading}
        >
          Save
        </Button>
        <Button variant="outlined" size="large" onClick={handleClose}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default EditPortfolio;
