import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import SearchIcon from '@mui/icons-material/Search';
import { Backdrop, Box, CircularProgress, Grid, InputAdornment, TextField } from '@mui/material';
import { debounce } from 'utils/utilityFunctions';
import { useGetClassStudentsQuery } from 'classes/classApi';

import { StyledDataGrid } from '../../styles';
import StudentCell from './StudentCell';

const CircularBackdropProgress = () => (
  <Backdrop open={!!true} sx={{ position: 'relative', zIndex: 5, height: '100%' }}>
    <CircularProgress />
  </Backdrop>
);

const columns = [
  {
    field: 'student',
    headerName: 'Name',
    width: 500,
    renderCell: (params) => <StudentCell student={params.value} />,
  },
];

const SelectStudents = ({ setSelectedStudents, selectedStudents }) => {
  const [studentNameSearch, setStudentNameSearch] = useState('');
  const { classId } = useParams();
  const { data, isLoading } = useGetClassStudentsQuery({ classId, studentNameSearch });
  const { results: studentList } = data || [];
  const [selectionModel, setSelectionModel] = useState([]);

  const [rows, setRows] = useState([]);
  const handleSearch = (event) => setStudentNameSearch(event.target.value);
  const debouncedSearch = useMemo(() => debounce(handleSearch), []);

  useEffect(() => {
    setRows([]);
    if (!isLoading) {
      const recs = studentList.map((student) => ({
        id: student.userId,
        student: {
          name: student.username,
          profilePic: student.profilePic,
        },
      }));
      setRows(recs);
      setSelectionModel(selectedStudents);
    }
  }, [isLoading, studentList, studentNameSearch, selectedStudents]);

  return (
    <Box mt={{ xs: 1, sm: 3 }} mb={{ xs: 2, sm: 5 }}>
      <Grid container px={2}>
        <Grid item xs={8}>
          &nbsp;
        </Grid>
        <Grid item xs={4}>
          <Box>
            <TextField
              id="student-search"
              placeholder="Search..."
              size="small"
              variant="outlined"
              sx={{ width: '100%' }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              onChange={debouncedSearch}
            />
          </Box>
        </Grid>
      </Grid>
      <Box sx={{ width: '100%', height: 400 }} borderBottom={1} borderColor="primary.dark">
        <StyledDataGrid
          rows={rows}
          columns={columns}
          components={{
            LoadingOverlay: CircularBackdropProgress,
          }}
          loading={isLoading}
          rowHeight={42}
          disableSelectionOnClick
          checkboxSelection
          hideFooter
          disableColumnMenu
          getRowClassName={(params) => (params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd')}
          selectionModel={selectionModel}
          onSelectionModelChange={(ids) => {
            setSelectedStudents(ids);
            setSelectionModel(ids);
          }}
        />
      </Box>
    </Box>
  );
};

export default SelectStudents;
