import { Box, Paper, Skeleton, Stack, Typography } from '@mui/material';

const CpdCardSkeleton = () => (
  <Paper elevation={3} sx={{ overflow: 'hidden' }}>
    <Skeleton variant="rectangular" height={140} sx={{ transform: 'none' }} />

    <Box p={2}>
      <Typography variant="caption">
        <Skeleton />
        <Skeleton />
      </Typography>

      <Stack sx={{ mt: 1, minHeight: 75 }} direction="row" flexWrap="wrap" gap={0.5}>
        <Skeleton width={80} height={30} />
        <Skeleton width={60} height={30} />
      </Stack>

      <Stack sx={{ mt: 0.5 }} direction="row" flexWrap="wrap" gap={0.5} justifyContent="space-between">
        <Skeleton variant="text" width={60} />
        <Skeleton variant="circular" width={16} height={16} />
      </Stack>
    </Box>
  </Paper>
);

export default CpdCardSkeleton;
