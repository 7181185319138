import BackdropLinearProgress from 'components/BackdropLinearProgress';
import { GENZ_BASE_URL } from 'config';
import parse from 'html-react-parser';
import { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link as RouterLink, useLocation, useParams } from 'react-router-dom';
import { useGetSupportArticleQuery, useRateGuideMutation } from 'supportMaterials/supportMaterialsApi';
import ArticleRating from 'teach/components/ArticleRating';
import { PAGE_TITLE, PALTFORM_NAME, ROUTES } from 'utils/values';

import AccessTimeIcon from '@mui/icons-material/AccessTime';
import NavigateNext from '@mui/icons-material/NavigateNext';
import ShareIcon from '@mui/icons-material/Share';
import {
  Box,
  Breadcrumbs,
  Button,
  Container,
  Link,
  Paper,
  Skeleton,
  Stack,
  Tooltip,
  Typography,
  alpha,
  useMediaQuery,
  useTheme,
} from '@mui/material';

const Article = () => {
  const theme = useTheme();
  const isScreenLtSm = useMediaQuery(theme.breakpoints.down('sm'));

  const { id } = useParams();
  const { data: article, isLoading, isFetching, isSuccess, isError } = useGetSupportArticleQuery({ id });

  const location = useLocation();
  const [linkCopied, setLinkCopied] = useState(false);

  const copyLink = () => {
    navigator.clipboard.writeText(GENZ_BASE_URL.slice(0, -1) + location.pathname);
    setLinkCopied(true);
  };

  return (
    <Container maxWidth="md" sx={{ position: 'relative' }}>
      {isFetching && !isLoading && <BackdropLinearProgress />}

      {isError && (
        <Typography variant="h3" textAlign="center" mt={5}>
          Support Article Not Found
        </Typography>
      )}

      {!isError && (
        <>
          <Helmet>
            <title>{`${PALTFORM_NAME} | ${PAGE_TITLE?.SUPPORT_MATERIALS} | ${article?.title}`}</title>
          </Helmet>
          <Breadcrumbs separator={<NavigateNext />} aria-label="breadcrumbs">
            <Link underline="hover" key="1" color="inherit" component={RouterLink} to={ROUTES.SUPPORT_MATERIALS}>
              Supporting Materials
            </Link>
            <Typography key="2" color="text.primary">
              {isLoading && <Skeleton width={250} />}
              {isSuccess && article.title}
            </Typography>
          </Breadcrumbs>

          <Paper elevation={3} sx={{ mt: 4 }}>
            <Box p={{ xs: 2, sm: 5 }}>
              <Typography variant={isScreenLtSm ? 'h5' : 'h4'}>
                {isLoading && <Skeleton />}
                {isSuccess && article.title}
              </Typography>

              <Typography variant="subtitle1" mt={3} sx={{ whiteSpace: 'nowrap' }}>
                {isLoading && <Skeleton width={90} />}
                {isSuccess && new Date(article.created).toLocaleDateString('en-GB')}
              </Typography>

              <Stack direction="row" justifyContent="space-between" alignItems="center" mt={2}>
                {isLoading && (
                  <>
                    <Typography variant="caption">
                      <Skeleton width={90} />
                    </Typography>

                    <Skeleton>
                      <Button startIcon={<ShareIcon />} size="small">
                        Share
                      </Button>
                    </Skeleton>
                  </>
                )}
                {isSuccess && (
                  <>
                    <Stack direction="row" gap={1} alignItems="center">
                      <AccessTimeIcon color="primary" sx={{ width: 12 }} />
                      <Typography variant="caption" color="primary">
                        {article.time > 1 ? `${article.time} Minutes` : `${article.time} Minute`}
                      </Typography>
                    </Stack>
                    <Tooltip
                      placement="top-start"
                      onMouseLeave={() => setLinkCopied(false)}
                      title={linkCopied ? 'Copied to clipboard' : 'Click to Copy Link'}
                    >
                      <Button startIcon={<ShareIcon />} size="small" onClick={copyLink}>
                        Share
                      </Button>
                    </Tooltip>
                  </>
                )}
              </Stack>

              <Typography
                varaint="body2"
                component="div"
                mt={2}
                fontSize="0.875rem"
                sx={{ '& img': { maxWidth: '100%', height: 'auto' } }}
              >
                {isLoading && (
                  <>
                    <br />
                    {[1, 2, 3, 4].map((num) => (
                      <Skeleton key={num} />
                    ))}
                    <Skeleton width={150} />
                    <br />
                    {[1, 2].map((num) => (
                      <Skeleton key={num} />
                    ))}
                    <Skeleton width={80} />
                    <br />
                    {[1, 2, 3].map((num) => (
                      <Skeleton key={num} />
                    ))}
                    <Skeleton width={250} />
                  </>
                )}
                {isSuccess && parse(article.content)}
              </Typography>
            </Box>
          </Paper>

          <Box mt={4}>
            {isLoading && (
              <Box px={2} py={1} border={1} borderRadius={2} borderColor={alpha('#fff', 0.23)}>
                <Stack
                  direction={{ xs: 'column', xss: 'row' }}
                  gap={{ xss: 1 }}
                  alignItems={{ xs: 'flex-start', xss: 'center' }}
                >
                  <Typography variant="overline" component="p">
                    <Skeleton width={240} />
                  </Typography>

                  <Stack direction="row" gap={0.4}>
                    {[1, 2, 3, 4, 5].map((num) => (
                      <Skeleton variant="circular" width={16} height={16} key={num} />
                    ))}
                  </Stack>
                </Stack>
              </Box>
            )}
            {isSuccess && (
              <ArticleRating
                articleRating={article.rating}
                articleId={article.id}
                useRateMutation={useRateGuideMutation}
              />
            )}
          </Box>
        </>
      )}
    </Container>
  );
};

export default Article;
