import { useGetActiveProgramBadgesQuery, useGetActivityFeedQuery, useGetProgressQuery } from 'dashboard/dashboardApi';

import { Box, Grid } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';

import ActivityFeed from './components/ActivityFeed';
import ActivityFeedSkeleton from './components/ActivityFeedSkeleton';
import CharacterDisplay from './components/CharacterDisplay';
import NextLesson from './components/NextLesson';
import NextLessonSkeleton from './components/NextLessonSkeleton';
import ProgramProgress from './components/ProgramProgress';
import ProgramProgressSkeleton from './components/ProgramProgressSkeleton';
import RecentBadges from './components/RecentBadges';
import SelfAssessment from './components/SelfAssessment';
import SelfAssessmentSkeleton from './components/SelfAssessmentSkeleton';

const SecondaryStudent = () => {
  const {
    data: studentProgress,
    isLoading: isLoadingStudentProgress,
    isSuccess: isSuccessStudentProgress,
  } = useGetProgressQuery();

  const {
    data: programBadges,
    isLoading: isLoadingProgramBadges,
    isSuccess: isSuccessProgramBadges,
  } = useGetActiveProgramBadgesQuery();
  const {
    data: activityFeed,
    isLoading: isLoadingActivityFeed,
    isSuccess: isSuccessActivityFeed,
  } = useGetActivityFeedQuery();

  return (
    <Grid container rowSpacing={2} columnSpacing={3}>
      <Grid item xs={12} sm={4.5} smm={6}>
        {isLoadingProgramBadges && isLoadingStudentProgress && (
          <Box>
            <Skeleton variant="rectangular" width="100%" height={300} />
          </Box>
        )}
        {isSuccessProgramBadges && (
          <CharacterDisplay {...programBadges} characterVideoUrl={studentProgress?.characterVideoUrl} />
        )}
      </Grid>
      <Grid item xs={12} sm={7.5} smm={6}>
        {isLoadingStudentProgress && <ProgramProgressSkeleton />}
        {isSuccessStudentProgress && <ProgramProgress {...studentProgress} />}
      </Grid>

      <Grid item xs={12} md={6}>
        {isLoadingStudentProgress && <NextLessonSkeleton />}
        {isSuccessStudentProgress && studentProgress && <NextLesson {...studentProgress?.nextLesson} />}
      </Grid>

      <Grid item xs={12} md={6}>
        {isLoadingActivityFeed && <ActivityFeedSkeleton />}
        {isSuccessActivityFeed && <ActivityFeed activityFeed={activityFeed?.results} />}
      </Grid>
      <Grid item xs={12}>
        {isLoadingStudentProgress && <SelfAssessmentSkeleton />}
        {isSuccessStudentProgress && studentProgress && (
          <SelfAssessment
            introUnit={studentProgress?.progress?.unitsProgress?.introUnit}
            outroUnit={studentProgress?.progress?.unitsProgress?.outroUnit}
          />
        )}
      </Grid>
      <Grid item xs={12}>
        <RecentBadges />
      </Grid>
    </Grid>
  );
};

export default SecondaryStudent;
