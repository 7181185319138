import { Navigate } from 'react-router-dom';
import { useGetUserInfoQuery } from 'users/userApi';
import { ROUTES } from 'utils/values';

const RequireAuth = ({ children }) => {
  const { data: user } = useGetUserInfoQuery();

  if (!user) {
    return <Navigate to={ROUTES.ROOT} />;
  }

  return children;
};

export default RequireAuth;
