import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useGetUserInfoQuery, useUpdateProfileMutation } from 'users/userApi';
import { notifyError, notifySuccess } from 'utils/notification';

import { Box, Button, Container, Paper, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';

import ChangePassword from './Components/ChangePassword';
import StudentProfile from './StudentProfile';
import TeacherProfile from './TeacherProfile';

const UserProfile = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const isScreenLtSm = useMediaQuery(theme.breakpoints.down('sm'));
  const { data: user = {} } = useGetUserInfoQuery();
  const [updateProfile] = useUpdateProfileMutation();
  const [selectedImage, setSelectedImage] = useState(false);
  const [selectedImageSrc, setSelectedImageSrc] = useState(false);
  const [selectedCharacter, setSelectedCharacter] = useState('');

  const fromPrivateSchool = user.schoolType === 'Private';

  const handleProfileChange = async () => {
    let data = {};
    if (user.isTeacher && selectedImage) {
      data = new FormData();
      data.append('image', selectedImage);
    }
    if (user.isStudent && selectedCharacter) {
      data = { character: selectedCharacter };
    }
    await updateProfile(data)
      .unwrap()
      .then(() => {
        notifySuccess('Your profile has been updated.');
        setSelectedImage(false);
        setSelectedImageSrc(false);
        navigate(-1);
      })
      .catch((err) => {
        notifyError(err.data);
      });
  };

  const isDisabled = (user.isTeacher && !selectedImage) || (user.isStudent && selectedCharacter === user.characterId);

  return (
    <Container maxWidth="md">
      <Box component="section">
        <Typography variant="h5">My Profile</Typography>
        {user.isStudent && (
          <StudentProfile {...user} character={selectedCharacter} setCharacter={setSelectedCharacter} />
        )}
        {user.isTeacher && (
          <TeacherProfile
            {...user}
            setProfile={setSelectedImage}
            setImageSrc={setSelectedImageSrc}
            selectedImageSrc={selectedImageSrc}
          />
        )}
        <Stack direction="row" gap={2} mt={3}>
          <Button variant="contained" disabled={isDisabled} onClick={handleProfileChange}>
            Save
          </Button>
          <Button variant="outlined" onClick={() => navigate(-1)}>
            Cancel
          </Button>
        </Stack>
      </Box>

      {fromPrivateSchool && <ChangePassword title="Change Password" />}

      <Box component="section" mt={3} hidden>
        <Paper elevation={6} sx={{ p: 2 }}>
          <Typography variant="h5">Platform Tour</Typography>
          <Stack
            direction={isScreenLtSm ? 'column' : 'row'}
            gap={1}
            justifyContent="space-between"
            alignItems={isScreenLtSm ? 'flex-start' : 'flex-end'}
            mt={1}
          >
            <Typography variant="body2">
              Take a tour of the platform to help you understand each page and their functions.
            </Typography>
            <Button size="small" variant="outlined" sx={{ minWidth: 'fit-content' }}>
              Start Tour
            </Button>
          </Stack>
        </Paper>
      </Box>
    </Container>
  );
};

export default UserProfile;
