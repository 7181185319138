import JoinClassImage from 'assets/images/join-class.svg';
import { useJoinClassByCodeMutation } from 'dashboard/dashboardApi';
import { useState } from 'react';
import { useGetUserInfoQuery } from 'users/userApi';
import { notifySuccess } from 'utils/notification';

import { Box, Button, Dialog, DialogContent, TextField, Typography } from '@mui/material';

import { StyledJoinClassSection } from '../styles';

const JoinClass = () => {
  const [joinClassPopOpen, setJoinClassPopOpen] = useState(false);
  const [joinClassCode, setJoinClassCode] = useState('');
  const [joinClassError, setJoinClassError] = useState('');
  const [classJoined, setClassJoined] = useState(false);
  const { refetch } = useGetUserInfoQuery();

  const [joinCode, { isLoading }] = useJoinClassByCodeMutation();

  const handleJoinCodeChagne = (event) => {
    setJoinClassCode(event.target.value);
    setJoinClassError('');
  };

  const handleJoinClassPopUpOpen = () => {
    setJoinClassPopOpen(true);
  };

  const handleJoinClassPopUpClose = () => {
    setJoinClassPopOpen(false);
    setJoinClassCode('');
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const formJson = Object.fromEntries(formData.entries());
    const { joincode } = formJson;

    await joinCode(joincode)
      .unwrap()
      .then(() => {
        notifySuccess('You have joined the class successfully');
        handleJoinClassPopUpClose();
        setClassJoined(true);
        refetch();
      })
      .catch(() => {
        setJoinClassError('Invalid Class Code');
      });
    return null;
  };

  if (classJoined) return null;

  return (
    <>
      <StyledJoinClassSection elevation={3} sx={{ mb: 3 }}>
        <Box>
          <Box component="img" src={JoinClassImage} alt="Join Class" />
        </Box>
        <Box sx={{ flexGrow: 1, p: 1, pl: 3 }}>
          <Typography component="h2" variant="h6">
            You haven’t joined your class yet!
          </Typography>
          <Typography variant="body2">Enter your class join code or ask your teacher for help.</Typography>
        </Box>
        <Box sx={{ px: 3, py: 1 }}>
          <Button variant="outlined" onClick={handleJoinClassPopUpOpen} sx={{ whiteSpace: 'nowrap' }}>
            Join class
          </Button>
        </Box>
      </StyledJoinClassSection>
      <Dialog
        open={joinClassPopOpen}
        fullWidth
        sx={{ textAlign: 'center' }}
        maxWidth="sm"
        PaperProps={{
          elevation: 4,
          component: 'form',
          onSubmit: handleSubmit,
        }}
      >
        <DialogContent>
          <Typography component="h2" variant="h6">
            You haven’t joined your class yet!
          </Typography>
          <Box
            component="img"
            src={JoinClassImage}
            alt="Join Class"
            sx={{
              maxWidth: '100%',
              margin: '1rem auto',
              display: 'block',
            }}
          />
          <Typography variant="body2">If you have a code enter it here:</Typography>
          <Box sx={{ display: 'flex', alignItems: 'flex-start', py: 3 }}>
            <TextField
              size="small"
              value={joinClassCode}
              onChange={handleJoinCodeChagne}
              autoFocus
              error={joinClassError.length > 0}
              id="joincode"
              name="joincode"
              label="Join Code"
              type="text"
              helperText={joinClassError}
              fullWidth
            />
            <Button
              disabled={joinClassCode.length === 0 || isLoading}
              sx={{ ml: 2 }}
              size="large"
              type="submit"
              variant="contained"
            >
              Join
            </Button>
          </Box>
          <Typography variant="body2">If you don’t have a code, ask your teacher to help join the class.</Typography>
          <Box sx={{ pt: 2, pb: 1 }}>
            <Button size="large" onClick={handleJoinClassPopUpClose}>
              Skip for now
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default JoinClass;
