import { createApi } from '@reduxjs/toolkit/query/react';
import { GENZ_API_URL, LMS_BASE_URL } from 'config';
import axiosClient from 'utils/client';

const axiosBaseQuery =
  ({ baseUrl, baseOptions } = { baseUrl: '', baseOptions: {} }) =>
  async ({ url, method, data, params, queryOptions }) => {
    try {
      const result = await axiosClient({
        url: new URL(url, baseUrl).href,
        method,
        data,
        params,
        ...baseOptions,
        ...queryOptions,
      });

      return { data: result.data };
    } catch (axiosError) {
      const err = axiosError;

      return {
        error: {
          status: err.response?.status,
          data: err.response?.data || err.message,
        },
      };
    }
  };

const baseApiSlice = createApi({
  baseQuery: axiosBaseQuery({ baseUrl: LMS_BASE_URL }),

  endpoints: (builder) => ({
    getSkills: builder.query({
      query: () => ({
        url: `${GENZ_API_URL}/skills/`,
        keepUnusedDataFor: 60 * 10,
      }),
    }),
  }),
});

export const { useGetSkillsQuery } = baseApiSlice;

export default baseApiSlice;
