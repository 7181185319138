import { Box, TextField, Typography } from '@mui/material';

const AddClassName = ({ className, setClassName, classNameError, setClassNameError }) => {
  const handleUpdate = (event) => {
    const classNameInput = event.target.value.replace(/\s\s+/g, ' ');
    setClassName(classNameInput);
    setClassNameError('');
  };

  return (
    <Box pt={{ xs: 2, sm: 8 }} pb={{ xs: 2, sm: 5 }} px={{ xs: 0, sm: 6 }}>
      <Typography variant="h6" component="h2" sx={{ mb: 3, textAlign: 'center' }}>
        Give your class a unique name
      </Typography>
      <Typography variant="caption" component="p" sx={{ mb: 3, textAlign: 'center' }}>
        Eg. P7A - 2023/24
      </Typography>
      <TextField
        size="large"
        autoFocus
        id="className"
        name="className"
        label="Class Name"
        type="text"
        value={className}
        error={classNameError.length > 0}
        fullWidth
        onChange={handleUpdate}
        helperText={classNameError.length > 0 ? classNameError : ''}
      />
    </Box>
  );
};

export default AddClassName;
