import { Box, Card, CardActions, CardHeader, Skeleton, Typography } from '@mui/material';

const JournalEntrySkeleton = () => (
  <Card elevation={8}>
    <CardHeader
      title={
        <>
          <Skeleton />
          <Skeleton width="40%" />
        </>
      }
      subheader={
        <>
          <Typography display="block" mt={1} variant="caption" color="text.disabled">
            <Skeleton />
            <Skeleton width="75%" />
          </Typography>
        </>
      }
    />
    <Box padding={2}>
      <Skeleton width={100} variant="rectangular" />
    </Box>
    <CardActions
      sx={{
        padding: 2,
      }}
    >
      <Skeleton width={100} height={40} sx={{ borderRadius: '16px' }} />
    </CardActions>
  </Card>
);

export default JournalEntrySkeleton;
