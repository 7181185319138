import { nanoid } from '@reduxjs/toolkit';
import { useGetSkillsQuery } from 'api/baseApiSlice';
import { useGetClassStudentsQuery } from 'classes/classApi';
import BackdropLinearProgress from 'components/BackdropLinearProgress';
import { useGetJournalsQuery } from 'journals/journalsApi';
import { useEffect, useMemo, useReducer, useState } from 'react';
import { useParams } from 'react-router-dom';
import { debounce } from 'utils/utilityFunctions';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import GridViewIcon from '@mui/icons-material/GridView';
import ListIcon from '@mui/icons-material/List';
import SearchIcon from '@mui/icons-material/Search';
import SyncIcon from '@mui/icons-material/Sync';
import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Pagination,
  Paper,
  Skeleton,
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  darken,
  useTheme,
} from '@mui/material';

import { useTabs } from '../../tabsContext';
import AddTeacherFeedback from './AddTeacherFeedback';
import JournalEntries from './JournalEntries';
import JournalEntrySkeleton from './JournalEntrySkeleton';

const filtersInitialState = {
  page: 1,
  search: '',
  student: -1,
  skill: '',
};

const filtersReducer = (state, action) => {
  switch (action.type) {
    case 'page':
      return { ...state, page: action.payload };

    case 'search':
      return { ...state, page: 1, search: action.payload };

    case 'student':
      return { ...state, page: 1, student: action.payload };

    case 'skill':
      return { ...state, page: 1, skill: action.payload };

    default:
      throw new Error(`Filters Reducer: Invalid action type (${action.type})`);
  }
};

const Journals = () => {
  const [filters, dispatch] = useReducer(filtersReducer, filtersInitialState);
  const { page, search, student, skill } = filters;
  const [view, setView] = useState('grid');
  const { classId } = useParams();
  const entriesSkeleton = [];
  const limit = 12;
  const { activeTab } = useTabs();

  const [isTeacherFeedbackOpen, setIsTeacherFeebackOpen] = useState(false);
  const handleOpenTeacherFeedback = () => setIsTeacherFeebackOpen(true);
  const handleCloseTeacherFeedback = () => setIsTeacherFeebackOpen(false);

  const handleSearchChange = (event) => dispatch({ type: 'search', payload: event.target.value });
  const debouncedSearch = useMemo(() => debounce(handleSearchChange), []);
  useEffect(() => () => debouncedSearch.cancel(), [debouncedSearch]);

  const { data: skills, isLoading: isLoadingSkills, isSuccess: isSuccessSkills } = useGetSkillsQuery();
  const {
    data: students,
    isLoading: isLoadingStudents,
    isSuccess: isSuccessStudents,
  } = useGetClassStudentsQuery({ classId });
  const {
    data: journals,
    isLoading: isLoadingJournals,
    isFetching: isFetchingJournals,
    isSuccess: isSuccessJournals,
    refetch,
  } = useGetJournalsQuery(
    {
      offset: limit * (page - 1),
      limit,
      studentId: students?.results[student]?.id,
      search,
      skillId: skill === 'All' ? '' : skill,
    },
    { skip: student < 0 },
  );
  const pageCount = Math.ceil(journals?.overallTotal / limit);

  const disableStudentNavButtonPrev = isLoadingStudents || !students?.results?.length || student === 0;
  const disableStudentNavButtonNext =
    isLoadingStudents || !students?.results?.length || student === students?.results?.length - 1;

  const disableFeedbackButton = !students?.results?.length;

  const theme = useTheme();

  const handleViewChange = (_, newView) => {
    if (newView !== null) {
      setView(newView);
    }
  };

  const handlePageChange = (_, newPage) => {
    window.scrollTo(0, 0);
    dispatch({ type: 'page', payload: newPage });
  };

  useEffect(() => {
    if (isSuccessStudents && students.results.length) {
      dispatch({
        type: 'student',
        payload: activeTab.studentId === -1 ? 0 : activeTab.studentId,
      });
    }
  }, [isSuccessStudents, students?.results?.length, activeTab]);

  const handleStudentNavClick = (offset) => {
    const totalStudents = students.results.length;
    const nextStudent = (totalStudents + student + offset) % totalStudents;
    if (nextStudent === student) return;
    dispatch({ type: 'student', payload: nextStudent });
  };

  for (let i = 0; i < 6; i++) {
    entriesSkeleton.push(
      <Grid item xs={4} key={nanoid()}>
        <JournalEntrySkeleton />
      </Grid>,
    );
  }

  return (
    <Paper elevation={4}>
      {isLoadingStudents && (
        <Box padding={2}>
          <Grid container spacing={2} pb={2}>
            <Grid item xs={12} sm={8}>
              <Skeleton variant="rectangular" height={40} />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Skeleton variant="rectangular" height={40} />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={5}>
              <Skeleton variant="rectangular" height={40} />
            </Grid>
            <Grid item xs={12} sm={5}>
              <Skeleton variant="rectangular" height={40} />
            </Grid>
            <Grid item xs={12} sm={2}>
              <Skeleton variant="rectangular" height={40} />
            </Grid>
          </Grid>
        </Box>
      )}
      {isSuccessStudents && (
        <Grid container padding={2} pt={0} spacing={2} mt={0} position="relative">
          <Grid item xs={12} smm={6} pr={{ xs: '100px', smm: 0 }}>
            {isSuccessStudents && student !== -1 && (
              <InputLabel sx={{ overflow: 'visible' }}>
                <TextField
                  select
                  id="select-student-journals-filter"
                  label="Student"
                  size="small"
                  fullWidth
                  value={student}
                  onChange={(e) => dispatch({ type: 'student', payload: e.target.value })}
                >
                  {students.results.map((currentStudent, i) => (
                    <MenuItem key={currentStudent.id} value={i}>
                      {currentStudent.username}
                    </MenuItem>
                  ))}
                </TextField>
              </InputLabel>
            )}
          </Grid>
          <Grid
            item
            xs={12}
            smm={6}
            sx={{
              display: 'flex',
              alignItems: { xs: 'stretch', smm: 'center' },
              gap: 2,
              justifyContent: 'flex-end',
              flexDirection: { xs: 'column', smm: 'row' },
            }}
          >
            <Stack
              direction="row"
              sx={{
                position: { xs: 'absolute', smm: 'static' },
                right: 16,
                top: 16,
                mr: 'auto',
              }}
            >
              <IconButton
                aria-label="previous-student"
                disabled={disableStudentNavButtonPrev}
                onClick={() => handleStudentNavClick(-1)}
              >
                <ChevronLeftIcon />
              </IconButton>

              <IconButton
                aria-label="next-student"
                disabled={disableStudentNavButtonNext}
                onClick={() => handleStudentNavClick(1)}
              >
                <ChevronRightIcon />
              </IconButton>
            </Stack>
            <Button
              size="small"
              startIcon={<AddCircleOutlineIcon />}
              variant="outlined"
              disabled={disableFeedbackButton}
              onClick={handleOpenTeacherFeedback}
            >
              Add teacher feedback
            </Button>
            <Button
              size="small"
              variant="outlined"
              disabled={isFetchingJournals}
              onClick={refetch}
              aria-label="Refetch Journals"
            >
              <SyncIcon />
            </Button>
          </Grid>

          <Grid item xs={12} sm={6}>
            <InputLabel htmlFor="search-journals" className="sr-only">
              Search Journals
            </InputLabel>
            <TextField
              id="search-journals"
              placeholder="Search..."
              size="small"
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              onChange={debouncedSearch}
            />
          </Grid>

          <Grid item xs={12} sm={6} smm={4}>
            <InputLabel sx={{ overflow: 'visible' }}>
              <TextField
                select
                id="select-skill-journals-filter"
                label="Skill"
                size="small"
                fullWidth
                value={skill}
                onChange={(e) => dispatch({ type: 'skill', payload: e.target.value })}
              >
                <MenuItem value="All">{isLoadingSkills ? <em>Loading...</em> : <em>All</em>}</MenuItem>
                {isSuccessSkills &&
                  skills.map((currentSkill) => (
                    <MenuItem value={currentSkill.id} key={currentSkill.id}>
                      {currentSkill.name}
                    </MenuItem>
                  ))}
              </TextField>
            </InputLabel>
          </Grid>

          <Grid item xs={12} smm={2} sx={{ display: 'flex', justifyContent: 'flex-end', flexDirection: 'row', gap: 2 }}>
            <ToggleButtonGroup
              value={view}
              exclusive
              onChange={handleViewChange}
              aria-label="journal view"
              size="small"
            >
              <ToggleButton value="grid" aria-label="grid view" sx={{ py: 1, px: 1.5 }}>
                <GridViewIcon />
              </ToggleButton>

              <ToggleButton value="list" aria-label="list view" sx={{ py: 1, px: 1.5 }}>
                <ListIcon />
              </ToggleButton>
            </ToggleButtonGroup>
          </Grid>
        </Grid>
      )}

      <AddTeacherFeedback
        isOpen={isTeacherFeedbackOpen}
        handleClose={handleCloseTeacherFeedback}
        student={students?.results[student]}
      />

      <Divider sx={{ borderColor: darken(theme.palette.primary.dark, 0.3) }} />

      {(isLoadingStudents || isLoadingJournals) && (
        <Grid container spacing={2} p={3}>
          {entriesSkeleton}
        </Grid>
      )}

      {isSuccessStudents && !students.results.length && (
        <Box p={3}>
          <Typography variant="h4" textAlign="center">
            No Students Found!
          </Typography>
        </Box>
      )}

      {isSuccessJournals && (
        <Box position="relative">
          {isFetchingJournals && <BackdropLinearProgress backdropProps={{ sx: { marginTop: 2 } }} />}
          <JournalEntries journals={journals.results} view={view} />
        </Box>
      )}

      {isSuccessJournals && pageCount > 1 && (
        <Stack alignItems="center" p={3}>
          <Pagination count={pageCount} color="primary" page={page} onChange={handlePageChange} />
        </Stack>
      )}
    </Paper>
  );
};

export default Journals;
