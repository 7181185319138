import { Box, Typography, darken, useTheme } from '@mui/material';
import { StyledCount } from 'classes/classDashboard/styles';

const AnswersCard = ({ index, title, studentsCount, studentsAnsweredCount, children }) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        p: 4,
        borderBottom: `1px solid ${darken(theme.palette.primary.dark, 0.3)}`,
        position: 'relative',
        '&:last-of-type': {
          borderBottom: 0,
        },
      }}
    >
      <Box sx={{ pl: 4, position: 'relative' }}>
        <StyledCount variant="body1" component="span">
          {index}.
        </StyledCount>
        <Box sx={{ flexGrow: 1 }}>
          <Typography variant="body1">{title}</Typography>
          {studentsAnsweredCount > 0 && (
            <Typography variant="body2" sx={{ opacity: 0.7 }}>
              {studentsAnsweredCount} out of {studentsCount} students answered
            </Typography>
          )}
          {children}
        </Box>
      </Box>
    </Box>
  );
};

export default AnswersCard;
