import { useEffect, useRef } from 'react';

import { CharacterDisplayContainer, StyledBadges } from '../styles';

const CharacterDisplay = ({ results: programBadges, characterVideoUrl }) => {
  const { slug, imageUrl, awarded, unitBadges } = programBadges?.length ? programBadges[0] : '';
  const videoRef = useRef();

  useEffect(() => {
    videoRef.current?.load();
  }, [characterVideoUrl]);

  return (
    <CharacterDisplayContainer>
      <video width="100%" height="auto" autoPlay muted ref={videoRef}>
        <source src={characterVideoUrl} type="video/mp4" />
        <track kind="captions" />
        Your browser does not support the video tag.
      </video>

      <StyledBadges>
        <ul>
          {unitBadges?.map((badge) => (
            <li className={badge.awarded ? '' : 'disabled'} key={badge.slug}>
              <img src={badge.imageUrl} alt="Unit-Badge" />
            </li>
          ))}
          {slug && (
            <li className={awarded ? '' : 'disabled'} key={slug}>
              <img src={imageUrl} alt="Program-Badge" />
            </li>
          )}
        </ul>
      </StyledBadges>
    </CharacterDisplayContainer>
  );
};

export default CharacterDisplay;
