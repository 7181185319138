import { Link } from 'react-router-dom';
import { Box, Button, Typography, useMediaQuery, useTheme } from '@mui/material';
import Abby from 'assets/images/Characters/Abby.png';
import Jay from 'assets/images/Characters/Jay.png';
import Nadiya from 'assets/images/Characters/Nadiya.png';
import MaxPlug from 'assets/images/max-plug.png';
import { ROUTES } from 'utils/values';


const NoPageFound = () => {
  const theme = useTheme();
  const isScreenLtSm = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box
      mt="-30px"
      mb={30}
      sx={{
        minHeight: 550,
        position: 'relative',
        background: `linear-gradient(to bottom, ${theme.palette.background.default} 50%, rgba(36, 49, 77, 1))`,
      }}
    >
      <Box pt={6} px={2} textAlign="center">
        <Typography variant={isScreenLtSm ? 'h4' : 'h3'} color="white" mb={2}>
          Oops!
        </Typography>
        <Typography variant="body1" color="white" textAlign="center" mb={3}>
          Max was charging his phone and must have unplugged something by accident.
          <br />
          We have our skilled 404 Team on the case, but in the mean time, lets get you...
        </Typography>
        <Button variant="contained" color="secondary" mb={3} to={ROUTES.ROOT} component={Link}>
          back to GEN+
        </Button>
        <Typography fontSize={isScreenLtSm ? '10rem' : '16rem'} fontWeight="600" color="white" lineHeight="1">
          404
        </Typography>
      </Box>
      <Box
        sx={{
          position: 'absolute',
          bottom: {
            xs: -100,
            lg: -60,
          },
          right: 0,
          width: {
            sm: '525px',
          },
        }}
      >
        <img src={MaxPlug} width="100%" alt="Max Plugs" />
      </Box>
      <Box
        sx={{
          position: 'absolute',
          bottom: 0,
          left: '50%',
          transform: 'translateX(-50%)',
          margin: '0 0 -215px -120px',
          '&::after': {
            content: '""',
            position: 'absolute',
            width: 100,
            height: 50,
            borderRadius: '100%',
            bottom: -16,
            left: '50%',
            transform: 'translateX(-50%)',
            backgroundColor: 'rgba(0, 0, 0, 0.2)',
            zIndex: -1,
          },
          display: {
            xs: 'none',
            lg: 'block',
          },
        }}
      >
        <img src={Abby} alt="Abby" />
      </Box>
      <Box
        sx={{
          position: 'absolute',
          bottom: 0,
          left: '50%',
          transform: 'translateX(-50%)',
          marginBottom: '-150px',
          '&::after': {
            content: '""',
            position: 'absolute',
            width: isScreenLtSm ? 120 : 160,
            height: 35,
            borderRadius: '100%',
            bottom: -8,
            left: '50%',
            backgroundColor: 'rgba(0, 0, 0, 0.2)',
            zIndex: -1,
            transform: 'rotate(-2deg) translateX(-50%)',
          },
          display: {
            xs: 'none',
            lg: 'block',
          },
        }}
      >
        <img src={Jay} width="100" alt="Jay" />
      </Box>

      <Box
        sx={{
          position: 'absolute',
          bottom: 0,
          left: '50%',
          transform: 'translateX(-50%)',
          margin: '0 0 -215px 110px',
          '&::after': {
            content: '""',
            position: 'absolute',
            width: isScreenLtSm ? 100 : 140,
            height: 35,
            borderRadius: '100%',
            bottom: -8,
            left: 10,
            backgroundColor: 'rgba(0, 0, 0, 0.2)',
            zIndex: -1,
            transform: 'rotate(2deg)',
          },
          display: {
            xs: 'none',
            lg: 'block',
          },
        }}
      >
        <img src={Nadiya} alt="Nadiya" height={280} />
      </Box>
    </Box>
  );
};

export default NoPageFound;
