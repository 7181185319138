import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Chip,
  Link,
  Skeleton,
  Stack,
  Typography,
} from '@mui/material';

const PortfolioCardSkeleton = () => (
  <>
    <Card elevation={3}>
      <CardHeader
        title={<Skeleton />}
        titleTypographyProps={{ variant: 'h6' }}
        subheader={<Skeleton />}
        sx={{ pb: 0 }}
      />

      <CardContent sx={{ pt: 1 }}>
        <Typography variant="caption" color="text.disabled">
          <Skeleton width={100} />
        </Typography>
      </CardContent>

      <CardActions sx={{ p: 2, pt: 0, alignItems: 'center', justifyContent: 'space-between' }}>
        <Stack direction={{ xs: 'column', xss: 'row' }} gap={1} alignItems="flex-start">
          <Chip label="" size="small" component={Link} href="#" clickable sx={{ width: '100px' }} />
          <Chip label="" size="small" sx={{ width: '100px' }} />
        </Stack>

        <Box alignSelf={{ xs: 'flex-start', xss: 'center' }}>
          <Button size="small">
            <Skeleton width={60} />
          </Button>
        </Box>
      </CardActions>
    </Card>
  </>
);

export default PortfolioCardSkeleton;
