import HomeIcon from '@mui/icons-material/Home';
import { AppBar, Box, Button, Container, Toolbar, Typography } from '@mui/material';
import logo from 'assets/images/logo.svg';
import { LMS_LOGOUT } from 'config';

const StaticHeader = () => (
  <AppBar position="static">
    <Container maxWidth="xl">
      <Toolbar disableGutters>
        <Box sx={{ mr: 2, display: { xs: 'none', md: 'flex' } }}>
          <Typography
            component="span"
            sx={{
              display: 'flex',
              color: 'text.disabled',
            }}
          >
            <HomeIcon sx={{ mr: 1, fontSize: 25 }} />
            <Box component="img" src={logo} alt="Gen Plus" />
          </Typography>
        </Box>
        <Box sx={{ display: { xs: 'flex', md: 'none' }, flexGrow: 1 }}>
          <Typography
            component="span"
            sx={{
              display: 'flex',
              color: 'text.disabled',
            }}
          >
            <HomeIcon sx={{ mr: 1, fontSize: 25 }} />
            <Box component="img" src={logo} alt="Gen Plus" />
          </Typography>
        </Box>

        <Box ml="auto">
          <Button size="small" sx={{ color: 'text.disabled' }} href={LMS_LOGOUT}>
            Logout
          </Button>
        </Box>
      </Toolbar>
    </Container>
  </AppBar>
);

export default StaticHeader;
