import { useState } from 'react';
import { useGetUserInfoQuery } from 'users/userApi';
import { stringToColor } from 'utils/utilityFunctions';

import { Avatar, Button, Card, CardActions, CardHeader, Stack, Typography } from '@mui/material';

import EditTeacherFeedback from './EditTeacherFeedback';

const TeacherEntry = ({ id, teacher, title, description, modified, cardElevation = 3 }) => {
  const { data: user } = useGetUserInfoQuery();
  const { id: userId } = user;
  const { userId: teacherUserId } = teacher;
  const isEntryOwner = userId === teacherUserId;

  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const handleOpenEditDialog = () => setIsEditDialogOpen(true);
  const handleCloseEditDialog = () => setIsEditDialogOpen(false);

  return (
    <Card elevation={cardElevation}>
      <CardHeader
        disableTypography
        title={
          <Typography variant="body2" color="text.disabled">
            {new Date(modified).toLocaleDateString('en-GB')}
          </Typography>
        }
        subheader={
          <>
            <Typography component="h2" variant="h6">
              {title}
            </Typography>
            <Typography varaint="body2" sx={{ my: 2.5 }}>
              {description}
            </Typography>
          </>
        }
        sx={{ paddingBottom: 0 }}
      />

      <CardActions
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          paddingX: 2,
          paddingTop: 1.25,
          paddingBottom: 2,
        }}
      >
        <Stack direction="row" gap={1} alignItems="center">
          {teacher?.profileImage ? (
            <Avatar src={teacher?.profileImage} alt={`${teacher?.name} profile image`} />
          ) : (
            <Avatar sx={{ width: 32, height: 32, backgroundColor: stringToColor(teacher?.name) }}>
              {teacher?.name[0]?.toUpperCase()}
            </Avatar>
          )}
          <Typography textTransform="uppercase">{teacher?.name}</Typography>
        </Stack>

        {isEntryOwner && (
          <>
            <Button size="small" onClick={handleOpenEditDialog}>
              Edit
            </Button>
            <EditTeacherFeedback
              isOpen={isEditDialogOpen}
              handleClose={handleCloseEditDialog}
              id={id}
              oldTitle={title}
              oldContent={description}
            />
          </>
        )}
      </CardActions>
    </Card>
  );
};

export default TeacherEntry;
