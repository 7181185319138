import { useConfirmRecentAwardedBadgesMutation, useGetRecentAwardedBadgesQuery } from 'badges/badgesApi';
import Lottie from 'lottie-react';
import moment from 'moment';
import { useRef, useState } from 'react';
import Slider from 'react-slick';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Box, Button, Dialog, DialogContent, Typography, useMediaQuery, useTheme } from '@mui/material';

import { SlickNextButton, SlickPrevButton, StyledAwardBadgeSlider } from '../styles';

const AwardAllBadgePopup = () => {
  const theme = useTheme();
  const isScreenLtSm = useMediaQuery(theme.breakpoints.down('sm'));
  const { data, isSuccess } = useGetRecentAwardedBadgesQuery();
  const [confirmBadges] = useConfirmRecentAwardedBadgesMutation();

  const [badgeCelebration, setBadgeCelebration] = useState(true);
  const [badgeAcceptance, setBadgeAcceptance] = useState(false);
  const lottieRef = useRef();

  const handleNext = () => {
    setBadgeCelebration(false);
    setBadgeAcceptance(true);
  };

  const handleBadgeAcceptanceClose = async () => {
    const badges = data?.badges.map(({ id, type }) => ({ badgeId: id, badgeType: type }));
    await confirmBadges(badges)
      .unwrap()
      .catch((error) => console.log(error))
      .finally(() => setBadgeAcceptance(false));
  };

  const SliderPrevArrow = ({ className, onClick }) => (
    <SlickPrevButton variant="outlined" onClick={onClick} className={className}>
      <ArrowBackIcon />
    </SlickPrevButton>
  );

  const SliderNextArrow = ({ className, onClick }) => (
    <SlickNextButton variant="outlined" onClick={onClick} className={className}>
      <ArrowForwardIcon />
    </SlickNextButton>
  );

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    className: 'awardbadge-slider',
    nextArrow: <SliderNextArrow />,
    prevArrow: <SliderPrevArrow />,
  };

  if (!isSuccess || data?.badges.length === 0) {
    return null;
  }

  return (
    <>
      <Dialog
        open={badgeCelebration}
        sx={{ textAlign: 'center' }}
        PaperProps={{ elevation: 4 }}
        fullWidth
        maxWidth="sm"
        fullScreen={isScreenLtSm}
      >
        <DialogContent>
          <Box sx={{ py: 2 }}>
            <Typography component="h2" variant="h6">
              You have been awarded a badge!
            </Typography>
            <Box sx={{ '& div': { height: 400 } }}>
              <Lottie autoplay={Boolean(true)} loop={Boolean(true)} path={data?.animation} lottieRef={lottieRef} />
            </Box>
            <Button variant="contained" onClick={handleNext}>
              Continue
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
      <Dialog
        open={badgeAcceptance}
        sx={{ textAlign: 'center' }}
        PaperProps={{ elevation: 4 }}
        fullWidth
        maxWidth="sm"
        fullScreen={isScreenLtSm}
      >
        <DialogContent>
          <StyledAwardBadgeSlider>
            <Slider {...settings}>
              {data?.badges.map((badge) => (
                <Box key={badge?.badgeName} sx={{ mb: 3 }}>
                  <img src={badge?.imageUrl} width="166" alt="Excellent Answer" />
                  <Typography component="h2" variant="body2">
                    {badge?.badgeName}
                  </Typography>
                  <Typography color="text.disabled" variant="caption">
                    {moment(badge?.created).format('DD MMMM YYYY')}
                  </Typography>
                  {badge?.feedback && <Typography sx={{ mt: 2 }}>{badge?.feedback}</Typography>}
                </Box>
              ))}
            </Slider>
            <Button variant="contained" onClick={handleBadgeAcceptanceClose}>
              Accept Badges ({data?.badges.length})
            </Button>
          </StyledAwardBadgeSlider>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AwardAllBadgePopup;
