import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation, useNavigate } from 'react-router-dom';
import { PAGE_TITLE, PALTFORM_NAME, ROUTES } from 'utils/values';

import { Box, Container, Tabs, Typography } from '@mui/material';

import ContentLibrary from './components/ContentLibrary';
import Portfolio from './components/Portfolio';
import { GenTeachTab } from './styles';

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`tabpanel-${index}`} aria-labelledby={`tab-${index}`} {...other}>
      {value === index && <Box sx={{ mt: 4 }}>{children}</Box>}
    </div>
  );
};

const a11yProps = (index) => ({
  id: `tab-${index}`,
  'aria-controls': `tabpanel-${index}`,
});

const TeachDashboard = () => {
  const [currentTab, setCurrentTab] = useState(0);
  const { search } = useLocation();
  const navigate = useNavigate();
  const handleTabChange = (event, newValue) => {
    navigate(`${ROUTES.TEACH}?activeTab=${newValue}`);
  };

  useEffect(() => {
    const query = new URLSearchParams(search);
    setCurrentTab(+query.get('activeTab'));
  }, [setCurrentTab, search]);

  return (
    <Container maxWidth="md">
      <Helmet>
        <title>{`${PALTFORM_NAME} | ${PAGE_TITLE?.TEACH} | ${currentTab === 1 ? 'My Portfolio' : 'Content Library'}
          `}</title>
      </Helmet>
      <Typography variant="h5" component="h1" color="white">
        Gen+ Teach
      </Typography>

      <Box mt={3}>
        <Box sx={{ borderBottom: 2, borderColor: '#1F4C6E' }}>
          <Tabs
            value={currentTab}
            onChange={handleTabChange}
            aria-label="class dashboard option selection tabs"
            variant="fullWidth"
            scrollButtons="auto"
            selectionFollowsFocus
          >
            <GenTeachTab label="Content Library" {...a11yProps(0)} />
            <GenTeachTab label="My Portfolio" {...a11yProps(1)} />
          </Tabs>
        </Box>

        <TabPanel value={currentTab} index={0}>
          <ContentLibrary />
        </TabPanel>
        <TabPanel value={currentTab} index={1}>
          <Portfolio />
        </TabPanel>
      </Box>
    </Container>
  );
};

export default TeachDashboard;
