import { Navigate } from 'react-router-dom';
import { useGetUserInfoQuery } from 'users/userApi';
import { ROUTES } from 'utils/values';

const RequireStaffAuth = ({ children }) => {
  const { data: user } = useGetUserInfoQuery();

  if (user.isStudent) {
    return user.onBoard ? <Navigate to="/" /> : <Navigate to={ROUTES.STUDENTONBOARD} />;
  }
  if (!user.hasChangedPassword) {
    return <Navigate to={ROUTES.PASSWORD_CHANGE} />;
  }

  return children;
};

export default RequireStaffAuth;
