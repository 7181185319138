import baseApiSlice from 'api/baseApiSlice';
import { GENZ_API_URL } from 'config';

const JOURNALS_API_URL = `${GENZ_API_URL}/journals/`;

const journalsApiWithTags = baseApiSlice.enhanceEndpoints({
  addTagTypes: ['Journal'],
});

const journalsApiSlice = journalsApiWithTags.injectEndpoints({
  endpoints: (builder) => ({
    getJournals: builder.query({
      query: (arg) => ({
        url: `${JOURNALS_API_URL}`,
        params: arg,
      }),
      providesTags: (result) =>
        result?.results
          ? [...result.results.map(({ id }) => ({ type: 'Journal', id })), { type: 'Journal', id: 'LIST' }]
          : [{ type: 'Journal', id: 'LIST' }],
    }),

    createJournal: builder.mutation({
      query: (data) => ({
        url: JOURNALS_API_URL,
        method: 'POST',
        data,
      }),
      invalidatesTags: [{ type: 'Journal', id: 'LIST' }],
    }),

    updateJournal: builder.mutation({
      query: ({ id, ...data }) => ({
        url: `${JOURNALS_API_URL}${id}/`,
        method: 'PATCH',
        data,
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'Journal', id: arg.id }],
    }),
  }),

  overrideExisting: false,
});

export const { useGetJournalsQuery, useLazyGetJournalsQuery, useCreateJournalMutation, useUpdateJournalMutation } =
  journalsApiSlice;

export default journalsApiSlice;
