import MenuBookIcon from '@mui/icons-material/MenuBook';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Chip,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { useState } from 'react';

import EditPortfolio from './EditPortfolio';

const PortfolioCard = ({ created, entryType, data }) => {
  const [isEditPortfolioOpen, setIsEditPortfolioOpen] = useState(false);
  const handleOpenAddPortfolio = () => setIsEditPortfolioOpen(true);
  const handleCloseAddPortfolio = () => setIsEditPortfolioOpen(false);

  const isPortfolioEntry = entryType === 'portfolioentry';

  const { id, teacher, answer = '', reflection = {}, title = '', description = '', gtcs = [], skill = {} } = data;

  return (
    <>
      <Card elevation={3}>
        <CardHeader
          title={isPortfolioEntry ? title : reflection.title}
          titleTypographyProps={{ variant: 'h6' }}
          subheader={isPortfolioEntry ? description : answer}
          subheaderTypographyProps={{
            mt: 2,
            variant: 'body2',
            color: 'text',
            whiteSpace: 'pre-wrap',
          }}
          sx={{ pb: 0 }}
        />

        <CardContent sx={{ pt: 1 }}>
          <Typography variant="caption" color="text.disabled">
            {new Date(created).toLocaleDateString('en-GB')}
          </Typography>
        </CardContent>

        {isPortfolioEntry ? (
          <CardActions
            sx={{
              p: 2,
              pt: 0,
              gap: 1,
              alignItems: 'center',
              justifyContent: 'space-between',
              flexDirection: { xs: 'column', sm: 'row' },
            }}
          >
            <Stack direction="row" gap={1} flexWrap="wrap">
              {gtcs?.length
                ? gtcs.map((gtcsItem) => (
                    <Tooltip key={gtcsItem.id} title={gtcsItem.name} placement="top">
                      <Chip icon={<MenuBookIcon />} label={`${gtcsItem.name.slice(0, 14)}...`} size="small" />
                    </Tooltip>
                  ))
                : null}
              {skill ? <Chip label={skill.name} variant="outlined" size="small" /> : null}
            </Stack>

            <Box alignSelf="flex-end">
              <Button size="small" onClick={handleOpenAddPortfolio}>
                Edit
              </Button>
            </Box>
          </CardActions>
        ) : null}
      </Card>

      {isPortfolioEntry && (
        <EditPortfolio
          isOpen={isEditPortfolioOpen}
          handleClose={handleCloseAddPortfolio}
          oldTitle={title}
          oldThoughts={description}
          id={id}
          teacher={teacher}
        />
      )}
    </>
  );
};

export default PortfolioCard;
