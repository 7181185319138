import { useGetLessonsQuery } from 'lessons/lessonsApi';
import { useGetUserInfoQuery } from 'users/userApi';

import { Container, Grid, Skeleton, Typography } from '@mui/material';

import BrowseLessons from './BrowseLessons';
import CardSkeleton from './components/CardSkeleton';

const Lessons = () => {
  let visiblePrograms = [];

  const { data: programs, isLoading, isSuccess } = useGetLessonsQuery();
  const { data: user } = useGetUserInfoQuery();

  if (!isLoading) {
    if (user?.isStudent) {
      visiblePrograms = programs?.results?.filter((program) => program?.studentBrowsable);
    } else {
      visiblePrograms = programs?.results?.filter(
        (program) => program?.staffBrowsable || program?.staffBrowsable === null,
      );
    }
  }

  return (
    <Container maxWidth="md" sx={{ px: { md: user?.belongsToSecondary ? 1 : 0 } }}>
      <Grid item xs={12}>
        {isLoading && (
          <>
            <Skeleton height={80} sx={{ mb: 3 }} />
            <CardSkeleton />
            <CardSkeleton />
            <CardSkeleton />
          </>
        )}
        {isSuccess &&
          (visiblePrograms.length > 0 ? (
            <BrowseLessons programs={visiblePrograms} />
          ) : (
            <Typography variant="h5" textAlign="center">
              No Lessons Found!
            </Typography>
          ))}
      </Grid>
    </Container>
  );
};

export default Lessons;
