import { useReadFeedActivityMutation } from 'dashboard/dashboardApi';
import { useState } from 'react';
import { useGetUserInfoQuery } from 'users/userApi';

import { Button, Stack, TableCell, Typography, useMediaQuery, useTheme } from '@mui/material';

import { ActivityNotificationIcon } from '../styles';
import ActivityFeedModal from './AcitivityFeedModal';
import { activityTypes } from './FeedComponents';

const ActivityFeedNotification = ({ data }) => {
  const { data: userInfo = {} } = useGetUserInfoQuery();
  const activity = activityTypes[data.type];
  const user = data?.actor?.name ? data?.actor : userInfo;
  const created = new Date(data.created).toLocaleDateString('en-GB');
  const [feedModal, setFeedModal] = useState(false);
  const closeFeedModal = () => setFeedModal(false);
  const [readFeedActivity] = useReadFeedActivityMutation();
  const [isRead, setIsRead] = useState(data.isRead);
  const theme = useTheme();
  const isScreenLtSm = useMediaQuery(theme.breakpoints.down('xss'));
  const openFeedModal = async () => {
    if (!data.isRead && !isRead) {
      await readFeedActivity(data.id);
      setIsRead(true);
    }
    setFeedModal(true);
  };

  return (
    <>
      <TableCell sx={{ pl: 3 }}>
        <Stack direction="row" gap={2} alignItems="center">
          <ActivityNotificationIcon elevation={18} isNew={!isRead}>
            {activity.icon}
          </ActivityNotificationIcon>

          <Stack>
            <Typography variant="body2" color="text.secondary">
              {activity.title}
            </Typography>
            <Typography variant="body1">{user.name}</Typography>
          </Stack>
        </Stack>
      </TableCell>

      <TableCell>
        <Typography variant="body2">{created}</Typography>
      </TableCell>
      <TableCell sx={{ pr: 3 }}>
        <Stack>
          <Button size="small" onClick={() => openFeedModal()}>
            View
          </Button>
        </Stack>
      </TableCell>
      {data && (
        <ActivityFeedModal
          modal={feedModal}
          closeModal={() => closeFeedModal()}
          data={{ ...data, user }}
          isScreenLtSm={isScreenLtSm}
        />
      )}
    </>
  );
};

export default ActivityFeedNotification;
