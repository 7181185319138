import StudentDashboradProfile from 'assets/images/student-dashboard-profile.svg';
import { useGetProgressQuery } from 'dashboard/dashboardApi';
import Lessons from 'lessons';

import { Box, Grid } from '@mui/material';

import JoinClass from './components/JoinClass';
import ProgramProgress from './components/ProgramProgress';
import ProgramProgressSkeleton from './components/ProgramProgressSkeleton';
import RecentBadges from './components/RecentBadges';

const PrimaryStudent = ({ fromDefaultClass }) => {
  const {
    data: studentProgress,
    isLoading: isLoadingStudentProgress,
    isSuccess: isSuccessStudentProgress,
  } = useGetProgressQuery();

  return (
    <>
      {fromDefaultClass && <JoinClass />}
      <Grid container rowSpacing={2} columnSpacing={3} sx={{ alignItems: 'center', pt: 3, pb: 5 }}>
        <Grid item xs={12} sm={4.5} smm={6} textAlign="center">
          <Box
            component="img"
            sx={{ maxWidth: '100%' }}
            src={StudentDashboradProfile}
            alt="Student Dashborad Profile"
          />
        </Grid>
        <Grid item xs={12} sm={7.5} smm={6}>
          {isLoadingStudentProgress && <ProgramProgressSkeleton />}
          {isSuccessStudentProgress && <ProgramProgress {...studentProgress} hideProgramName />}
        </Grid>
      </Grid>
      <Box sx={{ mb: 3 }}>
        <RecentBadges />
      </Box>
      <Lessons />
    </>
  );
};

export default PrimaryStudent;
