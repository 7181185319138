import { Navigate } from 'react-router-dom';
import { useGetUserInfoQuery } from 'users/userApi';

import { ROUTES } from '../utils/values';

const RequireTeacherAuth = ({ children }) => {
  const { data: user = {} } = useGetUserInfoQuery();
  if (!user.hasChangedPassword) {
    return <Navigate to={ROUTES.PASSWORD_CHANGE} />;
  }

  if (!user.isTeacher) {
    return <Navigate to="/" />;
  }

  return children;
};

export default RequireTeacherAuth;
