import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Rating,
  Stack,
  TextField,
  Typography,
  alpha,
} from '@mui/material';
import { useState } from 'react';
import { useGetUserInfoQuery } from 'users/userApi';
import { notifyError, notifySuccess } from 'utils/notification';
import { stringToColor } from 'utils/utilityFunctions';

const ArticleRating = ({ articleRating, articleId, useRateMutation }) => {
  const { data: user } = useGetUserInfoQuery();

  const [rating, setRating] = useState(articleRating?.rating || 0);
  const [comment, setComment] = useState(articleRating?.comment || '');

  const [openEditDialog, setOpenEditDialog] = useState(false);
  const handleOpenEditDialog = () => setOpenEditDialog(true);

  const handleCloseEditDialog = () => {
    setOpenEditDialog(false);
    setRating(articleRating?.rating || 0);
    setComment(articleRating?.comment || '');
  };

  const [rateArticle, { isLoading }] = useRateMutation();
  const disableSaveButton =
    (rating === articleRating?.rating && comment === articleRating?.comment) || isLoading || rating <= 0 || !comment;

  const handleSave = async () => {
    const data = {
      rating,
      comment,
    };

    try {
      const res = await rateArticle({ id: articleId, data }).unwrap();
      notifySuccess(res);
    } catch (error) {
      notifyError(error.data.detail);
    }

    setOpenEditDialog(false);
  };

  return (
    <Box px={2} py={1} border={1} borderRadius={2} borderColor={alpha('#fff', 0.23)}>
      {articleRating ? (
        <>
          <Stack direction="row" gap={1} alignItems="center">
            <Typography variant="overline">Your rating:</Typography>
            <Rating name="article-rating-readonly" size="small" value={articleRating.rating} readOnly />
          </Stack>

          <Stack direction="row" gap={2} mt={2}>
            {user.profileImage ? (
              <Avatar
                sx={{ width: 32, height: 32 }}
                src={user.profileImage}
                alt={`${user.username}'s profile picture`}
              />
            ) : (
              <Avatar
                sx={{
                  width: 32,
                  height: 32,
                  backgroundColor: stringToColor(user.username),
                }}
              >
                {user.username.slice(0, 1).toUpperCase()}
              </Avatar>
            )}
            <Typography variant="body1">{articleRating.comment}</Typography>
          </Stack>

          <Box mt={2}>
            <Button size="small" onClick={handleOpenEditDialog}>
              Edit
            </Button>
          </Box>
        </>
      ) : (
        <>
          <Stack
            direction={{ xs: 'column', xss: 'row' }}
            gap={{ xss: 1 }}
            alignItems={{ xs: 'flex-start', xss: 'center' }}
          >
            <Typography variant="overline" component="p">
              How would you rate this Content?
            </Typography>
            <Rating
              name="article-rating"
              size="small"
              value={rating}
              onChange={(e, v) => {
                setRating(v);
                handleOpenEditDialog();
              }}
            />
          </Stack>
        </>
      )}

      <Dialog
        open={openEditDialog}
        onClose={handleCloseEditDialog}
        maxWidth="sm"
        fullWidth
        PaperProps={{ elevation: 3 }}
        disableRestoreFocus
      >
        <DialogContent>
          <Typography variant="body2">How would you rate this article:</Typography>

          <Box mt={2}>
            <Rating name="article-rating" value={rating} onChange={(e, v) => setRating(v)} />
          </Box>

          <TextField
            multiline
            id="article-review"
            fullWidth
            rows={4}
            placeholder="Please leave additional feedback here"
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            sx={{ mt: 2 }}
          />
        </DialogContent>

        <DialogActions sx={{ justifyContent: 'flex-start' }}>
          <Button type="submit" variant="contained" disabled={disableSaveButton} onClick={handleSave}>
            Save
          </Button>
          <Button variant="outlined" onClick={handleCloseEditDialog}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ArticleRating;
