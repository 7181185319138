import organisationImage from 'assets/images/organisation.png';
import { useSendMarketingDataMutation } from 'dashboard/dashboardApi';
import { useState } from 'react';
import { notifyError, notifySuccess } from 'utils/notification';
import { YEAR_GROUPS } from 'utils/values';

import {
  Backdrop,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';

import { StyledYearGroupChip } from '../styles';

const GetMarketingInfoPopup = () => {
  const remindMeLater = localStorage.getItem('has_submitted_marketing_form');

  const [open, setOpen] = useState(remindMeLater !== 'true');
  const [selectedYearGroups, setSelectedYearGroups] = useState([]);
  const [acceptCopyrights, setAcceptCopyrights] = useState(false);
  const [marketingEmail, setMarketingEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [allowMarketing, setAllowMarketing] = useState(false);

  const [sendMarketingData, { isLoading: isUpdating }] = useSendMarketingDataMutation();

  const theme = useTheme();
  const isScreenLtSm = useMediaQuery(theme.breakpoints.down('sm'));

  const handleClose = () => {
    setOpen(false);
  };

  const handleSelect = (event) => {
    const currentLabel = event.target.innerText;
    setSelectedYearGroups((yearGroups) =>
      yearGroups.indexOf(currentLabel) !== -1
        ? yearGroups.filter((groupLabel) => groupLabel !== currentLabel)
        : [...yearGroups, currentLabel],
    );
  };

  const handleTempClose = () => {
    localStorage.setItem('has_submitted_marketing_form', 'true');
    handleClose();
  };

  const handleSendMarketingData = async (data) => {
    await sendMarketingData(data)
      .unwrap()
      .then(() => {
        notifySuccess('Form submitted successfully');
      })
      .catch(() => {
        notifyError('Something went wrong. Please try again later!');
      })
      .finally(() => {
        handleTempClose();
      });
  };

  const handleEmailChange = (event) => {
    setMarketingEmail(event.target.value);
    setEmailError('');
  };

  const hanldeAcceptCopyrightsChange = (event) => {
    setAcceptCopyrights(event.target.checked);
  };

  const handleAllowMarketingChange = (event) => {
    setAllowMarketing(event.target.checked);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const isValidEmail = emailRegex.test(marketingEmail);

    if (!isValidEmail) {
      setEmailError('Invalid Email');
      return;
    }
    const data = {
      email: marketingEmail,
      allowMarketingInfo: allowMarketing,
      teachingGroup: `${selectedYearGroups.join(';')};`,
    };
    handleSendMarketingData(data);
  };

  const submitDisabled =
    selectedYearGroups.length === 0 || !acceptCopyrights || marketingEmail.length === 0 || isUpdating;

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth="sm"
      fullScreen={isScreenLtSm}
      PaperProps={{
        component: 'form',
        elevation: 4,
        onSubmit: handleSubmit,
      }}
    >
      <Box sx={{ alignSelf: 'center', py: 4, px: { sm: 2, md: 4 }, position: 'relative' }}>
        {isUpdating && (
          <Backdrop
            open={Boolean(true)}
            sx={{
              position: 'absolute',
              zIndex: (UItheme) => UItheme.zIndex.drawer + 1,
            }}
          >
            <CircularProgress />
          </Backdrop>
        )}
        <DialogTitle sx={{ textAlign: 'center' }} gutterBottom>
          <Box component="img" src={organisationImage} alt="Contact info about teachers" />
          <Typography component="p" variant="h6">
            Please share your details to stay updated on available support, CLPL webinars and programme updates
          </Typography>
        </DialogTitle>
        <DialogContent>
          <TextField
            value={marketingEmail}
            autoFocus
            error={emailError.length > 0}
            margin="dense"
            id="name"
            name="email"
            label="Preferred Email *"
            type="email"
            size="small"
            helperText={emailError}
            fullWidth
            onChange={handleEmailChange}
          />
          <DialogContentText sx={{ fontSize: 12, px: 2, mb: 3 }}>
            This is where we will send programme updates and support. It does not need to match your school/login email.
          </DialogContentText>
          <Typography component="h2" gutterBottom>
            Which year groups do you teach Gen+? *
          </Typography>
          {YEAR_GROUPS.map((label) => {
            const checked = selectedYearGroups.includes(label);
            return (
              <StyledYearGroupChip
                key={label}
                clickable
                data-value={label}
                variant={checked ? 'primary' : 'outlined'}
                sx={{
                  backgroundColor: checked ? 'primary.main' : 'outlined',
                  color: checked ? 'primary.contrastText' : '',
                }}
                label={label}
                onClick={handleSelect}
              />
            );
          })}
          <DialogContentText sx={{ fontSize: 12, px: 2, mb: 3 }}>
            This is to help us better understand how the platform is used.
          </DialogContentText>
          <Box sx={{ px: 2 }}>
            <FormControlLabel
              control={<Checkbox checked={acceptCopyrights} onChange={hanldeAcceptCopyrightsChange} />}
              label="Accept Copyright Terms *"
            />
            <DialogContentText sx={{ fontSize: 12, mb: 3 }}>
              Gen+ Copyright Notice: Unauthorised reproduction, downloading, sharing, distribution, printing, or passing
              off of this work is strictly prohibited.
            </DialogContentText>
          </Box>
          <Box sx={{ px: 2 }}>
            <FormControlLabel
              control={<Checkbox checked={allowMarketing} onChange={handleAllowMarketingChange} />}
              label="Allow Marketing Communications"
            />
            <DialogContentText sx={{ fontSize: 12 }}>
              We’d love to be able to keep you up to date with the latest support, programme updates and CLPL webinars.
            </DialogContentText>
          </Box>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center' }}>
          <Button size="large" variant="contained" type="submit" disabled={submitDisabled}>
            Confirm
          </Button>
          <Button size="large" onClick={handleTempClose}>
            Remind me later
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default GetMarketingInfoPopup;
