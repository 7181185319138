import { nanoid } from '@reduxjs/toolkit';
import { CLASS_MESSAGES, PROGRESS_STATUS } from 'utils/values';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import PendingIcon from '@mui/icons-material/Pending';
import SchoolIcon from '@mui/icons-material/School';
import { Stack, Tooltip, Typography } from '@mui/material';

const UnitProgressionCell = ({ lessonCompletions }) => (
  <Stack direction="row">
    {lessonCompletions.map((completion) => {
      if (completion === PROGRESS_STATUS.TEACHER_LED) {
        return (
          <Tooltip arrow title={CLASS_MESSAGES.TEACHER_LED} placement="top" key={nanoid()}>
            <SchoolIcon sx={{ fontSize: '19px', color: 'info.dark', opacity: 0.5 }} />
          </Tooltip>
        );
      }
      if (completion === PROGRESS_STATUS.COMPLETED) {
        return (
          <Tooltip arrow title={CLASS_MESSAGES.COMPLETE} placement="top" key={nanoid()}>
            <CheckCircleIcon sx={{ fontSize: '19px', color: 'success.dark' }} />
          </Tooltip>
        );
      }
      if (completion === PROGRESS_STATUS.IN_PROGRESS) {
        return (
          <Tooltip arrow title={CLASS_MESSAGES.IN_PROGRESS} placement="top" key={nanoid()}>
            <CheckCircleOutlineIcon sx={{ fontSize: '19px', color: 'warning.dark' }} />
          </Tooltip>
        );
      }
      return (
        <Tooltip arrow title={CLASS_MESSAGES.NOT_STARTED} placement="top" key={nanoid()}>
          <PendingIcon sx={{ fontSize: '19px', color: 'primary.dark' }} />
        </Tooltip>
      );
    })}
    {!lessonCompletions.length && <Typography variant="body2">No Lessons</Typography>}
  </Stack>
);

export default UnitProgressionCell;
