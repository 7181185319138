import { Link as RouterLink } from 'react-router-dom';

import HomeIcon from '@mui/icons-material/Home';
import { Box, Typography } from '@mui/material';
import { ROUTES } from 'utils/values';
import logo from 'assets/images/logo.svg';

const Logo = () => (
  <RouterLink to={ROUTES.ROOT}>
    <Typography
      component="span"
      sx={{
        display: 'flex',
        color: 'text.disabled',
        ':hover': { color: 'text.primary' },
        ':focus': { color: 'text.primary' },
      }}
    >
      <HomeIcon sx={{ mr: 1, fontSize: 25 }} />
      <Box component="img" src={logo} alt="Gen Plus" />
    </Typography>
  </RouterLink>
);

export default Logo;
