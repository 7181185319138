import LinearProgressWithLabel from 'components/Progress';
import { useGetUserInfoQuery } from 'users/userApi';

import LockIcon from '@mui/icons-material/Lock';
import { Box, Button, CardActions, CardContent, Typography } from '@mui/material';

import { StyledBadgeCard, StyledProgress, StyledUnit } from '../styles';

const Unit = ({
  index,
  lmsUrl,
  pdfUrl,
  setPdf,
  isLocked,
  progress,
  openModal,
  isTeacher,
  isStudent,
  subtitle,
  displayName,
  bannerImageUrl,
  shortDescription,
  completionBadgeUrl,
}) => {
  const { data: user = {} } = useGetUserInfoQuery();
  const handleOpenModal = (e) => {
    setPdf(e.target.dataset.pdf);
    openModal();
  };

  return (
    <StyledUnit style={{ backgroundImage: `url(${bannerImageUrl})` }}>
      <img hidden className="sponsor-logo" src="{sponsorLogo}" alt="Sponsored By Keyloop" />
      <CardContent>
        <Typography variant="subtitle2" color="text.secondary" component="span">
          {subtitle === null ? `Unit ${index + 1}` : subtitle}
        </Typography>
        <Typography component="h2" variant="h4" sx={{ mb: 1.8 }}>
          {displayName}
        </Typography>
        <Typography fontSize="14px">{shortDescription}</Typography>
      </CardContent>
      {progress === 100 && completionBadgeUrl ? (
        <StyledBadgeCard item sm={4} className="badge-card">
          <Box className="badge badge-animation" component="img" src={completionBadgeUrl} alt="Gen Plus" />
        </StyledBadgeCard>
      ) : null}
      <CardActions>
        {user?.belongsToSecondary && isLocked ? (
          <Button variant="contained" disabled size="large" sx={{ mr: 2 }} startIcon={<LockIcon />}>
            Locked
          </Button>
        ) : (
          <Button variant="outlined" size="large" sx={{ mr: 2 }} href={lmsUrl}>
            Enter
          </Button>
        )}
        {isTeacher && (
          <Button sx={{ mr: 2 }} onClick={handleOpenModal} hidden data-pdf={pdfUrl}>
            View Unit Spec
          </Button>
        )}
        {isStudent && (
          <StyledProgress>
            <LinearProgressWithLabel value={progress} />
          </StyledProgress>
        )}
      </CardActions>
    </StyledUnit>
  );
};

export default Unit;
