import { Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { useGetAllStudentsAnswersQuery } from 'classes/classApi';
import { StyledTableRow } from 'classes/classDashboard/styles';

const AnswersModal = (props) => {
  const { classId, unit, problemKey, problemId } = props;
  const { data: response, isLoading } = useGetAllStudentsAnswersQuery({ classId, unit, problemKey, problemId });

  return (
    <>
      {isLoading && (
        <>
          <Skeleton height={30} sx={{ mt: 4 }} />
          <Skeleton height={30} />
          <Skeleton height={30} />
          <Skeleton height={30} />
          <Skeleton height={30} />
        </>
      )}
      {!isLoading && (
        <TableContainer>
          <Table sx={{ minWidth: 650, mt: 4 }} aria-label="simple table" size="small">
            <TableHead>
              <TableRow>
                <TableCell width="25%">
                  <Typography sx={{ opacity: 0.7 }}>Name</Typography>
                </TableCell>
                <TableCell width="75%">
                  <Typography sx={{ opacity: 0.7 }}>Response</Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {response &&
                Object.values(response?.results).map((responseUnit) =>
                  responseUnit?.answers.map((result) => (
                    <StyledTableRow key={result.username}>
                      <TableCell>{result.fullName}</TableCell>
                      <TableCell>{result?.answer}</TableCell>
                    </StyledTableRow>
                  )),
                )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
};

export default AnswersModal;
