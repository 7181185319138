import { Box, Typography } from '@mui/material';
import { StyledBadgeCard } from 'badges/styles';

const Badge = ({ badge, booster = false, program = false }) => {
  let badgeClass = badge.awarded ? '' : 'in-active';
  badgeClass = program ? `${badgeClass} program-badge` : `${badgeClass}`;
  const awardDate = badge.awarded ? new Date(badge.awardedOn).toLocaleString('en-GB').split(',')[0] : '';

  return (
    <StyledBadgeCard item sm={booster ? 4 : 3} className={`badge-card ${badgeClass}`}>
      <Box component="img" src={badge.imageUrl} alt="Gen Plus" />
      {badge.awardedOn && (
        <Typography component="span" sx={booster ? { fontSize: '10px' } : {}}>
          {awardDate}
        </Typography>
      )}
    </StyledBadgeCard>
  );
};

export default Badge;
