import BackdropLinearProgress from 'components/BackdropLinearProgress';
import { useState } from 'react';
import { useGetUserInfoQuery } from 'users/userApi';

import {
  Box,
  Button,
  Container,
  Dialog,
  Paper,
  Stack,
  Table,
  TableBody,
  TableContainer,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';

import { useGetClassesQuery } from '../classApi';
import ClassRowSkeleton from './components/ClassRowSkeleton';
import ContactUs from './components/ContactUs';
import CreateClass from './components/CreateClass';
import DefaultClassRow from './components/DefaultClassRow';
import TeacherClassRow from './components/TeacherClassRow';

function TeacherClasses() {
  const theme = useTheme();
  const isScreenLtSm = useMediaQuery(theme.breakpoints.down('sm'));
  const [openCreateClass, setOpenCreateClass] = useState(false);

  const handleOpenCreateClass = () => setOpenCreateClass(true);
  const handleCloseCreateClass = () => setOpenCreateClass(false);

  const [openContactForm, setOpenContactForm] = useState(false);
  const handleOpenContactForm = () => setOpenContactForm(true);
  const handleCloseContactForm = () => setOpenContactForm(false);

  const { data: classes, isLoading, isFetching, isSuccess } = useGetClassesQuery();
  const { data: user } = useGetUserInfoQuery();
  let defaultClass = {};
  const allClasses = [];

  if (!isLoading) {
    classes?.classes.forEach((classData) => {
      if (classData?.isDefault) {
        defaultClass = { ...defaultClass, ...classData };
      } else {
        allClasses.push(classData);
      }
    });
  }

  return (
    <Container maxWidth="md">
      <Box component="section">
        <Stack
          justifyContent="space-between"
          gap={2}
          sx={{
            flexDirection: 'row',
          }}
        >
          <Typography
            variant="h5"
            component="h1"
            sx={{
              alignSelf: {
                xs: 'flex-start',
                smm: 'center',
              },
            }}
          >
            My Classes
          </Typography>
          {user?.belongsToPrimary && (
            <Stack direction={{ xs: 'column', xss: 'row' }} alignItems="center" columnGap={0.5} alignSelf="center">
              <Button size="small" variant="outlined" onClick={handleOpenCreateClass}>
                + Create Class
              </Button>
            </Stack>
          )}
        </Stack>
        {isSuccess && classes?.favouriteClasses.length === 0 && (
          <Box sx={{ mt: 2.5 }}>
            <Typography variant="subtitle2" textAlign="center">
              You haven’t favourited any classes yet!
              <br />
              To do so, browse your classes below and click on ‘+ My Classes’.
            </Typography>
          </Box>
        )}
        <Paper sx={{ mt: 2.5, position: 'relative' }} elevation={3}>
          {isFetching && !isLoading && <BackdropLinearProgress />}
          <TableContainer sx={{ background: 'inherit' }}>
            <Table aria-label="favorite class list table" sx={{ background: 'inherit' }}>
              <TableBody sx={{ background: 'inherit' }}>
                {isLoading && (
                  <>
                    <ClassRowSkeleton />
                    <ClassRowSkeleton />
                  </>
                )}

                {isSuccess &&
                  classes.favouriteClasses.map((classData) => (
                    <TeacherClassRow
                      isFavorite
                      key={classData.id}
                      classData={classData}
                      belongsToPrimary={user?.belongsToPrimary}
                    />
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Box>

      <Box component="section" sx={{ mt: 5 }}>
        <Stack
          direction={{ xs: 'column', xss: 'row' }}
          alignItems={{ xs: 'flex-start', xss: 'center' }}
          justifyContent="space-between"
          columnGap={10}
          rowGap={1}
        >
          <Typography component="h1" variant="h5">
            All Classes
          </Typography>

          <Stack direction={{ xs: 'column', xss: 'row' }} alignItems="center" columnGap={0.5} alignSelf="center">
            <Button size="small" onClick={handleOpenContactForm}>
              CONTACT US
            </Button>
          </Stack>
        </Stack>

        {user?.belongsToPrimary && Object.keys(defaultClass).length > 0 && (
          <Paper sx={{ mt: 3, position: 'relative' }} elevation={3}>
            <TableContainer sx={{ background: 'inherit' }}>
              <Table aria-label="Default class" sx={{ background: 'inherit' }}>
                <TableBody sx={{ background: 'inherit' }}>
                  {isLoading && <ClassRowSkeleton />}
                  {isSuccess && <DefaultClassRow defaultClass={defaultClass} />}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        )}

        <Paper sx={{ mt: 3, position: 'relative' }} elevation={3}>
          {isFetching && !isLoading && <BackdropLinearProgress />}
          <TableContainer sx={{ background: 'inherit' }}>
            <Table aria-label="all class list table" sx={{ background: 'inherit' }}>
              <TableBody sx={{ background: 'inherit' }}>
                {isLoading && (
                  <>
                    <ClassRowSkeleton />
                    <ClassRowSkeleton />
                    <ClassRowSkeleton />
                  </>
                )}
                {isSuccess &&
                  allClasses.map((classData) => (
                    <TeacherClassRow
                      key={classData.id}
                      classData={classData}
                      belongsToPrimary={user?.belongsToPrimary}
                    />
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Box>

      <ContactUs open={openContactForm} handleClose={handleCloseContactForm} />
      {user?.belongsToPrimary && (
        <Dialog
          aria-labelledby="Create Class modal"
          aria-describedby="A modal window for creating a class"
          open={openCreateClass}
          onClose={handleCloseCreateClass}
          fullWidth
          maxWidth="md"
          fullScreen={isScreenLtSm}
          PaperProps={{ elevation: 4 }}
        >
          <CreateClass closeModal={handleCloseCreateClass} />
        </Dialog>
      )}
    </Container>
  );
}

export default TeacherClasses;
