import BrowserInteractionTime from 'browser-interaction-time';
import BackdropLinearProgress from 'components/BackdropLinearProgress';
import { GENZ_BASE_URL } from 'config';
import parse from 'html-react-parser';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { PAGE_TITLE, PALTFORM_NAME, ROUTES } from 'utils/values';

import AccessTimeIcon from '@mui/icons-material/AccessTime';
import NavigateNext from '@mui/icons-material/NavigateNext';
import ShareIcon from '@mui/icons-material/Share';
import {
  Box,
  Breadcrumbs,
  Button,
  Chip,
  Container,
  Divider,
  Link,
  Paper,
  Skeleton,
  Stack,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';

import ArticleRating from './components/ArticleRating';
import ReflectionCard from './components/ReflectionCard';
import { Image, StyledContentBox } from './styles';
import { useGetArticleQuery, useLogArticleEngagementTimeMutation, useRateArticleMutation } from './teachApi';

const TeachArticle = () => {
  const theme = useTheme();
  const isScreenLtSm = useMediaQuery(theme.breakpoints.down('sm'));
  const { id } = useParams();

  const [linkCopied, setLinkCopied] = useState(false);

  const { data: article, isSuccess, isFetching, isLoading } = useGetArticleQuery({ id });
  const [logArticleTime] = useLogArticleEngagementTimeMutation();

  const copyLink = (link) => {
    navigator.clipboard.writeText(link);
    setLinkCopied(true);
  };

  useEffect(() => {
    if (!isSuccess) return () => {};

    const interactionTime = new BrowserInteractionTime({
      idleTimeoutMs: 30 * 1000,
    });
    interactionTime.startTimer();

    const cleanUp = () => {
      interactionTime.stopTimer();
      logArticleTime({
        id,
        data: { count: 1, engagement: Math.round(interactionTime.getTimeInMilliseconds() / 1000) },
      });
      interactionTime.reset();
      interactionTime.destroy();
    };

    window.addEventListener('beforeunload', cleanUp);

    return () => {
      cleanUp();
      window.removeEventListener('beforeunload', cleanUp);
    };
  }, [id, logArticleTime, isSuccess]);

  return (
    <Container maxWidth="md" sx={{ position: 'relative' }}>
      {isFetching && !isLoading && <BackdropLinearProgress />}
      {isSuccess && article && !isLoading && (
        <>
          <Helmet>
            <title>{`${PALTFORM_NAME} | ${PAGE_TITLE?.TEACH} | ${article.title}`}</title>
          </Helmet>
          <Breadcrumbs separator={<NavigateNext />} aria-label="breadcrumbs">
            <Link underline="hover" key="1" color="inherit" component={RouterLink} to={ROUTES.TEACH}>
              Gen+ Teach
            </Link>
            <Typography key="2" color="text.primary">
              {article.title}
            </Typography>
          </Breadcrumbs>

          <Paper elevation={3} sx={{ mt: 4 }}>
            <Image src={article.cover} alt={article.title} />

            <Box p={{ xs: 2, sm: 5 }}>
              <Typography component="h1" variant={isScreenLtSm ? 'h5' : 'h4'}>
                {article.title}
              </Typography>

              <Stack
                direction={{ xs: 'column', sm: 'row' }}
                alignItems={{ xs: 'flex-start', sm: 'center' }}
                justifyContent="space-between"
                gap={2}
                mt={3}
              >
                <Stack>
                  <Typography variant="subtitle1" component="h2" sx={{ whiteSpace: 'nowrap' }}>
                    {moment(article.created).format('DD MMMM YYYY')}
                  </Typography>
                  <Typography varaint="body2" fontSize="0.875rem" color="text.secondary">
                    {article.author}
                  </Typography>
                </Stack>

                <Box direction="row" alignSelf={{ xs: 'flex-end', sm: 'center' }} gap={2}>
                  {article.skills.map((skill) => (
                    <Chip
                      label={skill.name}
                      key={skill.id}
                      size={isScreenLtSm ? 'small' : 'medium'}
                      sx={{ m: '2px 4px 2px -2px' }}
                    />
                  ))}
                  {article.gtcs.map((gtc) => (
                    <Tooltip key={gtc.id} title={gtc.name}>
                      <Chip
                        label={gtc.name.length > 17 ? `${gtc.name.slice(0, 16)}...` : gtc.name}
                        size={isScreenLtSm ? 'small' : 'medium'}
                        sx={{ m: '2px 4px 2px -2px' }}
                      />
                    </Tooltip>
                  ))}
                </Box>
              </Stack>

              <Stack direction="row" justifyContent="space-between" alignItems="center" mt={2}>
                <Stack direction="row" gap={1} alignItems="center">
                  <AccessTimeIcon color="primary" sx={{ width: 12 }} />
                  <Typography variant="caption" color="primary">
                    {article.time} Minutes
                  </Typography>
                </Stack>
                <Tooltip
                  placement="top-start"
                  onMouseLeave={() => setLinkCopied(false)}
                  title={linkCopied ? 'Copied to clipboard' : 'Click to Copy Link'}
                >
                  <Button
                    startIcon={<ShareIcon />}
                    size="small"
                    onClick={() => copyLink(`${GENZ_BASE_URL}teach/article/${id}`)}
                  >
                    Share
                  </Button>
                </Tooltip>
              </Stack>

              <StyledContentBox variant="body1" component="div" mt={3.5}>
                {parse(`${article.content}`)}
              </StyledContentBox>
            </Box>
          </Paper>

          <Paper elevation={3} sx={{ p: { xs: 2, sm: 4 }, pt: { xs: 1, sm: 3 }, mt: 4 }}>
            <Typography component="h2" variant="h5">
              Reflections
            </Typography>

            <Divider sx={{ mt: { xs: 1, sm: 2 } }} />

            {article.reflections.map((reflection, index) => (
              <ReflectionCard key={reflection.id} reflection={reflection} index={index} articleId={id} />
            ))}
          </Paper>

          <Box mt={4}>
            <ArticleRating
              articleRating={article.rating}
              articleId={article.id}
              useRateMutation={useRateArticleMutation}
            />
          </Box>
        </>
      )}
      {isLoading && (
        <>
          <Box sx={{ mb: 4 }}>
            <Skeleton variant="rectangular" width={400} />
          </Box>
          <Paper elevation={3}>
            <Box sx={{ mb: 4 }}>
              <Skeleton variant="rectangular" width="100%" height={300} sx={{ mb: 4 }} />
            </Box>
            <Box sx={{ mb: 4, px: 4, pb: 4 }}>
              <Skeleton variant="rectangular" width="30%" height={50} sx={{ mb: 4 }} />
              <Skeleton variant="rectangular" sx={{ mb: 2 }} />
              <Skeleton variant="rectangular" sx={{ mb: 2 }} />
              <Skeleton variant="rectangular" sx={{ mb: 2 }} />
              <Skeleton variant="rectangular" sx={{ mb: 2 }} />
              <Skeleton variant="rectangular" sx={{ mb: 2 }} />
              <Skeleton variant="rectangular" width="30%" />
            </Box>
          </Paper>
        </>
      )}
    </Container>
  );
};

export default TeachArticle;
