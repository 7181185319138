import { StyledTableRow } from 'classes/classDashboard/styles';
import { useState } from 'react';
import { useTeacherPasswordResetMutation } from 'users/userApi';
import { notifyError, notifySuccess } from 'utils/notification';

import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import {
  Box,
  Button,
  Dialog,
  IconButton,
  InputAdornment,
  Radio,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';

import CreateAvatar from './CreateAvatar';

const visibilityAdornment = (visible, dispatch) => (
  <InputAdornment position="end">
    <IconButton aria-label="toggle password visibility" onClick={() => dispatch(!visible)}>
      {visible ? <VisibilityOffIcon color="error" /> : <VisibilityIcon />}
    </IconButton>
  </InputAdornment>
);

const PasswordResetModal = ({ open, students, closeModal }) => {
  const theme = useTheme();
  const isScreenLtSm = useMediaQuery(theme.breakpoints.down('sm'));
  const size = () => (isScreenLtSm ? 'small' : 'medium');
  const [student, setSudent] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [error, setError] = useState({ flag: false, message: '' });
  const [PasswordReset, { isLoading }] = useTeacherPasswordResetMutation();

  const handleRadioChange = (event) => {
    setSudent(event.target.value);
  };
  const handleNewPassword = (event) => {
    setNewPassword(event.target.value);
  };
  const handleCloseModal = () => {
    setSudent('');
    setNewPassword('');
    setPasswordVisible(false);
    setError({ flag: false, message: '' });
    closeModal();
  };

  const passwordValidation = () => {
    const passwordRegex = new RegExp('^(.{0,7}|[^0-9]*|[^A-Z]*|[^a-z]*|[a-zA-Z0-9]*)$');

    if (newPassword.length < 8) {
      setError({ flag: true, message: 'Password length must be greater than 7 characters' });
      return false;
    }

    if (passwordRegex.test(newPassword)) {
      setError({
        flag: true,
        message: 'Password must contain atleast an upper letter, a lower letter, a digit and a special character',
      });
      return false;
    }
    return true;
  };

  const handlePasswordReset = async () => {
    if (passwordValidation()) {
      const data = {
        students: [student],
        password: newPassword,
      };

      try {
        await PasswordReset(data).unwrap();
        notifySuccess('Student password has been reset.');
        handleCloseModal();
      } catch {
        notifyError('Failed to reset the password.');
      }
    }
  };

  return (
    <Dialog
      aria-labelledby="Reset Student Password"
      aria-describedby="A modal window for resetting password for student(s)"
      open={open}
      onClose={closeModal}
      fullWidth
      maxWidth="md"
      fullScreen={isScreenLtSm}
      PaperProps={{ elevation: 4 }}
    >
      <Box mt={{ xs: 1, sm: 3 }} mb={{ xs: 2, sm: 5 }} px={{ xs: 0, sm: 6 }} textAlign="center">
        <Typography variant="h6" sx={{ mb: 2, mt: 3 }}>
          Reset Student Password
        </Typography>
        <Typography variant="body1">
          Select a student, fill password and click ‘Reset’ to reset their password.
        </Typography>
        <Box sx={{ maxHeight: '55vh', overflowY: 'auto' }}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell sx={{ width: '20px' }}> </TableCell>
                <TableCell colSpan={2}>Student</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {students.map((currentStudent) => (
                <StyledTableRow key={currentStudent?.username}>
                  <TableCell sx={{ paddingRight: 0 }}>
                    <Radio
                      checked={student === `${currentStudent?.id}`}
                      onChange={handleRadioChange}
                      value={`${currentStudent?.id}`}
                      name="Students"
                      inputProps={{ 'aria-label': currentStudent?.username }}
                    />
                  </TableCell>
                  <TableCell sx={{ width: '20px', paddingRight: 0 }}>
                    <CreateAvatar profileImage={currentStudent?.profilePic} name={currentStudent?.username} />
                  </TableCell>
                  <TableCell>{currentStudent?.username}</TableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </Box>
      <Stack direction="row" px={{ xs: 0, sm: 6 }} pb={3}>
        <TextField
          type={passwordVisible ? 'text' : 'password'}
          id="new-password"
          label="New Password"
          value={newPassword}
          onChange={handleNewPassword}
          variant="outlined"
          size={size()}
          InputProps={{
            endAdornment: visibilityAdornment(passwordVisible, setPasswordVisible),
          }}
          error={error.flag}
          helperText={error.message}
        />
      </Stack>

      <Stack direction="row" justifyContent="space-between" px={{ xs: 0, sm: 6 }} pb={6}>
        <Stack direction="row" gap={2}>
          <Button
            variant="contained"
            size={size()}
            disabled={!(student && newPassword) || isLoading}
            onClick={handlePasswordReset}
          >
            Reset
          </Button>
        </Stack>
        <Button onClick={handleCloseModal} variant="outlined" size={size()}>
          Cancel
        </Button>
      </Stack>
    </Dialog>
  );
};

export default PasswordResetModal;
