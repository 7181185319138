import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import LockIcon from '@mui/icons-material/Lock';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { Button, CardContent, Typography } from '@mui/material';

import { StyledAssessmentContent, StyledAssessmentImageBox, StyledSelfAssessment } from './styles';

const SelfAssessment = ({ lmsUrl, isLocked, isComplete, displayName, shortDescription, courseImageUrl }) => (
  <StyledSelfAssessment>
    <CardContent>
      <StyledAssessmentImageBox>
        <img src={courseImageUrl} width="50" alt={displayName} />
      </StyledAssessmentImageBox>
      <StyledAssessmentContent>
        <Typography component="h1" variant="h6">
          {displayName}
          {isComplete != null &&
            (isComplete ? (
              <Typography variant="subtitle2" color="text.secondary" component="span">
                <CheckCircleIcon color="success" fontSize="10" />
                Completed
              </Typography>
            ) : (
              <Typography variant="subtitle2" color="text.secondary" component="span">
                <RemoveCircleIcon color="warning" fontSize="10" />
                To Do
              </Typography>
            ))}
        </Typography>
        <Typography fontSize="14px">{shortDescription}</Typography>
        {isLocked ? (
          <Button variant="contained" disabled size="large" sx={{ mr: 2 }} startIcon={<LockIcon />}>
            Locked
          </Button>
        ) : (
          <Button variant="outlined" size="large" sx={{ mr: 2 }} href={lmsUrl}>
            Enter
          </Button>
        )}
      </StyledAssessmentContent>
    </CardContent>
  </StyledSelfAssessment>
);

export default SelfAssessment;
