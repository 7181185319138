import { Box, Button, Skeleton, Typography } from '@mui/material';

import { NextLessonContainer } from '../styles';

const NextLessonSkeleton = () => (
  <NextLessonContainer elevation={3}>
    <Box zIndex={10} position="relative">
      <Typography variant="subtitle1">
        <Skeleton />
      </Typography>
      <Typography variant="h6">
        <Skeleton />
      </Typography>
      <Typography variant="body2" mt={2}>
        <Skeleton />
        <Skeleton />
        <Skeleton />
      </Typography>
      <Button variant="outlined" sx={{ marginTop: 7, marginBottom: 5 }}>
        <Skeleton />
      </Button>
    </Box>
  </NextLessonContainer>
);

export default NextLessonSkeleton;
