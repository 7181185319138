import CreateAvatar from 'components/CreateAvatar';
import { useGetUserInfoQuery } from 'users/userApi';

import { Grid, Paper, TextField } from '@mui/material';

import FileUploadButton from '../components/FileUploadButton';

const TeacherProfile = ({
  firstName,
  lastName,
  email,
  username,
  school,
  profileImage,
  setProfile,
  setImageSrc,
  selectedImageSrc,
}) => {
  const { data: user = {} } = useGetUserInfoQuery();
  return (
    <Paper sx={{ mt: 1.5, px: 2.8, py: 3 }} elevation={4}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField label="First Name" value={firstName} disabled fullWidth />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField label="Last Name" value={lastName} disabled fullWidth />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField label="Email" value={email} disabled fullWidth />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField label="Display Name" value={username} disabled fullWidth />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField label="School" value={school} disabled fullWidth />
        </Grid>

        <Grid item xs={12} sm={6}>
          <Grid container>
            <Grid item>
              <CreateAvatar {...user} size="large" profileImage={profileImage || selectedImageSrc} />
            </Grid>

            <Grid item>
              <FileUploadButton setProfile={setProfile} setImageSrc={setImageSrc} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default TeacherProfile;
