import { Box, Button, Paper, Stack, Table, TableBody, TableContainer, TableRow, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { ROUTES } from 'utils/values';

import ActivityFeedNotification from './ActivityFeedNotification';

const ActivityFeed = ({ activityFeed }) => (
  <Paper elevation={3}>
    <Stack px={4} pt={3} pb={2} direction="row" alignItems="center" justifyContent="space-between" boxShadow={4}>
      <Typography vairant="h6" sx={{ fontSize: '1.25rem', fontWeight: 500 }}>
        Activity Feed
      </Typography>
      <Button size="small" LinkComponent={Link} to={ROUTES.ACTIVITY_FEED}>
        See all activity
      </Button>
    </Stack>
    <Box sx={{ height: 245, overflowY: 'auto' }}>
      <TableContainer component={Paper} elevation={2}>
        <Table aria-label="recent-activity-table">
          <TableBody>
            {activityFeed?.map((activity) => (
              <TableRow
                sx={{
                  '& td': { border: 'none' },
                  whiteSpace: 'nowrap',
                  borderTop: '1px solid #63d9ff4d',
                }}
                key={activity.id}
              >
                <ActivityFeedNotification isNew={!activity.isRead} data={activity} />
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  </Paper>
);

export default ActivityFeed;
