import { nanoid } from '@reduxjs/toolkit';
import { useState } from 'react';
import { debounce } from 'utils/utilityFunctions';

import SearchIcon from '@mui/icons-material/Search';
import { Box, Grid, InputAdornment, InputLabel, MenuItem, TextField } from '@mui/material';

import { useGetArticlesQuery, useGetContentFiltersQuery } from '../teachApi';
import ContentList from './ContentList';
import CpdCardSkeleton from './CpdCardSkeleton';

const ContentLibrary = () => {
  const [articleSearch, setSearch] = useState('');
  const [gtcs, setGtcs] = useState('');
  const [isCompleted, setIsCompleted] = useState('');
  const [skill, setSkill] = useState('');
  const [pageNum, setPageNum] = useState(1);
  const [mediaType, setMediaType] = useState('');
  const [academicYear, setAcademicYear] = useState('');

  const {
    data: articlesData,
    isLoading,
    isFetching,
  } = useGetArticlesQuery({
    gtcs: gtcs === 'All' ? '' : gtcs,
    skill: skill === 'All' ? '' : skill,
    mediaType: mediaType === 'All' ? '' : mediaType,
    isCompleted: isCompleted === 'All' ? '' : isCompleted,
    academicYear: academicYear === 'All' ? '' : academicYear,
    pageSize: 12,
    page: pageNum,
    search: articleSearch,
  });
  const { data: filtersData, isSuccess: filtersIsSuccess } = useGetContentFiltersQuery();
  const handleSearch = debounce((e) => {
    setSearch(e.target.value);
  }, 500);

  return (
    <>
      <Box key="filters-box">
        <InputLabel htmlFor="search-teach" className="sr-only">
          Search Teach Article
        </InputLabel>
        <TextField
          placeholder="Search..."
          id="search-teach"
          size="small"
          variant="outlined"
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          onChange={(e) => {
            e.persist();
            handleSearch(e);
          }}
        />
        {filtersData && filtersIsSuccess && (
          <Grid container mt={1.25} spacing={1.75}>
            <Grid item xs={12} smm={4} md={3}>
              <InputLabel sx={{ overflow: 'visible' }}>
                <TextField
                  select
                  id="selectGTCSStandard"
                  label="GTCS Standard"
                  size="small"
                  fullWidth
                  value={gtcs}
                  onChange={(e) => setGtcs(e.target.value)}
                >
                  <MenuItem value="All">
                    <em>All</em>
                  </MenuItem>
                  {filtersData.gtcs.map((value) => (
                    <MenuItem key={value.id} value={value.id}>
                      {value.name}
                    </MenuItem>
                  ))}
                </TextField>
              </InputLabel>
            </Grid>

            <Grid item xs={6} sm={6} smm={4} md={3}>
              <InputLabel sx={{ overflow: 'visible' }}>
                <TextField
                  select
                  label="Academic Year"
                  size="small"
                  fullWidth
                  value={academicYear}
                  onChange={(e) => setAcademicYear(e.target.value)}
                >
                  <MenuItem value="All">
                    <em>All</em>
                  </MenuItem>
                  {filtersData?.academicYear.map((value) => (
                    <MenuItem key={nanoid()} value={value}>
                      {value}
                    </MenuItem>
                  ))}
                </TextField>
              </InputLabel>
            </Grid>

            <Grid item xs={6} smm={4} md={2}>
              <InputLabel sx={{ overflow: 'visible' }}>
                <TextField
                  select
                  label="Skill"
                  size="small"
                  fullWidth
                  value={skill}
                  onChange={(e) => setSkill(e.target.value)}
                >
                  <MenuItem value="All">
                    <em>All</em>
                  </MenuItem>
                  {filtersData.skills.map((value) => (
                    <MenuItem key={value.id} value={value.id}>
                      {value.name}
                    </MenuItem>
                  ))}
                </TextField>
              </InputLabel>
            </Grid>

            <Grid item xs={6} md={2}>
              <InputLabel sx={{ overflow: 'visible' }}>
                <TextField
                  select
                  label="Media Type"
                  size="small"
                  fullWidth
                  value={mediaType}
                  onChange={(e) => setMediaType(e.target.value)}
                >
                  <MenuItem value="All">
                    <em>All</em>
                  </MenuItem>
                  {filtersData.mediaTypes.map((value) => (
                    <MenuItem key={value.id} value={value.id}>
                      {value.name}
                    </MenuItem>
                  ))}
                </TextField>
              </InputLabel>
            </Grid>

            <Grid item xs={6} md={2}>
              <InputLabel sx={{ overflow: 'visible' }}>
                <TextField
                  select
                  label="Completion"
                  size="small"
                  fullWidth
                  value={isCompleted}
                  onChange={(e) => setIsCompleted(e.target.value)}
                >
                  <MenuItem value="All">
                    <em>All</em>
                  </MenuItem>
                  <MenuItem value="true">Completed</MenuItem>
                  <MenuItem value="false">Uncompleted</MenuItem>
                </TextField>
              </InputLabel>
            </Grid>
          </Grid>
        )}
      </Box>

      {isLoading && (
        <Grid container spacing={2} mt={4}>
          {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((id) => (
            <Grid item xs={12} xss={6} smm={4} md={3} key={id}>
              <CpdCardSkeleton />
            </Grid>
          ))}
        </Grid>
      )}

      {articlesData && (
        <ContentList
          libraryContent={articlesData.articles}
          favoriteArticles={articlesData.favorites}
          pageNum={pageNum}
          isFetchingArticles={isFetching}
          setPageNum={setPageNum}
        />
      )}
    </>
  );
};

export default ContentLibrary;
