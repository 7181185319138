import { Skeleton, Stack, Typography } from '@mui/material';

import { QuoteBox } from '../styles';

export const QuoteBanner = ({ banner, text, author }) => (
  <QuoteBox py={2} px={4} banner={banner}>
    <Stack gap={1} alignItems="center">
      <Typography fontWeight={300} fontSize="1.25rem">
        {text}
      </Typography>
      <Typography variant="overline" letterSpacing={1} color="text.secondary">
        {author}
      </Typography>
    </Stack>
  </QuoteBox>
);

export const QuoteBannerSkeleton = () => (
  <QuoteBox py={2} px={4}>
    <Stack gap={1} alignItems="center">
      <Typography fontWeight={300} fontSize="1.25rem">
        <Skeleton width={400} />
      </Typography>
      <Typography variant="overline" letterSpacing={1} color="text.secondary">
        <Skeleton width={100} height={25} />
      </Typography>
    </Stack>
  </QuoteBox>
);
