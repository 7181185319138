import {
  Box,
  Grid,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  darken,
  useTheme,
} from '@mui/material';

const AnswersSkeleton = () => {
  const theme = useTheme();
  return (
    <>
      <Box sx={{ p: 4 }}>
        <Box sx={{ pl: 4, position: 'relative' }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="body1">
              <Skeleton />
            </Typography>
            <Box>
              <Skeleton height={200} />
            </Box>
            <Skeleton height={70} width={150} />
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          p: 4,
          border: `1px solid ${darken(theme.palette.primary.dark, 0.3)}`,
          borderWidth: `1px 0`,
          position: 'relative',
        }}
      >
        <Box sx={{ pl: 4, position: 'relative' }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="body1">
              <Skeleton />
            </Typography>
            <Typography variant="body2" sx={{ opacity: 0.7 }}>
              <Skeleton />
            </Typography>
            <Grid container spacing={2} pt={4}>
              <Grid item xs={12} sm={4} order={{ sm: 2 }}>
                <Box sx={{ maxWidth: 160, margin: '0 auto' }}>
                  <Skeleton variant="circular" width={160} height={160} />
                </Box>
              </Grid>
              <Grid item xs={12} sm={8} order={{ sm: 1 }}>
                <Skeleton height={30} />
                <Skeleton height={30} />
                <Skeleton height={30} />
                <Skeleton height={30} />
                <Skeleton height={30} sx={{ mb: 3 }} />
                <Skeleton height={70} width={150} />
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          p: 4,
          border: `1px solid ${darken(theme.palette.primary.dark, 0.3)}`,
          borderWidth: `1px 0`,
        }}
      >
        <Box sx={{ pl: 4, position: 'relative' }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="body1">
              <Skeleton />
            </Typography>
            <TableContainer>
              <Table sx={{ minWidth: 600 }} aria-label="Skills" size="small">
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ border: 0 }} width="25%">
                      <Typography component="span" variant="body1" sx={{ opacity: 0.7 }}>
                        <Skeleton />
                      </Typography>
                    </TableCell>
                    <TableCell sx={{ border: 0 }} width="75%">
                      <Typography component="span" variant="body1" sx={{ opacity: 0.7 }}>
                        <Skeleton />
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell sx={{ border: 0, verticalAlign: 'top' }}>
                      <Skeleton />
                    </TableCell>
                    <TableCell sx={{ border: 0, verticalAlign: 'top' }}>
                      <Skeleton />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{ border: 0, verticalAlign: 'top' }}>
                      <Skeleton />
                    </TableCell>
                    <TableCell sx={{ border: 0, verticalAlign: 'top' }}>
                      <Skeleton />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{ border: 0, verticalAlign: 'top' }}>
                      <Skeleton />
                    </TableCell>
                    <TableCell sx={{ border: 0, verticalAlign: 'top' }}>
                      <Skeleton />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{ border: 0, verticalAlign: 'top' }}>
                      <Skeleton />
                    </TableCell>
                    <TableCell sx={{ border: 0, verticalAlign: 'top' }}>
                      <Skeleton />
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default AnswersSkeleton;
