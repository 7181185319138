import { ArcElement, Chart as ChartJS, Legend, Tooltip } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import { pieChart } from 'utils/chartValues';

const { defaultOptions, defaultValues } = pieChart;

ChartJS.register(ArcElement, Tooltip, Legend);

const PieChart = ({ data, options }) => (
  <Pie data={{ ...defaultValues, ...data }} options={{ ...defaultOptions, ...options }} />
);

export default PieChart;
