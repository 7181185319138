import { ReactComponent as Question } from 'assets/images/Question.svg';
import { Link } from 'react-router-dom';
import { ROUTES, SKILLASSESSMENTDESCRIPTION } from 'utils/values';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { Box, Button, Chip, Grid, Paper, Stack, Typography } from '@mui/material';

const AssessmentIcon = (status) =>
  status ? (
    <CheckCircleIcon sx={{ '&, &.MuiChip-icon': { color: 'success.dark' } }} />
  ) : (
    <RemoveCircleIcon sx={{ '&, &.MuiChip-icon': { color: 'warning.dark' } }} />
  );

const SelfAssessment = ({ introUnit, outroUnit }) => (
  <Paper elevation={3}>
    <Grid
      container
      direction={{ xs: 'column', md: 'row' }}
      p={4}
      alignItems="center"
      justifyContent="space-between"
      spacing={2}
      mt={1}
    >
      <Grid item xs={9}>
        <Stack direction="row" mb={1}>
          <Box mr={4}>
            <Question />
          </Box>
          <Stack direction="column">
            <Typography vairant="h6" mr={1} sx={{ fontSize: '1.25rem', fontWeight: 500 }}>
              Meta-Skills Tracker
            </Typography>
            <Typography variant="body2">{SKILLASSESSMENTDESCRIPTION}</Typography>
          </Stack>
        </Stack>
      </Grid>
      <Grid item xs={2}>
        <Stack direction="column" justifyContent="center" spacing={1}>
          {introUnit && introUnit?.isComplete != null && (
            <Chip
              icon={AssessmentIcon(introUnit?.isComplete)}
              sx={{ display: 'flex', justifyContent: 'flex-start' }}
              mb={1}
              label="Start of Course"
            />
          )}
          {outroUnit && outroUnit?.isComplete != null && (
            <Chip
              icon={AssessmentIcon(outroUnit?.isComplete)}
              sx={{ display: 'flex', justifyContent: 'flex-start' }}
              mb={1}
              label="End of Course"
            />
          )}
          {((outroUnit && outroUnit?.isComplete) || (introUnit && introUnit?.isComplete)) && (
            <Button variant="outlined" to={ROUTES.SKILL_ASSESSMENT} component={Link}>
              View Results
            </Button>
          )}
        </Stack>
      </Grid>
    </Grid>
  </Paper>
);

export default SelfAssessment;
