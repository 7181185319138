import { Link as RouterLink } from 'react-router-dom';
import { ROUTES } from 'utils/values';

import { Box, Button, Grid, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';

import Container from '../Container';
import { StyledFooter, StyledFooterNav } from './styles';

const Footer = () => {
  const theme = useTheme();
  const isScreenLtSm = useMediaQuery(theme.breakpoints.down('xss'));
  return (
    <StyledFooter>
      <Container maxWidth="lg">
        <Grid item xs={12} md={6}>
          <StyledFooterNav>
            <Stack direction={isScreenLtSm ? 'column' : 'row'}>
              <Box>
                <ul>
                  <li>
                    <Button href="https://www.genplus.co.uk/privacy-policy/" target="_blank">
                      Privacy Policy
                    </Button>
                  </li>

                  <li>
                    <Button href="https://www.genplus.co.uk/terms-conditions/" target="_blank">
                      Terms &amp; Conditions
                    </Button>
                  </li>
                  <li>
                    <Button component={RouterLink} to={ROUTES.CONTACTUS}>
                      Contact Us
                    </Button>
                  </li>
                </ul>
              </Box>
            </Stack>
          </StyledFooterNav>
        </Grid>
        <Grid item xs={12} md={6} className="footer-description">
          <Typography>&copy; GenPlus Leaders | Scottish Charity number: SC050610</Typography>
        </Grid>
      </Container>
    </StyledFooter>
  );
};

export default Footer;
