import { Card, CardContent, Skeleton } from '@mui/material';

const CardSkeleton = () => (
  <Card sx={{ mb: 2 }}>
    <CardContent>
      <Skeleton width={80} />
      <Skeleton height={40} sx={{ mb: 2 }} />
      <Skeleton />
      <Skeleton width="90%" />
      <Skeleton width="80%" sx={{ mb: 2 }} />
      <Skeleton height={65} width={100} />
    </CardContent>
  </Card>
);

export default CardSkeleton;
