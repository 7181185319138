import BackdropLinearProgress from 'components/BackdropLinearProgress';
import { useGetJournalsQuery } from 'journals/journalsApi';

import { Box, CircularProgress, Grid, Pagination, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';

import BadgeEntry from './BadgeEntry';
import ProblemEntry from './ProblemEntry';
import StudentEntry from './StudentEntry';
import TeacherEntry from './TeacherEntry';

const entryComponents = {
  BoosterBadgeAward: (props) => <BadgeEntry {...props} />,
  StudentReflection: (props) => <StudentEntry {...props} />,
  TeacherFeedback: (props) => <TeacherEntry {...props} />,
  ProblemEntry: (props) => <ProblemEntry {...props} />,
};

const MAX_ITEMS = 9;

const JournalEntries = ({ view, filters, handlePageChange }) => {
  const theme = useTheme();
  const isScreenGtMd = useMediaQuery(theme.breakpoints.up('md'));
  const isScreenGtSm = useMediaQuery(theme.breakpoints.up('sm'));

  const { skillId, search, page } = filters;

  const {
    data: journals,
    isLoading,
    isFetching,
    isSuccess,
    isError,
  } = useGetJournalsQuery({
    offset: (page - 1) * MAX_ITEMS,
    limit: MAX_ITEMS,
    skillId: skillId === 'All' ? '' : skillId,
    search,
  });
  const pageCount = Math.ceil(journals?.overallTotal / MAX_ITEMS);

  const getGridCols = () => {
    if (isScreenGtMd) return 3;
    if (isScreenGtSm) return 2;
    return 1;
  };

  let content;

  if ((isSuccess && !journals?.results?.length) || isError) {
    content = (
      <Grid item xs={12}>
        <Typography variant="h4" textAlign="center">
          No Results Found!
        </Typography>
      </Grid>
    );
  } else if (view === 'list') {
    content = journals?.results.map((entry) => (
      <Grid item xs={12} key={`${entry.id}${entry.type}`}>
        {entryComponents[entry.type]({ ...entry, extended: true })}
      </Grid>
    ));
  } else if (view === 'grid') {
    const maxCols = getGridCols();
    const columnContent = [];

    for (let i = 0; i < maxCols; i++) {
      columnContent.push({ id: i, content: [] });
    }

    for (let i = 0; i < journals?.results.length; i++) {
      const item = entryComponents[journals?.results[i].type]({
        ...journals?.results[i],
        key: `${journals?.results[i].id}${journals?.results[i].type}`,
      });

      columnContent[i % maxCols].content.push(item);
    }

    content = columnContent.map((columnEntries) => (
      <Grid item xs={12 / maxCols} key={columnEntries.id}>
        <Stack gap={2}>{columnEntries.content}</Stack>
      </Grid>
    ));
  }

  return (
    <Box position="relative">
      {isFetching && !isLoading && <BackdropLinearProgress />}
      <Grid container spacing={2} mt={2}>
        {content}
        {isLoading && (
          <Grid item xs={12} display="flex" justifyContent="center">
            <CircularProgress />
          </Grid>
        )}
        {isSuccess && pageCount > 1 && (
          <Grid item xs={12}>
            <Stack alignItems="center" sx={{ mt: 4, mb: 1 }}>
              <Pagination count={pageCount} color="primary" page={page} onChange={handlePageChange} />
            </Stack>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default JournalEntries;
