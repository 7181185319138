import { EditorState, convertFromRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';

import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ArticleIcon from '@mui/icons-material/Article';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import MessageIcon from '@mui/icons-material/Message';
import StarsIcon from '@mui/icons-material/Stars';
import { Avatar, Card, CardActions, CardHeader, Stack, Typography } from '@mui/material';
import { ROUTES } from 'utils/values';
import { stringToColor } from 'utils/utilityFunctions';
import { Badge, BadgeContainer } from 'journals/styles';
import { NavLink } from 'components/header/NavLink';

export const activityTypes = {
  BadgeAward: {
    icon: <StarsIcon color="primary" />,
    title: 'Booster Badge',
    component: (data) => <FeedBadgeAward {...data} />,
    action: { link: ROUTES.BADGES, linkTitle: 'Badges' },
  },
  JournalEntryByStudent: {
    icon: <MessageIcon color="primary" />,
    title: 'My Reflection',
    component: (data) => <StudentReflection {...data} />,
    action: { link: ROUTES.JOURNALS, linkTitle: 'My Journals' },
  },
  JournalEntryByTeacher: {
    icon: <ArticleIcon color="primary" />,
    title: 'Teacher Feedback',
    component: (data) => <TeacherFeedback {...data} />,
    action: { link: ROUTES.JOURNALS, linkTitle: 'My Journals' },
  },
  LessonCompletion: {
    icon: <CheckCircleIcon color="primary" />,
    title: 'Lesson Completion',
    component: (data) => <FeedLessonCompletion {...data} />,
    action: { link: ROUTES.LESSONS, linkTitle: 'Visit Lesson' },
  },
  OnBoarded: {
    icon: <AccountCircleIcon color="primary" />,
    title: 'Onboarded',
    component: (data) => <FeedOnBoarded {...data} />,
    action: { link: ROUTES.USERPROFILE, linkTitle: 'My Profile' },
  },
};

export const ActivityTitle = ({ type, date }) => (
  <Stack direction="row" justifyContent="space-between" sx={{ mb: 2 }}>
    <Typography variant="body2" color="text.disabled">
      {new Date(date).toLocaleDateString('en-GB')}
    </Typography>
    <Typography variant="body2" color="text.disabled">
      {activityTypes[type].title}
    </Typography>
  </Stack>
);
export const ActivityAction = ({ user, type }) => {
  const { icon, action } = activityTypes[type];
  return (
    <CardActions
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingX: 2,
        paddingTop: 1.25,
        paddingBottom: 2,
      }}
    >
      <Stack direction="row" gap={1} alignItems="center">
        {user.profileImage ? (
          <Avatar src={user.profileImage} alt={`${user.name} profile image`} sx={{ width: 26, height: 26 }} />
        ) : (
          <Avatar sx={{ width: 32, height: 32, backgroundColor: stringToColor(user.name) }}>
            {user.name[0].toUpperCase()}
          </Avatar>
        )}
        <Typography textTransform="uppercase">{user.name}</Typography>
      </Stack>
      <NavLink to={action.link} sx={{ m: 0, p: 0 }}>
        {icon}
        <Typography component="span" sx={{ fontSize: 13 }}>
          {action.linkTitle}
        </Typography>
      </NavLink>
    </CardActions>
  );
};

export const StudentReflection = ({ type, created, actionObject, user }) => {
  const state = EditorState.createWithContent(convertFromRaw(JSON.parse(actionObject.description)));
  return (
    <Card elevation={4}>
      <CardHeader
        disableTypography
        title={<ActivityTitle type={type} date={created} />}
        subheader={
          <>
            <Typography variant="h6">{actionObject.title}</Typography>
            <Editor editorState={state} readOnly toolbarHidden />
          </>
        }
        sx={{ paddingBottom: 0, mb: 2 }}
      />
      <ActivityAction user={user} type={type} />
    </Card>
  );
};

export const TeacherFeedback = ({ type, created, actionObject, user }) => (
  <Card elevation={4}>
    <CardHeader
      disableTypography
      title={<ActivityTitle type={type} date={created} />}
      subheader={
        <>
          <Typography variant="h6">{actionObject.title}</Typography>
          <Typography varaint="body2">{actionObject.description}</Typography>
        </>
      }
      sx={{ paddingBottom: 0, mb: 2 }}
    />

    <ActivityAction user={user} type={type} />
  </Card>
);

export const FeedOnBoarded = ({ type, created, actionObject, user }) => (
  <Card elevation={4}>
    <CardHeader
      disableTypography
      title={<ActivityTitle type={type} date={created} />}
      subheader={
        <Stack alignItems="center" direction="column">
          <Avatar alt={user.username} src={user.profileImage} sx={{ width: 150, height: 150 }} />
          <Typography sx={{ mt: 1 }}>You chose {actionObject.character.name}</Typography>
        </Stack>
      }
      sx={{ paddingBottom: 0, mb: 2 }}
    />
    <ActivityAction user={user} type={type} />
  </Card>
);
export const FeedBadgeAward = ({ type, created, actionObject, user }) => (
  <Card elevation={4}>
    <CardHeader
      disableTypography
      title={<ActivityTitle type={type} date={created} />}
      subheader={
        <Stack alignItems="center">
          <BadgeContainer>
            <Badge src={actionObject.imageUrl} alt="badge" />
          </BadgeContainer>
        </Stack>
      }
      sx={{ paddingBottom: 0, mb: 2 }}
    />
    <ActivityAction user={user} type={type} />
  </Card>
);

export const FeedLessonCompletion = ({ type, created, actionObject, user }) => (
  <Card elevation={4}>
    <CardHeader
      disableTypography
      title={<ActivityTitle type={type} date={created} />}
      subheader={
        <Stack alignItems="center">
          <Typography variant="h4">{actionObject.courseName}</Typography>
          <Typography variant="h6">{actionObject.lessonName}</Typography>
          <Typography varaint="body2" sx={{ mt: 2 }}>
            Congrats you have completed your lesson.
          </Typography>
        </Stack>
      }
      sx={{ paddingBottom: 0, mb: 2 }}
    />
    <ActivityAction user={user} type={type} />
  </Card>
);
