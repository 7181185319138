import { useGetCharactersQuery } from 'onboard/student/charactersApi';
import { useEffect } from 'react';

import { Grid, Paper, TextField, Tooltip } from '@mui/material';

import { StyledAvatar } from './styles';

const StudentProfile = ({ firstName, lastName, school, characterId, character, setCharacter, belongsToPrimary }) => {
  const { data, isSuccess } = useGetCharactersQuery(null, { skip: belongsToPrimary });
  const characters = isSuccess ? data.results : [];

  useEffect(() => {
    setCharacter(characterId);
  }, [characterId, setCharacter]);

  const handleCharacterChange = (e) => {
    const ch = characters.filter((char) => char.profilePic === e.target.src);
    setCharacter(ch[0].id);
  };

  return (
    <Paper sx={{ mt: 1.5, px: 2.8, py: 3 }} elevation={4}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField label="First Name" value={firstName} disabled fullWidth />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField label="Last Name" value={lastName} disabled fullWidth />
        </Grid>
        <Grid item xs={12}>
          <TextField label="School" value={school} disabled fullWidth />
        </Grid>
      </Grid>

      <Grid container mt={3} gap={2}>
        {characters.map((ch) => (
          <Tooltip title={ch.name} placement="top" key={ch.id}>
            <Grid item>
              <StyledAvatar
                src={ch.profilePic}
                className={ch.id === character ? 'active' : ''}
                onClick={handleCharacterChange}
                data-char={ch.id}
              />
            </Grid>
          </Tooltip>
        ))}
      </Grid>
    </Paper>
  );
};

export default StudentProfile;
