import { useState } from 'react';
import { notifyError, notifySuccess } from 'utils/notification';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  InputLabel,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';

import { useAddArticleReflectionMutation } from '../teachApi';

const ReflectionCard = ({ reflection, index, articleId }) => {
  const theme = useTheme();
  const isScreenLtSm = useMediaQuery(theme.breakpoints.down('sm'));

  const { title, id } = reflection;

  const [answer, setAnswer] = useState(reflection.answer || '');

  const [openEditDialog, setOpenEditDialog] = useState(false);
  const handleOpenEditDialog = () => setOpenEditDialog(true);
  const handleCloseEditDialog = () => setOpenEditDialog(false);

  const [addArticleReflection, { isLoading }] = useAddArticleReflectionMutation();

  const addReflection = () => {
    addArticleReflection({ id, answer, articleId })
      .unwrap()
      .then((res) => {
        notifySuccess(res);
      })
      .catch((err) => {
        notifyError(err.data.detail);
      });
  };

  const handleUpdateReflection = () => {
    addReflection();
    handleCloseEditDialog();
  };

  return (
    <>
      {reflection?.answer ? (
        <Stack sx={{ mt: 3 }} direction="row" gap={2} justifyContent="space-between">
          <Typography variant="body1">
            {index + 1}.&nbsp;
            <InputLabel htmlFor={`id-${id}`} sx={{ display: 'inline', color: 'inherit', whiteSpace: 'normal' }}>
              {title}
            </InputLabel>
          </Typography>
          <CheckCircleIcon color="success" sx={{ width: 16 }} />
        </Stack>
      ) : (
        <Typography variant="body1" mt={3}>
          {index + 1}.&nbsp;
          <InputLabel htmlFor={`id-${id}`} sx={{ display: 'inline', color: 'inherit', whiteSpace: 'normal' }}>
            {title}
          </InputLabel>
        </Typography>
      )}

      {reflection?.answer ? (
        <>
          <Box
            mt={1}
            p={2}
            border={1}
            borderColor="divider"
            borderRadius={1}
            sx={{ display: 'flex', flexDirection: 'column' }}
          >
            <Typography variant="body1" sx={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}>
              {reflection?.answer}
            </Typography>
            <Button size="small" sx={{ mt: 1, alignSelf: 'flex-end' }} onClick={handleOpenEditDialog}>
              Edit
            </Button>
          </Box>

          <Dialog
            open={openEditDialog}
            onClose={handleCloseEditDialog}
            maxWidth="sm"
            fullWidth
            PaperProps={{
              elevation: 3,
              'aria-labelledby': 'edit-answer-title',
            }}
          >
            <DialogContent>
              <Typography variant="body1" id="edit-answer-title">
                {index + 1}.&nbsp;
                <InputLabel htmlFor={`edit-${id}`} sx={{ display: 'inline', color: 'inherit', whiteSpace: 'normal' }}>
                  {title}
                </InputLabel>
              </Typography>

              <TextField
                rows={4}
                fullWidth
                multiline
                id={`edit-${id}`}
                value={answer}
                onChange={(e) => setAnswer(e.target.value)}
                placeholder="Write your answer here..."
                sx={{ mt: 2 }}
              />
            </DialogContent>

            <DialogActions sx={{ justifyContent: 'flex-start' }}>
              <Button
                type="submit"
                variant="contained"
                disabled={answer.length === 0 || reflection.answer === answer || isLoading}
                onClick={handleUpdateReflection}
              >
                Save
              </Button>
              <Button variant="outlined" onClick={handleCloseEditDialog}>
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
        </>
      ) : (
        <>
          <TextField
            rows={4}
            fullWidth
            multiline
            inputProps={{ 'aria-label': 'write reflection answer' }}
            id={`id-${id}`}
            value={answer}
            onChange={(e) => setAnswer(e.target.value)}
            placeholder="Write your answer here..."
            sx={{ mt: 2 }}
          />
          <Button
            disabled={answer.length === 0 || isLoading}
            variant="contained"
            size={isScreenLtSm ? 'medium' : 'large'}
            onClick={addReflection}
            sx={{ mt: 2 }}
          >
            Save to portfolio
          </Button>
        </>
      )}
    </>
  );
};

export default ReflectionCard;
