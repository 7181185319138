import { nanoid } from '@reduxjs/toolkit';
import { useState } from 'react';

import { Box, Button, Typography, darken, useTheme } from '@mui/material';

import { StyledSRTitles } from '../../styles';
import NoResponse from './NoResponse';
import ReflectionModal from './ReflectionModal';

const ReflectionCard = ({ student, children, questionTitle, questionTitles, classStudents, isLikert, programId }) => {
  const theme = useTheme();
  const [openViewAnswers, setOpenViewAnswers] = useState(false);
  const handleOpenViewAnswers = () => setOpenViewAnswers(true);
  const handleCloseViewAnswers = () => setOpenViewAnswers(false);

  return (
    <>
      <Box
        sx={{
          p: 4,
          borderBottom: `1px solid ${darken(theme.palette.primary.dark, 0.3)}`,
          '&:last-child': { border: 0 },
        }}
      >
        {questionTitles.length > 0 ? (
          <Box sx={{ flexGrow: 1 }}>
            {questionTitles.map(({ skill, title }) => (
              <StyledSRTitles key={nanoid()}>
                <Typography variant="body1" component="strong" fontWeight="bold">
                  {skill}:
                </Typography>
                <Typography variant="body2" sx={{ pt: '3px' }}>
                  {title}
                </Typography>
              </StyledSRTitles>
            ))}
            {student === 'all' ? (
              <>
                <Typography variant="body2" sx={{ opacity: 0.7 }} hidden>
                  {2 + 1}/{classStudents} Responses
                </Typography>
                <Box sx={{ py: 3 }}>{children}</Box>
                <Button variant="text" sx={{ textDecoration: 'underline' }} onClick={handleOpenViewAnswers}>
                  View All Answers
                </Button>
              </>
            ) : (
              <Box sx={{ py: 3 }}>{children}</Box>
            )}
          </Box>
        ) : (
          <NoResponse />
        )}
      </Box>
      {openViewAnswers && (
        <ReflectionModal
          index={1}
          questionTitle={questionTitle}
          isLikert={isLikert}
          programId={programId}
          openViewAnswers={openViewAnswers}
          handleCloseViewAnswers={handleCloseViewAnswers}
        />
      )}
    </>
  );
};

export default ReflectionCard;
