import { useGetUserInfoQuery } from 'users/userApi';
import { getGreetings } from 'utils/utilityFunctions';

import { Box, Container, Typography } from '@mui/material';

import PrimaryStudent from './PrimaryStudent';
import SecondaryStudent from './SecondaryStudent';

const StudentDashboard = () => {
  const { data: user } = useGetUserInfoQuery();

  return (
    <Container maxWidth="md">
      <Box
        sx={{
          display: { xs: 'block', smm: 'flex' },
          alignItems: 'center',
        }}
      >
        <Typography variant="h5" component="h2" sx={{ color: '#fff', mb: 2, pr: 2 }}>
          {getGreetings()},{' '}
          <Box component="span" sx={{ textTransform: 'capitalize' }}>
            {user?.name}
          </Box>
        </Typography>
        {user?.className && (
          <Typography variant="body2" sx={{ mb: 2, ml: 'auto' }}>
            Class: {user?.className}
          </Typography>
        )}
      </Box>
      {user?.belongsToPrimary ? <PrimaryStudent fromDefaultClass={user?.isFromDefaultClass} /> : <SecondaryStudent />}
    </Container>
  );
};

export default StudentDashboard;
