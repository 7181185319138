export const ROUTES = {
  ROOT: '/',
  BADGES: '/badges',
  JOURNALS: '/journals',
  LESSONS: '/lessons',
  STUDENTONBOARD: '/student-onboard',
  PASSWORD_CHANGE: '/password-change',
  MUICOMPONENTS: '/mui-components',
  CLASSES: '/classes',
  USERPROFILE: '/user-profile',
  CLASSDASHBOARD: '/classes/:classId',
  CONTACTUS: '/contact-us',
  TEACH: '/teach',
  APPLOGOUT: '/logout',
  TEACH_ARTICLE: '/teach/article/:id',
  TEACH_ARTICLE_URL: '/teach/article',
  ACTIVITY_FEED: '/activityfeed',
  SKILL_ASSESSMENT: '/skill-assessment',
  SUPPORT_MATERIALS: '/supporting-materials',
  SUPPORT_ARTICLE: '/supporting-materials/:id',
};

export const PALTFORM_NAME = 'Genplus';

export const PAGE_TITLE = {
  FOR_STUDENT: 'Student',
  FOR_TEACHER: 'Teacher',
  DASHBOARD: 'Dashboard',
  BOOSTER_BADGES: 'Booster Badges',
  COURSE_BADGES: 'Course Badges',
  JOURNALS: 'Journal',
  LESSONS: 'Lessons',
  STUDENT_ONBOARD: 'Student Onboard',
  PASSWORD_CHANGE: 'Change Password',
  CLASSES: 'Classes',
  USER_PROFILE: 'User Profile',
  CONTACTUS: 'Contact Us',
  TEACH: 'Teach',
  ACTIVITY_FEED: 'Activity Feed',
  SUPPORT_MATERIALS: 'Supporting Materials',
  NO_PAGE_FOUND: 'Page Not Found',
};

/*
============================================
  Genplus user's role from RMUnify
============================================
*/

export const ROLE = {
  STUDENT: 'Student',
  FACULTY: 'Faculty',
  AFFILIATE: 'Affiliate',
  EMPLOYEE: 'Employee',
  TEACHING_STAFF: 'TeachingStaff',
  NON_TEACHING_STAFF: 'NonTeachingStaff',
  XPORTER_TEACHING_STAFF: 'staff',
};

export const SCHOOL_LEVEL = {
  PRIMARY: 'Primary',
  SECONDARY: 'Secondary',
};

export const TEACHING_ROLES = [ROLE.TEACHING_STAFF, ROLE.NON_TEACHING_STAFF, ROLE.XPORTER_TEACHING_STAFF];

export const DASHBOARD_YOUTUBE_VIDEO = 'https://www.youtube.com/embed/IkX_TDx496g?si=9dhAWgwB63tZh4I9';

export const SUPPORT_EMAIL = 'support@genplus.co.uk';

export const INFO_EMAIL = 'info@genplus.com';

export const SCHOOLTYPES = {
  PRIVATE: 'Private',
  RMUNIFY: 'RmUnify',
};

export const SKILLS = {
  collaboration: {
    skill: 'Collaboration',
    scoreStartOfYear: 0,
    scoreEndOfYear: 0,
  },
  resilience: {
    skill: 'Resilience',
    scoreStartOfYear: 0,
    scoreEndOfYear: 0,
  },
  initiative: {
    skill: 'Initiative',
    scoreStartOfYear: 0,
    scoreEndOfYear: 0,
  },
  communication: {
    skill: 'Communication',
    scoreStartOfYear: 0,
    scoreEndOfYear: 0,
  },
  leadership: {
    skill: 'Leadership',
    scoreStartOfYear: 0,
    scoreEndOfYear: 0,
  },
  organisation: {
    skill: 'Organisation',
    scoreStartOfYear: 0,
    scoreEndOfYear: 0,
  },
};

export const PAGEINTROTEXT = {
  LESSON: `This is where the fun begins, it’s where you access your Gen+ lessons. Some may be locked,
            that’s fine, your teacher will unlock them when your class is ready to start learning. Let's go! `,

  COURSE_BADGE: `As you work through the Gen+ programme you will collect badges for completing units.
                  This page allows you to view what badges you have collected and what badges you still
                  need to collect before you complete the programme.`,

  BOOSTER_BADGE: `We think it’s right that you get a recognition for doing great things in Gen+ and
                  the wider school. When you do something good your teacher will give you a booster badge!
                  There are 8 to collect and you can view them all here. Why don’t you ask your teacher
                  if you can get one? That’s kind of like showing initiative, isn’t it?`,
};

export const SKILLASSESSMENTDESCRIPTION = `Your Meta-Skills Tracker helps you measure your 
                                                   progress in understanding meta-skills by assessing your confidence 
                                                   and knowledge at the start and end of each unit within the course.`;

export const CLASS_MESSAGES = {
  DEFAULT_CLASS: 'Students who are not added to a class can be found here.',
  JOIN_CLASS: 'Pupils can join this class by entering this code on their device',
  DONT_SEE_CLASS: `Don't see your class here?`,
  TEACHER_STUDENTS_LED: 'Teacher + students on devices',
  TEACHER_LED: 'Teacher-led only',
  NOT_STARTED: 'Not started',
  IN_PROGRESS: 'Incomplete',
  COMPLETE: 'Complete',
};

export const LEARNMORELINK = 'https://www.genplus.co.uk/teach/';

export const DEFAULT_CLASS = 'Pupil Pool';

export const PROGRESS_STATUS = {
  NOT_STARTED: 'not_started',
  IN_PROGRESS: 'in_progress',
  COMPLETED: 'completed',
  TEACHER_LED: 'teacher_led_only',
  TEACHER_AND_STUDENT: 'teacher_and_student',
};

export const YEAR_GROUPS = ['P6', 'P7', 'P6/P7', 'S1', 'S2', 'S3'];

export default SKILLS;
