import BackdropLinearProgress from 'components/BackdropLinearProgress';
import { useState } from 'react';
import { ExpandMore } from 'teach/styles';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Badge, Box, Collapse, Divider, Grid, Pagination, Stack, Typography } from '@mui/material';

import CpdCard from './CpdCard';

const ContentList = ({ libraryContent, favoriteArticles, pageNum, setPageNum, isFetchingArticles }) => {
  const [expanded, setExpanded] = useState(true);
  const [libraryExpanded, setLibraryExpanded] = useState(true);
  const handleExpandClick = () => setExpanded(!expanded);
  const handleLibraryExpandClick = () => setLibraryExpanded(!libraryExpanded);
  const handleChange = (event, value) => {
    setPageNum(value);
  };
  return (
    <Box mt={3}>
      <Divider />
      {favoriteArticles && (
        <>
          <Stack direction="row" alignItems="center" mt={3}>
            {favoriteArticles.length > 0 && (
              <ExpandMore expand={expanded} onClick={handleExpandClick} aria-expanded={expanded} aria-label="show more">
                <ExpandMoreIcon />
              </ExpandMore>
            )}

            <Typography variant="h6" component="h2" color="white">
              My List
            </Typography>

            <Badge badgeContent={favoriteArticles.length} sx={{ ml: 2 }} color="primary" />
          </Stack>
          {favoriteArticles.length === 0 && (
            <Box sx={{ mt: 2.5 }}>
              <Typography variant="subtitle2" textAlign="center">
                You haven’t saved any favourites yet!
                <br />
                To do so, click on a star in the list below.
              </Typography>
            </Box>
          )}
        </>
      )}

      <Collapse in={expanded} timeout="auto" sx={{ mt: 2 }}>
        <Grid container spacing={2} sx={{ position: 'relative' }}>
          {isFetchingArticles && <BackdropLinearProgress backdropProps={{ sx: { left: 16 } }} />}
          {favoriteArticles.map((articleData) => (
            <CpdCard key={articleData.id} isFavorite cpdData={articleData} isFetchingArticles={isFetchingArticles} />
          ))}
        </Grid>
      </Collapse>
      <Stack direction="row" alignItems="center" mt={3}>
        <ExpandMore
          expand={libraryExpanded}
          onClick={handleLibraryExpandClick}
          aria-expanded={libraryExpanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </ExpandMore>

        <Typography variant="h6" component="h2" color="white">
          Library
        </Typography>

        <Badge badgeContent={libraryContent.count} sx={{ ml: 2 }} color="primary" />
      </Stack>

      <Collapse in={libraryExpanded} timeout="auto" sx={{ mt: 2 }}>
        <Grid container spacing={2} sx={{ position: 'relative' }}>
          {isFetchingArticles && <BackdropLinearProgress backdropProps={{ sx: { left: 16 } }} />}
          {libraryContent.results.map((articleData) => (
            <CpdCard
              key={articleData.id}
              cpdData={articleData}
              isFetchingArticles={isFetchingArticles}
              totalFavoriteArticles={favoriteArticles?.length || 0}
            />
          ))}
        </Grid>

        <Stack mt={2.5} alignItems="Center">
          <Pagination count={libraryContent.numPages} size="large" page={pageNum} onChange={handleChange} />
        </Stack>
      </Collapse>
    </Box>
  );
};

export default ContentList;
