import { Grid, Link, Typography } from '@mui/material';
import Container from 'components/Container';
import { SUPPORT_EMAIL } from 'utils/values';

const ContactUs = () => (
  <Container>
    <Grid item xs={12}>
      <Typography variant="h3" mb={3}>
        Contact Us
      </Typography>
      <Typography mb={3}>
        Our aim at Gen+ is to provide every child, in every classroom, with a leadership and personal development
        programme that helps them to recognise and develop their unique skills and talents.
      </Typography>
      <Typography mb={3}>
        While we strive to achieve our aims we understand you may need to get in touch for support or require further
        information. Please use the information below to get in touch with a member of the team.
      </Typography>
      <Typography mb={3}>
        We have a FAQ section on our website which is a great place top start&nbsp;
        <Link href="https://www.genplus.co.uk/faq/" target="_blank">
          https://www.genplus.co.uk/faq/
        </Link>
      </Typography>
      <Typography mb={3}>
        You can obtain technical support for the Gen+ app by emailing the support team at&nbsp;
        <Link href={`mailto:${SUPPORT_EMAIL}`} target="_blank">
          {SUPPORT_EMAIL}
        </Link>
      </Typography>
      <Typography mb={3}>
        Or you can contact Genplus about operational queries about the programme at&nbsp;
        <Link href="mailto:hello@genplus.co.uk" target="_blank">
          hello@genplus.co.uk
        </Link>
      </Typography>
      <Typography mb={3}>
        General information about Genplus can be found on our website at&nbsp;
        <Link href="https://www.genplus.co.uk" target="_blank">
          https://www.genplus.co.uk
        </Link>
      </Typography>
    </Grid>
  </Container>
);

export default ContactUs;
