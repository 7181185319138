import { useEffect, useMemo, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { Link as RouterLink } from 'react-router-dom';

import NavigateNext from '@mui/icons-material/NavigateNext';
import {
  Breadcrumbs,
  CircularProgress,
  Container,
  Link,
  Paper,
  Stack,
  Table,
  TableBody,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';
import { ROUTES } from 'utils/values';
import { useGetActivityFeedQuery } from 'dashboard/dashboardApi';

import ActivityFeedNotification from './ActivityFeedNotification';

const ActivityFeedPage = () => {
  const [page, setPage] = useState(1);
  const [combinedData, setCombinedData] = useState([]);
  const { ref: lastElementRef, inView } = useInView();
  const {
    data: activityFeed,
    isLoading: isLoadingActivityFeed,
    isSuccess: isSuccessActivityFeed,
    isFetching: isFetchingActivityFeed,
  } = useGetActivityFeedQuery(page);
  const pageCount = useMemo(() => (activityFeed ? activityFeed.numPages : 1), [activityFeed]);
  useEffect(() => {
    if (activityFeed) {
      setCombinedData((previousData) => {
        const newIds = activityFeed.results.map((ac) => ac.id);
        const newRec = [...previousData.filter((ac) => newIds.indexOf(ac.id) === -1), ...activityFeed.results];
        return newRec;
      });
    }
  }, [activityFeed]);

  useEffect(() => {
    if (inView) setPage((prevPage) => (prevPage < pageCount ? prevPage + 1 : prevPage));
  }, [inView, pageCount]);

  return (
    <Container maxWidth="md">
      <Breadcrumbs separator={<NavigateNext />} aria-label="breadcrumbs">
        <Link underline="hover" key="1" color="inherit" component={RouterLink} to={ROUTES.ROOT}>
          Dashboard
        </Link>

        <Typography key="2" color="text.primary">
          Activity Feed
        </Typography>
      </Breadcrumbs>
      <Paper elevation={3} sx={{ mt: 4, '& :first-of-type': { border: 'none' } }}>
        <TableContainer component={Paper} elevation={2}>
          <Table aria-label="recent-activity-table">
            <TableBody>
              {isSuccessActivityFeed &&
                combinedData?.map((activity, i) => (
                  <TableRow
                    sx={{
                      '& td': { border: 'none' },
                      whiteSpace: 'nowrap',
                      borderTop: '1px solid #63d9ff4d',
                    }}
                    ref={i + 1 === combinedData.length ? lastElementRef : null}
                    key={activity.id}
                  >
                    <ActivityFeedNotification isNew={!activity.isRead} data={activity} />
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        {(isLoadingActivityFeed || isFetchingActivityFeed) && (
          <Stack alignItems="center" p={3}>
            <CircularProgress />
          </Stack>
        )}
      </Paper>
    </Container>
  );
};
export default ActivityFeedPage;
