import BackdropLinearProgress from 'components/BackdropLinearProgress';
import { useEffect, useMemo, useState } from 'react';
import { useGetContentFiltersQuery } from 'teach/teachApi';
import { debounce } from 'utils/utilityFunctions';

import SearchIcon from '@mui/icons-material/Search';
import {
  Box,
  Container,
  Grid,
  InputAdornment,
  MenuItem,
  Skeleton,
  Stack,
  TextField,
  Typography,
  capitalize,
  useMediaQuery,
  useTheme,
} from '@mui/material';

import CpdCard from './components/CpdCard';
import CpdCardSkeleton from './components/CpdCardSkeleton';
import { useGetSupportArticlesQuery } from './supportMaterialsApi';

const SupportMaterials = () => {
  const [search, setSearch] = useState('');
  const [type, setType] = useState('');

  const theme = useTheme();
  const isScreenLtSm = useMediaQuery(theme.breakpoints.down('sm'));

  const handleSearchChange = (event) => setSearch(event.target.value);
  const debouncedSearch = useMemo(() => debounce(handleSearchChange), []);
  useEffect(() => () => debouncedSearch.cancel(), [debouncedSearch]);

  const { data: filters, isLoading: isLoadingFilters, isSuccess: IsSuccessFilters } = useGetContentFiltersQuery();
  const {
    data: articlesGroup,
    isLoading,
    isFetching,
    isSuccess,
  } = useGetSupportArticlesQuery({
    search,
    media_type: type === 'All' ? '' : type,
  });

  let noArticles = true;

  return (
    <Container maxWidth="md">
      <Typography variant="h5" color="white">
        Supporting Materials
      </Typography>

      <Stack direction="row" gap={2} flexWrap="wrap" sx={{ mt: 3 }} justifyContent="space-between">
        <TextField
          id="search-supporting-materials"
          placeholder="Search..."
          size="small"
          variant="outlined"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          onChange={debouncedSearch}
          fullWidth={isScreenLtSm}
        />

        <TextField
          select
          id="select-type-filter"
          label="Filter by type"
          size="small"
          value={type}
          onChange={(e) => setType(e.target.value)}
          sx={{ minWidth: 195 }}
          fullWidth={isScreenLtSm}
        >
          <MenuItem value="All">
            <em>All</em>
          </MenuItem>
          {isLoadingFilters && <MenuItem disabled>Loading...</MenuItem>}
          {IsSuccessFilters &&
            filters.mediaTypes?.map((mediaType) => (
              <MenuItem key={mediaType.id} value={mediaType.id}>
                {capitalize(mediaType.name)}
              </MenuItem>
            ))}
        </TextField>
      </Stack>

      {isLoading &&
        [1, 2].map((boxId) => (
          <Box mt={5} key={boxId}>
            <Typography variant="h6">
              <Skeleton width={180} />
            </Typography>

            <Grid container mt={1} spacing={2}>
              {[1, 2, 3, 4].map((itemId) => (
                <Grid item xs={12} xss={6} smm={4} md={3} key={itemId}>
                  <CpdCardSkeleton />
                </Grid>
              ))}
            </Grid>
          </Box>
        ))}

      <Box mt={5} sx={{ position: 'relative' }}>
        {isFetching && !isLoading && <BackdropLinearProgress />}

        {isSuccess &&
          articlesGroup.map((group) => {
            if (!group.helpGuides.length) return null;

            noArticles = false;

            return (
              <Box mt={5} key={group.id}>
                <Typography variant="h6">{group.title}</Typography>
                <Grid container mt={1} spacing={2}>
                  {group.helpGuides.map((article) => (
                    <Grid item xs={12} xss={6} smm={4} md={3} key={article.id}>
                      <CpdCard article={article} />
                    </Grid>
                  ))}
                </Grid>
              </Box>
            );
          })}

        {isSuccess && noArticles && (
          <Typography variant="h4" textAlign="center">
            No Articles Found
          </Typography>
        )}
      </Box>
    </Container>
  );
};

export default SupportMaterials;
