import baseApiSlice from 'api/baseApiSlice';
import { GENZ_LEARNING_API_URL } from 'config';

const lessonsApiSlice = baseApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getLessons: builder.query({
      query: () => ({
        url: `${GENZ_LEARNING_API_URL}/lessons/`,
      }),
    }),
  }),
});

export const { useGetLessonsQuery } = lessonsApiSlice;

export default lessonsApiSlice;
