import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Box, LinearProgress, Typography } from '@mui/material';

const LinearProgressWithLabel = (props) => (
  <Box sx={{ display: 'flex', alignItems: 'center' }}>
    <Box sx={{ minWidth: 35 }}>
      <Typography variant="body2">{`${Math.round(props.value)}%`}</Typography>
    </Box>
    <Box sx={{ width: '100%', mx: 1 }}>
      <LinearProgress variant="determinate" {...props} />
    </Box>
    <Box sx={{ minWidth: 20 }}>{props.value === 100 ? <CheckCircleIcon color="success" fontSize="10" /> : ''}</Box>
  </Box>
);

export default LinearProgressWithLabel;
