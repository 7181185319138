import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { CardActionArea, CardContent, CardHeader, Chip, Stack, Typography, capitalize } from '@mui/material';
import { Link, generatePath } from 'react-router-dom';
import { CpdCardContainer } from 'teach/styles';
import { ROUTES } from 'utils/values';

const CpdCard = ({ article }) => (
  <CpdCardContainer elevation={3}>
    <CardActionArea LinkComponent={Link} to={generatePath(ROUTES.SUPPORT_ARTICLE, { id: article.id })}>
      <CardHeader
        title={article.title}
        titleTypographyProps={{
          variant: 'caption',
          sx: {
            display: '-webkit-box',
            WebkitLineClamp: 2,
            WebkitBoxOrient: 'vertical',
            overflow: 'hidden',
            height: `${2 * 0.75 * 16 * 1.66}px`, // number of lines * font-size * 1rem * line-height
          },
        }}
      />

      <CardContent sx={{ pt: 0 }}>
        <Stack direction="row" flexWrap="wrap" gap={0.5} minHeight={60}>
          {article.mediaTypes.map((type) => (
            <Chip
              size="small"
              label={capitalize(type.name)}
              sx={{ fontSize: '0.625rem', m: '2px 4px 2px -2px' }}
              key={type.id}
            />
          ))}
        </Stack>

        <Stack direction="row" alignItems="center" gap={1} mt={1}>
          <AccessTimeIcon fontSize="0.75rem" sx={{ color: 'text.disabled' }} />
          <Typography varaint="caption" color="text.disabled" fontSize="0.75rem">
            {article.time} Min
          </Typography>
        </Stack>
      </CardContent>
    </CardActionArea>
  </CpdCardContainer>
);

export default CpdCard;
