// import { unitCompleteBadge } from 'assets/images/badges';
// import { ReactComponent as Trophy } from 'assets/images/badges/trophy.svg';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { Box, Grid, Stack, Typography, alpha, useTheme } from '@mui/material';

const CourseBadges = ({ badges }) => {
  const theme = useTheme();
  const borderColor = alpha(theme.palette.primary.dark, 0.4);
  const listItemBgColor = alpha(theme.palette.primary.dark, 0.1);

  return (
    <Grid container rowSpacing={8} columnSpacing={6} px={{ xs: 2, sm: 4 }}>
      {!badges.length && (
        <Grid item xs={12}>
          <Typography variant="h4" textAlign="center">
            No Results Found!
          </Typography>
        </Grid>
      )}

      {badges.map((program) => (
        <Grid item xs={12} sm={6} key={program.slug}>
          <Stack direction="row" alignItems="center" gap={2} pb={2}>
            <Box component="img" height={40} src={program.imageUrl} alt="Gen Plus" />
            <Typography sx={{ flexGrow: 1 }} variant="h6" component="h2">
              {program.displayName}
            </Typography>
          </Stack>
          <Box border={1} borderColor={borderColor} borderRadius={2}>
            {program?.unitBadges.map((badge) => (
              <Stack
                key={badge.slug}
                direction="row"
                alignItems="center"
                gap={2}
                sx={{ p: 1, '&:nth-of-type(odd)': { backgroundColor: listItemBgColor } }}
              >
                <Box component="img" height={40} src={badge.imageUrl} alt="Gen Plus" />
                <Box sx={{ flexGrow: 1 }}>
                  <Typography variant="body2">{badge.displayName}</Typography>
                </Box>
                {badge.awarded ? (
                  <CheckCircleIcon sx={{ size: '20', color: 'success.dark' }} />
                ) : (
                  <RemoveCircleIcon sx={{ size: '20', color: 'warning.dark' }} />
                )}
              </Stack>
            ))}

            <Stack
              key={program.slug}
              direction="row"
              alignItems="center"
              gap={2}
              sx={{ p: 1, '&:nth-of-type(odd)': { backgroundColor: listItemBgColor } }}
            >
              <Box component="img" height={40} src={program.imageUrl} alt="Gen Plus" />
              <Box sx={{ flexGrow: 1 }}>
                <Typography variant="body2">{program.displayName}</Typography>
              </Box>
              {program.awarded ? (
                <CheckCircleIcon sx={{ size: '20', color: 'success.dark' }} />
              ) : (
                <RemoveCircleIcon sx={{ size: '20', color: 'warning.dark' }} />
              )}
            </Stack>
          </Box>
        </Grid>
      ))}
    </Grid>
  );
};

export default CourseBadges;
