import orgImg from 'assets/images/bg-organization.png';

import { Box, Button, Typography } from '@mui/material';

import { NextLessonContainer, NextLessonImageContainer } from '../styles';

const availableDescription = `When prompted by your teacher, join your next Gen+ lesson by clicking ‘Let's Go’!`;
const notAvailableDescription = `There seems to be no lessons for you to complete next.`;

const NextLesson = ({ url, unitName, lessonName }) => {
  const description = url ? availableDescription : notAvailableDescription;
  let displayName = 'Not Available';
  if (unitName && lessonName) {
    displayName = `${unitName} - ${lessonName}`;
  }
  return (
    <NextLessonContainer elevation={3}>
      <NextLessonImageContainer src={orgImg} />

      <Box zIndex={10} position="relative">
        <Typography variant="subtitle1">Next lesson</Typography>
        <Typography variant="h6">{displayName}</Typography>
        <Typography variant="body2" mt={2}>
          {description}
        </Typography>
        <Button variant="outlined" sx={{ marginTop: 5, marginBottom: 4 }} href={url} disabled={!url}>
          Let&apos;s go
        </Button>
      </Box>
    </NextLessonContainer>
  );
};
export default NextLesson;
