import { memo } from 'react';
import { Link, generatePath } from 'react-router-dom';
import { CLASS_MESSAGES, ROUTES } from 'utils/values';

import { Info as InfoIcon, Person as PersonIcon } from '@mui/icons-material';
import { Avatar, Button, IconButton, Stack, TableCell, TableRow, Typography } from '@mui/material';

import StyledClassRowTooltip from '../styles';

const DefaultClassRow = ({ defaultClass }) => (
  <TableRow
    sx={{
      '&:last-child td': { border: 0 },
      '& td': { borderColor: 'divider' },
      whiteSpace: 'nowrap',
    }}
  >
    <TableCell>
      <Stack direction="row" alignItems="center" gap={2}>
        <Avatar sx={{ backgroundColor: 'primary.main' }}>
          <PersonIcon />
        </Avatar>
        <Stack>
          <Typography noWrap variant="caption" sx={{ color: 'text.disabled' }}>
            Class Name
          </Typography>
          <Typography sx={{ color: 'white' }}>
            {defaultClass?.name}
            <StyledClassRowTooltip title={CLASS_MESSAGES?.DEFAULT_CLASS} placement="top" arrow>
              <IconButton size="small">
                <InfoIcon sx={{ color: 'text.disabled', size: 14 }} />
              </IconButton>
            </StyledClassRowTooltip>
          </Typography>
        </Stack>
      </Stack>
    </TableCell>

    <TableCell>
      <Stack sx={{ alignItems: 'flex-end' }}>
        <Button
          variant="outlined"
          size="small"
          LinkComponent={Link}
          sx={{ width: 100 }}
          to={generatePath(ROUTES.CLASSDASHBOARD, { classId: defaultClass?.id })}
        >
          View
        </Button>
      </Stack>
    </TableCell>
  </TableRow>
);

export default memo(DefaultClassRow);
