import { useGetRecentAwardedBadgesQuery } from 'badges/badgesApi';
import CreateAvatar from 'components/CreateAvatar';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useGetUserInfoQuery } from 'users/userApi';
import { ROUTES, SUPPORT_EMAIL } from 'utils/values';

import ArticleIcon from '@mui/icons-material/Article';
import GroupIcon from '@mui/icons-material/Group';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import MenuIcon from '@mui/icons-material/Menu';
import MessageIcon from '@mui/icons-material/Message';
import SchoolIcon from '@mui/icons-material/School';
import StarsIcon from '@mui/icons-material/Stars';
import {
  AppBar,
  Box,
  Button,
  Container,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  Toolbar,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';

import Logo from './Logo';
import { MenuLink } from './MenuLink';
import { NavLink } from './NavLink';
import { StyledProfileItem } from './styles';

const Header = () => {
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [anchorElHelp, setAnchorElHelp] = useState(null);
  const [anchorElGenTeach, setAnchorElGenTeach] = useState(null);
  const handleGenTeachClick = (event) => {
    setAnchorElGenTeach(event.currentTarget);
  };

  const handleGenTeachClose = () => {
    setAnchorElGenTeach(null);
  };

  const { data: user = {} } = useGetUserInfoQuery();
  const theme = useTheme();
  const isScreenLtSm = useMediaQuery(theme.breakpoints.down('xss'));
  const { refetch } = useGetRecentAwardedBadgesQuery(null, { skip: user?.isTeacher });

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleOpenHelpMenu = (event) => {
    setAnchorElHelp(event.currentTarget);
  };

  const handleCloseHelpMenu = () => {
    setAnchorElHelp(null);
  };

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleRefetchRecentBadges = () => {
    if (user.isStudent) {
      refetch();
    }
    setAnchorElNav(null);
  };

  return (
    <AppBar position="static">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Box sx={{ mr: 2, display: { xs: 'none', md: 'flex' } }}>
            <Logo onClick={handleRefetchRecentBadges} />
          </Box>
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {user.isTeacher && (
                <MenuLink onClick={handleCloseNavMenu} to={ROUTES.CLASSES}>
                  <GroupIcon sx={{ fontSize: 18, mr: 1 }} />
                  <Typography component="span" sx={{ fontSize: 13 }}>
                    Classes
                  </Typography>
                </MenuLink>
              )}
              {!(user.isStudent && user.belongsToPrimary) && (
                <MenuLink to={ROUTES.LESSONS} onClick={handleRefetchRecentBadges}>
                  <SchoolIcon sx={{ fontSize: 18, mr: 1 }} />
                  <Typography component="span" sx={{ fontSize: 13 }}>
                    Lessons
                  </Typography>
                </MenuLink>
              )}
              {user.isTeacher && <Divider sx={{ my: 0.5 }} />}
              {user.isTeacher && (
                <MenuLink onClick={handleCloseNavMenu} to={`${ROUTES.TEACH}?activeTab=0`}>
                  <Typography component="span" sx={{ fontSize: 13 }}>
                    Content Library
                  </Typography>
                </MenuLink>
              )}
              {user.isTeacher && (
                <MenuLink onClick={handleCloseNavMenu} to={`${ROUTES.TEACH}?activeTab=1`}>
                  <Typography component="span" sx={{ fontSize: 13 }}>
                    My Portfolio
                  </Typography>
                </MenuLink>
              )}
              {user.isTeacher && (
                <MenuLink onClick={handleCloseNavMenu} to={ROUTES.SUPPORT_MATERIALS}>
                  <Typography component="span" sx={{ fontSize: 13 }}>
                    Supporting Materials
                  </Typography>
                </MenuLink>
              )}
              {user.isStudent && (
                <MenuLink to={ROUTES.JOURNALS} onClick={handleRefetchRecentBadges}>
                  <ArticleIcon sx={{ fontSize: 18, mr: 1 }} />
                  <Typography component="span" sx={{ fontSize: 13 }}>
                    My Journal
                  </Typography>
                </MenuLink>
              )}
              {user.isStudent && (
                <MenuLink to={ROUTES.BADGES} onClick={handleRefetchRecentBadges}>
                  <ArticleIcon sx={{ fontSize: 18, mr: 1 }} />
                  <Typography component="span" sx={{ fontSize: 13 }}>
                    Badges
                  </Typography>
                </MenuLink>
              )}
            </Menu>
          </Box>
          <Box sx={{ display: { xs: 'flex', md: 'none' }, flexGrow: 1 }}>
            <Logo />
          </Box>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {user.isTeacher && (
              <NavLink to={ROUTES.CLASSES}>
                <GroupIcon sx={{ fontSize: 18, mr: 1 }} />
                <Typography component="span" sx={{ fontSize: 13 }}>
                  Classes
                </Typography>
              </NavLink>
            )}
            {!(user.isStudent && user.belongsToPrimary) && (
              <NavLink to={ROUTES.LESSONS} onClick={handleRefetchRecentBadges}>
                <SchoolIcon sx={{ fontSize: 18, mr: 1 }} />
                <Typography component="span" sx={{ fontSize: 13 }}>
                  Lessons
                </Typography>
              </NavLink>
            )}
            {user.isTeacher && (
              <>
                <Button
                  component="a"
                  onClick={handleGenTeachClick}
                  sx={{
                    my: 1,
                    mr: 1,
                    color: 'text.disabled',
                    ':hover': { color: 'text.primary' },
                  }}
                >
                  <ArticleIcon sx={{ fontSize: 18, mr: 1 }} />
                  <Typography component="span" sx={{ fontSize: 13 }}>
                    Gen+ teach
                  </Typography>
                  <KeyboardArrowDownIcon />
                </Button>
                <Menu
                  id="gen-teach-menu"
                  MenuListProps={{
                    'aria-labelledby': 'gen-teach-button',
                  }}
                  anchorEl={anchorElGenTeach}
                  open={Boolean(anchorElGenTeach)}
                  onClose={handleGenTeachClose}
                >
                  <MenuItem component={Link} to={`${ROUTES.TEACH}?activeTab=0`} onClick={handleGenTeachClose}>
                    Content Library
                  </MenuItem>
                  <MenuItem component={Link} to={`${ROUTES.TEACH}?activeTab=1`} onClick={handleGenTeachClose}>
                    My Portfolio
                  </MenuItem>
                  <MenuItem component={Link} to={ROUTES.SUPPORT_MATERIALS} onClick={handleGenTeachClose}>
                    Supporting Materials
                  </MenuItem>
                </Menu>
              </>
            )}
            {user.isStudent && (
              <NavLink to={ROUTES.JOURNALS} onClick={handleRefetchRecentBadges}>
                <MessageIcon sx={{ fontSize: 18, mr: 1 }} />
                <Typography component="span" sx={{ fontSize: 13 }}>
                  My Journal
                </Typography>
              </NavLink>
            )}
            {user.isStudent && (
              <NavLink to={ROUTES.BADGES} onClick={handleRefetchRecentBadges}>
                <StarsIcon sx={{ fontSize: 18, mr: 1 }} />
                <Typography component="span" sx={{ fontSize: 13 }}>
                  Badges
                </Typography>
              </NavLink>
            )}
          </Box>
          <Box sx={{ display: 'flex', gap: 0.5, flexGrow: 0 }}>
            {user.isTeacher && (
              <Tooltip title="Open Help Menu">
                <IconButton
                  aria-label="account of current user"
                  aria-controls="menu-help"
                  aria-haspopup="true"
                  sx={{ color: '#96b0b7' }}
                  onClick={handleOpenHelpMenu}
                >
                  <HelpOutlineIcon />
                </IconButton>
              </Tooltip>
            )}
            <Tooltip title="Open settings">
              <IconButton
                onClick={handleOpenUserMenu}
                id="menu-profile"
                aria-label="account of current user"
                aria-controls="menu-profile"
                aria-haspopup="true"
                sx={{ p: 0, mr: 1 }}
              >
                <CreateAvatar {...user} />
              </IconButton>
            </Tooltip>
            <Stack display="inline-flex">
              <Typography
                component="span"
                sx={{
                  fontSize: 13,
                  color: 'text.primary',
                  mr: 1,
                }}
              >
                {user.firstName ? user.firstName : user.username}
              </Typography>
              {!isScreenLtSm && (
                <Typography variant="caption" fontWeight={500} fontSize="0.725rem" color="text.secondary">
                  {user.school}
                </Typography>
              )}
            </Stack>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-profile"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              <MenuItem onClick={handleCloseUserMenu} to={ROUTES.USERPROFILE} component={Link}>
                <Typography textAlign="center">Profile</Typography>
              </MenuItem>
              <MenuItem onClick={handleCloseUserMenu} to={ROUTES.APPLOGOUT} component={Link}>
                <Typography textAlign="center">Logout</Typography>
              </MenuItem>
            </Menu>
            {user.isTeacher && (
              <Menu
                id="menu-help"
                sx={{ mt: '45px' }}
                anchorEl={anchorElHelp}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                open={Boolean(anchorElHelp)}
                onClose={handleCloseHelpMenu}
              >
                <StyledProfileItem>
                  <Typography sx={{ fontSize: 14, color: 'text.primary' }}>Need help?</Typography>
                </StyledProfileItem>
                <StyledProfileItem>
                  <List sx={{ py: 0, pl: 2, listStyle: 'disc', color: 'text.secondary' }}>
                    <ListItem sx={{ display: 'list-item', px: 0, pt: 0 }}>
                      <ListItemText secondary="Contact us at:" />
                      <Button
                        onClick={handleCloseHelpMenu}
                        href={`mailto:${SUPPORT_EMAIL}`}
                        size="small"
                        component="a"
                        target="_blank"
                      >
                        {SUPPORT_EMAIL}
                      </Button>
                    </ListItem>
                    <ListItem sx={{ display: 'list-item', px: 0, pt: 0 }}>
                      <ListItemText secondary="Check out our" />
                      <Button
                        variant="outlined"
                        onClick={handleCloseHelpMenu}
                        to={ROUTES.SUPPORT_MATERIALS}
                        size="small"
                        component={Link}
                      >
                        Help Guides
                      </Button>
                    </ListItem>
                  </List>
                </StyledProfileItem>
              </Menu>
            )}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default Header;
