import { Navigate } from 'react-router-dom';
import { useGetUserInfoQuery } from 'users/userApi';
import { ROUTES } from 'utils/values';

const RequirePasswordChange = ({ children }) => {
  const { data: user } = useGetUserInfoQuery();

  if (user && !user.hasChangedPassword) {
    return <Navigate to={ROUTES.PASSWORD_CHANGE} />;
  }

  return children;
};

export default RequirePasswordChange;
