import UploadIcon from '@mui/icons-material/Upload';
import Button from '@mui/material/Button';

const FileUploadButton = ({ setProfile, setImageSrc }) => {
  const handleInputChange = (e) => {
    const file = e.target.files[0];
    if (!file) {
      // eslint-disable-next-line no-alert
      alert('No file selected.');
      return;
    }

    // Check if the file is an image
    if (!file.type.startsWith('image/')) {
      // eslint-disable-next-line no-alert
      alert('Please select a valid image file.');
      return;
    }

    try {
      const objectUrl = URL.createObjectURL(file);
      setProfile(file);
      setImageSrc(objectUrl);
    } catch (error) {
      console.error('Failed to read the file:', error);
      // eslint-disable-next-line no-alert
      alert('Failed to load the image.');
    }
  };

  return (
    <label htmlFor="contained-button-file">
      <input
        accept="image/*"
        id="contained-button-file"
        type="file"
        onChange={(e) => handleInputChange(e)}
        style={{ position: 'absolute', opacity: 0 }}
      />
      <Button variant="text" component="span" style={{ margin: '10px' }}>
        <UploadIcon />
        UPLOAD IMAGE
      </Button>
    </label>
  );
};

export default FileUploadButton;
