import AddAlertIcon from '@mui/icons-material/AddAlert';
import { Button, Stack, Typography, useTheme } from '@mui/material';

const Notification = ({ type, message, link }) => {
  const theme = useTheme();

  const borderColors = {
    info: theme.palette.info.dark,
    warning: theme.palette.warning.dark,
    success: theme.palette.success.dark,
    error: theme.palette.error.dark,
  };

  return (
    <Stack
      border="1px solid"
      borderColor={borderColors[type]}
      borderRadius={1}
      p={1}
      direction="row"
      alignItems="center"
      gap={1}
      flexWrap="wrap"
    >
      <AddAlertIcon color={type} />
      <Typography variant="caption">{message}</Typography>
      <Button size="small" sx={{ ml: 'auto' }} href={link} target="_blank">
        Start
      </Button>
    </Stack>
  );
};

export default Notification;
