import { nanoid } from '@reduxjs/toolkit';
import { useGetReflectionResponsesQuery } from 'classes/classApi';
import { useParams } from 'react-router-dom';

import {
  Box,
  Button,
  Dialog,
  Skeleton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';

import { StyledTableRow } from '../../styles';

const ReflectionModal = ({ handleCloseViewAnswers, openViewAnswers, questionTitle, programId, isLikert }) => {
  const { classId } = useParams();
  const problemType = isLikert ? 2 : 1;
  const theme = useTheme();
  const isScreenLtSm = useMediaQuery(theme.breakpoints.down('sm'));
  const buttonSize = () => (isScreenLtSm ? 'small' : 'medium');

  const { data, isSuccess, isLoading } = useGetReflectionResponsesQuery({ programId, problemType, classId });

  let users = {};

  const prePareUsers = ({ results }) => {
    results.forEach(({ skill, submissions }) => {
      Object.entries(submissions).forEach(([userName, value]) => {
        if (!(userName in users)) {
          users = {
            ...users,
            [userName]: {
              ...users[userName],
              name: value.name,
              results: {
                [skill]: {
                  skill,
                  ...value,
                },
              },
            },
          };
        }
        if (!(skill in users[userName]?.results)) {
          users = {
            ...users,
            [userName]: {
              ...users[userName],
              name: value.name,
              results: {
                ...users[userName].results,
                [skill]: {
                  skill,
                  ...users[userName].results[skill],
                  ...value,
                },
              },
            },
          };
        }
      });
    });
  };

  if (isSuccess) {
    prePareUsers(data);
  }

  const skeletonRow = (
    <StyledTableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
      <TableCell padding="normal">
        <Skeleton />
      </TableCell>
      <TableCell padding="normal">
        <Skeleton />
      </TableCell>
      <TableCell padding="normal">
        <Skeleton />
      </TableCell>
      {isLikert && (
        <TableCell padding="normal">
          <Skeleton />
        </TableCell>
      )}
    </StyledTableRow>
  );

  const RenderRows = (skill, even) => {
    let name = '';
    const rows =
      Object.values(skill?.results).length > 0 &&
      Object.values(skill?.results).map((user) => {
        const row = (
          <TableRow key={nanoid()} sx={{ background: even ? '' : 'rgba(99, 217, 255, 0.08)' }}>
            {name !== user?.name && <TableCell rowSpan={Object.keys(skill?.results).length}>{user?.name}</TableCell>}
            <TableCell>{user?.skill}</TableCell>
            <TableCell>{user?.intro?.responseText}</TableCell>
            {isLikert && <TableCell>{user?.outro?.responseText}</TableCell>}
          </TableRow>
        );
        name = user?.name;
        return row;
      });
    return rows;
  };

  const rows = (
    <>
      {skeletonRow}
      {skeletonRow}
      {skeletonRow}
    </>
  );

  return (
    <Dialog
      aria-labelledby="Skill Reflection modal"
      aria-describedby="A modal window for skill Reflection of student(s)"
      open={openViewAnswers}
      onClose={handleCloseViewAnswers}
      fullWidth
      maxWidth="md"
      fullScreen={isScreenLtSm}
      PaperProps={{ elevation: 4 }}
    >
      <Box mt={{ xs: 1, sm: 3 }} mb={{ xs: 2, sm: 5 }} px={{ xs: 3, sm: 6 }}>
        <Box>
          <Box sx={{ flexGrow: 1, mb: 3 }}>
            <Typography variant="body1">{questionTitle}</Typography>
            <Typography variant="body2" sx={{ opacity: 0.7 }} hidden>
              students?.availableResponses / students?.totalRespones Responses
            </Typography>
          </Box>
        </Box>
        <Box>
          <TableContainer>
            <Table sx={{ minWidth: 650 }} aria-label="simple table" size="small">
              <TableHead>
                <TableRow>
                  <TableCell width="20%">Name</TableCell>
                  <TableCell width="20%">Skill</TableCell>
                  {isLikert ? (
                    <>
                      <TableCell width="30%">Start of Year</TableCell>
                      <TableCell width="30%">End of Year</TableCell>
                    </>
                  ) : (
                    <TableCell width="60%">Response</TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.values(users).length > 0 &&
                  Object.values(users).map((skill, index) => RenderRows(skill, index % 2 === 0))}
                {isLoading && rows}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
      <Stack direction="row" justifyContent="space-between" px={{ xs: 3, sm: 6 }} pb={6}>
        <Button onClick={handleCloseViewAnswers} variant="outlined" size={buttonSize()}>
          Close
        </Button>
      </Stack>
    </Dialog>
  );
};

export default ReflectionModal;
