import { createContext, useContext, useReducer } from 'react';

const ClassTabsContext = createContext();

export const CLASS_TABS_ACTIONS = {
  SHOW_SUMMARY: 'SHOW_SUMMARY',
  SHOW_REFLECTIONS: 'SHOW_REFLECTIONS',
  SHOW_ANSWERS: 'SHOW_ANSWERS',
  SHOW_JOURNALS: 'SHOW_JOURNALS',
  SHOW_BADGES: 'SHOW_BADGES',
  RESET_TABS: 'RESET_TABS',
};

const tabsInitialState = {
  activeMainTab: 0,
  studentId: -1,
  userId: 'all',
};

const tabsReducer = (state, action) => {
  const newState = {
    ...state,
    studentId: action?.studentId || tabsInitialState.studentId,
    userId: action?.userId || tabsInitialState.userId,
  };
  switch (action?.type) {
    case CLASS_TABS_ACTIONS.SHOW_SUMMARY:
      return { ...newState, activeMainTab: 0 };

    case CLASS_TABS_ACTIONS.SHOW_JOURNALS:
      return { ...newState, activeMainTab: 1 };

    case CLASS_TABS_ACTIONS.SHOW_ANSWERS:
      return action.belongsToPrimary ? null : { ...newState, activeMainTab: 2 };

    case CLASS_TABS_ACTIONS.SHOW_REFLECTIONS:
      return { ...newState, activeMainTab: action.belongsToPrimary ? 2 : 3 };

    case CLASS_TABS_ACTIONS.SHOW_BADGES:
      return { ...newState, activeMainTab: action.belongsToPrimary ? 3 : 4 };

    default:
      throw new Error(`Tabs Reducer: Invalid action type (${action?.type})`);
  }
};
export const ClassTabsProvider = ({ children }) => {
  const [activeTab, dispatch] = useReducer(tabsReducer, tabsInitialState);

  return <ClassTabsContext.Provider value={{ activeTab, dispatch }}>{children}</ClassTabsContext.Provider>;
};

export const useTabs = () => {
  const context = useContext(ClassTabsContext);
  if (!context) {
    throw new Error('useTabs must be used within a ClassTabsProvider');
  }
  return context;
};
