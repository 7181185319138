import { nanoid } from '@reduxjs/toolkit';
import { useState } from 'react';

import {
  Box,
  Button,
  Dialog,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { StyledCount, StyledTableRow } from 'classes/classDashboard/styles';

import AnswersModal from './AnswerModal';
import AnswersCard from './AnswersCard';

const AggregateShortAnswer = ({ problem, studentsCount, studentsAnsweredCount, index, ...rest }) => {
  const theme = useTheme();
  const isScreenLtSm = useMediaQuery(theme.breakpoints.down('sm'));
  const buttonSize = () => (isScreenLtSm ? 'small' : 'medium');
  const [openViewAnswers, setOpenViewAnswers] = useState(false);
  const handleCloseViewAnswers = () => setOpenViewAnswers(false);
  const handleOpenViewAnswers = () => setOpenViewAnswers(true);

  const { questionText, answers } = problem;

  const rows = Object.values(answers).map((problemUnit) => (
    <StyledTableRow key={nanoid()}>
      <TableCell sx={{ border: 0, verticalAlign: 'top' }}>{problemUnit.fullName}</TableCell>
      <TableCell sx={{ border: 0, verticalAlign: 'top' }}>{problemUnit.answer}</TableCell>
    </StyledTableRow>
  ));

  return (
    <AnswersCard
      key={problem?.problemId}
      title={questionText}
      index={index}
      studentsCount={studentsCount}
      studentsAnsweredCount={studentsAnsweredCount}
    >
      <Box sx={{ py: 4 }}>
        <TableContainer>
          <Table sx={{ minWidth: 600 }} aria-label="Skills" size="small">
            <TableHead>
              <TableRow>
                <TableCell sx={{ border: 0 }} width="25%">
                  <Typography component="span" variant="body1" sx={{ opacity: 0.7 }}>
                    Name
                  </Typography>
                </TableCell>
                <TableCell sx={{ border: 0 }} width="75%">
                  <Typography component="span" variant="body1" sx={{ opacity: 0.7 }}>
                    Response
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{rows}</TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Button variant="text" sx={{ textDecoration: 'underline' }} onClick={handleOpenViewAnswers}>
        View All Answers
      </Button>
      <Dialog
        aria-labelledby="Student Answers modal"
        aria-describedby="A modal window for Student Answers"
        open={openViewAnswers}
        onClose={handleCloseViewAnswers}
        fullWidth
        maxWidth="md"
        fullScreen={isScreenLtSm}
        PaperProps={{ elevation: 4 }}
      >
        <Box mt={{ xs: 1, sm: 3 }} mb={{ xs: 2, sm: 5 }} px={{ xs: 0, sm: 6 }}>
          <Box sx={{ pl: 4, position: 'relative' }}>
            <StyledCount variant="body1" component="span">
              {index}.
            </StyledCount>
            <Box sx={{ flexGrow: 1 }}>
              <Typography variant="body1">{problem?.questionText}</Typography>
            </Box>
          </Box>
          <Box>
            <AnswersModal {...rest} />
          </Box>
        </Box>
        <Stack direction="row" justifyContent="space-between" px={{ xs: 0, sm: 6 }} pb={6}>
          <Button onClick={handleCloseViewAnswers} variant="outlined" size={buttonSize()}>
            Close
          </Button>
        </Stack>
      </Dialog>
    </AnswersCard>
  );
};

export default AggregateShortAnswer;
